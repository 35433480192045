import { BaseEntity } from '../../xform-compat';
import { Permission } from './permission.model';
import { BasicUser } from './basicUser.model';
import { Searchable } from './interfaces/searchable.interface';


export class Role implements BaseEntity, Searchable {
  id: number;
  name: string;
  description: string;
  active: boolean;
  permissions: Array<Permission>;
  timesApplied: number;
  noOfPermissions: number;
  createdBy: BasicUser;
  updated: string;
  created: string;

  constructor(role: any) {
    this.id = role.id;
    this.name = role.name;
    this.description = role.description;
    this.active = role.active;
    this.permissions = role.permissions ? role.permissions : [];
    this.timesApplied = role.timesApplied;
    this.noOfPermissions = role.noOfPermissions;
    this.createdBy = role.createdBy;
  }

  public getDisplayName() {
    return this.name;
  }
}

