import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Image } from '../../shared/models/image.model';
import { AbstractCRUD, CRUDService, ExceptionService, UtilsService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';


@Injectable()
export class AlignService extends AbstractCRUD<Image> implements CRUDService<Image> {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/bundle/service';
  }

  private getAlignUploadPath(mapsManagerVersionId: number) {
    return super.getApiPath().concat(`/align/${mapsManagerVersionId}`);
  }

  private postRequest(apiUrl: string, body: any) {
    return this.getHTTP().post(apiUrl, body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }


  public uploadFile(mapsManagerVersionId: number, path: Blob) {
    const formData = new FormData();
    formData.append('file', path);
    return this.postRequest(this.getAlignUploadPath(mapsManagerVersionId), formData);
  }
}
