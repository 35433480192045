import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthoringIntegrationService } from '../../services/authoringIntegration.service';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { LegendItem } from '../../models/legendItem.model';
import { ImageService } from '../../../core/data-services/image.service';
import { Image } from '../../models/image.model';
import { TranslateService } from '@ngx-translate/core';
import { LegendItemService } from '../../../core/data-services/legendItem.service';
import { environment } from '../../../../environments/environment';
import { CreateLegendResource } from '../../models/resources/createLegendResource.model';
import { HttpParams } from '@angular/common/http';
import { GlobalIntegrationServices } from '../../services/globalIntegration.services';
import { BannerNotificationsService, UtilsService } from '../../../xform-compat';
import * as $ from 'jquery';
import { CONFIG } from '../../../config';


@Component({
  selector: 'emap-authoring-legend',
  templateUrl: './authoring-legend.component.html',
  styleUrls: ['./authoring-legend.component.scss'],
  providers: [LegendItemService],
  encapsulation: ViewEncapsulation.None
})
export class AuthoringLegendComponent implements OnInit, OnDestroy {

  private AUTHORING_LEGEND_DIALOG_ID = 'authoring-legend-dialog';
  private AUTHORING_LEGEND_DIALOG_MAIN_CLASS = 'authoring-legend-dialog-main';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_SECTION_CLASS = 'authoring-legend-dialog-accordion-main';
  private AUTHORING_LEGEND_DIALOG_NEW_SECTION_TEXT_CLASS = 'authoring-legend-dialog-new-section-text';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_MAIN_CLASS = 'authoring-legend-dialog-accordion-main';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_TEXT_CLASS = 'authoring-legend-dialog-accordion-header-text';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_CLASS = 'authoring-legend-dialog-accordion-header';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_PANEL_CLASS = 'authoring-legend-dialog-accordion-panel';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_CLASS = 'authoring-legend-dialog-accordion-table';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_SYMBOL_ICONS_UPLOAD_CLASS = 'authoring-legend-dialog-accordion-table-row-symbol-icons-upload';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_SYMBOL_ICONS_TEXT_CLASS = 'authoring-legend-dialog-accordion-table-row-symbol-icons-text';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_SYMBOL_IMAGE_CLASS = 'authoring-legend-dialog-accordion-table-row-symbol-image';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_SYMBOL_TEXT_CLASS = 'authoring-legend-dialog-accordion-table-row-symbol-text';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_ACTION_CLASS = 'authoring-legend-dialog-accordion-table-row-action';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_CLASS = 'authoring-legend-dialog-accordion-table-row';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ADD_ROW_CLASS = 'authoring-legend-dialog-accordion-table-add-row';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_DELETE_CLASS = 'authoring-legend-dialog-accordion-header-delete';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_EDIT_CLASS = 'authoring-legend-dialog-accordion-header-edit';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_ENTER_CLASS = 'authoring-legend-dialog-accordion-header-enter';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_CANCEL_CLASS = 'authoring-legend-dialog-accordion-cancel';
  private AUTHORING_LEGEND_DIALOG_ACCORDION_SAVE_CLASS = 'authoring-legend-dialog-accordion-save';
  private GLOBAL_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS = 'global-panel-results-table-header-icon';
  private AUTHORING_LEGEND_UPLOAD_DIALOG_ID = 'authoring-legend-upload-dialog';
  private AUTHORING_LEGEND_UPLOAD_DIALOG_SELECT_ID = 'authoring-legend-upload-dialog-select';
  private AUTHORING_LEGEND_UPLOAD_DIALOG_SELECT_PARENT_ID = 'authoring-legend-upload-dialog-select-parent';
  private AUTHORING_LEGEND_UPLOAD_DIALOG_FILTER_IMAGE_INPUT_ID = 'authoring-legend-upload-dialog-filter-image-input';
  private AUTHORING_LEGEND_UPLOAD_DIALOG_ACCORDION_UPDATE_CLASS = 'authoring-legend-upload-dialog-accordion-update';
  private AUTHORING_LEGEND_UPLOAD_DIALOG_ACCORDION_CANCEL_CLASS = 'authoring-legend-upload-dialog-accordion-cancel';
  private SYMBOL_DISPLAY_NONE_CLASS = 'symbol-display-none';

  private LEGEND_PANEL_WIDTH = 800;
  private LEGEND_UPLOAD_DIALOG_PANEL_HEIGHT = 500;
  private IMAGE_DROP_DOWN_SCROLL_HEIGHT = 200;
  private LEGEND_EDITOR_TITLE = 'Legend Editor';
  private LEGEND_UPLOAD_EDITOR_TITLE = 'Upload Legend Image';
  private ADD_ROW_TEXT = 'Add Row';
  private ALERT_MESSAGE_NO_IMAGE = 'There are no image to select. Please upload an image in the media management';

  private legendItemData: Array<LegendItem> = [];
  private legendImageReferenceData: Array<Image> = [];
  private currentParentRowSelector: object;
  private s3url = CONFIG.aws.s3[environment.env];

  @Input() isMaster = false;
  private currentLegendFilterVal: string;

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private authoringIntegrationService: AuthoringIntegrationService,
    private globalIntegrationServices: GlobalIntegrationServices,
    private imageService: ImageService,
    private bannerNotificationsService: BannerNotificationsService,
    private translateService: TranslateService,
    private legendItemService: LegendItemService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.legendLoader();
    this.initLegend();
  }

  private legendLoader() {
    this.getLegendItemData();
    this.getLegendImageData();
    this.authoringLegendIconClickHandler();
    this.addNewLegendSectionClickEventHandler();
    this.elementRefreshEvents();
    this.clickSaveEventHandler();
    this.clickCancelEventHandler();
  }

  private initLegend() {
    this.globalIntegrationServices.mapImageLoadEventEmitter.subscribe(() => {
      this.legendLoader();
    });
  }

  private getLegendItemData() {
    const legendItemsParams = { isMaster: this.isMaster };
    if (!this.isMaster) {
      legendItemsParams['mapsManagerId'] = this.globalIntegrationServices.currentMapId;
    }
    this.legendItemService.getLegendItem(this.utilsService.generateParams(legendItemsParams))
      .subscribe(
        value => { this.legendItemData = value; },
        error => {this.bannerNotificationsService.error(this.translateService.instant('AUTHORING.LEGEND.ERRORS.LEGEND_ITEM_ERROR')); }
      );
  }

  private getLegendImageData() {
    const params = new HttpParams().set('category', 'legend');
    this.imageService.getAllImages(params)
      .subscribe(
        data => { this.legendImageReferenceData = data; },
        error => this.bannerNotificationsService.error(this.translateService.instant('AUTHORING.LEGEND.ERRORS.LEGEND_IMAGE_ERROR'))
      );
  }

  private elementRefreshEvents() {
    this.legendUploadImageIconClickEventHandler();
    this.legendEnterTextIconClickEventHandler();
    this.legendItemDeleteClickEventHandler();
    this.legendRowDeleteClickEventHandler();
    this.legendRowEditClickEventHandler();
    this.addNewRowClickEventHandler();
  }

  private authoringLegendIconClickHandler() {
    this.authoringIntegrationService.authoringLegendIconClickEventHandler.subscribe(value => {
      this.authoringLegendDialogInit();
    });
  }

  private authoringLegendDialogInit() {
    const legendDialogOptions = {
      dialogClass: this.AUTHORING_LEGEND_DIALOG_MAIN_CLASS,
      width: this.LEGEND_PANEL_WIDTH,
      title: this.LEGEND_EDITOR_TITLE,
      autoResize: true,
      height: 'auto',
      open: this.legendDialogOpenEvent(),
      close: (event, ui) => { this.destroyDialogInstance(this.AUTHORING_LEGEND_DIALOG_ID.toId()); },
      resize: 'auto'
    } as any;
    this.jqueryCommonServices.dialog(this.AUTHORING_LEGEND_DIALOG_ID.toId(), legendDialogOptions);
  }

  private legendDialogOpenEvent() {
    const customAccordionOptions = { header: this.AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_CLASS.toClass() } as any;
    const customSortableOptions = { items: this.AUTHORING_LEGEND_DIALOG_ACCORDION_PANEL_CLASS.toClass() } as any;
    this.jqueryCommonServices
      .initiateAccordion(this.AUTHORING_LEGEND_DIALOG_ACCORDION_SECTION_CLASS.toClass(), customAccordionOptions)
      .sortable(customSortableOptions);
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_CLASS.toClass()).sortable();
    this.bindLegendItemData();
  }

  private toggleDialogDisplayState(uiElement: any, state: boolean) {
    const dialogElement = this.jqueryCommonServices.selector(uiElement).dialog('instance')['uiDialog'];
    state ? dialogElement.show() : dialogElement.hide();
  }

  private legendEnterTextIconClickEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_SYMBOL_ICONS_TEXT_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_SYMBOL_ICONS_TEXT_CLASS.toClass(),
      (event) => {
        if (!event.hasOwnProperty('target')) { return; }
        const symbolDivList = this.jqueryCommonServices.selector(event.target).parent().siblings();
        this.switchSymbolHandler(false);
      });
  }

  private switchSymbolHandler(isImage: boolean) {
    const parentRowImageSelector = this.jqueryCommonServices.selector(this.currentParentRowSelector)
      .find(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_SYMBOL_IMAGE_CLASS.toClass());
    const parentRowTextSelector = this.jqueryCommonServices.selector(this.currentParentRowSelector)
      .find(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_SYMBOL_TEXT_CLASS.toClass());
    if (isImage) {
      parentRowImageSelector.removeClass(this.SYMBOL_DISPLAY_NONE_CLASS);
      parentRowTextSelector.addClass(this.SYMBOL_DISPLAY_NONE_CLASS);
    } else {
      parentRowImageSelector.addClass(this.SYMBOL_DISPLAY_NONE_CLASS);
      parentRowTextSelector.removeClass(this.SYMBOL_DISPLAY_NONE_CLASS);
    }
  }

  private addNewLegendSectionClickEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_NEW_SECTION_TEXT_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_LEGEND_DIALOG_NEW_SECTION_TEXT_CLASS.toClass(), (event) => {
      this.addNewLegendSection();
      this.elementRefreshEvents();
      this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_CLASS.toClass()).sortable();
    });
  }

  private legendItemDeleteClickEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_ACTION_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_ACTION_CLASS.toClass(), (event) => {
      this.jqueryCommonServices.selector(event.target).parent().remove();
    });
  }

  private legendRowDeleteClickEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_DELETE_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_DELETE_CLASS.toClass(), (event) => {
      if (!event.hasOwnProperty('target')) { return; }
      const parentSelector = this.jqueryCommonServices.selector(event.target).parent().parent();
      parentSelector.remove();
    });
  }

  private legendRowEditClickEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_EDIT_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_EDIT_CLASS.toClass(), (event) => {
      if (!event.hasOwnProperty('target')) { return; }
      event.stopImmediatePropagation();
      const parent = this.jqueryCommonServices.selector(event.target).parent();
      const inputSelector = this.jqueryCommonServices.selector(parent).find('input');
      inputSelector.removeAttr('readonly');
      inputSelector.val('');
      inputSelector.focus();
      const inputEnterSelector = this.jqueryCommonServices.selector(parent).find(this.AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_ENTER_CLASS.toClass());
      inputEnterSelector.removeClass(this.SYMBOL_DISPLAY_NONE_CLASS);
      this.jqueryCommonServices.clickEvent(inputEnterSelector, (e) => {
        inputSelector.attr('readonly', true);
        inputEnterSelector.addClass(this.SYMBOL_DISPLAY_NONE_CLASS);
        e.stopImmediatePropagation();
      });
    });
  }

  private addNewLegendSection(sectionText?: string) {
    const accordionMainClass = '.authoring-legend-dialog-accordion-main.global-panel-results-table.ui-accordion';
    const accordionMainSelector = this.jqueryCommonServices.selector(accordionMainClass);
    const sampleHeaderText = 'Sample Header';
    const accordionNewPanel = this.jqueryCommonServices.divConstruct().addClass(this.AUTHORING_LEGEND_DIALOG_ACCORDION_PANEL_CLASS);
    accordionNewPanel.append(this.constructLegendHeader(sectionText ? sectionText : sampleHeaderText)).append(this.constructLegendItem());
    accordionMainSelector.append(accordionNewPanel);
    this.overrideAccordionHeaderKeyPress();
    this.jqueryCommonServices.accordionRefresh(accordionMainSelector);
    return accordionNewPanel;
  }

  private constructLegendHeader(headerText?: string) {
    const newHeaderSelector = this.jqueryCommonServices.divConstruct().addClass(this.AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_CLASS);
    const enterClass = 'authoring-legend-dialog-accordion-header-enter symbol-display-none legend-header-margin fa fa-check icon';
    const editClass = 'authoring-legend-dialog-accordion-header-edit legend-header-margin fa fa-pencil icon';
    const deleteClass = 'authoring-legend-dialog-accordion-header-delete legend-header-margin fa fa-trash icon';
    newHeaderSelector
      .append(this.jqueryCommonServices.divConstruct().addClass(enterClass))
      .append(this.jqueryCommonServices.divConstruct().addClass(editClass))
      .append(this.jqueryCommonServices.divConstruct().addClass(deleteClass))
      .append(this.jqueryCommonServices.divConstruct().addClass(this.GLOBAL_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS));
    if (headerText) {
      newHeaderSelector
        .append(this.jqueryCommonServices.divConstruct().addClass(this.AUTHORING_LEGEND_DIALOG_ACCORDION_HEADER_TEXT_CLASS)
          .append('<input class="legend-data legend-header" value="' + headerText + '" readonly>'));
    }
    return newHeaderSelector;
  }

  private overrideAccordionHeaderKeyPress() {
    this.jqueryCommonServices.selector('.authoring-legend-dialog-accordion-header-text input').keydown(event => {
      event.stopImmediatePropagation();
    });
  }

  private constructLegendItem() {
    const newLegendItem = this.jqueryCommonServices.divConstruct().addClass(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_CLASS);
    newLegendItem.append(this.jqueryCommonServices.divConstruct(this.ADD_ROW_TEXT)
      .addClass(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ADD_ROW_CLASS));
    return newLegendItem;
  }

  private addNewRowClickEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ADD_ROW_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ADD_ROW_CLASS.toClass(), (event) => {
      if (!event.hasOwnProperty('target')) { return; }
      const parentElement = this.jqueryCommonServices.selector(event.target).parent();
      parentElement.append(this.constructLegendItemRow());
      this.elementRefreshEvents();
    });
  }

  private constructLegendItemRow(legendItem?: LegendItem) {
    const legendRowSelector = this.jqueryCommonServices.divConstruct()
      .addClass(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_CLASS).addClass('legend-data');
    legendRowSelector
      .append(this.constructLegendRowSymbol(legendItem ? legendItem : null))
      .append(this.constructLegendRowDescription(legendItem ? legendItem.description : ''))
      .append(this.constructLegendRowAction());
    return legendRowSelector;
  }

  private constructLegendRowSymbol(legendItem?: LegendItem) {
    // The string constant are placed inline because they will not be used anywhere else in the code. So there is not need for member construct.
    // and for better code readability
    const legendRowSymbolSelector = this.jqueryCommonServices.divConstruct()
      .addClass('authoring-legend-dialog-accordion-table-row-symbol authoring-legend-dialog-accordion-table-row-elem');
    const imageDivConstruct = this.jqueryCommonServices.divConstruct().addClass('authoring-legend-dialog-accordion-table-row-symbol-image');
    const textDivConstruct = this.jqueryCommonServices.divConstruct().addClass('authoring-legend-dialog-accordion-table-row-symbol-text')
      .append('<input class="authoring-legend-dialog-accordion-table-row-symbol-text-input">');
    if (legendItem && legendItem.image) {
      imageDivConstruct.css({ 'background-image': `url(${this.s3url.concat(legendItem.image.s3Reference)})` });
      imageDivConstruct.data({ image: legendItem.image });
      textDivConstruct.addClass(this.SYMBOL_DISPLAY_NONE_CLASS);
    } else {
      imageDivConstruct.addClass(this.SYMBOL_DISPLAY_NONE_CLASS);
      this.jqueryCommonServices.selector(textDivConstruct.find('input')).val(legendItem ? legendItem.text : '');
    }
    legendRowSymbolSelector
      .append(imageDivConstruct)
      .append(textDivConstruct)
      .append(this.jqueryCommonServices.divConstruct().addClass('authoring-legend-dialog-accordion-table-row-symbol-icons')
        .append(this.jqueryCommonServices.divConstruct().addClass('authoring-legend-dialog-accordion-table-row-symbol-icons-upload'))
        .append(this.jqueryCommonServices.divConstruct().addClass('authoring-legend-dialog-accordion-table-row-symbol-icons-text'))
      );
    return legendRowSymbolSelector;
  }

  private constructLegendRowDescription(legendItemDescription?: string) {
    // The string constant are placed inline because they will not be used anywhere else in the code. So there is not need for member construct.
    // and for better code readability
    const legendRowDescriptionSelector = this.jqueryCommonServices.divConstruct()
      .addClass('authoring-legend-dialog-accordion-table-row-description')
      .addClass('authoring-legend-dialog-accordion-table-row-elem');
    legendRowDescriptionSelector
      .append($('<input>', {
        class: 'authoring-legend-dialog-accordion-table-row-description-input'
        , placeholder: 'Enter Description Here', value: legendItemDescription
      }));
    return legendRowDescriptionSelector;
  }

  private constructLegendRowAction() {
    return this.jqueryCommonServices.divConstruct('Delete')
      .addClass('authoring-legend-dialog-accordion-table-row-action')
      .addClass('authoring-legend-dialog-accordion-table-row-elem');
  }

  private clickSaveEventHandler() {
    const symbolTextInputClass = '.authoring-legend-dialog-accordion-table-row-symbol-text-input';
    const symbolImageInputClass = '.authoring-legend-dialog-accordion-table-row-symbol-image';
    const descriptionInputClass = '.authoring-legend-dialog-accordion-table-row-description-input';
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_SAVE_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_LEGEND_DIALOG_ACCORDION_SAVE_CLASS.toClass(), (event) => {
      const newLegendItems = [];
      this.jqueryCommonServices.selector('.legend-data').each((index, eachLegendData) => {
        const currentLegendItem = new LegendItem();
        const currentElementSelector = this.jqueryCommonServices.selector(eachLegendData);
        currentLegendItem.isHeader = currentElementSelector.hasClass('legend-header');
        if (currentLegendItem.isHeader) {
          currentLegendItem.text = currentElementSelector.val();
          currentLegendItem.description = '';
        } else {
          const symbolText = currentElementSelector.find(symbolTextInputClass);
          const imagePath = currentElementSelector.find(symbolImageInputClass);
          const rowDescription = currentElementSelector.find(descriptionInputClass);
          currentLegendItem.text = symbolText.val();
          currentLegendItem.description = rowDescription.val();
          currentLegendItem.image = this.jqueryCommonServices.selector(imagePath).data('image');
        }
        newLegendItems.push(currentLegendItem);
      });
      this.legendItemData = newLegendItems;
      const createLegendResource = new CreateLegendResource();
      createLegendResource.mapId = this.globalIntegrationServices.currentMapId;
      createLegendResource.isMaster = this.isMaster;
      createLegendResource.createLegendItemResourceList = this.legendItemData;
      this.legendItemService.postLegendItemData(createLegendResource)
        .subscribe(
          value => { this.bannerNotificationsService.success(this.translateService.instant('AUTHORING.LEGEND.SAVE_SUCCESS')); },
          error => { this.bannerNotificationsService.error(this.translateService.instant('AUTHORING.LEGEND.ERRORS.LEGEND_ITEM_SAVE_ERROR')); }
        );
      this.destroyDialogInstance(this.AUTHORING_LEGEND_DIALOG_ID.toId());
    });
  }

  private clickCancelEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_CANCEL_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_LEGEND_DIALOG_ACCORDION_CANCEL_CLASS.toClass(), (event) => {
      this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ID.toId()).dialog('destroy');
    });
  }

  private destroyDialogInstance(element: string) {
    const legendDialogSelector = this.jqueryCommonServices.selector(element);
    if (legendDialogSelector.dialog('instance')) {
      legendDialogSelector.dialog('destroy');
    }
  }

  private bindLegendItemData() {
    let currentAccordionPanel;
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_MAIN_CLASS.toClass()).empty();
    this.legendItemData.forEach((eachLegendItemData) => {
      if (eachLegendItemData.isHeader) {
        currentAccordionPanel = this.addNewLegendSection(eachLegendItemData.text);
      } else {
        currentAccordionPanel
          .find(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_CLASS.toClass())
          .append(this.constructLegendItemRow(eachLegendItemData));
      }
    });
    this.elementRefreshEvents();
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_CLASS.toClass()).sortable();
  }

  private legendUploadImageIconClickEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_SYMBOL_ICONS_UPLOAD_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_SYMBOL_ICONS_UPLOAD_CLASS.toClass(), (event) => {
      if (!event.hasOwnProperty('target')) { return; }
      if (this.legendImageReferenceData.length === 0) {
        alert(this.ALERT_MESSAGE_NO_IMAGE);
        return;
      }
      const eventTargetSelector = this.jqueryCommonServices.selector(event.target);
      const uploadImageDialogOptions = {
        title: this.LEGEND_UPLOAD_EDITOR_TITLE,
        height: this.LEGEND_UPLOAD_DIALOG_PANEL_HEIGHT,
        open: this.initiateImageDropDown(),
        close: (e, ui) => { this.toggleDialogDisplayState(this.AUTHORING_LEGEND_DIALOG_ID.toId(), true); }
      } as any;
      this.currentParentRowSelector = eventTargetSelector.parent().parent();
      this.jqueryCommonServices.dialog(this.AUTHORING_LEGEND_UPLOAD_DIALOG_ID.toId(), uploadImageDialogOptions);
    });
  }

  private initiateImageDropDown() {
    this.addSelectOptionElement();
    this.bindImageDropDownData();
    const imageDropDownOption = { height: this.IMAGE_DROP_DOWN_SCROLL_HEIGHT };
    this.toggleDialogDisplayState(this.AUTHORING_LEGEND_DIALOG_ID.toId(), false);
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_UPLOAD_DIALOG_SELECT_ID.toId()).ddslick(imageDropDownOption);
    this.updateCurrentImageSelection();
    this.cancelCurrentImageSelection();
  }

  private addSelectOptionElement() {
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_UPLOAD_DIALOG_SELECT_PARENT_ID.toId()).empty()
      .append('<select id="' + this.AUTHORING_LEGEND_UPLOAD_DIALOG_SELECT_ID + '"></select>');
  }

  public filterImageDropDownImages() {
    this.currentLegendFilterVal = this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_UPLOAD_DIALOG_FILTER_IMAGE_INPUT_ID.toId()).val();
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_UPLOAD_DIALOG_SELECT_PARENT_ID.toId()).empty();
    this.addSelectOptionElement();
    this.bindImageDropDownData();
    const imageDropDownOption = { height: this.IMAGE_DROP_DOWN_SCROLL_HEIGHT };
    this.toggleDialogDisplayState(this.AUTHORING_LEGEND_DIALOG_ID.toId(), false);
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_UPLOAD_DIALOG_SELECT_ID.toId()).ddslick(imageDropDownOption);
    this.updateCurrentImageSelection();
    this.cancelCurrentImageSelection();
  }

  private updateCurrentImageSelection() {
    this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_UPLOAD_DIALOG_ACCORDION_UPDATE_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_LEGEND_UPLOAD_DIALOG_ACCORDION_UPDATE_CLASS.toClass(), (event) => {
      const currentDropDownSelection = this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_UPLOAD_DIALOG_SELECT_ID.toId()).data('ddslick');
      if (!currentDropDownSelection) { return; }
      const selectedData = currentDropDownSelection['selectedData'];
      const imageSelector = this.jqueryCommonServices.selector(this.currentParentRowSelector)
        .find(this.AUTHORING_LEGEND_DIALOG_ACCORDION_TABLE_ROW_SYMBOL_IMAGE_CLASS.toClass());
      imageSelector.css({ 'background-image': `url(${selectedData['imageSrc']})` });
      const currentImage = new Image();
      currentImage.id = selectedData['value'];
      // currentImage.s3Reference = selectedData['imageSrc'].replace(this.aws.s3PrivateDomain, '');
      imageSelector.data('image', currentImage);
      this.switchSymbolHandler(true);
      this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_UPLOAD_DIALOG_ID.toId()).dialog('close');
      this.toggleDialogDisplayState(this.AUTHORING_LEGEND_DIALOG_ID.toId(), true);
    });
  }

  private cancelCurrentImageSelection() {
    this.jqueryCommonServices.clickEvent(this.AUTHORING_LEGEND_UPLOAD_DIALOG_ACCORDION_CANCEL_CLASS.toClass(), (event) => {
      this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_UPLOAD_DIALOG_ID.toId()).dialog('close');
      this.toggleDialogDisplayState(this.AUTHORING_LEGEND_DIALOG_ID.toId(), true);
    });
  }

  private bindImageDropDownData() {
    const legendOptionSelector = this.jqueryCommonServices.selector(this.AUTHORING_LEGEND_UPLOAD_DIALOG_SELECT_ID.toId());
    legendOptionSelector.empty();
    legendOptionSelector.ddslick('destroy');
    this.legendImageReferenceData.forEach((eachLegendImage) => {
      if (typeof (this.currentLegendFilterVal) === 'undefined' ||
        (eachLegendImage.fileName.toLowerCase().includes(this.currentLegendFilterVal.toLowerCase()))) {
        legendOptionSelector.append(
          this.jqueryCommonServices.selector('<option>')
            .attr('value', eachLegendImage.id)
            .attr('data-imagesrc', this.s3url.concat(eachLegendImage.s3Reference))
            .attr('data-description', eachLegendImage.fileName)
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyDialogInstance(this.AUTHORING_LEGEND_DIALOG_ID.toId());
  }
}
