import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {MindMapCoreComponent} from './mind-map-core/mind-map-core.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NavigationModalComponent} from './mind-map-core/navigation-modal/navigation-modal.component';


@Injectable({providedIn: 'root'})
export class MindMapCanDeactivateGuard implements CanDeactivate<MindMapCoreComponent> {
    constructor(private modalService: NgbModal) {
    }

    canDeactivate(
        component: MindMapCoreComponent,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | boolean {
        return this.checkNavigation(component);
    }

    private checkNavigation(component: MindMapCoreComponent): Observable<boolean> | boolean {
        const navigationSubscription$ = new Subject<boolean>();
        const modalRefComponent = this.modalService
            .open(NavigationModalComponent, {centered: true, windowClass: 'mindnode-navigation-modal'})
            .componentInstance;
        modalRefComponent.saveMindMapSubscription$ = component.saveSubscription$;
        modalRefComponent.navigationSubscription$ = navigationSubscription$;
        return navigationSubscription$.asObservable();
    }
}
