import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { Permission } from '../../shared/models';


@Injectable()
export class PermissionService extends AbstractCRUD<Permission> implements CRUDService<Permission>, Searcher {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService,
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath(): string {
    return '/rms/permissions';
  }

  public getDisplayName(item: any): string {
    return item.name;
  }

}
