import { Component } from '@angular/core';
import { AbstractSpinner, SpinnerService } from '../../../xform-compat';


@Component({
  selector: 'xform-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent extends AbstractSpinner {

  constructor(spinnerService: SpinnerService) {
    super(spinnerService);
  }

}
