import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'auth' },
  { path: 'auth', loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'authoring', loadChildren: () => import('./modules/authoring/authoring.module').then(m => m.AuthoringModule) },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
  { path: 'dashboard',  redirectTo: '/mapsManager/list', pathMatch: 'full'  },
  { path: 'mapsManager', loadChildren: () => import('./modules/maps-manager/maps-manager.module').then(m => m.MapsManagerModule) },
  { path: 'nodesManager', loadChildren: () => import('./modules/nodes-manager/nodes-manager.module').then(m => m.NodesManagerModule) },
  { path: 'mindMap', loadChildren: () => import('./modules/mind-map/mind-map.module').then(m => m.MindMapModule) },
  { path: 'map', loadChildren: () => import('./modules/map/map.module').then(m => m.MapModule) },
  { path: 'system-administration', loadChildren: () => import('./modules/system-admin/system-admin.module').then(m => m.SystemAdminModule) },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
