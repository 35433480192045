import {Synonym} from "./synonym.model";

export class MapVersionIndication {
    mapsManagerVersionId: number;
    indication: Synonym;
    indicationId?: number;
    status?: string;

    constructor(mmvi: MapVersionIndication = {} as MapVersionIndication) {
        this.mapsManagerVersionId = mmvi.mapsManagerVersionId;
        this.indication = mmvi.indication;
        this.indicationId = mmvi.indicationId || null;
        this.status = mmvi.status || null;
    }
}
