import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AbstractCRUD, CRUDService, ExceptionService, UtilsService} from '../../xform-compat';
import {MindMapVersionNode} from '../../shared/models/mindMapVersionNode.model';
import {catchError, map} from 'rxjs/operators';
import {Company} from '../../shared/models/company.model';
import {Product} from '../../shared/models/product.model';


@Injectable({
  providedIn: 'root'
})
export class MindMapVersionNodeService extends AbstractCRUD<MindMapVersionNode> implements CRUDService<MindMapVersionNode> {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/map/service/mindmapversionnode';
  }

  public getAllNoPagination(mapsManagerVersionId: number) {
    const params = new HttpParams().set('mapsManagerVersionId', String(mapsManagerVersionId));
    return super.getHTTP().get(this.getAllNoPaginationPath(), {params: params})
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public updateOrDelete(mindMapVersionId: number, mindMapVersionNodes: Array<MindMapVersionNode>) {
    return super.getHTTP().put(this.getApiPath() + `/${mindMapVersionId}`, mindMapVersionNodes)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public archive(mindMapVersionId: number, mindMapVersionNodes: Array<MindMapVersionNode>) {
    return super.getHTTP().put(this.getApiPath() + `/archive/${mindMapVersionId}`, mindMapVersionNodes)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public createNewMindMapVersionNode(mapsManagerVersionId: number) {
    return super.getHTTP().post(`${this.getApiPath()}/new`, {mapsManagerVersionId: mapsManagerVersionId})
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public updateSingleNode(mindMapVersionNode: MindMapVersionNode) {
    return super.getHTTP().post(`${this.getApiPath()}/save`, mindMapVersionNode);
  }

  public bindCompanyProduct(companyProductPairs: Array<{ companies: Array<Company>, products: Array<Product> }>, node: any, label: string) {
    const body = {companyProductPairs: companyProductPairs, node: node, label: label};
    return this.getHTTP().post(`${this.getApiPath()}/bindNode/companyProduct`, body);
  }

  public bindMilestone(milestones: any, label: string, node: any) {
    const body = {milestoneValues: milestones, node: node, label: label};
    return this.getHTTP().post(`${this.getApiPath()}/bindNode/milestone`, body);
  }

  private getAllNoPaginationPath() {
    return super.getApiPath().concat('/all/noPagination');
  }
}
