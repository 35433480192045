import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LegendItemService } from '../../../core/data-services/legendItem.service';
import { TranslateService } from '@ngx-translate/core';
import { ImageService } from '../../../core/data-services/image.service';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { LegendItem } from '../../models/legendItem.model';
import { environment } from '../../../../environments/environment';
import { GlobalIntegrationServices } from '../../services/globalIntegration.services';
import { BannerNotificationsService, UtilsService } from '../../../xform-compat';
import { CONFIG } from '../../../config';

@Component({
  selector: 'emap-legend-panel',
  templateUrl: './legend-panel.component.html',
  styleUrls: ['./legend-panel.component.scss'],
  providers: [LegendItemService],
  encapsulation: ViewEncapsulation.None
})
export class LegendPanelComponent implements OnInit {
  private LEGEND_PANEL_RESULTS_TABLE_CLASS = 'legend-panel-results-table';
  private GLOBAL_PANEL_RESULTS_TABLE_HEADER_CLASS = 'global-panel-results-table-header';
  private GLOBAL_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS = 'global-panel-results-table-header-icon';
  private LEGEND_PANEL_RESULTS_TABLE_HEADER_MAP_NAME_CLASS = 'legend-panel-results-table-header-map-name';
  private LEGEND_PANEL_RESULTS_TABLE_NODE_LIST_CLASS = 'legend-panel-results-table-node-list';
  private LEGEND_PANEL_RESULTS_TABLE_NODE_LIST_IMAGE_CLASS = 'legend-panel-results-table-node-list-image';
  private LEGEND_PANEL_RESULTS_TABLE_NODE_LIST_DESCRIPTION_CLASS = 'legend-panel-results-table-node-list-description';

  private legendItemData: Array<LegendItem> = [];

  private s3Url = CONFIG.aws.s3[environment.env];

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private globalIntegrationServices: GlobalIntegrationServices,
    private imageService: ImageService,
    private bannerNotificationsService: BannerNotificationsService,
    private translateService: TranslateService,
    private legendItemService: LegendItemService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.getLegendPanelData();
  }

  private getLegendPanelData() {
    const legendItemsParams = {
      mapsManagerId: this.globalIntegrationServices.currentMapId,
      isMaster: false
    };
    this.legendItemService.getLegendItem(this.utilsService.generateParams(legendItemsParams))
      .subscribe(
        value => {
          this.legendItemData = value;
          this.constructLegendPanel();
          this.initializeAccordionOnTable();
          },
        error => {
          this.bannerNotificationsService
          .error(this.translateService.instant('AUTHORING.LEGEND.ERRORS.LEGEND_ITEM_ERROR'));
        }
      );
  }

  private initializeAccordionOnTable() {
    (this.jqueryCommonServices.selector(this.LEGEND_PANEL_RESULTS_TABLE_CLASS.toClass()) as any).accordion({
      collapsible: true,
      icons: false,
      heightStyle: 'content'
    });
  }

  private constructLegendPanel() {
    const legendPanelResultsTableSelector = this.jqueryCommonServices.selector(this.LEGEND_PANEL_RESULTS_TABLE_CLASS.toClass());
    let legendPanelTableNodeListSelector;
    this.legendItemData.forEach(eachLegendItem => {
      if (eachLegendItem.isHeader) {
        legendPanelResultsTableSelector.append(this.constructLegendHeader(eachLegendItem));
        legendPanelTableNodeListSelector = this.jqueryCommonServices.divConstruct().addClass(this.LEGEND_PANEL_RESULTS_TABLE_NODE_LIST_CLASS);
        legendPanelResultsTableSelector.append(legendPanelTableNodeListSelector);
      }else {
        legendPanelTableNodeListSelector.append(this.constructLegendItem(eachLegendItem, legendPanelTableNodeListSelector));
      }
    });
  }

  private constructLegendHeader(legendItem: LegendItem) {
    const headerDivSelector = this.jqueryCommonServices.divConstruct().addClass(this.GLOBAL_PANEL_RESULTS_TABLE_HEADER_CLASS);
    const iconDivSelector = this.jqueryCommonServices.divConstruct().addClass(this.GLOBAL_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS);
    const headerNameDivSelector = this.jqueryCommonServices.divConstruct(legendItem.text.toUpperCase())
      .addClass(this.LEGEND_PANEL_RESULTS_TABLE_HEADER_MAP_NAME_CLASS);
    return headerDivSelector.append(iconDivSelector).append(headerNameDivSelector);
  }

  private constructLegendItem(eachLegendItem: LegendItem, legendNodeListSelector: any) {
    const legendItemImageDivSelector = this.jqueryCommonServices.divConstruct().addClass(this.LEGEND_PANEL_RESULTS_TABLE_NODE_LIST_IMAGE_CLASS);
    if (eachLegendItem.image) {
      legendItemImageDivSelector.append(this.jqueryCommonServices.selector('<img>')
        .attr('src', this.s3Url.concat(eachLegendItem.image.s3Reference)));
    }else {
      legendItemImageDivSelector.append(this.jqueryCommonServices.divConstruct(eachLegendItem.text));
    }
    const legendItemDescriptionDivSelector = this.jqueryCommonServices.divConstruct(eachLegendItem.description)
      .addClass(this.LEGEND_PANEL_RESULTS_TABLE_NODE_LIST_DESCRIPTION_CLASS);
    this.jqueryCommonServices.selector(legendNodeListSelector).append(legendItemImageDivSelector).append(legendItemDescriptionDivSelector);
  }
}
