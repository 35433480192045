import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthoringRoutingModule } from './authoring-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { MapAuthoringComponent } from './map-authoring/map-authoring.component';
import { FinalizationReportComponent } from './finalization-report/finalization-report.component';


@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    AuthoringRoutingModule
  ],
  declarations: [MapAuthoringComponent, FinalizationReportComponent],
  exports: [MapAuthoringComponent]
})
export class AuthoringModule {
}
