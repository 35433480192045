import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ExceptionService {

  constructor(
    private translateService: TranslateService
  ) { }


  public catchBadResponse(response): Observable<any> {
    let code;

    if (response instanceof HttpErrorResponse) {
      code = response.error.code;
    }

    // TODO: Rethink where should be more convenient to add this conditional logic to display
    // the received message from the API instead of the mapped error message.
    if (code === 'BUS_1027') {
      return throwError(response.error.message);
    }

    return throwError(this.translateService.instant(`API_ERRORS.${code}`, { value: '' }));
  }
}
