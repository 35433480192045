import { OnInit } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import {BaseEntity} from "../models/baseEntity.interface";
import {ExceptionService} from "../services/exception.service";
import {UtilsService} from "../services/utils.service";
import {PaginatedList} from "../models/paginatedList.model";


export abstract class AbstractCRUD<R extends BaseEntity>  implements OnInit {
  private apiPath: string;

  protected constructor(
    private exceptionService: ExceptionService,
    private http: HttpClient,
    private utilsService: UtilsService
  ) {
    this.setApiPath();
  }

  ngOnInit() {

  }

  public abstract getResourcePath(): string;

  protected getHTTP() {
    return this.http;
  }

  protected getException() {
    return this.exceptionService;
  }

  protected getApiPath(): string {
    return this.apiPath;
  }

  protected setApiPath() {
    this.apiPath = this.utilsService.getAPIPath() + this.getResourcePath();
  }

  protected generateParams(params: object): HttpParams {
    return this.utilsService.generateParams(params);
  }

  public getRecords(params?: object, onlyRecords?: boolean): Observable<any> {

    const httpParams = this.utilsService.generateParams(params);

    return this.http.get<PaginatedList<R>>(this.apiPath, { params: httpParams }).pipe(
      map((res) => {
        if (onlyRecords) {
          return <Array<R>> res.records;
        } else {
          return res;
        }
      }),
      catchError(ex => this.exceptionService.catchBadResponse(ex))
    );
  }

  public getRecord(id: number, params?: object): Observable<any> {
    const httpParams = this.utilsService.generateParams(params);
    return this.http.get<R>(`${this.apiPath}/${id}`, { params: httpParams })
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public createRecord(record: R): Observable<any> {
    return this.http.post<R>(`${this.apiPath}`, record)
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public updateRecord(record: R): Observable<any> {
    return this.http.put<R>(`${this.apiPath}/${record.id}`, record)
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public deleteRecord(record: R): Observable<any> {
    return this.http.delete(`${this.apiPath}/${record.id}`)
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

}
