import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NodesManagerRoutingModule } from './nodes-manager-routing.module';
import { NodesManagerListComponent } from './nodes-manager-list/nodes-manager-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { FileUploadModule } from 'ng2-file-upload';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsSettingsService } from '../../core/data-services/notificationSettings.service';
import { NgSelectModule } from '@ng-select/ng-select';
import {NodesManagerProductsComponent} from "./nodes-manager-products/nodes-manager-products.component";
import {NodesManagerMilestonesComponent} from "./nodes-manager-milestones/nodes-manager-milestones.component";
import {MatSortModule} from "@angular/material/sort";
import {NodesManagerFilterComponent} from "./nodes-manager-filter/nodes-manager-filter.component";
import {DragDropModule} from "@angular/cdk/drag-drop";

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        NodesManagerRoutingModule,
        FileUploadModule,
        TranslateModule,
        NgSelectModule,
        MatSortModule,
        DragDropModule,
    ],
    declarations: [NodesManagerListComponent, NodesManagerProductsComponent, NodesManagerMilestonesComponent,
        NodesManagerFilterComponent],
    exports: [NodesManagerListComponent, NodesManagerProductsComponent, NodesManagerMilestonesComponent,
        NodesManagerFilterComponent],
    providers: [NgbActiveModal, NotificationsSettingsService]
})
export class NodesManagerModule { }
