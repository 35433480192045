import { NodeSearchCategoryValueResource, NodeSearchResource } from '../models/resources/nodeSearchResource.model';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class SearchFilterKeyboardServices {
  private _fuzzySearchObject: Array<NodeSearchResource>;
  private _flatMapNodeList: any;
  private _filterData: object;
  private _filterDataState: object = {};
  private _keyboardData: Array<string>;
  private _searchResultChangeEmitter: Subject<boolean> = new Subject<boolean>();
  private _filterResultChangeEmitter: Subject<boolean> = new Subject<boolean>();
  private _filterResultRemoveEmitter: Subject<object> = new Subject<object>();
  private _keyboardChangeEmitter: Subject<boolean> = new Subject<boolean>();

  set fuzzySearchObject(value: Array<NodeSearchResource>) {
    this._fuzzySearchObject = value;
  }

  get flatMapNodeList(): any {
    return this._flatMapNodeList;
  }

  get filterData(): object {
    return this._filterData;
  }

  set filterDataState(value: object) {
    this._filterDataState = value;
  }

  get keyboardData(): Array<string> {
    return this._keyboardData;
  }

  set keyboardData(value: Array<string>) {
    this._keyboardData = value;
  }

  get searchResultChangeEmitter(): Subject<boolean> {
    return this._searchResultChangeEmitter;
  }

  get filterResultChangeEmitter(): Subject<boolean> {
    return this._filterResultChangeEmitter;
  }

  get filterResultRemoveEmitter(): Subject<object> {
    return this._filterResultRemoveEmitter;
  }

  get keyboardChangeEmitter(): Subject<boolean> {
    return this._keyboardChangeEmitter;
  }

  get filterDataState(): object {
    return this._filterDataState;
  }

  public flattenNodeObject() {
    const mapAggregation = [];
    const mapIndex = {};
    this._filterData = {};
    this._filterDataState = {};
    this._fuzzySearchObject.forEach( (eachNode: NodeSearchResource) => {
      const currMapId = eachNode.mi;
      const currMapName = eachNode.mn;
      const currMapVersionId = eachNode.mvi;
      const currentMapNode = this.constructFlatNode(eachNode);

      if (mapIndex.hasOwnProperty(currMapId)) {
        const mapAggregationIndex = mapIndex[currMapId];
        const mapAggregationEachMapObject = mapAggregation[mapAggregationIndex];
        mapAggregationEachMapObject[2] += 1;
        mapAggregationEachMapObject[3].push(currentMapNode);
      }else {
        mapAggregation.push([currMapName, currMapId, 1, [currentMapNode], currMapVersionId]);
        mapIndex[currMapId] = mapAggregation.length - 1;
      }
    });
    // sorting by map node count
    mapAggregation.sort((a, b) => b[2] - a[2]);
    // sorting node list of each map by node name
    mapAggregation.map((eachMap) => {eachMap[3] = _.sortBy(eachMap[3], (nodeElem) => nodeElem[0]); });
    this._flatMapNodeList = mapAggregation;
    return this._flatMapNodeList;
  }

  private constructFlatNode(node: NodeSearchResource) {
    return [node.nn, node.ni, this.collectNodeCategoryIds(node), true];
  }

  private collectNodeCategoryIds(node: NodeSearchResource) {
    const eachNodeCategoryValueIdList = [];
    const eachNodeCategoryIdList = [];
    node.nm.forEach((nodeSearchCategoryValueResource: NodeSearchCategoryValueResource) => {
      eachNodeCategoryIdList.push(nodeSearchCategoryValueResource.ci);
      eachNodeCategoryValueIdList.push(nodeSearchCategoryValueResource.cvi);
      this.filterDataCollection(nodeSearchCategoryValueResource);
    });
    return [eachNodeCategoryIdList, eachNodeCategoryValueIdList];
  }

  private filterDataCollection(nodeSearchCategoryValueResource: NodeSearchCategoryValueResource) {
    const categoryId = Number(nodeSearchCategoryValueResource.ci);
    const categoryValueId = Number(nodeSearchCategoryValueResource.cvi);
    const categoryValueObject = {};
    categoryValueObject[categoryValueId] = [nodeSearchCategoryValueResource.cvn];
    if (!this._filterData.hasOwnProperty(categoryId)) {
      this._filterData[categoryId] = {cn: nodeSearchCategoryValueResource.cn, cv: categoryValueObject};
    }else {
      const currentCategoryValueObject = this._filterData[categoryId]['cv'];
      if (!currentCategoryValueObject.hasOwnProperty(categoryValueId)) {
        Object.assign(currentCategoryValueObject, categoryValueObject);
      }
    }
  }

  public filterSelectionDataHandler() {
    const categoryIdList = Object.keys(this._filterDataState);
    this._flatMapNodeList.forEach((eachMapData) => {
      let nodeCount = 0;
      const eachMapNodeList = eachMapData[3];
      eachMapNodeList.forEach((eachNode) => {
        const currentNodeCategoryValueIdList = eachNode[2][1];
        if (categoryIdList.length === 0) {
          eachNode[3] = true;
        } else if ( categoryIdList.length === 1 ) {
          eachNode[3] = _.intersection(currentNodeCategoryValueIdList, this._filterDataState[categoryIdList[0]]).length > 0;
        } else {
          let multiCategoryState = true;
          categoryIdList.forEach((eachCategoryId) => {
            multiCategoryState = multiCategoryState &&
              (_.difference(this._filterDataState[eachCategoryId], currentNodeCategoryValueIdList).length === 0);
          });
          eachNode[3] = multiCategoryState;
        }
        if (eachNode[3]) { nodeCount += 1; }
      });
      eachMapData[5] = categoryIdList.length > 0 ? nodeCount : null;
    });
  }
}
