import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {BannerNotificationsService} from '../../../xform-compat';
import 'tinymce/tinymce.min';
import 'tinymce/themes/modern';
import 'tinymce/plugins/link';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';

/**
 * Declare a variable to prevent errors
 * Because of unrecognized 3rd party type
 */
declare var tinymce: any;
declare var $: any;

/**
 * Component class for TinyMCE rich text editor
 */
@Component({
    selector: 'emap-rich-text-editor',
    template: `<textarea id="{{elementId}}" required>{{content}}</textarea>`,
    styleUrls: ['./rich-text-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTextEditorComponent implements OnDestroy, AfterViewInit {
    @Input() elementId: string;
    @Input() content: string;
    @Output() onEditorChange = new EventEmitter<{ content: string, isOffline: boolean }>();
    private isOffline: boolean;
    public editor: any;

    constructor(
        private bannerNotificationService: BannerNotificationsService,
    ) {
    }

    /**
     * Waits until the browser renders the component to init the editor and set the configs
     */
    ngAfterViewInit(): void {
        tinymce.init({
            selector: `#${this.elementId}`,
            // tslint:disable-next-line:max-line-length
            font_formats: 'Sans Serif=sans-serif;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
            plugins: ['link autoresize textcolor colorpicker image lists table'],
            table_appearance_options: false,
            toolbar: 'undo redo | styleselect | fontselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify ' +
                '| numlist bullist outdent indent | forecolor backcolor | table tabledelete | tableprops tablerowprops ' +
                'tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
            menubar: 'edit insert view format table tools',
            skin_url: '../../../assets/third-parties/tinymce/skins/lightgray',
            setup: editor => {
                this.editor = editor;
                editor.on('init', () => {
                    this.content = this.content || '';
                    editor.setContent(this.content);
                    editor.focus();
                });
                this.editor.on('change', () => {
                    this.onEditorUpdated();
                });
                this.editor.on('keyup', () => {
                    this.onEditorUpdated();
                });
            }
        });
    }

    /**
     * Destroys the editor instance on destroy the component
     */
    ngOnDestroy(): void {
        tinymce.remove(this.editor);
    }

    private onEditorUpdated(): void {
        this.onEditorChange.emit({
            content: this.editor.getContent(),
            isOffline: this.isOffline
        });
    }
}
