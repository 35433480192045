
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


/**
 * Data service for Heartbeat requests
 */
@Injectable({
  providedIn: 'root'
})
export class HeartbeatService {

  constructor(private http: HttpClient) {}

  public get(): Observable<any> {
    const url = '/api/uaa/heartbeats';

    return this.http
      .post(url, {}).pipe(
        catchError(ex => { throw ex; }));
  }

}
