<div class="container-fluid">
  <div class="row">
    <div class="col text-center maps-manager-parent">
      <div class="float-left p-2 pb-1 maps-manager-header page-header-title">
        <span class="header-static-name">{{ (mapsManagerVersionHistoryMode ? "MAPS_MANAGER.VERSION_HISTORY" : "MAPS_MANAGER.MAPS_MANAGER") | translate}}</span>
        <span class="header-dynamic-map-name page-header-title-name">{{ (mapsManagerVersionHistoryMode ? " | ".concat(getSelectedMapManagerName()) : "")}}</span>
      </div>
      <div class="col-5 d-flex float-right" [hidden]="mapsManagerVersionHistoryMode || mapsManagerArchiveMode">
        <div class="col-9 maps-manager-search">
          <div class="search-input">
            <span class="fa fa-search"></span>
            <input class="ml-2" type="text" #mapsManagerSearch
                   (keyup)="mapsManagerSearchTypeahead$.next(mapsManagerSearch.value)"
                   [(ngModel)]="mapsManagerSearchTerm" placeholder="Search by Map Name">
          </div>
          <div class="p-1 float-right map-count">Map Count: {{list.totalRecords}}</div>
        </div>
        <button class="btn btn-primary position-absolute btn-right-float mt-2 maps-manager-add-map-button"
                (click)="showModal()" *ngIf="authorizationService.hasPermission([PERMISSIONS.EmapMedia])">
          {{"MAPS_MANAGER.ADD_MAP" | translate}}
        </button>
      </div>
      <div class="col-2 mb-4 pt-2 d-flex float-right maps-manager-toggle"
           [hidden]="!mapsManagerVersionHistoryMode" (click)="recordAdded()">
        <div>{{"MAPS_MANAGER.BACK_TO_MAPS_MANAGER" | translate | uppercase}}</div>
      </div>
      <div class="col-2 mb-4 pt-2 d-flex float-right maps-manager-toggle"
           [hidden]="!mapsManagerArchiveMode" (click)="recordAdded()">
        <div>{{"MAPS_MANAGER.BACK_TO_MAPS_MANAGER" | translate | uppercase}}</div>
      </div>
    </div>
  </div>
  <div class="row maps-manager-body" [hidden]="mapsManagerArchiveMode">
    <div class="w-100 maps-manager-table" matSort matSortActive="name" matSortDirection="asc"
         (matSortChange)="sortTable($event)">
      <div class="maps-manager-row header-row py-2">
        <div mat-sort-header="name"
             class="maps-manager-table-header header-map-name col-l">{{"MAPS_MANAGER.MAP_NAME" | translate | uppercase}}</div>
        <div class="maps-manager-table-header col-m">{{"MAPS_MANAGER.VERSION" | translate | uppercase}}</div>
        <div mat-sort-header="versionUpdated"
             class="maps-manager-table-header col-s">{{"MAPS_MANAGER.UPDATED" | translate | uppercase}}</div>
        <div mat-sort-header="interactive"
             class="maps-manager-table-header col-s">{{"MAPS_MANAGER.INTERACTIVE" | translate | uppercase}}</div>
        <div class="maps-manager-table-header col-s">{{"MAPS_MANAGER.PUBLISHED_STATE" | translate | uppercase}}</div>
        <div class="maps-manager-table-header col-l flex-grow-1"></div>
      </div>
      <div *ngFor="let eachMapsManager of list.records; let mapsManagerIndex = index">
        <div class="maps-manager-row" [hidden]="!eachMapsManagerVersion.active"
             [class.maps-manager-row-odd]="mapsManagerIndex % 2 == 1"
             *ngFor="let eachMapsManagerVersion of eachMapsManager.mapsManagerVersions; let index = index">
          <div class="maps-manager-version-row" *ngIf="mapsManagerVersionHistoryMode || index === 0">
            <div class="col-l px-1 maps-manager-col maps-manager-col-name"
                 [class.map-name-visible]="index != 0">
                {{eachMapsManager.name}}
            </div>
            <div class="col-m px-1 maps-manager-col maps-manager-col-version">
              <div class="maps-manager-version-name">
              <span class="maps-manager-version-name-label" [hidden]="eachMapsManagerVersion.isEdit">
                {{eachMapsManagerVersion.name}}
              </span>
                <input class="maps-manager-version-name-input"
                       [hidden]="!eachMapsManagerVersion.isEdit"
                       [(ngModel)]="eachMapsManagerVersion.name">
                <button type="button"
                        class="maps-manager-version-edit-button btn btn-outline-teal text-transform-none mb-1 button-bck-white button-edit"
                        [hidden]="!eachMapsManagerVersion.isEdit"
                        (click)="eachMapsManagerVersion.isEdit = false">
                  <i class="maps-manager-version-edit-icon maps-manager-version-edit-close"></i>
                </button>
                <button type="button"
                        class="maps-manager-version-edit-button btn btn-outline-teal text-transform-none mb-1 button-bck-white button-edit"
                        [hidden]="!eachMapsManagerVersion.isEdit"
                        (click)="saveMapsManagerVersion(eachMapsManagerVersion, eachMapsManager.id)">
                  <i class="maps-manager-version-edit-icon maps-manager-version-edit-save"></i>
                </button>
                <button type="button"
                        class="maps-manager-version-edit-button btn btn-outline-teal text-transform-none mb-1 button-bck-white button-edit"
                        [hidden]="eachMapsManagerVersion.isEdit"
                        (click)="editMapsManagerVersion(eachMapsManagerVersion)">
                  <i class="maps-manager-version-edit-icon maps-manager-version-edit-pencil"></i>
                </button>
                <button type="button"
                        class="maps-manager-version-edit-button btn btn-outline-teal text-transform-none mb-1 button-bck-white button-edit"
                        [hidden]="!eachMapsManagerVersion.interactive || eachMapsManager.mapsManagerVersionArchiveCount < 1 || eachMapsManagerVersion.isEdit"
                        (click)="getArchive(eachMapsManagerVersion.id)">
                  <i class="maps-manager-version-edit-icon maps-manager-version-edit-archive"></i>
                </button>
              </div>
              <div class="maps-manager-version-count" [hidden]="mapsManagerVersionHistoryMode"
                   (click)="mapsManagerVersionHistory(eachMapsManager.id)">
                {{"MAPS_MANAGER.VERSION_HISTORY" | translate}} ({{eachMapsManager.mapsManagerVersionCount}})
              </div>
            </div>
            <div class="col-s maps-manager-col maps-manager-col-updated">
              <div [innerText]="formatTimestamp(eachMapsManagerVersion.updated)"></div>
            </div>
            <div class="col-s px-1 maps-manager-col maps-manager-col-interactive px-0">
              <div [ngClass]="eachMapsManagerVersion.interactive ? 'interactive' : 'static'">
                {{eachMapsManagerVersion.interactive ? 'Interactive' : 'Static'}}
              </div>
            </div>
            <div class="col-s px-1 maps-manager-col maps-manager-col-published-state px-0"
                 [ngClass]="{ 'fail': mapsManagerVersionPublishedStatuses.get(eachMapsManagerVersion.id) === 'failed' }"
                 [innerHTML]="mapsManagerVersionPublishedStatuses.get(eachMapsManagerVersion.id) | titlecase | highlight:'interactive'">
            </div>
            <div class="col-l px-1 maps-manager-col maps-manager-actions flex-grow-1">
              <emap-maps-manager-version-action-list [mapsManagerVersion]="eachMapsManagerVersion"
                                                     [mapsManager]="eachMapsManager"
                                                     [isVersionPage]="mapsManagerVersionHistoryMode"
                                                     [refresh]="refresh.bind(this)"
                                                     [uploader]="uploader"></emap-maps-manager-version-action-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <emap-pagination [pagination]="pagination"
                       [totalRecords]="list.totalRecords"
                       (onPageChanged)="refresh()"
                       (onRecordsPerPageChanged)="refresh()">
      </emap-pagination>
    </div>
  </div>
  <div class="row maps-manager-version-archive"
       *ngIf="selectedMapsManagerVersion"
       [hidden]="!mapsManagerArchiveMode">
    <div class="col-12 py-2 maps-manager-table">
      <div class="row p-2">
        <div class="maps-manager-table-header py-2 px-1 col-4">{{"MAPS_MANAGER.VERSION" | translate | uppercase}}</div>
        <div class="maps-manager-table-header py-2 px-1 col-4">{{"MAPS_MANAGER.UPDATED" | translate | uppercase}}</div>
        <div class="maps-manager-table-header py-2 px-1 col-4">{{"MAPS_MANAGER.ACTIONS" | translate | uppercase}}</div>
      </div>
      <div *ngFor="let item of selectedMapsManagerVersion.mapsManagerVersionArchives; let archiveIndex = index">
        <div class="row maps-manager-row">
          <div class="col-4 px-1 maps-manager-col maps-manager-col-name"
               [class.map-name-visible]="archiveIndex != 0">
            {{selectedMapsManagerVersion.name}}
          </div>
          <div class="col-4 px-1 maps-manager-col maps-manager-col-updated">
            <div class="updated-timestamp" [innerText]="formatTimestamp(item.updated)"></div>
          </div>
          <div class="col-4 px-1 maps-manager-col maps-manager-actions">
            <div class="maps-manager-version-icon icon-shadow maps-manager-version-mindmap"
                 (click)="openMindMapArchive(selectedMapsManagerVersion.id, item.id)"
                 ngbTooltip="Open MindMap"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <emap-maps-manager-form (onRecordProcessed)="recordAdded()"></emap-maps-manager-form>
</div>
