export class WSNotification {
  titles: Array<object>;
  actions: Array<string>;
  mentions: Array<string>;

  constructor() {
    this.titles = [
      {
        type: null,
        name: null
      }
    ];
    this.actions = [];
    this.mentions = [];
  }
}
