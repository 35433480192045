import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoggedGuard } from '../../core/guards/logged.guard';
import { TwoFactorComponent } from './two-factor/two-factor.component';
import { PasswordExpiredComponent } from './password-expired/password-expired.component';
import { OktaLoginComponent } from './okta-login/okta-login.component';


export const routedComponents = [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, PasswordExpiredComponent, OktaLoginComponent  ];

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent, canLoad: [LoggedGuard], canActivate: [LoggedGuard] },
  { path: 'two-factor', component: TwoFactorComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'okta-login', component: OktaLoginComponent },
  { path: 'password-expired', component: PasswordExpiredComponent }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuthRoutingModule {}
