import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {CONFIG} from '../../config';


@Injectable()
export class UtilsService {
  private config = CONFIG;

  constructor() {
  }

  public isObjectOnList(obj: object, list: Array<any>, key?: string) {
    let found = false;
    key = key || 'id';

    for (const item of list) {
      if (item[key] === obj[key]) {
        found = true;
      }
    }

    return found;
  }

  public getUUID() {
    return new Date().getTime();
  }

  public generateParams(params: any): HttpParams {
    let httpParams: HttpParams = new HttpParams();

    for (const key in params) {
      if (params[key] instanceof Array) {
        // tslint:disable-next-line:forin
        for (const value in params[key]) {
          httpParams = httpParams.append(key, params[key][value]);
        }
      } else {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return httpParams;
  }

  public generateParamsWithArray(params: Array<string>, paramName: string): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    params.forEach((param) => {
      httpParams = httpParams.append(paramName, param);
    });
    return httpParams;
  }

  public getAPIPath(): string {
    return this.config.apiUrl;
  }

  public static replaceGreekLetters(htmlStrVal: string) {
    htmlStrVal = htmlStrVal.replace(/&fnof;/g, '&#x192;');
    htmlStrVal = htmlStrVal.replace(/&Alpha;/g, '&#x391;');
    htmlStrVal = htmlStrVal.replace(/&Beta;/g, '&#x392;');
    htmlStrVal = htmlStrVal.replace(/&Gamma;/g, '&#x393;');
    htmlStrVal = htmlStrVal.replace(/&Delta;/g, '&#x394;');
    htmlStrVal = htmlStrVal.replace(/&Epsilon;/g, '&#x395;');
    htmlStrVal = htmlStrVal.replace(/&Zeta;/g, '&#x396;');
    htmlStrVal = htmlStrVal.replace(/&Eta;/g, '&#x397;');
    htmlStrVal = htmlStrVal.replace(/&Theta;/g, '&#x398;');
    htmlStrVal = htmlStrVal.replace(/&Iota;/g, '&#x399;');
    htmlStrVal = htmlStrVal.replace(/&Kappa;/g, '&#x39A;');
    htmlStrVal = htmlStrVal.replace(/&Lambda;/g, '&#x39B;');
    htmlStrVal = htmlStrVal.replace(/&Mu;/g, '&#x39C;');
    htmlStrVal = htmlStrVal.replace(/&Nu;/g, '&#x39D;');
    htmlStrVal = htmlStrVal.replace(/&Xi;/g, '&#x39E;');
    htmlStrVal = htmlStrVal.replace(/&Omicron;/g, '&#x39F;');
    htmlStrVal = htmlStrVal.replace(/&Pi;/g, '&#x3A0;');
    htmlStrVal = htmlStrVal.replace(/&Rho;/g, '&#x3A1;');
    htmlStrVal = htmlStrVal.replace(/&Sigma;/g, '&#x3A3;');
    htmlStrVal = htmlStrVal.replace(/&Tau;/g, '&#x3A4;');
    htmlStrVal = htmlStrVal.replace(/&Upsilon;/g, '&#x3A5;');
    htmlStrVal = htmlStrVal.replace(/&Phi;/g, '&#x3A6;');
    htmlStrVal = htmlStrVal.replace(/&Chi;/g, '&#x3A7;');
    htmlStrVal = htmlStrVal.replace(/&Psi;/g, '&#x3A8;');
    htmlStrVal = htmlStrVal.replace(/&Omega;/g, '&#x3A9;');
    htmlStrVal = htmlStrVal.replace(/&alpha;/g, '&#x3B1;');
    htmlStrVal = htmlStrVal.replace(/&beta;/g, '&#x3B2;');
    htmlStrVal = htmlStrVal.replace(/&gamma;/g, '&#x3B3;');
    htmlStrVal = htmlStrVal.replace(/&delta;/g, '&#x3B4;');
    htmlStrVal = htmlStrVal.replace(/&epsilon;/g, '&#x3B5;');
    htmlStrVal = htmlStrVal.replace(/&zeta;/g, '&#x3B6;');
    htmlStrVal = htmlStrVal.replace(/&eta;/g, '&#x3B7;');
    htmlStrVal = htmlStrVal.replace(/&theta;/g, '&#x3B8;');
    htmlStrVal = htmlStrVal.replace(/&iota;/g, '&#x3B9;');
    htmlStrVal = htmlStrVal.replace(/&kappa;/g, '&#x3BA;');
    htmlStrVal = htmlStrVal.replace(/&lambda;/g, '&#x3BB;');
    htmlStrVal = htmlStrVal.replace(/&mu;/g, '&#x3BC;');
    htmlStrVal = htmlStrVal.replace(/&nu;/g, '&#x3BD;');
    htmlStrVal = htmlStrVal.replace(/&xi;/g, '&#x3BE;');
    htmlStrVal = htmlStrVal.replace(/&omicron;/g, '&#x3BF;');
    htmlStrVal = htmlStrVal.replace(/&pi;/g, '&#x3C0;');
    htmlStrVal = htmlStrVal.replace(/&rho;/g, '&#x3C1;');
    htmlStrVal = htmlStrVal.replace(/&sigmaf;/g, '&#x3C2;');
    htmlStrVal = htmlStrVal.replace(/&sigma;/g, '&#x3C3;');
    htmlStrVal = htmlStrVal.replace(/&tau;/g, '&#x3C4;');
    htmlStrVal = htmlStrVal.replace(/&upsilon;/g, '&#x3C5;');
    htmlStrVal = htmlStrVal.replace(/&phi;/g, '&#x3C6;');
    htmlStrVal = htmlStrVal.replace(/&chi;/g, '&#x3C7;');
    htmlStrVal = htmlStrVal.replace(/&psi;/g, '&#x3C8;');
    htmlStrVal = htmlStrVal.replace(/&omega;/g, '&#x3C9;');
    htmlStrVal = htmlStrVal.replace(/&thetasym;/g, '&#x3D1;');
    htmlStrVal = htmlStrVal.replace(/&upsih;/g, '&#x3D2;');
    htmlStrVal = htmlStrVal.replace(/&piv;/g, '&#x3D6;');
    htmlStrVal = htmlStrVal.replace(/&bull;/g, '&#x2022;');
    htmlStrVal = htmlStrVal.replace(/&hellip;/g, '&#x2026;');
    htmlStrVal = htmlStrVal.replace(/&prime;/g, '&#x2032;');
    htmlStrVal = htmlStrVal.replace(/&Prime;/g, '&#x2033;');
    htmlStrVal = htmlStrVal.replace(/&oline;/g, '&#x203E;');
    htmlStrVal = htmlStrVal.replace(/&frasl;/g, '&#x2044;');
    htmlStrVal = htmlStrVal.replace(/&weierp;/g, '&#x2118;');
    htmlStrVal = htmlStrVal.replace(/&image;/g, '&#x2111;');
    htmlStrVal = htmlStrVal.replace(/&real;/g, '&#x211C;');
    htmlStrVal = htmlStrVal.replace(/&trade;/g, '&#x2122;');
    htmlStrVal = htmlStrVal.replace(/&alefsym;/g, '&#x2135;');
    htmlStrVal = htmlStrVal.replace(/&larr;/g, '&#x2190;');
    htmlStrVal = htmlStrVal.replace(/&uarr;/g, '&#x2191;');
    htmlStrVal = htmlStrVal.replace(/&rarr;/g, '&#x2192;');
    htmlStrVal = htmlStrVal.replace(/&darr;/g, '&#x2193;');
    htmlStrVal = htmlStrVal.replace(/&harr;/g, '&#x2194;');
    htmlStrVal = htmlStrVal.replace(/&crarr;/g, '&#x21B5;');
    htmlStrVal = htmlStrVal.replace(/&lArr;/g, '&#x21D0;');
    htmlStrVal = htmlStrVal.replace(/&uArr;/g, '&#x21D1;');
    htmlStrVal = htmlStrVal.replace(/&rArr;/g, '&#x21D2;');
    htmlStrVal = htmlStrVal.replace(/&dArr;/g, '&#x21D3;');
    htmlStrVal = htmlStrVal.replace(/&hArr;/g, '&#x21D4;');
    htmlStrVal = htmlStrVal.replace(/&forall;/g, '&#x2200;');
    htmlStrVal = htmlStrVal.replace(/&part;/g, '&#x2202;');
    htmlStrVal = htmlStrVal.replace(/&exist;/g, '&#x2203;');
    htmlStrVal = htmlStrVal.replace(/&empty;/g, '&#x2205;');
    htmlStrVal = htmlStrVal.replace(/&nabla;/g, '&#x2207;');
    htmlStrVal = htmlStrVal.replace(/&isin;/g, '&#x2208;');
    htmlStrVal = htmlStrVal.replace(/&notin;/g, '&#x2209;');
    htmlStrVal = htmlStrVal.replace(/&ni;/g, '&#x220B;');
    htmlStrVal = htmlStrVal.replace(/&prod;/g, '&#x220F;');
    htmlStrVal = htmlStrVal.replace(/&sum;/g, '&#x2211;');
    htmlStrVal = htmlStrVal.replace(/&minus;/g, '&#x2212;');
    htmlStrVal = htmlStrVal.replace(/&lowast;/g, '&#x2217;');
    htmlStrVal = htmlStrVal.replace(/&radic;/g, '&#x221A;');
    htmlStrVal = htmlStrVal.replace(/&prop;/g, '&#x221D;');
    htmlStrVal = htmlStrVal.replace(/&infin;/g, '&#x221E;');
    htmlStrVal = htmlStrVal.replace(/&ang;/g, '&#x2220;');
    htmlStrVal = htmlStrVal.replace(/&and;/g, '&#x2227;');
    htmlStrVal = htmlStrVal.replace(/&or;/g, '&#x2228;');
    htmlStrVal = htmlStrVal.replace(/&cap;/g, '&#x2229;');
    htmlStrVal = htmlStrVal.replace(/&cup;/g, '&#x222A;');
    htmlStrVal = htmlStrVal.replace(/&int;/g, '&#x222B;');
    htmlStrVal = htmlStrVal.replace(/&there4;/g, '&#x2234;');
    htmlStrVal = htmlStrVal.replace(/&sim;/g, '&#x223C;');
    htmlStrVal = htmlStrVal.replace(/&cong;/g, '&#x2245;');
    htmlStrVal = htmlStrVal.replace(/&asymp;/g, '&#x2248;');
    htmlStrVal = htmlStrVal.replace(/&ne;/g, '&#x2260;');
    htmlStrVal = htmlStrVal.replace(/&equiv;/g, '&#x2261;');
    htmlStrVal = htmlStrVal.replace(/&le;/g, '&#x2264;');
    htmlStrVal = htmlStrVal.replace(/&ge;/g, '&#x2265;');
    htmlStrVal = htmlStrVal.replace(/&sub;/g, '&#x2282;');
    htmlStrVal = htmlStrVal.replace(/&sup;/g, '&#x2283;');
    htmlStrVal = htmlStrVal.replace(/&nsub;/g, '&#x2284;');
    htmlStrVal = htmlStrVal.replace(/&sube;/g, '&#x2286;');
    htmlStrVal = htmlStrVal.replace(/&supe;/g, '&#x2287;');
    htmlStrVal = htmlStrVal.replace(/&oplus;/g, '&#x2295;');
    htmlStrVal = htmlStrVal.replace(/&otimes;/g, '&#x2297;');
    htmlStrVal = htmlStrVal.replace(/&perp;/g, '&#x22A5;');
    htmlStrVal = htmlStrVal.replace(/&sdot;/g, '&#x22C5;');
    htmlStrVal = htmlStrVal.replace(/&lceil;/g, '&#x2308;');
    htmlStrVal = htmlStrVal.replace(/&rceil;/g, '&#x2309;');
    htmlStrVal = htmlStrVal.replace(/&lfloor;/g, '&#x230A;');
    htmlStrVal = htmlStrVal.replace(/&rfloor;/g, '&#x230B;');
    htmlStrVal = htmlStrVal.replace(/&lang;/g, '&#x2329;');
    htmlStrVal = htmlStrVal.replace(/&rang;/g, '&#x232A;');
    htmlStrVal = htmlStrVal.replace(/&loz;/g, '&#x25CA;');
    htmlStrVal = htmlStrVal.replace(/&spades;/g, '&#x2660;');
    htmlStrVal = htmlStrVal.replace(/&clubs;/g, '&#x2663;');
    htmlStrVal = htmlStrVal.replace(/&hearts;/g, '&#x2665;');
    htmlStrVal = htmlStrVal.replace(/&diams;/g, '&#x2666;');
    return htmlStrVal;
  }

  public static replaceUnicodeToHtml(text: any) {
    return text
        .replaceAll('&', '&amp;')
        .replaceAll('<', '&lt;')
        .replaceAll('>', '&gt;')
        .replaceAll('"', '&quot;')
        .replaceAll('\'', '&#039;')
        .replaceAll(/\u2028/gu, '')
        .replaceAll(/\u201D/gu, '')
        .replaceAll('“', '')
        .replaceAll('•', '')
        .replaceAll(/\u0060/gu, '&grave;')
        .replaceAll(/\u20B9/gu, '&#x20B9;')
        .replaceAll(/\u0152/gu, '&OElig;')
        .replaceAll(/\u0153/gu, '&oelig;')
        .replaceAll(/\u0160/gu, '&Scaron;')
        .replaceAll(/\u0161/gu, '&scaron;')
        .replaceAll(/\u0178/gu, '&Yuml;')
        .replaceAll(/\u0192/gu, '&fnof;')
        .replaceAll(/\u02c6/gu, '&circ;')
        .replaceAll(/\u02dc/gu, '&tilde;')
        .replaceAll(/\u0391/gu, '&Alpha;')
        .replaceAll(/\u0392/gu, '&Beta;')
        .replaceAll(/\u0395/gu, '&Gamma;')
        .replaceAll(/\u0394/gu, '&Delta;')
        .replaceAll(/\u0395/gu, '&Epsilon;')
        .replaceAll(/\u0396/gu, '&Zeta;')
        .replaceAll(/\u0397/gu, '&Eta;')
        .replaceAll(/\u0398/gu, '&Theta;')
        .replaceAll(/\u0399/gu, '&Iota;')
        .replaceAll(/\u039a/gu, '&Kappa;')
        .replaceAll(/\u039b/gu, '&Lambda;')
        .replaceAll(/\u039c/gu, '&Mu;')
        .replaceAll(/\u039D/gu, '&Nu;')
        .replaceAll(/\u039e/gu, '&Xi;')
        .replaceAll(/\u039f/gu, '&Omicron;')
        .replaceAll(/\u03a0/gu, '&Pi;')
        .replaceAll(/\u03a1/gu, '&Rho;')
        .replaceAll(/\u03A3/gu, '&Sigma;')
        .replaceAll(/\u03A4/gu, '&Tau;')
        .replaceAll(/\u03A5/gu, '&Upsilon;')
        .replaceAll(/\u03A6/gu, '&Phi;')
        .replaceAll(/\u03A7/gu, '&Chi;')
        .replaceAll(/\u03A8/gu, '&Psi;')
        .replaceAll(/\u03A9/gu, '&Omega;')
        .replaceAll(/\u03B1/gu, '&alpha;')
        .replaceAll(/\u03B2/gu, '&beta;')
        .replaceAll(/\u03B3/gu, '&gamma;')
        .replaceAll(/\u03B4/gu, '&delta;')
        .replaceAll(/\u03B5/gu, '&epsilon;')
        .replaceAll(/\u03B6/gu, '&zeta;')
        .replaceAll(/\u03B7/gu, '&eta;')
        .replaceAll(/\u03B8/gu, '&theta;')
        .replaceAll(/\u03B9/gu, '&iota;')
        .replaceAll(/\u03Ba/gu, '&kappa;')
        .replaceAll(/\u03Bb/gu, '&lambda;')
        .replaceAll(/\u03Bc/gu, '&mu;')
        .replaceAll(/\u03Bd/gu, '&nu;')
        .replaceAll(/\u03Be/gu, '&xi;')
        .replaceAll(/\u03Bf/gu, '&omicron;')
        .replaceAll(/\u03c0/gu, '&pi;')
        .replaceAll(/\u03c1/gu, '&rho;')
        .replaceAll(/\u03C2/gu, '&sigmaf;')
        .replaceAll(/\u03C3/gu, '&sigma;')
        .replaceAll(/\u03C4/gu, '&tau;')
        .replaceAll(/\u03C5/gu, '&upsilon;')
        .replaceAll(/\u03C7/gu, '&chi;')
        .replaceAll(/\u03C8/gu, '&psi;')
        .replaceAll(/\u03C9/gu, '&omega;')
        .replaceAll(/\u03D1/gu, '&thetasym;')
        .replaceAll(/\u03D2/gu, '&upsih;')
        .replaceAll(/\u03D6/gu, '&piv;')
        .replaceAll(/\u2002/gu, '&ensp;')
        .replaceAll(/\u2003/gu, '&emsp;')
        .replaceAll(/\u2009/gu, '&thinsp;')
        .replaceAll(/\u200C/gu, '&zwnj;')
        .replaceAll(/\u200d/gu, '&zwj;')
        .replaceAll(/\u200e/gu, '&lrm;')
        .replaceAll(/\u200f/gu, '&rlm;')
        .replaceAll(/\u2013/gu, '&ndash;')
        .replaceAll(/\u2014/gu, '&mdash;')
        .replaceAll(/\u2018/gu, '&lsquo;')
        .replaceAll(/\u2019/gu, '&rsquo;')
        .replaceAll(/\u201a/gu, '&sbquo;')
        .replaceAll(/\u201c/gu, '&ldquo;')
        .replaceAll(/\u201d/gu, '&rdquo;')
        .replaceAll(/\u201e/gu, '&bdquo;')
        .replaceAll(/\u2020/gu, '&dagger;')
        .replaceAll(/\u2021/gu, '&Dagger;')
        .replaceAll(/\u2030/gu, '&permil;')
        .replaceAll(/\u2039/gu, '&lsaquo;')
        .replaceAll(/\u203a/gu, '&rsaquo;')
        .replaceAll(/\u2219/gu, '&bull;');
  }

}
