import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { CONFIG } from '../../config';
import { ExceptionService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';
import { MapsManagerVersion } from '../../shared/models/mapsManagerVersion.model';
import {RemoteServerDeploy} from "../../shared/models/remoteServerDeploy.model";

@Injectable()
export class DeploymentService {

  constructor(
    private exceptionService: ExceptionService,
    private http: HttpClient,
  ) { }

  public updateMapsToTvs(mapVersionList: Array<MapsManagerVersion>) {
    return this.updateRequest(this.getUpdatePath(), mapVersionList);
  }

  public unpublishMapsFromTvs(deploymentList: Array<RemoteServerDeploy>) {
    return this.http.post(this.getUnpublishPath(), deploymentList)
        .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  private updateRequest(apiUrl: string, body: any) {
    return this.http.put(apiUrl, body)
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  private getResourcePath(): string {
    return `${CONFIG.apiUrl}/core/service/deploy`;
  }

  private getUpdatePath() {
    return this.getResourcePath().concat('/update/all');
  }

  private getUnpublishPath() {
    return this.getResourcePath().concat('/unpublish/all');
  }
}
