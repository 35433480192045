/* -- Angular Dependencies -- */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
/* -- 3rd party Dependencies -- */
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
/* -- Custom Services -- */
import { HttpLoaderFactory } from './core/i18n/loader';
/* -- Custom Components -- */
import { AppComponent } from './app.component';
/* -- Custom Modules -- */
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { SystemAdminModule } from './modules/system-admin/system-admin.module';
import { AdminModule } from './modules/admin/admin.module';
import { HeaderModule } from './modules/header/header.module';

import { CONFIG } from './config';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MapModule } from './modules/map/map.module';
import { AuthoringModule } from './modules/authoring/authoring.module';
import { MapsManagerModule } from './modules/maps-manager/maps-manager.module';
import { XFORMModule } from './xform-compat';
import { MindMapModule } from './modules/mind-map/mind-map.module';
import {NodesManagerModule} from "./modules/nodes-manager/nodes-manager.module";


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    XFORMModule.forRoot({
      configFile: CONFIG,
      translationService: TranslateService
    }),
    AuthenticationModule,
    DashboardModule,
    HeaderModule,
    SystemAdminModule,
    AdminModule,
    MapModule,
    MapsManagerModule,
    NodesManagerModule,
    MindMapModule,
    AuthoringModule,
    AppRoutingModule // always AppRoutingModule at the end
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
