import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';

import { MapsManagerRoutingModule } from './maps-manager-routing.module';
import { MapsManagerListComponent } from './maps-manager-list/maps-manager-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { FileUploadModule } from 'ng2-file-upload';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsSettingsService } from '../../core/data-services/notificationSettings.service';
import { MapsManagerFormComponent } from './maps-manager-form/maps-manager-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TvPublisherComponent } from './tv-publisher/tv-publisher.component';
import { MapsManagerVersionActionListComponent } from './maps-manager-version-action-list/maps-manager-version-action-list.component';
import {MapsManagerIntegrationService} from "../../shared/services/maps-manager-integration.service";
import { IndicationManagerComponent } from './indication-manager/indication-manager.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    MatSortModule,
    MapsManagerRoutingModule,
    FileUploadModule,
    TranslateModule,
    NgSelectModule,
  ],
  declarations: [MapsManagerListComponent, MapsManagerFormComponent,
     TvPublisherComponent, MapsManagerVersionActionListComponent, IndicationManagerComponent],
  exports: [MapsManagerListComponent, MapsManagerFormComponent],
  providers: [NgbActiveModal, NotificationsSettingsService, MapsManagerIntegrationService]
})
export class MapsManagerModule { }
