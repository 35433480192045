import { Injectable } from '@angular/core';
import {JqueryCommonServices} from './jqueryCommon.services';
import * as Promise from 'bluebird';
import * as _ from 'lodash';
import { GlobalIntegrationServices } from './globalIntegration.services';


@Injectable()
export class AnimationCommonServices {
  private PAGE_GREY_OUT_CLASS = 'morph-action-page-grey-out';
  private HIGHLIGHT_CLASS_SOLID = 'morph-action-highlight';
  private HIGHLIGHT_CLASS_BORDER = 'morph-action-highlight-border';
  private MORPH_ACTION_BRIGHTNESS_CLASS = 'morph-action-brightness';
  private GREY_OUT_MILLIS = 2000;

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private globalIntegrationServices: GlobalIntegrationServices,
  ) { }

  public greyOutTargetPage(targetName: any, excludingTarget: any, interval?: number) {
    return new Promise((resolve) => {
      const greyOutDiv = this.jqueryCommonServices.divConstruct().addClass(this.PAGE_GREY_OUT_CLASS);
      this.jqueryCommonServices.selector(targetName).prepend(greyOutDiv);
      this.jqueryCommonServices.selector(excludingTarget).addClass(this.MORPH_ACTION_BRIGHTNESS_CLASS);
      resolve(this.PAGE_GREY_OUT_CLASS.toClass());
    }).delay(_.isUndefined(interval) ? this.GREY_OUT_MILLIS : interval);
  }

  public removeGreyOut(greyOutClassName) {
    return new Promise((resolve) => {
      this.jqueryCommonServices.selector(greyOutClassName).remove();
      this.jqueryCommonServices.selector(this.MORPH_ACTION_BRIGHTNESS_CLASS.toClass()).removeClass(this.MORPH_ACTION_BRIGHTNESS_CLASS);
      resolve();
    });
  }

  public highlightTarget(target: any, isSolid: boolean) {
    return new Promise((resolve) => {
      const highlightClass = isSolid ? this.HIGHLIGHT_CLASS_SOLID : this.HIGHLIGHT_CLASS_BORDER;
      this.jqueryCommonServices.selector(target).addClass(highlightClass);
      resolve();
    });
  }

  public highlightTargetList(targets: any) {
    return new Promise((resolve) => {
      targets.forEach((id) => {
        this.jqueryCommonServices.selector(id.toId()).addClass(this.HIGHLIGHT_CLASS_BORDER);
      });
      resolve();
    });
  }

  public removeHighlights(targetClass) {
    return new Promise((resolve) => {
      this.jqueryCommonServices.selector(targetClass.toClass()).removeClass(targetClass);
      resolve();
    });
  }

  public zoomToPosition(target: any, disableAnimation: boolean) {
    return new Promise((resolve) => {
      const targetSelector = this.jqueryCommonServices.selector(target);
      const targetPosition = targetSelector.offset();
      const currentWidthHalf = targetSelector.width() / 2 * this.globalIntegrationServices.ratio;
      const currentHeightHalf = targetSelector.height() / 2 * this.globalIntegrationServices.ratio;
      this.globalIntegrationServices.currentZpanCommonService.zoomToPosition(targetPosition.top + currentHeightHalf, targetPosition.left + currentWidthHalf, disableAnimation);
      this.jqueryCommonServices.transitionEndEvent(this.globalIntegrationServices.currentMapImageSelector, () => {
        resolve();
      });
    });
  }

  public zoomToFit() {
    return new Promise((resolve) => {
      this.globalIntegrationServices.currentZpanCommonService.zoomToFit();
      this.jqueryCommonServices.transitionEndEvent(this.globalIntegrationServices.currentMapImageSelector, () => {
        resolve();
      });
    });
  }

  public rightSlideIn() {
    return new Promise((resolve) => {
      this.globalIntegrationServices.currentZpanCommonService.rightSlideIn();
      this.jqueryCommonServices.transitionEndEvent(this.globalIntegrationServices.currentMapImageSelector, () => {
        resolve();
      });
    });
  }

  public rightSlideOut() {
    return new Promise((resolve) => {
      this.globalIntegrationServices.currentZpanCommonService.rightSlideOut();
      this.jqueryCommonServices.transitionEndEvent(this.globalIntegrationServices.currentMapImageSelector, () => {
        resolve();
      });
    });
  }

  public loadImage(imageSrc: string) {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => resolve(imageSrc);
      image.src = imageSrc;
    });
  }

  public updateSource(targetSelector: any, source: string) {
    return new Promise((resolve) => {
      this.jqueryCommonServices.selector(targetSelector).prop('src', source);
      resolve();
    });
  }

  public moveTo(offset: any, disableAnimation: boolean) {
    return new Promise((resolve) => {
      this.globalIntegrationServices.currentZpanCommonService.moveTo(offset, disableAnimation);
      resolve();
    });
  }

  public accordionAction(event, resultsTableHeaderClass) {
    const target = this.jqueryCommonServices.selector(event.target);
    const currHeader = (target.hasClass(resultsTableHeaderClass)) ? target : target.parent();
    let currContent;
    currContent = currHeader.next('.ui-accordion-content');
    const isPanelSelected = currHeader.attr('aria-selected') === 'true';

    this.togglePanel(currHeader, currContent, isPanelSelected);

    if (!isPanelSelected) {
      this.collapseAllPanelsExcept(currHeader, resultsTableHeaderClass);
    }
  }

  private collapseAllPanelsExcept(currHeader, resultsTableHeaderClass) {
    const headers = this.jqueryCommonServices.selector(resultsTableHeaderClass.toClass()).not(currHeader);
    const contents = headers.next('.ui-accordion-content');
    this.togglePanel(headers, contents, true);
  }

  private togglePanel(header, content, isPanelSelected) {
    header.toggleClass('ui-corner-all ui-accordion-header-collapsed ', isPanelSelected)
      .toggleClass('accordion-header-active ui-state-active ui-corner-top ui-accordion-header-active', !isPanelSelected)
      .attr('aria-selected', ((!isPanelSelected).toString()));

    header.children('.ui-icon').toggleClass('ui-icon-triangle-1-e', isPanelSelected)
      .toggleClass('ui-icon-triangle-1-s', !isPanelSelected);

    content.toggleClass('accordion-content-active', !isPanelSelected);
    if (isPanelSelected) {
      content.slideUp();
    } else {
      content.slideDown();
    }
  }
}
