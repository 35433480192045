import * as go from 'gojs';
import {Binding, Diagram, GraphObject, Node, Picture, Spot, TreeLayout} from 'gojs';
import {UtilsService} from '../../xform-compat';
import {MindMapVersionNode} from '../../shared/models/mindMapVersionNode.model';
import {ProductCompanyInstance} from '../../shared/models/ProductCompanyInstance';

/**
 * A helper class for creating goJS elements for Mindmap. All maker functions in this class should be static
 * so that they can be called from the diagram's context.
 */
export class MindMapDiagramMaker {

    public static defaultNodeFontColor = 'black';
    public static defaultMilestoneFontColor = 'gray';
    private static nodeStrokeWidth = 1;
    private static nodePadding = 2;
    private static endNodeBarWidth = 6;
    private static endNodeBarSpace = 3;

    public diagram: Diagram;

    public static makeDiagram(): Diagram {
        return GraphObject.make(Diagram,
            {
                model: GraphObject.make(go.TreeModel),
                'commandHandler.copiesTree': true,
                'commandHandler.copiesParentKey': true,
                'commandHandler.deletesTree': true,
                'draggingTool.dragsTree': true,
                'undoManager.isEnabled': true,
                'toolManager.mouseWheelBehavior': go.ToolManager.WheelZoom,
                'LayoutCompleted': e => e.diagram.nodes.each(n => n.updateTargetBindings('html')),
                'resizingTool.resize': function (rect: any) {
                    go.ResizingTool.prototype.resize.call(this, rect);
                    this.adornedObject.part.updateTargetBindings('html');
                    MindMapDiagramMaker.layoutTree(this.adornedObject.part);
                }
            });
    }

    public static makeContextMenu() {
        const cxElement = document.getElementById('mind-map-context-menu');
        cxElement.addEventListener('mind-map-context-menu', e => {
            e.preventDefault();
            return false;
        }, false);

        return GraphObject.make(go.HTMLInfo, {show: MindMapDiagramMaker.showContextMenu, hide: MindMapDiagramMaker.hideContextMenu});
    }

    public static makeNodeTemplate(clickCallback: Function): go.Part {
        return GraphObject.make(go.Node, 'Spot',
            {
                resizable: true,
                selectionObjectName: 'TABLE',
                resizeAdornmentTemplate: getResizeAdornmentTemplate(),
                contextMenu: MindMapDiagramMaker.makeContextMenu(),
                mouseDragEnter: function (e, node: go.Node, prev) {
                    const diagram = node.diagram;
                    const selnode = diagram.selection.first() as go.Node;
                    if (!mayWorkFor(selnode, node)) {
                        return;
                    }
                    const shape = node.findObject('SHAPE') as go.Shape;
                    if (shape) {
                        diagram.model.setDataProperty(node.data, 'prevFill', shape.fill);
                        shape.fill = 'green';
                    }
                },
                mouseDragLeave: function (e, node: go.Node, next) {
                    const shape = node.findObject('SHAPE') as go.Shape;
                    if (shape && node.data.prevFill) {
                        shape.fill = node.data.prevFill;
                    }
                },
                mouseDrop: function (e, node: go.Node) {
                    const diagram = node.diagram;
                    const selNode = diagram.selection.first() as go.Node;
                    if (mayWorkFor(selNode, node)) {
                        const link = selNode.findTreeParentLink();
                        if (link !== null) {
                            reverseDirIfNeeded(diagram, node, selNode);
                            link.fromNode = node;
                        } else {
                            diagram.toolManager.linkingTool.insertLink(node, node.port, selNode, selNode.port);
                        }
                    }
                }
            },
            new Binding('width', 'width', parseFloat).makeTwoWay(s => s.toString()),
            new Binding('height', 'height', parseFloat).makeTwoWay(s => s.toString()),
            new Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
            new Binding('locationSpot', 'dir', (d) => MindMapDiagramMaker.spotConverter(d, false)),

            /** Make the default shape and style for nodes */
            GraphObject.make(go.Panel, 'Auto',
                GraphObject.make(go.Shape, 'RoundedRectangle',
                    {
                        name: 'SHAPE', fill: 'transparent',
                        portId: '', cursor: 'pointer', strokeWidth: MindMapDiagramMaker.nodeStrokeWidth,
                        fromLinkableDuplicates: true, toLinkableDuplicates: true,
                        fromSpot: go.Spot.LeftRightSides, toSpot: go.Spot.LeftRightSides,
                        spot1: go.Spot.TopLeft, spot2: go.Spot.BottomRight
                    },
                    new Binding('fill', 'isHighlighted', h => h ? 'gold' : '#ffffff').ofObject(),
                    new Binding('stroke', 'nodeStroke', (v) => v ? v : 'black').makeTwoWay(),
                    new Binding('stroke', 'text', (t) => !t ? 'red' : 'black'),
                    // User clicks Node background color in the Node Styling Panel
                    new Binding('fill', 'nodeBackground', (v) => v ? v : '#ffffff').makeTwoWay(),
                ),

                /** Format the content of the node */
                GraphObject.make(go.Panel, 'Table',
                    {
                        name: 'TABLE',
                        stretch: GraphObject.Fill
                    },
                    // Color bars to indicate end nodes and product/company bindings
                    // Company bar
                    GraphObject.make(go.Shape, 'Rectangle',
                        {
                            column: 0,
                            fill: '#D1D3D4',
                            width: MindMapDiagramMaker.endNodeBarWidth,
                            stretch: GraphObject.Vertical,
                            strokeWidth: 0,
                            parameter1: 4.5
                        },
                        new Binding('visible', 'isEndNode'),
                        new Binding('figure', 'dir', (v) => v === 'left' ? 'RoundedLeftRectangle' : 'Rectangle'),
                        new Binding('column', 'dir', (dir) => dir === 'left' ? 0 : 2),
                        new Binding('alignment', 'dir', (dir) => dir === 'left' ? Spot.Left : Spot.Right),
                        new Binding('fill', 'productCompanyInstances', (v) => getNodeBindingColor(v, 'company')),
                        new Binding('margin', 'dir', (dir) =>
                            dir === 'left' ?
                                new go.Margin(MindMapDiagramMaker.nodeStrokeWidth / 2,
                                    0, MindMapDiagramMaker.nodeStrokeWidth / 2,
                                    MindMapDiagramMaker.nodeStrokeWidth / 2) :
                                new go.Margin(MindMapDiagramMaker.nodeStrokeWidth / 2,
                                    MindMapDiagramMaker.endNodeBarWidth + MindMapDiagramMaker.endNodeBarSpace,
                                    MindMapDiagramMaker.nodeStrokeWidth / 2, 0))
                    ),
                    // Product bar
                    GraphObject.make(go.Shape, 'Rectangle',
                        {
                            column: 0,
                            fill: '#D1D3D4',
                            width: MindMapDiagramMaker.endNodeBarWidth,
                            stretch: GraphObject.Vertical,
                            strokeWidth: 0,
                            parameter1: 4.5
                        },
                        new Binding('visible', 'isEndNode'),
                        new Binding('figure', 'dir', (v) => v === 'right' ? 'RoundedRightRectangle' : 'Rectangle'),
                        new Binding('column', 'dir', (dir) => dir === 'left' ? 0 : 2),
                        new Binding('alignment', 'dir', (dir) => dir === 'left' ? Spot.Left : Spot.Right),
                        new Binding('fill', 'productCompanyInstances', (v) => getNodeBindingColor(v, 'product')),
                        new Binding('margin', 'dir', (dir) =>
                            dir === 'left' ?
                                new go.Margin(MindMapDiagramMaker.nodeStrokeWidth / 2,
                                    0, MindMapDiagramMaker.nodeStrokeWidth / 2,
                                    MindMapDiagramMaker.endNodeBarWidth + MindMapDiagramMaker.endNodeBarSpace) :
                                new go.Margin(MindMapDiagramMaker.nodeStrokeWidth / 2,
                                    MindMapDiagramMaker.nodeStrokeWidth / 2,
                                    MindMapDiagramMaker.nodeStrokeWidth / 2, 0))
                    ),
                    // Text content
                    GraphObject.make(go.TextBlock,
                        {
                            name: 'TEXT',
                            textAlign: 'left',
                            editable: true,
                            cursor: 'pointer',
                            stretch: GraphObject.Horizontal,
                            column: 1
                        },
                        new Binding('text', 'text').makeTwoWay(),
                        new Binding('font', 'font', (v) => v ? v : '13px sans-serif').makeTwoWay(),
                        new Binding('scale', 'scale', (v) => v ? v : 1).makeTwoWay(),
                        new Binding('isUnderline', 'isUnderline').makeTwoWay(),
                        new Binding('isStrikethrough', 'isStrikethrough').makeTwoWay(),
                        new Binding('stroke', '', MindMapDiagramMaker.getNodeFontColor).makeTwoWay(),
                        new Binding('visible', 'isRichText', (v) => !v),
                        new Binding('editable', 'isMilestone', (v) => !v),
                        new Binding('margin', '', getNodeTextMargin)
                    ),
                    // Image content
                    GraphObject.make(go.Picture,
                        {
                            row: 1,
                            name: 'IMAGE',
                            stretch: go.GraphObject.Fill,
                            imageStretch: go.GraphObject.Uniform,
                            margin: 1.5,
                            visible: false,
                            successFunction: (pic, e) => pic.visible = true
                        },
                        new Binding('source', 'image', (v) => v ? v : '').makeTwoWay()),
                    // Rich text content (rich text is converted to a svg and displayed as an image within the node)
                    GraphObject.make(go.Picture,
                        {
                            row: 1,
                            name: 'RICH',
                            margin: 1,
                            stretch: go.GraphObject.Fill,
                            imageStretch: go.GraphObject.None,
                        },
                        new Binding('visible', 'isRichText'),
                        new Binding('element', 'html', MindMapDiagramMaker.convertHtmlToImg),
                        {click: (e, node) => clickCallback(node)}
                    )
                ),
            ),

            /** External shape to warn the user of empty nodes */
            GraphObject.make(go.Panel, 'Auto', { alignment: Spot.Top },
                new Binding('visible', 'text', (d) => !d),
                GraphObject.make(go.Shape, 'RoundedRectangle', { height: 20, fill: 'red', strokeWidth: 0 }),
                GraphObject.make(go.TextBlock, { text: 'Node has no text', stroke: 'white', margin: 2 })
            )
        );

        /** Make the node resizing adornment */
        function getResizeAdornmentTemplate() {
            return GraphObject.make(go.Adornment, 'Spot',
                GraphObject.make(go.Placeholder),
                GraphObject.make(go.Shape, {
                    alignment: go.Spot.TopLeft,
                    desiredSize: new go.Size(8, 8),
                    fill: 'lightblue',
                    stroke: 'dodgerblue',
                    cursor: 'nw-resize'
                }),
                GraphObject.make(go.Shape, {
                    alignment: go.Spot.TopRight,
                    desiredSize: new go.Size(8, 8),
                    fill: 'lightblue',
                    stroke: 'dodgerblue',
                    cursor: 'ne-resize'
                }),
                GraphObject.make(go.Shape, {
                    alignment: go.Spot.BottomRight,
                    desiredSize: new go.Size(8, 8),
                    fill: 'lightblue',
                    stroke: 'dodgerblue',
                    cursor: 'se-resize'
                }),
                GraphObject.make(go.Shape, {
                    alignment: go.Spot.BottomLeft,
                    desiredSize: new go.Size(8, 8),
                    fill: 'lightblue',
                    stroke: 'dodgerblue',
                    cursor: 'sw-resize'
                })
            );
        }

        function reverseDirIfNeeded(diagram: go.Diagram, incomingNode: go.Node, destinationNode: go.Node) {
            const incomingNodeDir = incomingNode.data.dir;
            if (incomingNodeDir !== destinationNode) {
                updateNodeDirection(diagram, destinationNode, incomingNodeDir);
            }
        }

        function mayWorkFor(node1: go.Node, node2: go.Node) {
            if (!(node1 instanceof go.Node)) {
                return false;
            }  // must be a Node
            if (node1 === node2) {
                return false;
            }  // cannot work for yourself
            if (node2.isInTreeOf(node1)) {
                return false;
            }  // cannot work for someone who works for you
            return true;
        }

        function updateNodeDirection(diagram, node, dir) {
            diagram.model.setDataProperty(node.data, 'dir', dir);
            const chl = node.findTreeChildrenNodes();
            while (chl.next()) {
                updateNodeDirection(diagram, chl.value, dir);
            }
        }

        function getNodeTextMargin(node: MindMapVersionNode): go.Margin {
            const margin = new go.Margin(MindMapDiagramMaker.nodePadding);
            if (!node.isEndNode) {
                return margin;
            }
            const marginAmt = MindMapDiagramMaker.endNodeBarWidth;
            if (node.dir === 'left') {
                margin.left = marginAmt;
            } else if (node.dir === 'right') {
                margin.right = marginAmt;
            }

            return margin;
        }

        function getNodeBindingColor(pcis: Array<ProductCompanyInstance>, type: string): string {
            const companyColor = '#2480E6';
            const productColor = '#7FC104';
            const defaultColor = '#D1D3D4';

            if (type === 'company' && pcis.some(pci => pci.companies.length > 0)) {
                return companyColor;
            }
            if (type === 'product' && pcis.some(pci => pci.products.length > 0)) {
                return productColor;
            }

            return defaultColor;
        }
    }

    /**
     * Make an adornment shown when the user selects a node. Currently, displays a button to open the rich text editor.
     * @param clickCallback The function that should be called when the button is clicked
     */
    public static makeSelectionAdornment(clickCallback: Function) {
        return GraphObject.make(go.Adornment, 'Spot',
            GraphObject.make(go.Panel, 'Auto',
                GraphObject.make(go.Shape, {fill: null, stroke: 'dodgerblue', strokeWidth: 1}),
                GraphObject.make(go.Placeholder, {margin: new go.Margin(3, 3, 3, 3)})
            ),
            GraphObject.make('Button',
                {
                    alignment: go.Spot.Right,
                    alignmentFocus: go.Spot.Left,
                    click: (e, node) => clickCallback(node)
                },
                GraphObject.make(go.TextBlock, 'RT', {font: 'bold 8pt sans-serif'}),
                new Binding('visible', '', (o) => !o.isEndNode && !o.isMilestone)
            ),
            new Binding('visible', '', (o) => true)
        );
    }

    public static layoutTree(node?: Node, diagram?: Diagram) {
        const parts = node?.findTreeParts();
        const layout: TreeLayout = go.GraphObject.make(go.TreeLayout,
            {
                arrangement: go.TreeLayout.ArrangementFixedRoots,
                sorting: go.TreeLayout.SortingAscending,
                comparer: (a, b) => {
                    const ay = a.node.location.y;
                    const by = b.node.location.y;
                    if (isNaN(ay) || isNaN(by)) {
                        return 0;
                    }
                    if (ay < by) {
                        return -1;
                    }
                    if (ay > by) {
                        return 1;
                    }
                    return 0;
                },
                nodeSpacing: 5,
                layerSpacing: 20,
                setsPortSpot: false,
                setsChildPortSpot: false
            });
        if (node) {
            layout.angle = node.data.dir === 'left' ? 180 : 0;
        }
        layout.doLayout(parts || diagram);
    }

    public static makeLinkTemplate(): go.Link {
        return go.GraphObject.make(go.Link,
            {
                curve: go.Link.Bezier,
                selectable: false
            },
            go.GraphObject.make(go.Shape, {strokeWidth: 1}));
    }

    /**
     * Controls which options within the context menu should be shown when it opens
     */
    private static showContextMenu(obj: go.GraphObject, diagram: go.Diagram, _: go.Tool) {
        const cxElement = document.getElementById('mind-map-context-menu');
        let hasMenuItem = false;
        const node = obj ? obj.part : null;
        const isNode = !!node;
        const isMilestone = isNode && node.data.isMilestone;
        const isEndNode = isNode && node.data.isEndNode;
        const isRichText = isNode && node.data.isRichText;

        function maybeShowItem(elt, pred) {
            if (!elt) {
                return;
            }

            if (pred) {
                elt.style.display = 'block';
                hasMenuItem = true;
            } else {
                elt.style.display = 'none';
            }
        }

        maybeShowItem(document.getElementById('new-node'), !isMilestone && !isEndNode);
        maybeShowItem(document.getElementById('new-end-node'), isNode && !isMilestone && !isEndNode);
        maybeShowItem(document.getElementById('cp-builder'), isNode && !isMilestone && isEndNode);
        maybeShowItem(document.getElementById('milestone-builder'), isNode && !isMilestone && isEndNode);
        maybeShowItem(document.getElementById('undo'), diagram.commandHandler.canUndo());
        maybeShowItem(document.getElementById('redo'), diagram.commandHandler.canRedo());
        maybeShowItem(document.getElementById('copy'), diagram.commandHandler.canCopySelection());
        maybeShowItem(document.getElementById('paste'), diagram.commandHandler.canPasteSelection(diagram.lastInput.documentPoint));

        maybeShowItem(document.getElementById('styling-header'), isNode);
        maybeShowItem(document.getElementById('node-styling'), isNode);
        maybeShowItem(document.getElementById('text-styling'), isNode && !isRichText);
        maybeShowItem(document.getElementById('node-actions'), isNode);
        maybeShowItem(document.getElementById('layout'), isNode);
        maybeShowItem(document.getElementById('all-layout'), !isNode);

        if (hasMenuItem) {
            cxElement.classList.add('show-menu');
            const mousePt = diagram.lastInput.viewPoint;
            const leftPos = mousePt.x + 5;
            const topPos = mousePt.y;
            cxElement.style.left = leftPos + 'px';
            cxElement.style.top = topPos + 'px';
            if (cxElement.getBoundingClientRect().left + cxElement.clientWidth > window.innerWidth) {
                cxElement.style.left = (leftPos - cxElement.clientWidth) + 'px';
            }
            if (cxElement.getBoundingClientRect().top + cxElement.clientHeight > window.innerHeight) {
                cxElement.style.top = (topPos - cxElement.clientHeight) + 'px';
            }
        }

        window.addEventListener('pointerdown', MindMapDiagramMaker.hideCX, true);
    }

    private static hideContextMenu() {
        const cxElement = document.getElementById('mind-map-context-menu');
        cxElement.classList.remove('show-menu');
        window.removeEventListener('pointerdown', MindMapDiagramMaker.hideCX, true);
    }

    private static hideCX(diagram) {
        if (diagram.currentTool instanceof go.ContextMenuTool) {
            diagram.currentTool.doCancel();
        }
    }

    private static convertHtmlToImg(html: string, pic: Picture) {
        if (!pic.element) {
            pic.element = new Image();
            pic.element.onload = () => pic.redraw();
        }
        html = MindMapDiagramMaker.getHtmlTextWithoutLineBreaks(html);
        let width = pic.actualBounds.width;
        let height = pic.actualBounds.height;
        if (isNaN(width) || !isFinite(width) || width < 10) {
            width = 100;
        }
        if (isNaN(height) || !isFinite(height) || height === 0) {
            height = 100;
        }
        // tslint:disable-next-line:max-line-length
        const svg = `<svg encoding='UTF-16' xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}'><foreignObject x='0' y='0' width='${width}' height='${height}'><div xmlns='http://www.w3.org/1999/xhtml'>${html}</div></foreignObject></svg>`;
        (pic.element as HTMLImageElement).src = `data:image/svg+xml;base64,${btoa(svg)}`;
        return pic.element;
    }

    private static getHtmlTextWithoutLineBreaks(htmlStrVal: string) {
        htmlStrVal = htmlStrVal.replace(/&nbsp;/g, '');
        htmlStrVal = htmlStrVal.replace(/&bull;/g, '');
        htmlStrVal = htmlStrVal.replace(/&rsquo;/g, '\'');
        htmlStrVal = UtilsService.replaceGreekLetters(htmlStrVal);
        return htmlStrVal;
    }

    private static spotConverter(dir, from) {
        if (dir === 'left') {
            return (from ? go.Spot.Left : go.Spot.Right);
        } else {
            return (from ? go.Spot.Right : go.Spot.Left);
        }
    }

    private static getNodeFontColor(node: MindMapVersionNode): string {
        if (node.stroke) {
            return node.stroke;
        }
        if (node.isMilestone) {
            return MindMapDiagramMaker.defaultMilestoneFontColor;
        }
        return MindMapDiagramMaker.defaultNodeFontColor;
    }
}
