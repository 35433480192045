export class JqueryDialogOptions {
  autoOpen: boolean;
  resizable: boolean;
  modal: boolean;
  height: any;
  width: any;
  dialogClass: string;
  open: Function;
  close: Function;
  buttons: Array<object>;

  constructor(option: JqueryDialogOptions = {} as JqueryDialogOptions) {
    this.autoOpen = option.autoOpen || true;
    this.resizable = option.resizable || true;
    this.modal = option.modal || true;
    this.width = option.width || 'auto';
    this.height = option.height || 'auto';
    this.dialogClass = option.dialogClass || null;
    this.open = option.open || null;
    this.close = option.close || null;
    this.buttons = option.buttons || null;
  }
}
