import { Injectable } from '@angular/core';

/**
 * Injectable service to handle google tag manager operations
 */
@Injectable()
export class GoogleTagManagerService {

  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };

  constructor() {}

  public pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  private getDataLayer(): Array<any> {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }
}
