import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { Role, SearchResult, User } from '../../shared/models';
import { CONFIG } from '../../config';
import { catchError } from 'rxjs/operators';


@Injectable()
export class SearchService implements Searcher {
  private apiPath = `${CONFIG.apiUrl}/search`;

  private searchableTypes = {
    'User': User,
    'Role': Role
  };

  constructor(
    private http: HttpClient,
    private exceptionService: ExceptionService,
    private utilsService: UtilsService
  ) {}

  public getRecords(params?: object) {
    const httpParams = this.utilsService.generateParams(params);

    return this.http.get<Array<SearchResult>>(this.apiPath, { params: httpParams })
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getDisplayName(item: SearchResult): string {
    const typeClass: any = this.searchableTypes[item.type];
    return '';
    // const body = typeClass(item.body);
    // return body.getDisplayName();
  }
}
