<section class="container-fluid public-form password-expired admin-page">
  <div class="row justify-content-md-center m-top-100">
    <div class="col-xs-12 col-md-6 panel-box">
      <div class="row no-gutters">
        <div class="col">
          <h4 class="box-heading">{{"PASSWORD.CHANGE_YOUR_PASSWORD" | translate}}</h4>
        </div>
      </div>
      <hr class="heading-divider expanded">
      <div class="row">
        <form #resetPasswordForm="ngForm" class="col-xs-12 col-md-6 order-2 order-md-1" id="resetPasswordForm"
              [class.submitted]="resetPasswordForm.submitted" (ngSubmit)="onSubmit(resetPasswordForm)">
          <div class="form-group">
            <label for="newPassword">{{"FORMS.PASSWORD" | translate}}:
              <span class="error" *ngIf="resetPasswordForm.submitted">
                <span *ngIf="newPassword.errors?.required">{{"ERRORS.IS_REQUIRED" | translate}}</span>
              </span>
              <span class="error" *ngIf="resetPasswordForm.submitted">
                <span *ngIf="newPassword.errors?.pattern">{{"ERRORS.INVALID_PATTERN" | translate}}</span>
              </span>
            </label>
            <input type="password" class="form-control" name="newPassword" id="newPassword" [pattern]="passwordPattern"
                   ngModel #newPassword="ngModel" required>
          </div>

          <div class="form-group">
            <label for="confirmPassword">{{"FORMS.CONFIRM_PASSWORD" | translate}}:
              <span class="error" *ngIf="resetPasswordForm.submitted">
                <span *ngIf="!newPassword.errors?.required && !confirmPassword.valid">{{"ERRORS.MISMATCH" | translate}}</span>
              </span>
            </label>
            <input type="password" class="form-control" name="confirmPassword" id="confirmPassword" ngModel #confirmPassword="ngModel" required
                   xform-validate-equal="newPassword">
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary">{{"PASSWORD.CHANGE_PASSWORD_CTA" | translate}}</button>
          </div>
        </form>
        <div class="col-xs-12 col-md-6 order-1 order-md-2">
          <h5 class="small-title">{{"PASSWORD.PASSWORD_REQUIREMENTS_TITLE" | translate}}:</h5>
          <ol>
            <li>{{"PASSWORD.PASSWORD_REQUIREMENT_0" | translate}}</li>
            <li>{{"PASSWORD.PASSWORD_REQUIREMENT_1" | translate}}</li>
            <li>{{"PASSWORD.PASSWORD_REQUIREMENT_2" | translate}}</li>
            <li>{{"PASSWORD.PASSWORD_REQUIREMENT_3" | translate}}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section>
