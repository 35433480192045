import { Component, OnInit } from '@angular/core';
import { ToolboxAllPanelServices } from '../../services/toolboxAllPanel.services';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';


@Component({
  selector: 'emap-global-panel',
  templateUrl: './global-panel.component.html',
  styleUrls: ['./global-panel.component.scss'],
  providers: [JqueryCommonServices],
})
export class GlobalPanelComponent implements OnInit {
  private GLOBAL_PANEL_PARENT_CLASS = 'global-panel-parent';
  private GLOBAL_PANEL_CHILD_CLASS = 'global-panel-child';
  private PANEL_LEFT_SLIDE_OUT_CLASS = 'panel-left-slide-out';
  private SWAP_PANEL_SLIDE_CLASS =  'panel-right-slide-in panel-right-slide-out';
  private PANEL_DISPLAY_NONE = 'panel-display-none';
  private MAIN_PANEL_SUFFIX_TEXT = 'main-panel';
  private ACTIVE_CLASS = 'active';
  private TOOLBOX_PREFIX_TEXT = 'toolbox-';

  constructor(
    private toolboxAllPanelServices: ToolboxAllPanelServices,
    private jqueryCommonServices: JqueryCommonServices
  ) { }

  ngOnInit() {
    this.updateGlobalPanelDimensions();
    this.toolboxButtonClickHandler();
    this.toolboxSwapButtonClickHandler();
    this.globalPanelAnimationCompleteEventHandler();
    this.navigatorButtonHandler();
  }

  private updateGlobalPanelDimensions() {
    this.toolboxAllPanelServices.globalPanelWidth = this.jqueryCommonServices.widthHeight(this.GLOBAL_PANEL_PARENT_CLASS.toClass())['width'];
  }

  private toolboxButtonClickHandler() {
    this.toolboxAllPanelServices.toolboxButtonClickEventEmitter.subscribe(event => {
      const activeState = event['activeState'];
      const callingElement = event['event'];
      if (!callingElement) { return; }
      this.globalPanelOpenEventHandler(activeState);
      this.subPanelDisplayStateHandler(callingElement);
    });
  }

  private navigatorButtonHandler() {
    this.toolboxAllPanelServices.navigatorButtonClickEventEmitter.subscribe(event => {
      const targetElement = event['targetElement'];
      this.jqueryCommonServices.selector(targetElement).toggle();
    });
  }

  private globalPanelOpenEventHandler(activeState: boolean) {
    const globalPanelSelector = this.jqueryCommonServices.selector(this.GLOBAL_PANEL_CHILD_CLASS.toClass());
    globalPanelSelector.parent().removeClass(this.PANEL_DISPLAY_NONE);
    globalPanelSelector.toggleClass(this.PANEL_LEFT_SLIDE_OUT_CLASS, !activeState);
    this.toolboxAllPanelServices.globalPanelOpenState = !globalPanelSelector.hasClass(this.PANEL_LEFT_SLIDE_OUT_CLASS);
  }

  private globalPanelAnimationCompleteEventHandler() {
    this.jqueryCommonServices.animationCompleteEvent(this.GLOBAL_PANEL_CHILD_CLASS.toClass(), (event) => {
      const globalPanelChildSelector = this.jqueryCommonServices.selector(this.GLOBAL_PANEL_CHILD_CLASS.toClass());
      if (globalPanelChildSelector.hasClass(this.PANEL_LEFT_SLIDE_OUT_CLASS)) {
        globalPanelChildSelector.parent().addClass(this.PANEL_DISPLAY_NONE);
      }else {
        globalPanelChildSelector.parent().removeClass(this.PANEL_DISPLAY_NONE);
      }
    });
  }

  private subPanelDisplayStateHandler(callingElement: any) {
      const elementId = callingElement.currentTarget.id;
      const featureDisplayName = elementId.replace(this.TOOLBOX_PREFIX_TEXT, '');
      const featureClass = `.${featureDisplayName}-${this.MAIN_PANEL_SUFFIX_TEXT}`;
      this.jqueryCommonServices.selector(`.${this.MAIN_PANEL_SUFFIX_TEXT}`).removeClass(this.ACTIVE_CLASS);
      this.jqueryCommonServices.selector(featureClass).addClass(this.ACTIVE_CLASS);
  }

  private toolboxSwapButtonClickHandler() {
    this.toolboxAllPanelServices.swapButtonClickEventEmitter.subscribe(value => {
      const toolboxWidth = this.toolboxAllPanelServices.toolboxWidth;
      const globalPanelWidth = this.toolboxAllPanelServices.globalPanelWidth;
      const windowWidth = this.toolboxAllPanelServices.windowWidth;
      this.jqueryCommonServices.selector(this.GLOBAL_PANEL_PARENT_CLASS.toClass())
        .css({left: !value ? toolboxWidth : (windowWidth - (toolboxWidth + globalPanelWidth))});
      this.swapPanelAnimationHandler(value);
    });
  }

  private swapPanelAnimationHandler(value: boolean) {
    const globalPanelChildSelector = this.jqueryCommonServices.selector(this.GLOBAL_PANEL_CHILD_CLASS.toClass());
    if (value) {
      globalPanelChildSelector.addClass(this.SWAP_PANEL_SLIDE_CLASS);
    }else {
      globalPanelChildSelector.removeClass(this.SWAP_PANEL_SLIDE_CLASS);
    }
  }
}
