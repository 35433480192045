import { BaseEntity } from '../../xform-compat';
import { BasicUser } from './basicUser.model';
import { NodeCompany } from './nodeCompany.model';
import {Product} from './product.model';


export class Company implements BaseEntity {
  id: number;
  name: string;
  isIgnored: boolean;
  isApproved: boolean;
  rmsCompanyId: number;
  rmsSynonym: number;
  rmsRename: number;
  nodeCompany: Array<NodeCompany>;
  products: Array<Product>;
  createdBy: BasicUser;
  created: string;
  updated: string;
  active: boolean;
  edmGUID: string;

  constructor(company: Company = {} as Company) {
    this.id = company.id || null;
    this.name = company.name || null;
    this.isApproved = company.isApproved || false;
    this.isIgnored = company.isIgnored || false;
    this.rmsCompanyId = company.rmsCompanyId || null;
    this.rmsSynonym = company.rmsSynonym || null;
    this.rmsRename = company.rmsRename || null;
    this.nodeCompany = company.nodeCompany || null;
    this.products = company.products || null;
    this.createdBy = company.createdBy || null;
    this.created = company.created || null;
    this.updated = company.updated || null;
    this.active = company.active || null;
    this.edmGUID = company.edmGUID || null;
  }
}
