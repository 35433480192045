import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AbstractCRUD, CRUDService, ExceptionService, PaginatedList, UtilsService } from '../../xform-compat';
import { UserGroup } from '../../shared/models';
import { catchError } from 'rxjs/operators';


/**
 * Data Service for User Group CRUD's
 */
@Injectable({
  providedIn: 'root'
})
export class UserGroupsService extends AbstractCRUD<UserGroup> implements CRUDService<UserGroup> {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath(): string {
    return '/rms/usergroups';
  }

  /**
   * Autocomplete search for user groups
   * @param {string} term
   * @returns {Observable<PaginatedList<UserGroup>>}
   */
  public autocompleteUserGroups (term: string): Observable<PaginatedList<UserGroup>> {
    return super.getHTTP().get(`/api/rms/autocomplete/usergroups?q=${term}`).pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  /**
   * Gets the user settings
   * @param userId
   * {
      "hideNonOverviewNotes": false,
      "displayOverviewPage": false
      }
   */
  public getUserSettings(userId): Observable<any> {
    return super.getHTTP().get(`/api/rms/users/${userId}/settings/overview`);
  }

  /**
   * Saves the user settings
   * @param userId
   * @param body
   * {
        "hideNonOverviewNotes": true,
        "displayOverviewPage": false
    }
   */
  public updateUserSettings (userId: number, body: any): Observable<any> {
    return super.getHTTP().put(`/api/rms/users/${userId}/settings/overview`, body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

}
