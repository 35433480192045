import { Component, OnDestroy, OnInit } from '@angular/core';
import { StompService } from 'ng2-stomp-service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { CONFIG } from '../../../config';
import { WSNotification } from '../../../shared/models';
import { BannerNotificationsService, LocalStorageService } from '../../../xform-compat';


@Component({
  selector: 'xform-header-notification',
  templateUrl: './header-notification.component.html',
  styleUrls: ['./header-notification.component.scss']
})
export class HeaderNotificationComponent implements OnInit, OnDestroy {

  private subscription: any;
  // private topicToSubscribe = '/user/topic/AccountC'; // WebSocket Topic

  public selectedTitle: string;
  public translations: any;
  public messages: Array<string> = [];
  public notifications: WSNotification;

  constructor(
    private bannerNotificationsService: BannerNotificationsService,
    private authenticationService: AuthenticationService,
    private stomp: StompService,
    private translateService: TranslateService,
    private storage: LocalStorageService
  ) {

    this.stomp.configure({
      host: CONFIG.wsURL,
      debug: true,
      queue: { 'init': false },
      headers: { Authorization: `${this.authenticationService.getTokenInfo().accessToken}` },
      recTimeout:  CONFIG.wsRetryTimeOut
    });

    this.notifications = this.storage.get('notifications') || new WSNotification();
  }

  ngOnInit() {
    this.translateAlertMsgs();

    // this.stomp.startConnect().then(() => {
    //   this.stomp.done('init');
    //   this.subscription = this.stomp.subscribe(this.topicToSubscribe, this.wsResponse);
    // });
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
    // disconnect
    if (this.stomp.status === 'CONNECTED') {
      this.stomp.disconnect().then(() => {
        console.log('Connection closed');
      });
    }
  }

  private wsResponse = (data: any) => {
    this.bannerNotificationsService.warning(data.message);
    if (this.notifications.actions.length < 5) {
      this.notifications.actions.unshift(data.message);
    } else {
      this.notifications.actions.pop();
      this.notifications.actions.unshift(data.message);
    }
    this.storage.set('notifications', this.notifications);
  }

  private getMessages(notificationType: string = 'actions', event: any) {
    this.messages = this.notifications[notificationType];
    this.selectedTitle = notificationType;
    event.stopPropagation(); // stopPropagation to not close the dropdown
  }

  public translateAlertMsgs() {
    const messages = ['NOTIFICATIONS.NEED_ACTION', 'NOTIFICATIONS.ACTIONS', 'NOTIFICATIONS.MENTIONS'];
    this.translateService.get(messages)
      .subscribe(
        (translations) => {
          this.translations = translations;
          this.initNotificationPanel();
        }
      );
  }

  private initNotificationPanel() {
    this.notifications.titles = [
      {
        type: 'actions',
        name: this.translations['NOTIFICATIONS.NEED_ACTION']
      },
      {
        type: 'mentions',
        name: this.translations['NOTIFICATIONS.MENTIONS']
      }
    ];
    this.selectedTitle = this.translations['NOTIFICATIONS.ACTIONS'];
    this.messages = this.notifications.actions;
  }

}
