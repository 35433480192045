import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ExceptionService, UtilsService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';


/**
 * Data Service to request Job Executions
 */
@Injectable()
export class JobService {

  constructor(
    private http: HttpClient,
    private exceptionService: ExceptionService,
    private utilsService: UtilsService
  ) { }

  /**
   * Get Job types from the Server to populate the drop down filter field
   * @returns {Observable<any>}
   */
  public getJobTypes () {
    const url = '/api/rms/jobtypes';
    return this.http.get(url)
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  /**
   * Get Jobs from the server to populate table list with job execution data
   * @param params: possible filters
   * @returns {Observable<any>}
   */
  public getJobs (params?: any) {
    const url = '/api/rms/jobs';
    const httpParams: HttpParams = this.utilsService.generateParams(params);
    return this.http
      .get(url, {params: httpParams})
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

}
