import {BaseEntity} from '../../xform-compat';

export class Synonym implements BaseEntity {
  id: number;
  name: string;
  category: string;

  constructor(synonym: any = {}) {
    this.id = synonym.id || null;
    this.name = synonym.name || null;
    this.category = synonym.category || null;
  }
}
