
import { Injectable } from '@angular/core';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { Product } from '../../shared/models/product.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import {ProductResource} from '../../shared/models/resources/productResource.model';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ProductService extends AbstractCRUD<Product> implements CRUDService<Product>, Searcher {

    constructor(
        exceptionService: ExceptionService,
        http: HttpClient,
        utilsService: UtilsService
    ) {
        super(exceptionService, http, utilsService);
    }

    public getResourcePath() {
        return '/core/service/recon/product';
    }

    public getDisplayName(item: any): string {
        return item.name;
    }

    public getFuzzySearchForProductPath() {
        return super.getApiPath().concat('/search');
    }

    public getRmsProductPath(rmsProductId: string) {
        return super.getApiPath().concat('/rms/').concat(rmsProductId);
    }

    public getPutProductInstanceReconPath() {
        return super.getApiPath().concat('/save');
    }

    public getPutProductReconPath() {
        return super.getApiPath().concat('/update');
    }

    public getAllProductReconPath(mapVersionId: string) {
        return super.getApiPath().concat('/').concat(mapVersionId);
    }

    public getAllProductRecon(mapVersionId: string) {
        return this.getHTTP().get(this.getAllProductReconPath(mapVersionId))
          .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }

    public getFuzzySearchForProduct(productName: string) {
        const httpParams = new HttpParams().set('productName', productName);
        return this.getRequest(this.getFuzzySearchForProductPath(), httpParams);
    }

    public getRmsProduct(rmsProductId: string) {
        return this.getHTTP().get(this.getRmsProductPath(rmsProductId))
          .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }

    public putProductRecon(body: object) {
        return this.putRequest(this.getPutProductReconPath(), body);
    }

    public putProductInstanceRecon(body: object) {
        return this.putRequest(this.getPutProductInstanceReconPath(), body);
    }

    private getRequest(apiUrl: string, httpParams: HttpParams) {
        return this.getHTTP().get(apiUrl, { params: httpParams })
            .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }

    private putRequest(apiUrl: string, body: object) {
        return this.getHTTP().put(apiUrl, body)
            .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }
}
