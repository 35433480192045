import { Injectable } from '@angular/core';
import * as Hammer from 'hammerjs';


@Injectable()
export class HammerCommonServices {

  constructor() { }

  public hammerTapEventHandler(currentSelector: any) {
    const hammerManagerObject = new Hammer.Manager(currentSelector);
    hammerManagerObject.add( new Hammer.Tap({ event: 'doubletap', taps: 2 }));
    hammerManagerObject.add( new Hammer.Tap({ event: 'singletap' }));
    hammerManagerObject.get('doubletap').recognizeWith('singletap');
    hammerManagerObject.get('singletap').requireFailure('doubletap');
    return hammerManagerObject;
  }

  public hammerPressEventHandler(currentSelector: any) {
    const hammerManagerObject = new Hammer.Manager(currentSelector);
    hammerManagerObject.add( new Hammer.Press({ event: 'press', threshold: window.screen.width}));
    return hammerManagerObject;
  }
}
