<div class="onr-parent">
  <div class="onr-panel z-index-2">
    <div class="onr-button" (click)="togglePanelOpen()"></div>
    <div class="onr-sections">
      <div class="onr-section onr-detail">
        <div *ngIf="!node" class="onr-detail-placeholder">(No node selected.)</div>
        <div *ngIf="node" class="onr-detail-container scrollable">
          <label class="onr-section-title">Result Label:</label>
          <div class="onr-detail-text" *ngIf="node">{{node.name}}</div>
          <label class="onr-section-title mt-3">Mindnode Text:</label>
          <div class="onr-detail-text" *ngIf="node">{{node.name}}</div>
        </div>
        <div *ngIf="node" class="onr-detail-inputs">
          <button class="onr-input onr-input-add"
                  (click)="handleAddClick(node.id.toString())" [disabled]="!node || (node.isIncluded === true)">Add to Map</button>
          <button class="onr-input onr-input-exclude"
                  (click)="handleExcludeClick(node.id.toString())" [disabled]="!node || (node.isIncluded === false)">Exclude from Map</button>
        </div>
      </div>
      <div class="onr-section unrec">
        <label class="onr-section-title">Unreconciled Nodes ({{orphanNodes.size}})</label>
        <div class="table table-bordered table-striped onr-table">
          <div class="onr-thead">
            <div class="onr-table-row">
              <th class="onr-col-name">Result Label</th>
              <th class="onr-col-id">ID</th>
            </div>
          </div>
          <div class="onr-tbody scrollable-table">
            <div *ngFor="let each of values(orphanNodes)"
                [ngClass]="{'onr-table-row-selected': node && (each.id === node.id)}"
                class="onr-table-row" id="unreconciled-{{each.id}}"
                (click)="handleTableRowClick(each.id.toString())">
              <td class="onr-col-name">{{each.name}}</td>
              <td class="onr-col-id">{{each.id}}</td>
            </div>
          </div>
        </div>
      </div>
      <div class="onr-section exclude">
        <label class="onr-section-title">Excluded Nodes ({{excludedNodes.size}})</label>
        <div class="table table-bordered table-striped onr-table">
          <div class="onr-thead">
            <div class="onr-table-row">
              <th class="onr-col-name">Result Label</th>
              <th class="onr-col-id">ID</th>
            </div>
          </div>
          <div class="onr-tbody scrollable-table">
            <div *ngFor="let each of values(excludedNodes)"
                [ngClass]="{'onr-table-row-selected': node && (each.id === node.id)}"
                class="onr-table-row" id="excluded-{{each.id}}"
                (click)="handleTableRowClick(each.id.toString())">
              <td class="onr-col-name">{{each.name}}</td>
              <td class="onr-col-id">{{each.id}}</td>
            </div>
          </div>
        </div>
      </div>
      <div class="onr-section onr-continue">
        <button class="onr-input onr-input-save" [disabled]="!ready || !authoringIntegrationService.nodeLayerModel.hasUnsavedChanges" (click)="save()">Save</button>
        <button class="onr-input onr-input-continue" (click)="submit()">Continue</button>
      </div>
    </div>
  </div>
</div>
