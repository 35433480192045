<div class="modal-header">
    <h4 class="modal-title" id="delete-version-modal-basic-title">Manage TVs</h4>
</div>
<div class="modal-body">
    <ng-container *ngIf="mapsManager; else noMap">
        <div class="tv-publish-versions-header">
            Map Versions ({{ mapsManager.mapsManagerVersionCount }})
        </div>
        <div *ngIf="stagedServers.size > 0" class="staging-warning">
            <i class="fa fa-exclamation-circle ml-2 mr-1"></i>
            You have unpublished changes. Click the "Save" button to publish your changes to the selected TVs.
        </div>
        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
            <ngb-panel *ngFor="let version of mapsManager.mapsManagerVersions; let i = index; trackBy:trackById" id="ngb-panel-{{i}}">
                <ng-template ngbPanelHeader let-opened="opened">
                    <div class="tv-publish-version-container">
                        <button ngbPanelToggle class="btn panel-toggle-button">
                            <i class="fa fa-angle-down mr-3 panel-toggle-icon"></i>
                            <h3 class="mr-5 mb-0 panel-toggle-title">{{ version.name }}</h3>
                        </button>
                        <div ngbDropdown #serverDropdown="ngbDropdown" class="tv-publish-tv-selector" (openChange)="selectedServers.clear()">
                            <button type="button" class="tv-selector-toggle" ngbDropdownToggle>Select Televisions</button>
                            <div ngbDropdownMenu class="tv-selector-menu">
                                <div class="tv-selector-state-select">
                                    <div class="tv-selector-header">Publish As</div>
                                    <input type="radio"
                                           id="interactive-select"
                                           name="published-state-select-{{i}}"
                                           value="interactive"
                                           class="radio"
                                           [disabled]="!version.interactive"
                                           [(ngModel)]="selectedState">
                                    <label for="interactive-select" class="pl-2">Interactive</label>
                                    <br>
                                    <input type="radio"
                                           id="static-select"
                                           name="published-state-select-{{i}}"
                                           value="static"
                                           class="radio"
                                           [(ngModel)]="selectedState">
                                    <label for="static-select" class="pl-2">Static (Image Only)</label>
                                </div>

                                <div class="tv-selector-tv-select">
                                    <div class="tv-selector-header">Publish To</div>
                                    <ng-container *ngFor="let server of remoteServers">
                                        <input type="checkbox"
                                               id="{{version.id}}-server-{{server.id}}"
                                               value="{{server.id}}"
                                               class="tv-select-checkbox"
                                               [disabled]="!server.active || stagedServers.has(server.id)"
                                               [ngModel]="selectedServers.has(server)"
                                               (change)="serverSelectChange(server, $event.target.checked)"
                                               [ngbTooltip]="stagedServers.has(server.id) ? 'A map version is already staged to be published to this TV' : null">
                                        <label for="{{version.id}}-server-{{server.id}}" class="pl-2">
                                            {{ server.name }}
                                            <i class="fa fa-circle server-status-icon ml-1"
                                               [ngClass]="server.active ? 'active' : 'inactive'"></i>
                                        </label>
                                        <br>
                                    </ng-container>
                                </div>

                                <button class="btn btn-primary tv-selector-save-button w-100 my-2"
                                        (click)="stageVersionToServers(version); serverDropdown.close()"
                                        [disabled]="selectedServers.size === 0 || !selectedState">
                                    Publish
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="tv-publish-tvs-header row">
                        <div class="col-4">TV</div>
                        <div class="col-2">Published State</div>
                        <div class="col-3">Published Date/Time</div>
                        <div class="col-3">Published By</div>
                    </div>
                    <div *ngFor="let deployedVersion of version.remoteServerDeploys | orderBy:'remoteServer.name'" class="row tv-publish-server-row">
                        <div class="col-4 d-flex align-items-center server-column justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="mr-2 font-weight-bold">{{ deployedVersion.remoteServer.name }}</div>
                                <i class="fa fa-circle server-status-icon"
                                   [ngClass]="deployedVersion.remoteServer.active ? 'active' : 'inactive'"></i>
                            </div>
                            <div *ngIf="!deployedVersion.created && stagedServers.has(deployedVersion.remoteServer.id)">
                                <button class="btn btn-default remove-button" (click)="removeStagedServer(deployedVersion.remoteServer)">Remove</button>
                            </div>
                            <div *ngIf="deployedVersion.created">
                                <button class="btn btn-default remove-button" (click)="unpublish(deployedVersion)" [disabled]="!deployedVersion.remoteServer.active">Unpublish</button>
                            </div>
                        </div>
                        <div class="col-2 server-column" [ngClass]="{'warning': !deployedVersion.created && stagedServers.has(deployedVersion.remoteServer.id), 'interactive': deployedVersion.publishedState === 'interactive', 'fail': deployedVersion.publishedState === 'failed'}">
                            {{ (!deployedVersion.created && stagedServers.has(deployedVersion.remoteServer.id) ? 'Staging (' + deployedVersion.publishedState + ')' : deployedVersion.publishedState) | titlecase }}
                        </div>
                        <div class="col-3 server-column"><h4 class="mr-4 mb-0">{{ (deployedVersion.created | date) }}</h4><span>{{ deployedVersion.created | date:'shortTime' }}</span></div>
                        <div class="col-3 server-column">{{ deployedVersion.updatedBy?.fullName }}</div>
                    </div>
                    <div *ngIf="version.remoteServerDeploys?.length === 0" class="row tv-publish-server-row">
                        <div class="col-12 server-column">
                            This map version has not been published to any TVs.
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
        <div *ngIf="loadingVersions" class="mt-3 d-flex justify-content-center align-items-center">
            <div class="spinner-border spinner-border-sm mr-2" role="status"></div>
            <div>Loading previous versions...</div>
        </div>
    </ng-container>

    <ng-template #noMap>
        <div>
            No map found
        </div>
    </ng-template>
</div>
<div class="modal-footer d-flex flex-row justify-content-between">
    <button type="button" class="btn border" (click)="close()">Close</button>
    <button type="button"
            class="btn btn-primary"
            (click)="save()">
        Save
    </button>
</div>
