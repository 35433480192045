import { Injectable} from '@angular/core';
import { AbstractCRUD, CRUDService, ExceptionService, UtilsService } from '../../xform-compat';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Node } from '../../shared/models/node.model';
import { catchError } from 'rxjs/operators';
import { MapVersionNode } from '../../shared/models/mapVersionNode.model';

@Injectable()
export class NodeLayerService extends AbstractCRUD<Node> implements CRUDService<Node> {

    constructor(
        exceptionService: ExceptionService,
        http: HttpClient,
        utilsService: UtilsService
    ) {
        super(exceptionService, http, utilsService);
    }

    public getResourcePath() {
        return '/node/service';
    }

    public getNodeLayerByMapVersionId(mapVersionId: number, httpParams: HttpParams) {
        return this.getRequest(this.getNodeLayerByMapVersionIdPath(mapVersionId), httpParams);
    }

    public putNodeLayerByMapVersionId(mapVersionId: number, nodes: Iterable<MapVersionNode>) {
        return this.putRequest(this.getNodeLayerByMapVersionIdPath(mapVersionId), nodes);
    }

    private getNodeLayerByMapVersionIdPath(mapVersionId: number) {
        return super.getApiPath().concat('/nodelayer/').concat(mapVersionId.toString());
    }

    private getRequest(apiUrl: string, httpParams: HttpParams) {
        return this.getHTTP().get(apiUrl, { params: httpParams })
            .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }

    private putRequest(apiUrl: string, body: object) {
        return this.getHTTP().put(apiUrl, body)
            .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }
}
