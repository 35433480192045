import { Injectable } from '@angular/core';
import { MorphActionServices } from './morphAction.services';
import { HammerCommonServices } from './hammerCommon.services';
import { DesignIntegrationService } from './designIntegration.service';
import { JqueryCommonServices } from './jqueryCommon.services';
import { GlobalIntegrationServices } from './globalIntegration.services';
import { AnimationCommonServices } from './animationCommon.services';

@Injectable()
export class DesignActionEventsService extends MorphActionServices {

  constructor(
    hammerCommonServices: HammerCommonServices,
    designIntegrationService: DesignIntegrationService,
    jqueryCommonServices: JqueryCommonServices,
    globalIntegrationServices: GlobalIntegrationServices,
    animationCommonServices: AnimationCommonServices,
  ) {
    super(hammerCommonServices, designIntegrationService, jqueryCommonServices, globalIntegrationServices, animationCommonServices);
  }

  public endNodeTapActionEvent(endNodeList) {
    endNodeList.each((index, element) => {
      const hammerManager = this.hammerTapEventHandler(element);
      this.endNodeSingleTapEventHandler(hammerManager);
      this.endNodeDoubleTapEventHandler( hammerManager);
    });
  }

  public endNodePressActionEvent(endNodeList) {
    endNodeList.each((index, element) => {
      const hammerManager = this.hammerPressEventHandler(element);
      this.endNodePressEventHandler(hammerManager);
      this.endNodePressUpEventHandler(hammerManager);
    });
  }

  public mapNameTapActionEvent(mapNameList) {
    mapNameList.each((index, element) => {
      const hammerManager = this.hammerTapEventHandler(element);
      this.mapNameSingleTapEventHandler(hammerManager);
      this.mapNameDoubleTapEventHandler( hammerManager);
    });
  }

}
