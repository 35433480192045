import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';

@Component({
    selector: 'emap-rich-content-modal',
    templateUrl: './rich-content-modal.component.html',
    styleUrls: ['./rich-content-modal.component.scss']
})
export class RichContentModalComponent implements OnDestroy, OnInit {
    editorContent: string;
    saveSubscription$: Subject<string> = new Subject<string>();
    @Output() updateContentEvent$: EventEmitter<any> = new EventEmitter<any>();
    _disableChanges = false;

    constructor(
        public activeModal: NgbActiveModal
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
    }

    onDismiss() {
        this.activeModal.dismiss(false);
    }

    onConfirmation(b: boolean) {
        this._disableChanges = true;
        this.saveSubscription$.next(this.editorContent);
        this.activeModal.close();
    }

    editorChanged($event: { content: string; isOffline: boolean }) {
        if (!this._disableChanges) {
            this.editorContent = $event.content;
        }
    }
}
