import { Injectable } from '@angular/core';
import { CONFIG } from '../../config';


@Injectable()
export class LocalStorageService {
  private readonly storagePrefix: string;

  constructor(
  ) {
    this.storagePrefix = CONFIG.storagePrefix;
  }

  public set(name: string, elem: any) {
    localStorage.setItem(`${this.storagePrefix}-${name}`, JSON.stringify(elem));
  }

  public get(name: string) {
    return JSON.parse(localStorage.getItem(`${this.storagePrefix}-${name}`));
  }

  public delete(name: string) {
    localStorage.removeItem(`${this.storagePrefix}-${name}`);
  }

  public deleteAll() {
    localStorage.clear();
  }


}
