import { EventEmitter, Output, Directive } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { EventsHubService } from '../events-hub.service';
import { Subscription } from 'rxjs';
import { BannerNotificationsService, CRUDService, SpinnerService } from '../../xform-compat';


/**
 * Abstract class to implement on create and edit records
 */
@Directive()
export abstract class RmsFormAbstract<R> {

  /**
   * Event triggered to the parent component on form submitted successfully
   * @type {EventEmitter<boolean>}
   */
  @Output() onRecordProcessed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public modalSubscription: Subscription;
  public isEditMode = false;
  private modalReference: any;
  private recordId: number;
  protected selDate: string;

  constructor(
    private recordsService: CRUDService<R>,
    private eventsHubService: EventsHubService,
    private spinnerService: SpinnerService,
    private bannerNotificationsService: BannerNotificationsService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) {}

  /**
   * Implemented on child class to handle logic related to the component when the modal is opened
   * @param {boolean} isEdit: flag to check if is edit or create
   * @param {R} record: optional type of record only on edit
   */
  public abstract onModalOpens(isEdit: boolean, record?: R);

  /**
   * getResourceService, getSpinner, getNotifications
   ** Methods to use on child classes for shared helper services
   ** Makes available from child clases:
   ** - The CRUD methods from the resource service
   ** - The spinner and banner notification service
   */
  protected getResourceService() {
    return this.recordsService;
  }

  protected getSpinner() {
    return this.spinnerService;
  }

  protected getNotifications() {
    return this.bannerNotificationsService;
  }

  protected getTranslateService() {
    return this.translateService;
  }

  /**
   * Used to convert the form data and include extra validations
   * @param {object} form: contains the form data
   * @returns {R} returns a Model
   */
  public abstract resourceChecker(form: object): R;

  /**
   * RxJS Subscriber to check when modal form opens
   * @param modalRef: Modal Element Reference from template
   * Sets the isEdit flag value
   * Calls the abstract method implemented in the component
   * Opens the modal component
   * @param modelOptions tell the size of the model dialog
   */
  public subscribeModal(modalRef: any, modelOptions?: object) {
    this.modalSubscription = this.eventsHubService.openModal$.subscribe(
      record => {
        this.modalReference = this.modalService.open(modalRef, modelOptions);
        if (record) {
          this.recordId = record.id;
          this.isEditMode = true;
        }
        this.onModalOpens(this.isEditMode, record);
        this.modalReference.result.then(
          () => this.isEditMode = false,
          () => this.isEditMode = false);
      }
    );
  }

  private resetForm(form: any) {
    form.reset();
    this.modalReference.close();
  }

  public closeModal() {
    this.modalReference.close();
  }

  /**
   * Call the respective process method based on if is edit or create
   * @param form: contains the form fields
   */
  public processRecord(form: any) {
    if (!form.valid) {
      return;
    }

    let record: R;
    try {
      record = this.resourceChecker(form.value);
    } catch (e) {
      this.bannerNotificationsService.error(e);
      return;
    }

    this.spinnerService.start();
    if (this.isEditMode) {
      form.value.id = this.recordId;
      this.updateRecord(record, form);
    } else {
      this.createRecord(record, form);
    }
  }

  private createRecord(record: R, form: any) {
    this.recordsService.createRecord(record)
      .finally(() => this.spinnerService.stop())
      .subscribe(
        () => this.successProcess(form),
        (error) => this.bannerNotificationsService.error(error)
      );
  }

  private updateRecord(record: R, form: any) {
    this.recordsService.updateRecord(record)
      .finally(() => this.spinnerService.stop())
      .subscribe(
        () => this.successProcess(form),
        (error) => this.bannerNotificationsService.error(error)
      );
  }

  /**
   * On form success submission
   * @param form: form fields
   * Clear the form and closes it
   * Triggers the @Output Event to notify the parent component
   */
  private successProcess(form: any) {
    this.resetForm(form);
    this.bannerNotificationsService.success(
      this.translateService.instant((this.isEditMode ? 'MESSAGES.UPDATED' : 'MESSAGES.CREATED'), { value: 'Record' })
    );
    this.onRecordProcessed.emit(true);
  }

  /**
   * deletes modal Subscription on modal closes or the User leaves the app state/route
   */
  public unsubscribeModal() {
    this.modalSubscription.unsubscribe();
  }
}
