import { Component } from '@angular/core';


@Component({
  selector: 'xform-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss']
})

export class NoContentComponent {

}
