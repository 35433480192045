import { Injectable } from '@angular/core';
import { GlobalIntegrationServices } from './globalIntegration.services';
import { AuthoringIntegrationService } from './authoringIntegration.service';
import { NodeLayerIntegrationService } from './nodeLayerIntegration.service';
import { JqueryCommonServices } from './jqueryCommon.services';
import { JsPlumbCommonService } from './jsPlumbCommon.service';
import { NodeLayerAbstract } from '../components/node-layer/nodeLayer.abstract';
import { ZpanCommonServices } from './zpanCommon.services';
import { Node } from '../models/node.model';
import { MAP_VIEW_STATE } from '../enums';
import { MapVersionNode } from '../models/mapVersionNode.model';


@Injectable()
export class NodeGenericService extends NodeLayerAbstract {

  private NODE_LAYER_CHILD_PRIMARY_SELECTION_CLASS = 'node-layer-primary-selection';
  private NODE_LAYER_CHILD_SECONDARY_SELECTION_CLASS = 'node-layer-secondary-selection';

  private NODE_LAYER_CHILD_LEFT_SIDE_CLASS = 'node-layer-left-side';
  private NODE_LAYER_CHILD_RIGHT_SIDE_CLASS = 'node-layer-right-side';
  private NODE_LAYER_END_NODE_CLASS = 'node-layer-end-node';

  // TODO: new node size should not be relative to map size
  private NEW_NODE_WIDTH = 0.04;
  private NEW_NODE_HEIGHT = 0.01;

  private nodeSelectionBounds = {};
  private nodeSelectionPadding = {};
  private nodeSelectionLimits = {};
  private nodeSelectionFollowerIds = new Set();

  constructor(
    nodeLayerIntegrationService: NodeLayerIntegrationService,
    jqueryCommonService: JqueryCommonServices,
    private globalIntegrationService: GlobalIntegrationServices,
    private authoringIntegrationService: AuthoringIntegrationService,
    private jsPlumbCommonService: JsPlumbCommonService,
    private zPanCommonServices: ZpanCommonServices,
  ) {
    super(nodeLayerIntegrationService, jqueryCommonService);
  }

  public initNodeLayer(mapVersionNodes: Array<MapVersionNode>) {
    const nodeLayerElem = this.jqueryCommonService.selector(this.NODE_LAYER_PARENT_CLASS.toClass()).first();
    const nodeElems = mapVersionNodes.map(this.createNode.bind(this));
    nodeElems.forEach(elem => nodeLayerElem.append(elem));
    mapVersionNodes.forEach(this.placeNodeInitial.bind(this));
    mapVersionNodes.forEach(this.registerNodeCallbacks.bind(this));
  }

  public placeNodeInitial(node: Node) {
    const nodeElem = this.getNodeElement(node.id.toString());
    this.placeNodeNormalized(nodeElem, node.leftPosition, node.topPosition, node.width, node.height);
    node.isMoved = false;
  }

  private createNode(node: any) {
    const id = node.id.toString();

    if (this.nodeLayerIntegrationService.nodes.has(id)) {
      throw new Error('A node with id ' + id + 'already exists!');
    }
    this.nodeLayerIntegrationService.nodes.set(id, node);

    const nodeElemId = this.nodeIdToElementId(id);
    this.nodeLayerIntegrationService.elementIdToNodeId.set(nodeElemId, id);

    const newDiv = this.jqueryCommonService.divConstruct();
    newDiv.attr('id', nodeElemId);
    newDiv.attr('data-node-name', node.name);
    newDiv.addClass(this.NODE_LAYER_CHILD_CLASS);

    if (this.globalIntegrationService.currentMapViewState === MAP_VIEW_STATE.Authoring) {
      newDiv.attr('title', node.name);
    }

    if (node.isEndNode) {
      newDiv.addClass(this.NODE_LAYER_END_NODE_CLASS);
    }

    if (node.isIncluded !== true) {
      // newDiv.addClass(this.NODE_LAYER_CHILD_EXCLUDED_CLASS);
    }

    const middleXPosition = (node.leftPosition + (node.width / 2));
    if (middleXPosition < 0.5) {
      newDiv.addClass(this.NODE_LAYER_CHILD_LEFT_SIDE_CLASS);
    } else {
      newDiv.addClass(this.NODE_LAYER_CHILD_RIGHT_SIDE_CLASS);
    }

    return newDiv;
  }

  private registerNodeCallbacks(node: any) {
    const that = this;
    const id = node.id.toString();
    const newDiv = this.getNodeElement(id);
    const nodeElemId = this.nodeIdToElementId(id);

    newDiv.resizable({
      start: (function (event, ui) {
        that.zPanCommonServices.setPanningEnabled(false);
      }),
      resize: (function (event, ui) {
        const deltaWidth = ui.size.width - ui.originalSize.width;
        const deltaHeight = ui.size.height - ui.originalSize.height;
        const deltaWidthScaled = deltaWidth / that.globalIntegrationService.ratio;
        const deltaHeightScaled = deltaHeight / that.globalIntegrationService.ratio;
        const newWidth = ui.originalSize.width + deltaWidthScaled;
        const newHeight = ui.originalSize.height + deltaHeightScaled;
        ui.size.width = newWidth;
        ui.size.height = newHeight;
      }),
      stop: (function (event, ui) {
        const elemSelector = that.jqueryCommonService.selector(event.target);
        that.saveNodeCoordinates(elemSelector);
        if (that.jsPlumbCommonService.isReady()) { that.jsPlumbCommonService.revalidate(ui.element); }
        that.zPanCommonServices.setPanningEnabled(true);
        that.emitUnsavedChangesEvent(true);
      }),
      handles: 's, e, se',  // NOTE: allowing north/west resizing would require handling position change
      minWidth: 20,
      minHeight: 20,
    });

    newDiv.draggable({
      containment: that.NODE_LAYER_PARENT_CLASS,
      filter: '.ui-resizable-handle',  // This filter is necessary to prevent dragging while resizing
      start: (function (event) {
        that.zPanCommonServices.setPanningEnabled(false);
        if (that.authoringIntegrationService.nodeLayerModel.secondarySelectionIds.has(id)) {
          const otherNodeIds = new Set(that.authoringIntegrationService.nodeLayerModel.secondarySelectionIds);
          otherNodeIds.delete(id);
          that.nodeSelectionFollowerIds = otherNodeIds;
          that.nodeSelectionFollowerIds.forEach((nodeId: any) => {
            const nodeElem = that.getNodeElement(nodeId);
            nodeElem.data('dragStart', nodeElem.position());
          });
          that.recalculateSelectionNodeBounds();
          that.recalculateSelectionNodePadding(id);
          that.recalculateSelectionNodeLimits(id);
        } else {
          that.nodeSelectionFollowerIds = new Set();
        }
      }),
      drag: (function (event, ui) {
        if (that.nodeSelectionFollowerIds.size) {
          const newAbsoluteX = Math.min(Math.max(that.nodeSelectionLimits['minX'], ui.position.left /
            that.globalIntegrationService.ratio), that.nodeSelectionLimits['maxX']);
          const newAbsoluteY = Math.min(Math.max(that.nodeSelectionLimits['minY'], ui.position.top /
            that.globalIntegrationService.ratio), that.nodeSelectionLimits['maxY']);
          const deltaX = newAbsoluteX - (ui.originalPosition.left / that.globalIntegrationService.ratio);
          const deltaY = newAbsoluteY - (ui.originalPosition.top / that.globalIntegrationService.ratio);
          ui.position.left = newAbsoluteX;
          ui.position.top = newAbsoluteY;
          that.nodeSelectionFollowerIds.forEach((nodeId: any) => {
            const nodeElem = that.getNodeElement(nodeId);
            const nodeElemOriginalPosition = nodeElem.data('dragStart');
            nodeElem.css({
              left: nodeElemOriginalPosition.left / that.globalIntegrationService.ratio + deltaX,
              top: nodeElemOriginalPosition.top / that.globalIntegrationService.ratio + deltaY,
            });
          });
        } else {
          const nodeData = that.nodeLayerIntegrationService.nodes.get(id);
          const newAbsoluteX = Math.min(Math.max(0, ui.position.left / that.globalIntegrationService.ratio),
            that.nodeLayerIntegrationService.mapImageWidth - (nodeData.width * that.nodeLayerIntegrationService.mapImageWidth));
          const newAbsoluteY = Math.min(Math.max(0, ui.position.top / that.globalIntegrationService.ratio),
            that.nodeLayerIntegrationService.mapImageHeight - (nodeData.height * that.nodeLayerIntegrationService.mapImageHeight));
          ui.position.left = newAbsoluteX;
          ui.position.top = newAbsoluteY;
        }
        // NOTE: Add a call to "repaint" here for connections to repaint while dragging
      }),
      stop: (function (event, ui) {
        const elemSelector = that.jqueryCommonService.selector(event.target);
        that.saveNodeCoordinates(elemSelector);
        that.nodeSelectionFollowerIds.forEach((nodeId: any) => {
          const nodeElem = that.getNodeElement(nodeId);
          that.saveNodeCoordinates(nodeElem);
        });
        that.resetSelectionNodeBounds();
        that.zPanCommonServices.setPanningEnabled(true);
        if (that.jsPlumbCommonService.isReady()) {
          that.jsPlumbCommonService.revalidate(that.getNodeElements());
          that.jsPlumbCommonService.repaint();  // Necessary to update node endpoints to align with the node's new position
        }
        that.emitUnsavedChangesEvent(true);
      }),
    });

    // NOTE: Must register click events AFTER registering draggable to prevent click-on-drag
    this.jqueryCommonService.clickEvent(nodeElemId.toId(), this.emitNodeClickEvent.bind(this));
  }

  private emitNodeClickEvent(event) {
    const nodeElemId = event.target.id;
    const nodeId = this.elementIdToNodeId(nodeElemId);
    if (!nodeId) { return; }
    const primaryClick = (!event.altKey && !event.metaKey && !event.shiftKey);
    const secondaryClick = (event.metaKey || event.shiftKey);
    const hierarchyClick = event.altKey;
    const clickType = primaryClick ? 'primary' : (secondaryClick ? 'secondary' : (hierarchyClick ? 'hierarchy' : ''));
    this.nodeLayerIntegrationService.nodeLayerClickEventHandler.next({ event: event, nodeId: nodeId, clickType: clickType });
  }

  public setCurrentMode(clearSelection: boolean = true) {
    if (this.authoringIntegrationService.authoringHeaderModel.isHierarchyModeActive) {
      this.setHierarchyMode(clearSelection);
    } else if (this.authoringIntegrationService.authoringHeaderModel.isEditModeActive) {
      this.setEditMode(clearSelection);
      if (this.authoringIntegrationService.authoringToolboxModel.isHierarchyModeActive) {
        if (this.jsPlumbCommonService.isReady()) { this.jsPlumbCommonService.showConnections(this.getNodeElements()); }
      }
    } else {
      this.setDefaultMode(clearSelection);
    }
  }

  public setHierarchyMode(clearSelection: boolean = true) {
    const elems = this.getNodeElements();
    this.disableNodeResizing(elems);
    this.disableNodeDragging(elems);
    if (this.jsPlumbCommonService.isReady()) {
      this.jsPlumbCommonService.enableConnections(elems);
      this.jsPlumbCommonService.showConnections(elems);
      this.jsPlumbCommonService.repaint();
    }
    if (clearSelection) { this.clearNodeSelections(); }
  }

  public setEditMode(clearSelection: boolean = true) {
    const elems = this.getNodeElements();
    this.enableNodeResizing(elems);
    this.enableNodeDragging(elems);
    if (this.jsPlumbCommonService.isReady()) {
      this.jsPlumbCommonService.disableConnections();
      this.jsPlumbCommonService.hideConnections(elems);
      this.jsPlumbCommonService.repaint();
    }
    if (clearSelection) { this.clearNodeSelections(); }
  }

  public setDefaultMode(clearSelection: boolean = true) {
    const elems = this.getNodeElements();
    this.disableNodeResizing(elems);
    this.disableNodeDragging(elems);
    if (this.jsPlumbCommonService.isReady()) {
      this.jsPlumbCommonService.disableConnections();
      this.jsPlumbCommonService.hideConnections(elems);
      this.jsPlumbCommonService.repaint();
    }
    if (clearSelection) { this.clearNodeSelections(); }
  }

  private enableNodeResizing(elems: any) {
    elems.resizable('enable');
  }

  private disableNodeResizing(elems: any) {
    elems.resizable('disable');
  }

  private enableNodeDragging(elems: any) {
    elems.draggable('enable');
  }

  private disableNodeDragging(elems: any) {
    elems.draggable('disable');
  }

  /**
   * Node selection methods
   */
  public clearNodeSelections() {
    this.unsetPrimarySelection();
    this.removeAllFromSecondarySelection();
    this.emitNodeLayerSelectionChange();
  }

  public clearNodeSelectionFollowerIds() {
    this.nodeSelectionFollowerIds = new Set();
  }

  public setPrimarySelection(nodeId: string) {
    const nodeElem = this.getNodeElement(nodeId);
    this.authoringIntegrationService.nodeLayerModel.primarySelectionId = nodeId;
    nodeElem.toggleClass(this.NODE_LAYER_CHILD_PRIMARY_SELECTION_CLASS, true);
  }

  public unsetPrimarySelection() {
    const nodeId = this.authoringIntegrationService.nodeLayerModel.primarySelectionId;
    if (nodeId === null) { return; }
    const nodeElem = this.getNodeElement(nodeId);
    this.authoringIntegrationService.nodeLayerModel.primarySelectionId = null;
    nodeElem.toggleClass(this.NODE_LAYER_CHILD_PRIMARY_SELECTION_CLASS, false);
  }

  public removeAllFromSecondarySelection() {
    this.authoringIntegrationService.nodeLayerModel.secondarySelectionIds.forEach(
      (nodeId) => this.removeFromSecondarySelection(nodeId)
    );
  }

  public addToSecondarySelection(nodeId: string) {
    const nodeElem = this.getNodeElement(nodeId);
    this.authoringIntegrationService.nodeLayerModel.secondarySelectionIds.add(nodeId);
    nodeElem.toggleClass(this.NODE_LAYER_CHILD_SECONDARY_SELECTION_CLASS, true);
  }

  public removeFromSecondarySelection(nodeId: string) {
    const nodeElem = this.getNodeElement(nodeId);
    this.authoringIntegrationService.nodeLayerModel.secondarySelectionIds.delete(nodeId);
    nodeElem.toggleClass(this.NODE_LAYER_CHILD_SECONDARY_SELECTION_CLASS, false);
  }

  public emitNodeLayerSelectionChange() {
    this.authoringIntegrationService.nodeLayerSelectionEventHandler.next();
  }

  public emitUnsavedChangesEvent(hasUnsavedChanges: boolean) {
    this.authoringIntegrationService.nodeLayerModel.hasUnsavedChanges = hasUnsavedChanges;
    this.authoringIntegrationService.nodeLayerUnsavedChangesEventHandler.next();
  }

  public resetSelectionNodeBounds() {
    this.nodeSelectionBounds = {};
    this.nodeSelectionPadding = {};
    this.nodeSelectionLimits = {};
  }

  public recalculateSelectionNodeBounds() {
    this.resetSelectionNodeBounds();
    let left = Number.POSITIVE_INFINITY;
    let top = Number.POSITIVE_INFINITY;
    let right = Number.NEGATIVE_INFINITY;
    let bottom = Number.NEGATIVE_INFINITY;
    this.authoringIntegrationService.nodeLayerModel.secondarySelectionIds.forEach((nodeId) => {
      const node = this.nodeLayerIntegrationService.nodes.get(nodeId);
      const nodeElem = this.getNodeElement(nodeId);
      const nodeElemLeft = (nodeElem.position().left / this.globalIntegrationService.ratio) / this.nodeLayerIntegrationService.mapImageWidth;
      const nodeElemTop = (nodeElem.position().top / this.globalIntegrationService.ratio) / this.nodeLayerIntegrationService.mapImageHeight;
      left = Math.min(left, nodeElemLeft);
      top = Math.min(top, nodeElemTop);
      right = Math.max(right, nodeElemLeft + node.width);
      bottom = Math.max(bottom, nodeElemTop + node.height);
    });
    this.nodeSelectionBounds = { left: left, top: top, right: right, bottom: bottom };
  }

  public recalculateSelectionNodePadding(id: string) {
    const node = this.nodeLayerIntegrationService.nodes.get(id);
    const nodeElem = this.getNodeElement(id);
    const nodeElemLeft = (nodeElem.position().left / this.globalIntegrationService.ratio) / this.nodeLayerIntegrationService.mapImageWidth;
    const nodeElemTop = (nodeElem.position().top / this.globalIntegrationService.ratio) / this.nodeLayerIntegrationService.mapImageHeight;
    this.nodeSelectionPadding = {
      leftPad: nodeElemLeft - this.nodeSelectionBounds['left'],
      topPad: nodeElemTop - this.nodeSelectionBounds['top'],
      rightPad: this.nodeSelectionBounds['right'] - (nodeElemLeft + node.width),
      bottomPad: this.nodeSelectionBounds['bottom'] - (nodeElemTop + node.height),
    };
  }

  public recalculateSelectionNodeLimits(id: string) {
    const node = this.nodeLayerIntegrationService.nodes.get(id);
    this.nodeSelectionLimits = {
      minX: (this.nodeSelectionPadding['leftPad']) * this.nodeLayerIntegrationService.mapImageWidth,
      minY: (this.nodeSelectionPadding['topPad']) * this.nodeLayerIntegrationService.mapImageHeight,
      maxX: (1 - this.nodeSelectionPadding['rightPad'] - node.width) * this.nodeLayerIntegrationService.mapImageWidth,
      maxY: (1 - this.nodeSelectionPadding['bottomPad'] - node.height) * this.nodeLayerIntegrationService.mapImageHeight,
    };
  }

  /**
   * Node placement methods
   */
  public placeNodeAbsolute(elem: any, x: number, y: number, width: number, height: number) {
    elem.css({ 'left': x, 'top': y })
      .width(width).height(height);
    this.saveNodeCoordinates(elem);
  }

  public placeNodeNormalized(elem: any, x: number, y: number, width: number, height: number) {
    elem.css({ 'left': (x * this.nodeLayerIntegrationService.mapImageWidth), 'top': (y * this.nodeLayerIntegrationService.mapImageHeight) })
      .width((width * this.nodeLayerIntegrationService.mapImageWidth)).height((height * this.nodeLayerIntegrationService.mapImageHeight));
    this.saveNodeCoordinates(elem);
  }

  public moveNodeNormalized(elem: any, x: number, y: number) {
    elem.css({ 'left': (x * this.nodeLayerIntegrationService.mapImageWidth), 'top': (y * this.nodeLayerIntegrationService.mapImageHeight) });
    this.saveNodeCoordinates(elem);
  }

  public resizeNodeNormalized(elem: any, width: number, height: number) {
    elem.width((width * this.nodeLayerIntegrationService.mapImageWidth)).height((height * this.nodeLayerIntegrationService.mapImageHeight));
    this.saveNodeCoordinates(elem);
  }

  public saveNodeCoordinates(elem: any) {
    const id = this.nodeLayerIntegrationService.elementIdToNodeId.get(elem.attr('id'));
    const node = this.nodeLayerIntegrationService.nodes.get(id);
    node.leftPosition = (elem.position().left / this.globalIntegrationService.ratio) / this.nodeLayerIntegrationService.mapImageWidth;
    node.topPosition = (elem.position().top / this.globalIntegrationService.ratio) / this.nodeLayerIntegrationService.mapImageHeight;
    node.width = elem.width() / this.nodeLayerIntegrationService.mapImageWidth;
    node.height = elem.height() / this.nodeLayerIntegrationService.mapImageHeight;
    node.isMoved = true;
  }

  public placeNodeAtViewportCenter(nodeElem) {
    const viewportCenter = this.globalIntegrationService.currentZpanCommonService.getViewportCenter();
    const width = this.NEW_NODE_WIDTH;
    const height = this.NEW_NODE_HEIGHT;
    const x = viewportCenter['x'] - width / 2;
    const y = viewportCenter['y'] - height / 2;
    this.placeNodeNormalized(nodeElem, x, y, width, height);
  }
}
