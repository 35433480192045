<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="p-2 selected-filters">
                <div class="selected-filters-title">
                    Selected Filters:
                </div>
                <div *ngIf="filterMapActive" class="selected-filters-body">
                    <div *ngFor="let filter of getFilterList()" class="selected-filters-filter">
                        <i class="fa fa-times" (click)="removeFilter(filter)"></i><span>{{filter.category}}</span>|<span class="bold">{{filter.value}}</span>
                    </div>
                </div>
            </div>
            <div class="p-2">
                <table matSort (matSortChange)="sortData($event)" class="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th mat-sort-header="companyName" id="Company">
                            Company
                            <i class="filter-icon" (click)="filterData($event, 'Company')"></i>
                        </th>
                        <th mat-sort-header="productName" id="Product">
                            Product
                            <i class="filter-icon" (click)="filterData($event, 'Product')"></i>
                        </th>
                        <th mat-sort-header="{{ columnHeader }}"
                            *ngFor="let columnHeader of columnHeaderList"
                            class="table-header" id="{{columnHeader}}" [ngClass]="{ 'wide-col': columnHeader === PRODUCT_CATEGORY.RoA }">
                            {{ columnHeader }}
                            <i class="filter-icon" (click)="filterData($event, columnHeader)"></i>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let productData of getProductDataList()">
                        <td>{{ productData.companyName }}</td>
                        <td>{{ productData.productName }}</td>
                        <td *ngFor="let columnHeader of columnHeaderList">
                            {{ getCategoryValue(productData, columnHeader) }}
                            <span *ngIf="shouldShowManualInputIcon(productData, columnHeader)" class="roa-manual-icon ml-2"
                                  ngbTooltip="This value was added by a user" tooltipClass="roa-manual-tooltip">
                                <img src="assets/icons/node-manager/manual-value.svg" alt="Manually Input Value" />
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
