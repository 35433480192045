<ng-template #mapsManagerModal let-c="close" let-d="dismiss">
    <form #mapsManagerForm="ngForm" [class.submitted]="mapsManagerForm.submitted"
          (ngSubmit)="clickSubmitForm(mapsManagerForm)" novalidate>
        <div class="modal-header">
            <h4 class="modal-title">{{"MAPS_MANAGER.ADD_MAP" | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="d()">
                <span>&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <ngb-tabset class="maps-manager-form-tabset" activeId="upload-map-tab" (tabChange)="tabChange($event)">
                    <ngb-tab id="create-new-map-tab">
                        <ng-template ngbTabTitle>{{'MAPS_MANAGER.CREATE_NEW_MAP' | translate}}</ng-template>
                        <ng-template ngbTabContent>
                            <div class="col-xs-12 col-md-12">
                                <div class="form-group">
                                    <label for="newMapsManagerName">{{"MAPS_MANAGER.NEW_MAPS_MANAGER_NAME" | translate}}
                                        :
                                        <span class="error" *ngIf="mapsManagerForm.submitted">
                                        <span *ngIf="currentMapsManagerSelection == null">{{"ERRORS.IS_REQUIRED" | translate}}</span></span>
                                        <span class="error"
                                              *ngIf="mapsManagerNameExists">{{"ERRORS.MAPS_MANAGER_NAME_EXISTS" | translate}}</span>
                                    </label>
                                    <input id="newMapsManagerName" name="newMapsManagerName"
                                           (ngModelChange)="validateMapName($event)"
                                           [(ngModel)]="currentMapsManagerSelection" #newMapsManagerName="ngModel"
                                           class="form-control" required>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-12">
                                <div class="form-group">
                                    <label for="newMapsManagerVersion">{{"MAPS_MANAGER.VERSION_NAME" | translate}}:
                                        <span class="error" *ngIf="mapsManagerForm.submitted">
                                        <span *ngIf="newMapsManagerVersion.errors?.required">{{"ERRORS.IS_REQUIRED" | translate}}</span></span>
                                        <span class="error"
                                              *ngIf="mapsManagerVersionNameExists">{{"ERRORS.MAPS_MANAGER_VERSION_NAME_EXISTS" | translate}}</span>
                                    </label>
                                    <input id="newMapsManagerVersion" name="newMapsManagerVersion"
                                           (ngModelChange)="validateVersionName($event)"
                                           [(ngModel)]="mapsManagerVersion.name"
                                           #newMapsManagerVersion="ngModel" class="form-control" required>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="upload-map-tab">
                        <ng-template ngbTabTitle>Upload Existing Map</ng-template>
                        <ng-template ngbTabContent>
                            <div class="col-xs-12 col-md-12">
                                <div class="form-group">
                                    <label for="mapsManagerName">{{"MAPS_MANAGER.MAPS_MANAGER_NAME" | translate}}:
                                        <span class="error" *ngIf="mapsManagerForm.submitted">
                                <span *ngIf="currentMapsManagerSelection == null">{{"ERRORS.IS_REQUIRED" | translate}}</span>
                            </span>
                                        <span class="error"
                                              *ngIf="mapsManagerNameExists">{{"ERRORS.MAPS_MANAGER_NAME_EXISTS" | translate}}</span>
                                    </label>
                                    <ng-select
                                            [items]="mapsManagerNameList"
                                            bindLabel="name"
                                            bindValue="id"
                                            name="mapsManagerName"
                                            id="mapsManagerName"
                                            [addTag]="addNewMapsManagerName"
                                            [(ngModel)]="currentMapsManagerSelection">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-12">
                                <div class="form-group">
                                    <label for="version">{{"MAPS_MANAGER.VERSION_NAME" | translate}}:
                                        <span class="error" *ngIf="mapsManagerForm.submitted">
                                <span *ngIf="version.errors?.required">{{"ERRORS.IS_REQUIRED" | translate}}</span>
                            </span>
                                        <span class="error"
                                              *ngIf="mapsManagerVersionNameExists">{{"ERRORS.MAP_VERSION_NAME_EXISTS" | translate}}</span>
                                    </label>
                                    <input id="version" name="version" (ngModelChange)="validateVersionName($event)"
                                           [(ngModel)]="mapsManagerVersion.name"
                                           #version="ngModel" class="form-control" required>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-12">
                                <div class="form-group">
                                    <label for="mapsManagerFileUpload">{{"MAPS_MANAGER.UPLOAD_MAPS_MANAGER" | translate}}
                                        :
                                        <span class="error" *ngIf="mapsManagerForm.submitted">
                                <span *ngIf="uploader.queue[0] == null">{{"ERRORS.IS_REQUIRED" | translate}}</span>
                            </span>
                                    </label>
                                    <input id="mapsManagerFileUpload" name="mapsManagerFileUpload" type="file"
                                           ng2FileSelect [uploader]="uploader"
                                           #mapsManagerFileUpload
                                           accept=".eMapBundle">
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default" #closeBtn
                    (click)="c()">{{"GENERICS.CLOSE" | translate}}</button>
            <button type="submit" class="btn btn-primary">
                {{(isEditMode ? "GENERICS.EDIT" : "GENERICS.CREATE") | translate}}
            </button>
        </div>
    </form>
</ng-template>
<ng-template #mindNodeFileCheckValidationModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Map Bundle Upload Validation</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
                (click)="closeMindNodeValidationModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="!mindNodeFileExistsCheck">
            <p>MindNode is not present in the eMapBundleFile : <strong><span
                    class="text-primary">{{currentBundleFileName}}</span></strong></p>
            <p>Would you like to proceed further with Static Map process ?</p>
        </div>
        <div *ngIf="mindNodeFileExistsCheck">
            <p>All required files are present in the eMapBundleFile : <strong><span
                    class="text-primary">{{currentBundleFileName}}</span></strong></p>
            <p>Would you like to proceed further with MindMap transform process ?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="closeMindNodeValidationModal()">Cancel</button>
        <button type="button" ngbAutofocus class="btn btn-danger" (click)="createBundle()">Ok</button>
    </div>
</ng-template>
