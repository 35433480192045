/* -- Angular Dependencies -- */
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
/* -- 3rd party Dependencies -- */
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {StompService} from 'ng2-stomp-service';
/* -- Singleton Services -- */
import {AuthenticationService} from './services/authentication.service';
import {PasswordResetService} from './data-services/passwordReset.service';
import {AlignService} from './data-services/align.service';
import {UserService} from './data-services/user.service';
import {RoleService} from './data-services/role.service';
import {PermissionService} from './data-services/permission.service';
import {SearchService} from './data-services/search.service';
import {TemplateModalService} from './services/template-modal.service';
import {MetricsService} from './data-services/metrics.service';
import {FileUploadService} from './services/fileUpload.service';
import {EventsHubService} from './events-hub.service';
import {AuthGuard} from './guards/auth.guard';
import {LoggedGuard} from './guards/logged.guard';
import {AuthInterceptor} from './auth-interceptor';
import {AuthorizationService} from './services/authorization.service';
import {PasswordExpirationService} from './data-services/passwordExpiration.service';
import {throwIfAlreadyLoaded} from './module-import-guard';
import {PasswordExpiredService} from './data-services/passwordExpired.service';
import {HolidayService} from './data-services/holiday.service';
/* -- App Components -- */
import {SpinnerComponent} from '../shared/components/spinner/spinner.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {BannerNotificationsComponent} from '../shared/components/banner-notifications/banner-notifications.component';
import {UserGroupsService} from './data-services/userGroups.service';
import {HeartbeatService} from './data-services/heartbeat.service';
import {AuditSessionService} from './data-services/auditSession.service';
import {JobService} from './data-services/job.service';
import {MapService} from './data-services/map.service';
import {BundleService} from './data-services/bundle.service';
import {DeploymentService} from './data-services/deployment.service';
import {SearchFilterKeyboardServices} from '../shared/services/searchFilterKeyboard.services';
import {CategorizationService} from './data-services/categorization.service';
import {ToolboxAllPanelServices} from '../shared/services/toolboxAllPanel.services';
import {AuthoringIntegrationService} from '../shared/services/authoringIntegration.service';
import {ImageService} from './data-services/image.service';
import {JqueryCommonServices} from '../shared/services/jqueryCommon.services';
import {HammerCommonServices} from '../shared/services/hammerCommon.services';
import {AnimationCommonServices} from '../shared/services/animationCommon.services';
import {DesignActionEventsService} from '../shared/services/designActionEvents.service';
import {DesignIntegrationService} from '../shared/services/designIntegration.service';
import {GlobalIntegrationServices} from '../shared/services/globalIntegration.services';
import {RemoteServerService} from './data-services/remoteServer.service';
import {CompanyService} from './data-services/company.service';
import {NodeCategoryValueService} from './data-services/nodeCategoryValue.service';
import {NodeLayerIntegrationService} from '../shared/services/nodeLayerIntegration.service';
import {NodeHierarchyService} from '../shared/services/nodeHierarchy.service';
import {CategoryService} from './data-services/category.service';
import {CategoryValueService} from './data-services/category-value.service';
import {ProductService} from './data-services/product.service';
import {ReportService} from './data-services/report.service';
import {FinalizationService} from './data-services/finalization.service';
import {NodeLayerService} from './data-services/nodeLayer.service';
import {NodeProductService} from './data-services/nodeProduct.service';
import {AdminCategoryValueService} from './data-services/admin-category-value.service';
import {MapVersionNodeService} from './data-services/mapVersionNode.service';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {BannerNotificationsService, LocalStorageService, SanitizeService, SpinnerService, UtilsService} from '../xform-compat';
import {ProductCategoryValueService} from './data-services/productCategoryValue.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';


@NgModule({
  imports: [
    CommonModule, FormsModule, RouterModule, TranslateModule, HttpClientModule, NgbModule, SweetAlert2Module
  ],
  exports: [
    CommonModule, FormsModule, RouterModule, TranslateModule, HttpClientModule, NgbModule, SweetAlert2Module,
    PageNotFoundComponent, SpinnerComponent, BannerNotificationsComponent
  ],
  declarations: [PageNotFoundComponent, SpinnerComponent, BannerNotificationsComponent],
  providers: [
    AuthenticationService, PasswordResetService, AlignService, UserService, RoleService, SearchService, AuthGuard, EventsHubService, LoggedGuard,
    PermissionService, TemplateModalService, MetricsService, FileUploadService,
    LocalStorageService, TranslateService, StompService, SpinnerService, BannerNotificationsService, SanitizeService, UtilsService, HttpClient,
    AuthorizationService, PasswordExpirationService, PasswordExpiredService, UserGroupsService, HolidayService, HeartbeatService, AuditSessionService,
    JobService, MapService, BundleService, DeploymentService, ImageService, SearchFilterKeyboardServices, CategorizationService,
    ToolboxAllPanelServices, AuthoringIntegrationService, JqueryCommonServices, GlobalIntegrationServices, HammerCommonServices,
    AnimationCommonServices, DesignActionEventsService, DesignIntegrationService, RemoteServerService,
    CompanyService, NodeCategoryValueService, NodeLayerIntegrationService, NodeHierarchyService, CategoryService, CategoryValueService, ReportService,
    ProductService, ProductCategoryValueService, FinalizationService, NodeLayerService, NodeProductService, GoogleTagManagerService,
    AdminCategoryValueService, MapVersionNodeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
