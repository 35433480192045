import {Injectable} from "@angular/core";
import {RemoteServer} from "../models/remoteServer.model";
import {Subject} from "rxjs";

@Injectable()
export class MapsManagerIntegrationService {

    private _remoteServers: Array<RemoteServer> = [];
    private _$subMenuOpened: Subject<any> = new Subject<any>();

    get remoteServers(): Array<RemoteServer> {
        return this._remoteServers;
    }
    set remoteServers(value: Array<RemoteServer>) {
        this._remoteServers = value;
    }

    get $subMenuOpened(): Subject<any> {
        return this._$subMenuOpened;
    }
}
