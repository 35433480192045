import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { CONFIG } from '../../config';
import { Observable } from 'rxjs/internal/Observable';
import { BannerNotificationsService } from '../../xform-compat';


@Injectable()

export class FileUploadService {
  public uploader: FileUploader;
  private uploadingError: boolean;
  public onFileAdded: Observable<boolean>;
  public fileIndex: number;

  constructor(
    private bannerNotificationsService: BannerNotificationsService,
    private translateService: TranslateService
  ) {
    this.initFileUploader();
    this.uploaderSettings();
  }

  public initFileUploader() {
    this.uploader = new FileUploader({
      autoUpload: false,
      disableMultipart: false,
      removeAfterUpload: true,
      allowedMimeType: CONFIG.uploadSettings.allowedTypes,
      maxFileSize: CONFIG.uploadSettings.maxFileSize,
      authTokenHeader: CONFIG.authHeader
    });
  }

  public uploaderSettings() {

    this.uploader.onAfterAddingFile = () => {
      this.uploadingError = false;
      this.onFileAdded = new Observable(observer => {
        this.cleanQueue();
        observer.next(true);
      });
    };

    this.uploader.onBeforeUploadItem = (item: FileItem) => {
      // Future implementation purpose
    };

    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any) => {
      let errorMsg = '';
      let params: object;
      switch (filter.name) {
        case 'fileSize':
          errorMsg = 'ERRORS.FILE_SIZE';
          params = {
            file_size: (Math.round(item.size) / 1000),
            max_size: (Math.round(CONFIG.uploadSettings.maxFileSize) / 1000)
          };
          break;
        case 'mimeType':
          errorMsg = 'ERRORS.MIMETYPE';
          params = {
            type: item.type
          };
          break;
        default:
          errorMsg = 'ERRORS.UNKNOWN';
          params = {
            filter: filter.name
          };
      }
      this.uploadingError = true;
      this.bannerNotificationsService.error(this.translateService.instant(errorMsg, params));
    };
  }

  public cleanQueue() {
    if (this.uploader.queue.length > 0) {
      this.uploader.clearQueue();
    }
  }
}
