import { Injectable } from '@angular/core';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { NodeProduct } from '../../shared/models/nodeProduct.model';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


@Injectable()
export class NodeProductService extends AbstractCRUD<NodeProduct> implements CRUDService<NodeProduct>, Searcher {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/core/service/recon/productinstance';
  }

  public getDisplayName(item: any): string {
    return item.name;
  }

  public getAllNodeProductReconPath(mapVersionId: string) {
    return super.getApiPath().concat('/').concat(mapVersionId);
  }

  public getAllNodeProductRecon(mapVersionId: string) {
    return this.getHTTP().get(this.getAllNodeProductReconPath(mapVersionId))
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }
}
