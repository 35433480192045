import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { NodeLayerIntegrationService } from '../../services/nodeLayerIntegration.service';
import { DestroyerAbstract } from '../../../core/components/destroyer.abstract';

export abstract class NodeLayerAbstract extends DestroyerAbstract {

    protected NODE_LAYER_PARENT_CLASS = 'main-map-image';
    protected NODE_LAYER_CHILD_CLASS = 'node-layer-child';
    protected NODE_LAYER_CHILD_SHOW_CLASS = 'node-layer-child-show';
    protected NODE_LAYER_CHILD_EXCLUDED_CLASS = 'node-layer-child-excluded';

    private NODE_LAYER_CHILD_ELEMENT_PREFIX = 'node_';

    constructor(
        protected nodeLayerIntegrationService: NodeLayerIntegrationService,
        protected jqueryCommonService: JqueryCommonServices,
    ) {
        super();
    }

    protected nodeIdToElementId(id: string) {
        return this.NODE_LAYER_CHILD_ELEMENT_PREFIX + id;
    }

    protected nodeIdsToElementIds(ids: string[]) {
        const that = this;
        return ids.map(that.nodeIdToElementId.bind(this));
    }

    protected elementIdToNodeId(elementId: string) {
        if (!this.nodeLayerIntegrationService.elementIdToNodeId.has(elementId)) {
            throw new Error('No node element exists with id ' + elementId + '!');
        }
        return this.nodeLayerIntegrationService.elementIdToNodeId.get(elementId);
    }

    protected getNodeElement(id: string) {
        if (!this.nodeLayerIntegrationService.nodes.has(id)) {
            throw new Error('No node exists with id ' + id + '!');
        }
        const nodeElemId = this.nodeIdToElementId(id);
        return this.jqueryCommonService.selector(nodeElemId.toId());
    }

    protected getNodeElements() {
        return this.jqueryCommonService.selector(this.NODE_LAYER_CHILD_CLASS.toClass());
    }
}
