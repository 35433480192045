import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { NodesManagerListComponent } from './nodes-manager-list/nodes-manager-list.component';


const routes: Routes = [
  { path: 'list/:id/:name', component: NodesManagerListComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NodesManagerRoutingModule {}
