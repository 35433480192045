import { Injectable } from '@angular/core';
import { CategoryValueResource } from '../../shared/models/resources/categoryValueResource.model';
import { CONFIG } from '../../config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';


@Injectable()
export class CategoryValueService extends AbstractCRUD<CategoryValueResource> implements CRUDService<CategoryValueResource>, Searcher {

  private metadataPath = CONFIG.metadataPath;

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getDisplayName(item: any): string {
    return item.name;
  }

  public getResourcePath() {
    return '/node/service/rms/categoryvalue';
  }

  public getAllCategoryValuePath() {
    return super.getApiPath().concat('/all');
  }

  public getFuzzySearchForCategoryValuePath() {
    return super.getApiPath().concat('/search');
  }

  public getAllCategoryValue(httpParams: HttpParams) {
    return this.getHTTP().get(this.getAllCategoryValuePath(), { params: httpParams })
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public getAllCategoryValueByIds(httpParams: HttpParams) {
    return this.getHTTP().get(this.getApiPath(), { params: httpParams })
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public getFuzzySearchForCategoryValue(categoryValueName: string) {
    const httpParams = new HttpParams().set('categoryValueName', encodeURIComponent(categoryValueName));
    return this.getHTTP().get(this.getFuzzySearchForCategoryValuePath(), { params: httpParams })
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }
}
