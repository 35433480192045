<div *ngIf="loading">
  retrieving data
</div>

<div *ngIf="!items?.length && !loading">
  no data available
</div>

<!-- TODO: Create recursive component -->
<form #optionsForm="ngForm" *ngIf="items.length > 0">
  <ul *ngIf="items && !loading" class="items-wrapper bordered-box">
    <li class="item parent-list-item" *ngFor="let topLevelItem of items">
      <div class="checkbox">
        <input type="checkbox" id="{{selectGroup}}" name="{{selectGroup}}" (click)="select(topLevelItem)"
               [checked]="state === topLevelItem.label && topLevelItem.isChecked" [value]="topLevelItem.label" [(ngModel)]="state">
        <label for="{{selectGroup}}" class="description" (click)="toggle(topLevelItem)">
          <i class="fa fa-caret-{{topLevelItem.expanded ? 'down' : 'right'}}" aria-hidden="true"
             *ngIf="topLevelItem.children"></i>
          {{topLevelItem.label}}
        </label>
      </div>
      <ul class="items-wrapper" *ngIf="topLevelItem.children && topLevelItem.expanded">
        <li class="item" *ngFor="let secondLevelItem of topLevelItem.children">
          <div class="checkbox">
            <input type="checkbox" name="{{selectGroup}}"
                   (click)="select(secondLevelItem)"
                   [checked]="state === secondLevelItem.label" [value]="secondLevelItem.label" [(ngModel)]="state">
            <label class="description" (click)="toggle(secondLevelItem)">
              <i class="fa fa-caret-{{secondLevelItem.expanded ? 'down' : 'right'}}" aria-hidden="true" *ngIf="secondLevelItem.children"></i>
              {{secondLevelItem.label}}
            </label>
          </div>
          <ul class="items-wrapper" *ngIf="secondLevelItem.children && secondLevelItem.expanded">
            <li class="item" *ngFor="let thirdLevelItem of secondLevelItem.children">
              <input type="radio" name="{{selectGroup}}"
                     (click)="select(thirdLevelItem)"
                     [checked]="state === thirdLevelItem.label" [value]="thirdLevelItem.label" [(ngModel)]="state">
              <label class="description">
                {{thirdLevelItem.label}}
              </label>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</form>
