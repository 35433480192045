import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { EventsHubService } from '../../../core/events-hub.service';
import { DuoSecurityService } from '../../../core/data-services/duoSecurity.service';
import { DuoSecurity } from '../../../shared/models/interfaces/duoSecurity.interface';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { UserService } from '../../../core/data-services/user.service';
import { TokenInfo, User } from '../../../shared/models/';
import { CONFIG } from '../../../config';
import { Subscription } from 'rxjs';
import { BannerNotificationsService } from '../../../xform-compat';


declare let Duo: any;

@Component({
  selector: 'emap-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent implements OnInit, OnDestroy {

  public duoResult: DuoSecurity;
  private loginInfo: any;
  private duoSubscription: Subscription;

  constructor(
    private eventsHubService: EventsHubService,
    private duoService: DuoSecurityService,
    private elementRef: ElementRef,
    private bannerNotificationsService: BannerNotificationsService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private translateService: TranslateService
  ) { }

  public getDuoData() {
    this.duoSubscription = this.eventsHubService.duoSecureError$
      .subscribe(
        loginInfo => this.duoService.getDuoHostAndHash(loginInfo)
          .subscribe(
            result => {
              loginInfo.twoFactor = true;
              this.loginInfo = loginInfo;
              this.duoResult = <DuoSecurity>result;
              this.getScript();
            }
          )
      );
  }

  private getScript() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = CONFIG.duoScriptUrl;
    this.elementRef.nativeElement.appendChild(s);
    s.onload = () => this.triggerDuo();
  }

  private triggerDuo() {
    Duo.init({
      host: this.duoResult.hostname,
      sig_request: this.duoResult.authenticationHash,
      submit_callback: (form) => this.submitCallback(form)
    });
  }

  public submitCallback(form) {
    if (form.nodeName !== 'FORM') {
      this.bannerNotificationsService.error('Login Error');
      this.router.navigate(['/login']);
      return;
    }
    const responseInput = form.elements.namedItem('sig_response');
    const sigResponse = responseInput && responseInput.value;
    let tokenInfo: TokenInfo;

    if (sigResponse) {
      this.loginInfo.secretCode = responseInput.value;
      this.authenticationService.login(this.loginInfo).then(
        (response) => {
          tokenInfo = response;
          this.userService.getRecord(response.userId)
            .subscribe((user: User) => {
                this.authenticationService.setLoggedUser(user);
                this.authenticationService.setLang(user.attributes.language);
                this.eventsHubService.setLoggedIn(true);
                this.translateService.use(user.attributes.language || CONFIG.defaultLang);
                this.router.navigate(['/dashboard']);
              },
              (error) => {
                this.bannerNotificationsService.error(this.translateService.instant('API_ERRORS.BUS_0002'));
                this.router.navigate(['/login']);
              }
            );
        })
        .catch(response => {
          this.bannerNotificationsService.error(this.translateService.instant('TWO_FACTOR_AUTH.FAIL'));
        });
    }
  }

  ngOnInit() {
    this.getDuoData();
  }

  ngOnDestroy() {
    this.duoSubscription.unsubscribe();
  }
}
