import { Image } from './image.model';


export class ChartItem {
  id: number;
  image: Image;
  title: string;

  constructor(obj: ChartItem = {} as ChartItem) {
    this.id = obj.id;
    this.image = obj.image;
    this.title = obj.title;
  }
}
