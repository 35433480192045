import { Injectable } from '@angular/core';
import {AbstractCRUD, CRUDService, ExceptionService, UtilsService} from '../../xform-compat';
import {Company} from '../../shared/models/company.model';
import {Product} from '../../shared/models/product.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataBindService extends AbstractCRUD<Company | Product> implements CRUDService<Company | Product> {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/core/service';
  }

  public getCompanyAutocompleteEDM(searchTerm: string) {
    const params = new HttpParams().set('nameSearch', searchTerm);
    return this.getHTTP().get(`${this.getAutocompletePath()}/company/edm`, {params: params})
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public getProductAutocompleteEDM(searchTerm: string, selectedCompanies?: Array<Company>) {
    const params = new HttpParams().set('nameSearch', searchTerm);
    return this.getHTTP().put(`${this.getAutocompletePath()}/product/edm`, selectedCompanies.map(c => c.edmGUID), {params: params})
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public getMilestoneCategoryValues(params?: any) {
    return this.getHTTP().get(`${super.getApiPath()}/milestoneCategoryValuesMapped`, {params: params});
  }

  public getIndications() {
    return this.getHTTP().get(`${super.getApiPath()}/indications`);
  }

  private getAutocompletePath() {
    return `${super.getApiPath()}/autocomplete`;
  }
}
