import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment-timezone';

@Pipe({
  name: 'rmsTzFormat'
})
export class RmsTzFormatPipe implements PipeTransform {

  transform(value: string, timezone: string, dateFormat: string): string {
    if (!value) {
      return value;
    }
    value = moment.utc(value).tz(timezone).format(dateFormat);
    return value;
  }

}

