import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {FileUploadService} from '../../../core/services/fileUpload.service';
import {CONFIG} from '../../../config';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MindMapVersionNodeService} from '../../../core/data-services/mindMapVersionNode.service';
import {ActivatedRoute} from '@angular/router';
import {MapsManagerVersionService} from '../../../core/data-services/mapsManagerVersion.service';
import {BannerNotificationsService, SpinnerService, UtilsService} from '../../../xform-compat';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {MindMapVersionNode} from '../../../shared/models/mindMapVersionNode.model';
import {DataBindingModalComponent} from './data-binding-modal/data-binding-modal.component';
import {MILESTONE_CATEGORY} from '../../../shared/enums';
import {interval, Subject, Subscription} from 'rxjs';
import {ProductCategoryValueService} from '../../../core/data-services/productCategoryValue.service';
import {ProductCategoryValue} from '../../../shared/models/productCategoryValue.model';
import {MindMapCommandHandler} from '../../../../assets/js/gojs/extensions/MindMapCommandHandler';


import * as go from 'gojs';
import {Diagram, GraphObject, Overview} from 'gojs';
import {RealtimeDragSelectingTool} from '../../../../assets/js/gojs/extensions/RealtimeDragSelectingTool';
import {MindMapDiagramMaker} from '../mind-map-diagram-maker';
import {DataSyncService, DiagramComponent} from 'gojs-angular';
import {RichContentModalComponent} from './rich-content-modal/rich-content-modal.component';
import {takeUntil} from 'rxjs/operators';
import {Draft, produce} from 'immer';
import Swal from 'sweetalert2';

@Component({
  selector: 'emap-mind-map-core',
  templateUrl: './mind-map-core.component.html',
  styleUrls: ['./mind-map-core.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MindMapCoreComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {

  @ViewChild('diagramEle', { static: true }) myDiagram: DiagramComponent;
  @ViewChild('imageUploadModal', {static: true}) imageUploadModal: ElementRef;
  uploader: FileUploader;
  // Initialize skipsDiagramUpdate to true so that the initial data load and layout cannot be undone
  public diagramState: DiagramState = { nodeList: [], skipsDiagramUpdate: true };
  public observedDiagram: Diagram;
  public previewToggleOn = false;
  public enableClearSearchButton = false;
  public searchResults: go.Iterator<go.Node> = null;
  public searchTerm = null;
  public mapsManagerVersionName: string;
  public finalized: any;
  public saveSubscription$ = new Subject<boolean>();
  public isProdEnv: boolean = environment.env === 'prod';
  public isOpenPanel = false;
  public counterMessage: number;
  public curSearchNumSelected: number;
  private mapsManagerVersionId: number;
  private mapsManagerVersionArchiveId: number;
  private productCategoryValuesMap: Map<string, Array<ProductCategoryValue>> = new Map<string, Array<ProductCategoryValue>>();
  private imageAdornment: any;
  private imageUploadModal$: NgbModalRef;
  private imageBaseUrl = CONFIG.aws.s3[environment.env];
  private checkSave = false;
  private layoutSubscription$ = new Subject<any>();
  private dataTimerSubscription$: Subscription;
  private destroy$ = new Subject<boolean>();
  private STROKE_COLOR = 'red';
  private openModalNodeKeys: Set<string> = new Set<string>();
  public readonly PRODUCT_CATEGORY_NAMES_FILTER = ['Dosing', 'Route of Administration']; // add more default values here based on the business reqs
  public readonly AUTO_SAVE_INTERVAL = this.isProdEnv ? 300 : (environment.env === 'local' ? 3000 : 30);
  public readonly NODE_EDITS_ACTIVE_IDS = ['node-edits-panel-0', 'node-edits-panel-1'];
  public readonly COLORS_LIST = {
    'default': 'default',
    'red': '#FA0606',
    'purple': '#7706FA',
    'blue': '#0042BE',
    'green': '#498800',
    'yellow': '#F4DA17',
    'orange': '#EB8444',
    'black': '#000000',
    'white': '#FFFFFF'
  };

  constructor(
    private fileUploadService: FileUploadService,
    private authenticationService: AuthenticationService,
    private mapsManagerVersionService: MapsManagerVersionService,
    private mindMapVersionNodeService: MindMapVersionNodeService,
    private productCategoryValueService: ProductCategoryValueService,
    private bannerNotificationsService: BannerNotificationsService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    private eMapUtilsService: UtilsService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loadMindMap();
    this.initImageUploader();
    this.addTabCloseDialog();
    this.loadProductCategoryValues(this.PRODUCT_CATEGORY_NAMES_FILTER);
    this.layoutSubscription$.subscribe(layoutPart => {
      MindMapDiagramMaker.layoutTree(layoutPart);
    });
  }

  ngOnDestroy(): void {
    this.layoutSubscription$.unsubscribe();
    this.removeTabCloseDialog();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterContentInit() {
    document.getElementById('mind-map-header-search-input').addEventListener('keyup', (event) => {
      // keyCode is deprecated but code is not supported on all browsers, so check that it exists first
      if ((event.code && event.code === 'Enter') || event.keyCode === 13) {
        this.searchDiagram();
      }
    });
  }

  ngAfterViewInit(): void {
    const diagram = this.myDiagram.diagram;
    if (this.observedDiagram) {
      return;
    }
    this.observedDiagram = diagram;
    this.cdr.detectChanges(); // IMPORTANT: without this, Angular will throw ExpressionChangedAfterItHasBeenCheckedError (dev mode only)

    // Use the Initial Layout Completed event to call a secondary initialization method within the scope of this component
    diagram.addDiagramListener('InitialLayoutCompleted', () => {
      this.initDiagramComponentScope();
    });
  }

  /**
   * Initialize the goJS diagram. Automatically called by the `gojs-diagram` component.
   * In the scope of this function, `this` represents the diagram. Therefore, everything in this function must be
   * statically accessible, making no reference to the MindMapCore component or its instance fields. If you need to
   * add a property to the diagram which references the Core component, set it in the `initDiagramComponentScope` method.
   */
  initDiagram(): Diagram {
    go.Diagram.licenseKey = CONFIG.gojs.licenseKey;
    defineShapes();
    const diagram = MindMapDiagramMaker.makeDiagram();
    diagram.contextMenu = MindMapDiagramMaker.makeContextMenu();
    diagram.linkTemplate = MindMapDiagramMaker.makeLinkTemplate();
    diagram.commandHandler = new MindMapCommandHandler();
    diagram.toolManager.dragSelectingTool = new RealtimeDragSelectingTool();
    diagram.undoManager.isEnabled = false;  // Disable the undo manager until the mindmap is loaded, so that users cannot undo the initial data load

    // When the user double clicks, zoom to that point
    diagram.doubleClick = () => {
      const newZoomLevel = 1;
      const oldZoom = diagram.zoomPoint;
      if (diagram.scale === newZoomLevel) {
        return;
      }
      const oldPadding = diagram.padding instanceof go.Margin ?
          new go.Margin(diagram.padding.left, diagram.padding.right, diagram.padding.bottom, diagram.padding.top) :
          diagram.padding;
      diagram.zoomPoint = diagram.lastInput.viewPoint;
      diagram.padding = 1000; // Add a large amount of padding so that the viewpoint does not get forced away from the edges
      const animation = new go.Animation();
      animation.add(diagram, 'scale', diagram.scale, newZoomLevel);
      animation.start();
      animation.finished = () => {
        diagram.padding = oldPadding;
        diagram.zoomPoint = oldZoom;
      };
    };

    // Add a custom method when the user pastes nodes to the map
    diagram.addDiagramListener('ClipboardPasted', e => {
      diagram.startTransaction('Paste');
      const newNodeMap = new Map();
      let maxIntKey = getMaxIntKey();
      e.subject.each(part => {
        // Assign new keys to the pasted nodes
        if (part instanceof go.Node) {
          diagram.model.setDataProperty(part.data, 'id', null);
          newNodeMap.set(part.data.key, maxIntKey);
          diagram.model.setDataProperty(part.data, 'key', maxIntKey);
          maxIntKey += 1;
        }
      });
      e.subject.each(part => {
        // Set the parents of the pasted nodes
        if (part instanceof go.Node) {
          const newNodeParent = newNodeMap.get(part.data.parent);
          if (newNodeParent) {
            diagram.model.setDataProperty(part.data, 'parent', newNodeParent);
          }
        }
      });
      diagram.commitTransaction('Paste');
    });

    return diagram;

    function getMaxIntKey() {
      let max = 0;
      diagram.nodes.each(function (node) {
        if (Number.isInteger(node.data.key) && node.data.key > max) {
          max = node.data.key;
        }
      });
      return max + 1;
    }

    function defineShapes() {
      go.Shape.defineFigureGenerator('RoundedLeftRectangle', function (shape, w, h) {
        // this figure takes one parameter, the size of the corner
        let p1 = 5;  // default corner size
        if (!isNaN(shape?.parameter1) && shape?.parameter1 >= 0) {
          p1 = shape.parameter1;  // can't be negative or NaN
        }
        p1 = Math.min(p1, w);  // limit by width & height
        p1 = Math.min(p1, h / 3);
        const geo = new go.Geometry();
        // a single figure consisting of straight lines and quarter-circle arcs
        geo.add(new go.PathFigure(w, 0)
            .add(new go.PathSegment(go.PathSegment.Line, w, h))
            .add(new go.PathSegment(go.PathSegment.Line, p1, h))
            .add(new go.PathSegment(go.PathSegment.Arc, 90, 90, p1, h - p1, p1, p1))
            .add(new go.PathSegment(go.PathSegment.Line, 0, p1))
            .add(new go.PathSegment(go.PathSegment.Arc, 180, 90, p1, p1, p1, p1).close()));
        // don't intersect with two top corners when used in an "Auto" Panel
        geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0.3 * p1);
        geo.spot2 = new go.Spot(1, 1, -0.3 * p1, 0);
        return geo;
      });

      go.Shape.defineFigureGenerator('RoundedRightRectangle', function (shape, w, h) {
        // this figure takes one parameter, the size of the corner
        let p1 = 5;  // default corner size
        if (!isNaN(shape?.parameter1) && shape?.parameter1 >= 0) {
          p1 = shape.parameter1;  // can't be negative or NaN
        }
        p1 = Math.min(p1, w);  // limit by width & height
        p1 = Math.min(p1, h / 3);
        const geo = new go.Geometry();
        // a single figure consisting of straight lines and quarter-circle arcs
        geo.add(new go.PathFigure(0, 0)
            .add(new go.PathSegment(go.PathSegment.Line, w - p1, 0))
            .add(new go.PathSegment(go.PathSegment.Arc, 270, 90, w - p1, p1, p1, p1))
            .add(new go.PathSegment(go.PathSegment.Line, w, h - p1))
            .add(new go.PathSegment(go.PathSegment.Arc, 0, 90, w - p1, h - p1, p1, p1))
            .add(new go.PathSegment(go.PathSegment.Line, 0, h).close()));
        // don't intersect with two bottom corners when used in an "Auto" Panel
        geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0);
        geo.spot2 = new go.Spot(1, 1, -0.3 * p1, -0.3 * p1);
        return geo;
      });
    }
  }

  /**
   * Additional initializations for the goJS diagram. Called when the DiagramEvent 'InitialLayoutCompleted' is fired.
   * Called within the context of the MindMapCore component, so it can reference instance fields and methods.
   */
  initDiagramComponentScope(): void {
    const diagram = this.myDiagram.diagram;
    const that = this;
    diagram.isReadOnly = this.finalized;

    diagram.nodeTemplate = MindMapDiagramMaker.makeNodeTemplate((node: GraphObject) => {
      this.showRichContentEditor(node);
    });
    diagram.nodeSelectionAdornmentTemplate = MindMapDiagramMaker.makeSelectionAdornment((node: GraphObject) => {
      this.showRichContentEditor(node);
    });

    // Add a listener to add or remove an asterisk from the page title if the diagram is modified
    diagram.addDiagramListener('Modified', () => {
      const button: any = document.getElementById('mind-map-header-save');
      if (button) {
        button.disabled = !diagram.isModified;
      }
      const idx = document.title.lastIndexOf('*');
      if (diagram.isModified) {
        if (idx < 0) {
          document.title += '*';
          this.checkSave = true;
        }
      } else {
        this.checkSave = false;
        if (idx >= 0) {
          document.title = document.title.substr(0, idx);
        }
      }
    });

    // Add custom key handlers
    // Must be a function definition, not an arrow, so that the CommandHandler prototype works correctly
    diagram.commandHandler.doKeyDown = function () {
      const e = diagram.lastInput;
      // The meta (Command) key substitutes for "control" for Mac commands
      const control = e.control || e.meta;
      const key = e.key;
      // Specify the location the nodes should be pasted at (and skip the default paste behavior)
      if (control && key === 'V') {
        that.paste(diagram.viewportBounds.center);
      } else {
        // call base method with no arguments (default functionality)
        go.CommandHandler.prototype.doKeyDown.call(this);
      }
    };

    // Add a listener to handle moving nodes and attaching them to others
    diagram.addDiagramListener('SelectionMoved', () => {
      diagram.selection.each((node: go.Node) => {
        if (node == null) {
          return;
        }
        const parentNodeKey = node.data.parent;
        const currNodeDir = node.data.dir;
        if (parentNodeKey) {
          const parentNode = diagram.findNodeForKey(node.data.parent);
          if (parentNode == null) {
            return;
          }
          const parentNodeLocation = parentNode.location;
          const currentNodeLocation = node.location;
          if (currNodeDir === 'left' && currentNodeLocation.x > (parentNodeLocation.x)) {
            this.updateNodeDirection(node, 'right');
            MindMapDiagramMaker.layoutTree(node);
          } else if (currNodeDir === 'right' && (currentNodeLocation.x + node.data.width) < parentNodeLocation.x) {
            this.updateNodeDirection(node, 'left');
            MindMapDiagramMaker.layoutTree(node);
          }
        }
      });
    });

    // Start the autosave timer if the map is not finalized
    if (!this.finalized) {
        if (this.dataTimerSubscription$) {
          this.dataTimerSubscription$.unsubscribe();
          this.dataTimerSubscription$ = null;
        }
        this.counterMessage = this.AUTO_SAVE_INTERVAL;
        this.dataTimerSubscription$ = interval(1000)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.counterMessage--;
              if (this.counterMessage < 1) {
                this.counterMessage = this.AUTO_SAVE_INTERVAL;
                this.save(false);
              }
            });
    }

    this.saveSubscription$.subscribe(saveState => {
      if (saveState) {
        this.save();
      }
    });
  }

  /**
   * Initialize the diagram overview
   */
  initOverview(): Overview {
    return GraphObject.make(go.Overview, {contentAlignment: go.Spot.Center});
  }

  updateDiagramState(newState: DiagramState) {
    this.diagramState = produce(this.diagramState, (draft: Draft<DiagramState>) => {
      if (newState.nodeList) {
        draft.nodeList = newState.nodeList;
      }
      if (newState.skipsDiagramUpdate !== undefined) {
        draft.skipsDiagramUpdate = newState.skipsDiagramUpdate;
      }
    });
  }

  modelChangeHandler(changes: go.IncrementalData) {
    this.diagramState = produce(this.diagramState, (draft: Draft<DiagramState>) => {
      draft.skipsDiagramUpdate = true;
      draft.nodeList = DataSyncService.syncNodeData(changes, draft.nodeList, this.myDiagram.diagram.model) as Array<MindMapVersionNode>;
      // Perform a backup check to reconcile any nodes whose keys have changed, based on location and parent
      changes?.modifiedNodeData?.forEach((node: MindMapVersionNode) => {
        if (!draft.nodeList.some(n => n.key === node.key)) {
          const matchedNodeIdx = draft.nodeList.
            findIndex(n => n.parent === node.parent && n.loc === node.loc);
          if (matchedNodeIdx >= 0) {
            draft.nodeList.splice(matchedNodeIdx, 1, node);
          }
        }
      });
    });
  }

  save(enableSpinner = true) {
    if (enableSpinner) {
      this.spinnerService.start();
    }
    const mindMapVersionNodes = this.diagramState.nodeList;
    // Archiving mind map
    // archive takes longer time and is done asynchronously
    // and archiving shouldn't fail with the failure of CRUD
    this.mindMapVersionNodeService
        .archive(this.mapsManagerVersionId, mindMapVersionNodes)
        .subscribe((_) => { },
            () => {
              this.bannerNotificationsService.error(
                  'Error archiving Mind Map. Please contact the IT team if you find any errors with the map\'s data.');
            });
    // CRUD mind map nodes
    this.mindMapVersionNodeService
        .updateOrDelete(this.mapsManagerVersionId, mindMapVersionNodes)
        .subscribe(() => {
          if (enableSpinner) {
            this.spinnerService.stop();
          }
          this.myDiagram.diagram.isModified = false;
        }, error => {
          if (enableSpinner) {
            this.spinnerService.stop();
          }
          this.saveError(error);
        });
  }

  searchDiagram() {
    const diagram = this.myDiagram.diagram;
    const input: any = document.getElementById('mind-map-header-search-input');
    if (!input) {
      return;
    }
    diagram.focus();
    diagram.startTransaction('highlight search');
    if (input.value) {
      const safe = input.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(safe, 'i');
      if (input.value !== this.searchTerm) {
        this.curSearchNumSelected = 0;
        diagram.clearHighlighteds();
        this.searchTerm = input.value;
        this.searchResults = diagram.findNodesByExample({text: regex});
      }
      if (this.searchResults.count === 0) {
        return;
      }
      if (this.searchResults.next()) {
        diagram.scale = 1;
        const currNode = this.searchResults.value;
        diagram.centerRect(currNode.actualBounds);
        currNode.isHighlighted = true;
        this.enableClearSearchButton = true;
        this.curSearchNumSelected = (this.curSearchNumSelected % this.searchResults.count) + 1;
        input.focus();
      } else {
        this.searchTerm = null;
        this.searchDiagram();
      }
    } else {
      this.enableClearSearchButton = false;
      this.searchResults = null;
      this.searchTerm = null;
      this.curSearchNumSelected = null;
      diagram.clearHighlighteds();
    }
    diagram.commitTransaction('highlight search');
  }

  cxcommand(event, val?, styleVal?) {
    if (val === undefined) {
      val = event.currentTarget.id;
    }
    const diagram = this.myDiagram.diagram;
    const selectedNode = diagram.selection.first() as go.Node;
    switch (val) {
      case 'new-node':
        this.addNodeAndLink(selectedNode, false);
        break;
      case 'new-end-node':
        this.addNodeAndLink(selectedNode, true);
        break;
      case 'cp-builder':
        this.openDataBindModal(selectedNode);
        break;
      case 'milestone-builder':
        this.openDataBindModal(selectedNode, 'milestone');
        break;
      case 'copy':
        diagram.commandHandler.copySelection();
        break;
      case 'paste':
        this.paste(diagram.toolManager.contextMenuTool.mouseDownPoint);
        break;
      case 'undo':
        diagram.commandHandler.undo();
        break;
      case 'redo':
        diagram.commandHandler.redo();
        break;
      case 'all-layout':
        MindMapDiagramMaker.layoutTree(null, diagram);
        break;
      case 'forward-layout':
        MindMapDiagramMaker.layoutTree(selectedNode as go.Node);
        break;
      case 'reverse-layout':
        this.reverseDir(selectedNode);
        break;
      case 'node-style-stroke':
        this.changeNodeStroke(selectedNode, styleVal);
        break;
      case 'node-style-background':
        this.changeNodeBackground(selectedNode, styleVal);
        break;
      case 'text-style-bigger':
        this.changeTextSize(selectedNode, 1.1);
        break;
      case 'text-style-smaller':
        this.changeTextSize(selectedNode, 1 / 1.1);
        break;
      case 'text-style-bold-or-normal':
        this.toggleTextWeight(selectedNode);
        break;
      case 'text-style-underline':
        this.toggleUnderline(selectedNode);
        break;
      case 'text-style-strikethrough':
        this.toggleStrikeThrough(selectedNode);
        break;
      case 'text-style-stroke':
        this.changeTextStroke(selectedNode, styleVal);
        break;
      case 'node-actions-cut':
        diagram.commandHandler.cutSelection();
        break;
      case 'node-actions-copy':
        diagram.commandHandler.copySelection();
        break;
      case 'node-actions-paste':
        this.paste(diagram.toolManager.contextMenuTool.mouseDownPoint);
        break;
      case 'node-actions-delete':
        diagram.commandHandler.deleteSelection();
        break;
      case 'node-actions-add-image':
        this.addImage(selectedNode);
        break;
    }
    diagram.currentTool.stopTool();
  }

  nodeGroupEdit(event, nodeStyle?, eventVal?) {
    // Base condition - If there is no node selected, do nothing
    if (event == null) {
      return;
    }
    const nodeGroupSelection = this.myDiagram.diagram.selection;
    // If there is no node selected, notify the user
    if (nodeGroupSelection.count === 0) {
      this.bannerNotificationsService.info('Please select one or more node');
      return;
    }
    // If the event is a click event, get the id of the element that was clicked else the event is colorChangeEvent
    const elemId = event.hasOwnProperty('currentTarget') ? event.currentTarget.id : event;

    // If there are multiple nodes selected and a toggleable option was clicked, turn it on or off the same direction for each node
    let shouldActivate;
    switch (elemId) {
      case 'bold-font':
        shouldActivate = nodeGroupSelection.any(n => !n.data.font || !n.data.font.includes('bold'));
        break;
      case 'underline-font':
        shouldActivate = nodeGroupSelection.any(n => !n.data.font || !n.data.isUnderline);
        break;
      case 'strikethrough-font':
        shouldActivate = nodeGroupSelection.any(n => !n.data.font || !n.data.isStrikethrough);
        break;
    }

    // Iterate over the selected nodes and perform the action
    nodeGroupSelection.each(node => {
      if (node instanceof go.Node) {
        switch (elemId) {
          case 'increase-font-size':
            this.changeTextSize(node, 1.1);
            break;
          case 'decrease-font-size':
            this.changeTextSize(node, 1 / 1.1);
            break;
          case 'bold-font':
            this.toggleTextWeight(node, shouldActivate);
            break;
          case 'underline-font':
            this.toggleUnderline(node, shouldActivate);
            break;
          case 'strikethrough-font':
            this.toggleStrikeThrough(node, shouldActivate);
            break;
          case 'width-change':
            this.changeNodeWidth(node, this.numberOnly(eventVal));
            break;
          case 'colorChange':
            // switch on the nodeStyle to determine which style to change
            switch (nodeStyle) {
              case 'stroke':
                this.changeNodeStroke(node, eventVal);
                break;
              case 'background':
                this.changeNodeBackground(node, eventVal);
                break;
              case 'text-stroke':
                this.changeTextStroke(node, eventVal);
                break;
            }
        }
      }
    });
  }

  addNodeAndLink(parentNode: go.Node, isEndNode: boolean) {
    const diagram = this.myDiagram.diagram;
    diagram.startTransaction('Add Node');
    const parentNodeData = parentNode ? parentNode.data : null;
    const newNodeData: any = {
      text: isEndNode ? 'New End Node' : 'New Node',
      stroke: this.STROKE_COLOR,
      width: 100,
      isEndNode: isEndNode,
      loc: `${diagram.toolManager.contextMenuTool.mouseDownPoint.x} ${diagram.toolManager.contextMenuTool.mouseDownPoint.y}`
    };
    if (parentNodeData) {
      newNodeData.brush = parentNodeData.brush;
      newNodeData.dir = parentNodeData.dir;
      newNodeData.parent = parentNodeData.key;
    } else {
      newNodeData.dir = 'left';
    }

    this.myDiagram.diagram.model.addNodeData(newNodeData); // Add the new node to the model

    if (parentNode) { // If the new node is being attached to a parent, auto-layout the tree
      MindMapDiagramMaker.layoutTree(parentNode);
    }

    diagram.commitTransaction('Add Node');

    const newNode = diagram.findNodeForData(newNodeData);
    if (newNode !== null) {
      diagram.scrollToRect(newNode.actualBounds);
    }
  }

  reverseDir(node) {
    const diagram = this.myDiagram.diagram;
    diagram.startTransaction('Reverse Direction');
    const reverseDirection = node.data.dir === 'left' ? 'right' : 'left';
    this.updateNodeDirection(node, reverseDirection);
    MindMapDiagramMaker.layoutTree(node);
    diagram.commitTransaction('Reverse Direction');
  }

  updateNodeDirection(node, dir) {
    this.myDiagram.diagram.model.setDataProperty(node.data, 'dir', dir);
    const chl = node.findTreeChildrenNodes();
    while (chl.next()) {
      this.updateNodeDirection(chl.value, dir);
    }
  }

  changeNodeStroke(node, stroke) {
    this.myDiagram.diagram.startTransaction('Change Node Stroke');
    this.myDiagram.diagram.model.setDataProperty(node.data, 'nodeStroke', stroke);
    this.myDiagram.diagram.commitTransaction('Change Node Stroke');
  }

  changeNodeBackground(node, stroke) {
    this.myDiagram.diagram.startTransaction('Change Node Background');
    this.myDiagram.diagram.model.setDataProperty(node.data, 'nodeBackground', stroke);
    this.myDiagram.diagram.commitTransaction('Change Node Background');
  }

  changeTextSize(node, factor) {
    this.myDiagram.diagram.startTransaction('Change Text Size');
    const tb = node.findObject('TEXT');
    tb.scale *= factor;
    this.myDiagram.diagram.commitTransaction('Change Text Size');
  }

  changeTextStroke(node, color) {
    if (color === 'default') {
      color = node.data.isMilestone ? MindMapDiagramMaker.defaultMilestoneFontColor : MindMapDiagramMaker.defaultNodeFontColor;
    }
    this.myDiagram.diagram.startTransaction('Change Text Color');
    this.myDiagram.diagram.model.setDataProperty(node.data, 'stroke', color);
    this.myDiagram.diagram.commitTransaction('Change Text Color');
  }

  changeNodeWidth(node, width) {
    this.myDiagram.diagram.startTransaction('Change Node Width');
    this.myDiagram.diagram.model.setDataProperty(node.data, 'width', width);
    this.myDiagram.diagram.commitTransaction('Change Node Width');
  }

  toggleTextWeight(node, shouldActivate?: boolean) {
    this.myDiagram.diagram.startTransaction('Change Text Weight');
    const tb = node.findObject('TEXT');
    // assume "bold" is at the start of the font specifier
    const idx = tb.font.indexOf('bold');
    if (shouldActivate === undefined) {
      if (idx < 0) {
        tb.font = 'bold ' + tb.font;
      } else {
        tb.font = tb.font.substr(idx + 5);
      }
    } else {
      if (shouldActivate && idx < 0) {
        tb.font = 'bold ' + tb.font;
      } else if (!shouldActivate) {
        tb.font = tb.font.substr(idx + 5);
      }
    }
    this.myDiagram.diagram.commitTransaction('Change Text Weight');
  }

  toggleUnderline(node, shouldActivate?: boolean) {
    this.myDiagram.diagram.startTransaction('Change Underline');
    const tb = node.findObject('TEXT');
    if (shouldActivate === undefined) {
      tb.isUnderline = !tb.isUnderline;
    } else {
      tb.isUnderline = shouldActivate;
    }
    this.myDiagram.diagram.commitTransaction('Change Underline');
  }

  toggleStrikeThrough(node, shouldActivate?: boolean) {
    this.myDiagram.diagram.startTransaction('Change StrikeThrough');
    const tb = node.findObject('TEXT');
    if (shouldActivate === undefined) {
      tb.isStrikethrough = !tb.isStrikethrough;
    } else {
      tb.isStrikethrough = shouldActivate;
    }
    this.myDiagram.diagram.commitTransaction('Change StrikeThrough');
  }

  togglePreview() {
    this.previewToggleOn = !this.previewToggleOn;
  }

  toggleOpenPanel() {
    this.isOpenPanel = !this.isOpenPanel;
  }

  addImage(adorn) {
    adorn.diagram.startTransaction('Add Image');
    const pc = adorn.findObject('IMAGE');
    this.uploadImage(pc);
    adorn.diagram.commitTransaction('Add Image');
  }

  uploadImage(imageAdornment: any) {
    this.imageAdornment = imageAdornment;
    this.imageUploadModal$ = this.modalService.open(this.imageUploadModal, {size: 'lg'});
  }

  secsToMinSec(s) {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
  }

  numberOnly(val): number {
    return Number(val.replace(/^\D+/g, ''));
  }

  toggleSubMenu(subMenuEle: HTMLElement, openMenu: boolean) {
    if (openMenu) {
      const menuItemEle = subMenuEle.closest('.menu-item');
      subMenuEle.classList.add('show-menu');
      if (menuItemEle.getBoundingClientRect().top + subMenuEle.clientHeight > window.innerHeight) {
        subMenuEle.classList.add('sub-menu-high');
      } else {
        subMenuEle.classList.remove('sub-menu-high');
      }
      if (menuItemEle.getBoundingClientRect().right + subMenuEle.clientWidth > window.innerWidth) {
        subMenuEle.classList.add('sub-menu-left');
      } else {
        subMenuEle.classList.remove('sub-menu-left');
      }
    } else {
      subMenuEle.classList.remove('show-menu');
    }
  }

  paste(pos?: go.Point) {
    const diagram = this.myDiagram.diagram;
    if (!pos) {
      pos = diagram.viewportBounds.center;
    }
    if (diagram.commandHandler.canPasteSelection(pos)) {
      diagram.commandHandler.pasteSelection(pos);
    }
  }

  zoomToFit(animate?: boolean) {
    const diagram = this.myDiagram.diagram;
    if (animate) {
      diagram.commandHandler.zoomToFit();
    } else {
      diagram.zoomToFit();
    }
  }

  downloadSVG() {
    const svg = this.myDiagram.diagram.makeSvg({scale: 1, background: 'white'});
    const svgstr = new XMLSerializer().serializeToString(svg);
    const blob = new Blob([svgstr], {type: 'image/svg+xml'});
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a') as any;
    a.style = 'display: none';
    a.href = url;
    a.download = this.mapsManagerVersionName + '.svg';

    document.body.appendChild(a);
    requestAnimationFrame(() => {
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

  /** Returns the value of the given property for the currently selected node(s) if they are all equal, otherwise returns null */
  getSelectedNodesProp(prop: string, element?: string): any {
    const selection = this.myDiagram.diagram.selection;
    if (selection.count === 0) {
      return;
    }

    let val;
    if (element) {
      val = selection.first().findObject(element)[prop];
    } else {
      val = selection.first().data[prop];
    }
    selection.each((s) => {
      if (element) {
        if (s.findObject(element)[prop] !== val) {
          val = null;
        }
      } else {
        if (s.data[prop] !== val) {
          val = null;
        }
      }
    });
    return val;
  }

  private initImageUploader() {
    this.uploader = this.fileUploadService.uploader;
    this.uploader.options.url = '/api/image/service/upload?category=mindmap';
    this.uploader.options.queueLimit = 1;
    this.uploader.authToken = CONFIG.authPreamble + this.authenticationService.getTokenInfo().accessToken;
    this.uploader.onErrorItem = this.onImageUploaderError.bind(this);
    this.uploader.onCompleteItem = this.onImageUploaderComplete.bind(this);
  }

  private onImageUploaderComplete(item: any, response: any, status: any, headers: any) {
    if (item.isSuccess && status === 200) {
      this.uploader.destroy();
      this.imageUploadModal$.close();
      const imageFileName = JSON.parse(response)['s3Reference'];
      this.imageAdornment.source = `${this.imageBaseUrl}${imageFileName}`;
    } else {
      this.bannerNotificationsService.error(this.translateService.instant('API_ERRORS.SYS_0011'));
    }
  }

  private onImageUploaderError(item, response, status, header) {
    this.bannerNotificationsService.error(JSON.parse(response)['message']);
    this.uploader.clearQueue();
  }

  private loadMindMapVersionNodeData(mapsManagerVersionId: number) {
    return this.mapsManagerVersionService.getById(mapsManagerVersionId, {'mindMapVersionNode': true});
  }

  private loadMapsManagerVersionArchive(mapsManagerVersionId: number) {
    return this.mapsManagerVersionService.getById(mapsManagerVersionId, {'mapsManagerVersionArchive': true});
  }

  private getMapsManagerVersionArchive(mapsManagerVersionArchiveId: number, mapsManagerArchiveList: Array<any>) {
    let finalMindMapData = null;
    mapsManagerArchiveList.forEach(mapsManagerArchive => {
      if (Number(mapsManagerArchive.id) === mapsManagerVersionArchiveId) {
        finalMindMapData = JSON.parse(mapsManagerArchive['mindMapData']);
      }
    });
    return finalMindMapData;
  }

  // TODO: This entire method of company product builder feature needs to be refactored
  // it has become too complex and hard to understand and maintain (bad history)
  private openDataBindModal(adornedPart: go.Node, startingPage?: string) {
    const node: MindMapVersionNode = adornedPart.data;
    if (this.openModalNodeKeys.has(node.key)) {
      return;
    }

    const diagram = adornedPart.diagram;
    let companyProductPairs;
    if (node.productCompanyInstances && node.productCompanyInstances.length > 0) {
      companyProductPairs = [];
      for (const productCompanyInstance of node.productCompanyInstances.sort((i1, i2) => i1.id - i2.id)) {
        companyProductPairs.push({
          companies: [...productCompanyInstance.companies],
          products: [...productCompanyInstance.products]
        });
      }
    }
    const modalRef = this.modalService.open(DataBindingModalComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static',
      windowClass: 'draggable-modal'
    });
    modalRef.componentInstance.companyProductPairs = companyProductPairs;
    modalRef.componentInstance.companyProductLabel = node.text;
    modalRef.componentInstance.productCategoryValuesMap = this.productCategoryValuesMap;
    modalRef.componentInstance.productCategoryValues = node.productCategoryValues;
    modalRef.componentInstance.milestones = this.parseMilestone(node);
    modalRef.componentInstance.finalized = this.finalized;
    if (startingPage) {
      modalRef.componentInstance.activePage = startingPage;
    }
    this.openModalNodeKeys.add(node.key);
    modalRef.result.then((resp: any) => {
      this.openModalNodeKeys.delete(node.key);
      if (resp) {
        this.updateDiagramState({ skipsDiagramUpdate: true });
        diagram.model.startTransaction('Data Binding');
        if (node.id) {
          this.bindData(adornedPart, resp);
        } else {
          adornedPart.data.mapsManagerVersionID = this.mapsManagerVersionId;
          this.mindMapVersionNodeService.updateSingleNode(node).subscribe((newNode: MindMapVersionNode) => {
            if (newNode) {
              diagram.model.setDataProperty(adornedPart.data, 'id', newNode.id);
              diagram.model.setDataProperty(adornedPart.data, 'key', newNode.key);
              this.bindData(adornedPart, resp);
            }
          }, () => {
            this.bannerNotificationsService.error('Error saving node. Please try again after saving map.');
          });
        }
      }
    }).catch((modalError) => {
      console.error(modalError);
      this.openModalNodeKeys.delete(node.key);
    });
  }

  private bindData(adornedPart: any, modalResp: any) {
    const node = adornedPart.data;
    const diagram = adornedPart.diagram;
    this.bindProductCategoryValues(adornedPart, modalResp);
    this.mindMapVersionNodeService
      .bindCompanyProduct(modalResp.companyProductPairs, node, modalResp.dataLabel)
      .subscribe((nodeData: MindMapVersionNode) => {
        if (nodeData) {
          adornedPart.findObject('TEXT').text = nodeData.text;
          diagram.model.setDataProperty(node, 'productCompanyInstances', nodeData.productCompanyInstances);
          diagram.model.setDataProperty(node, 'productCategoryValues', nodeData.productCategoryValues);
          this.bindMilestone(adornedPart, modalResp);
        }
      }, (error) => this.saveError(error));
  }

  private bindMilestone(adornedPart: any, modalResp: any) {
    const node = adornedPart.data;
    const diagram = adornedPart.diagram;

    this.mindMapVersionNodeService.bindMilestone(modalResp.milestones, modalResp.milestoneLabel, node)
      .subscribe((nodeData: MindMapVersionNode) => {
        if (nodeData) {
          diagram.model.setDataProperty(node, 'milestones', nodeData.milestones);
          diagram.model.setDataProperty(node, 'milestoneLabel', nodeData.milestoneLabel);
          const milestonePart = this.getMilestoneNode(adornedPart);
          if (milestonePart != null) {
            // If there is already a milestone node for this endnode, modify it
            const textObject = milestonePart.findObject('TEXT');
            if (textObject.text !== modalResp.milestoneLabel) {
              textObject.stroke = this.STROKE_COLOR;
            }
            textObject.text = modalResp.milestoneLabel;
            diagram.model.setDataProperty(node, 'milestones', nodeData.milestones);
          } else {
            // If there is not yet a milestone node, create one
            const newData = {
              text: modalResp.milestoneLabel, brush: node.brush, dir: node.dir, parent: node.key,
              width: node.width, isMilestone: true, isEndNode: false, stroke: this.STROKE_COLOR
            };
            diagram.model.addNodeData(newData);
            this.layoutSubscription$.next(adornedPart);
          }
        }
        adornedPart.findObject('TEXT').stroke = this.STROKE_COLOR;
        this.updateDiagramState({ skipsDiagramUpdate: false });
        diagram.commitTransaction('Data Binding');
      }, (error) => {
        const errorVal = error.error ? error.error.message.substring(error.error.message.lastIndexOf(':') + 2) : null;
        this.saveError(error, errorVal);
      });
  }

  private bindProductCategoryValues(adornedPart: any, modalResp: any) {
    // if the modalResp is not null and the productCategoryValues is not null, then set the productCategoryValues
    if (modalResp != null && modalResp.productCategoryValues != null) {
      const node = adornedPart.data;
      const diagram = adornedPart.diagram;
      diagram.model.setDataProperty(node, 'productCategoryValues', modalResp.productCategoryValues);
    }
  }

  private getMilestoneNode(adornPart: any) {
    const childNodes = adornPart.findTreeChildrenNodes();
    let milestonePart;
    while (childNodes.next()) {
      const currNode = childNodes.value;
      if (currNode && currNode.data['isMilestone']) {
        milestonePart = currNode;
      }
    }
    return milestonePart;
  }

  private addTabCloseDialog() {
    const that = this;
    window.onbeforeunload = function (e) {
      if (e && that.checkSave) {
        e.returnValue = '';
      }
    };
  }

  private removeTabCloseDialog() {
    window.onbeforeunload = () => {
    };
  }

  private parseMilestone(node: MindMapVersionNode) {
    let milestones: Array<any>;
    if (node.milestones && node.milestones.length > 0) {
      milestones = [];
      for (const milestone of node.milestones.sort((m1, m2) => m1.id - m2.id)) {
        // This is a hacky way to filter only eMaps milestones
        // Not changing the backend because we need the non-emaps milestones for future use
        if (milestone.source !== 'eMaps') {
          continue;
        }
        const dateObj = milestone.milestoneCategoryValues.find(m => m.milestoneCategory === MILESTONE_CATEGORY.Date);
        const eventObj = milestone.milestoneCategoryValues.find(m => m.milestoneCategory === MILESTONE_CATEGORY.Event);
        const trialObj = milestone.milestoneCategoryValues.find(m => m.milestoneCategory === MILESTONE_CATEGORY.TrialType);
        const phaseObj = milestone.milestoneCategoryValues.find(m => m.milestoneCategory === MILESTONE_CATEGORY.Phase);
        const noteObj = milestone.milestoneCategoryValues.find(m => m.milestoneCategory === MILESTONE_CATEGORY.Note);
        const values = {
          date: dateObj && dateObj.name || null,
          event: eventObj && eventObj.name || null,
          trial: trialObj && trialObj.name || null,
          indications: milestone.synonyms.filter(s => s.category === 'Indication').map(s => s.name),
          phase: phaseObj && phaseObj.name || null,
          note: noteObj && noteObj.name || null
        };
        milestones.push(values);
      }
    }
    return milestones;
  }

  private loadProductCategoryValues(productCategoryNames?: Array<string>) {
    this.productCategoryValuesMap = new Map<string, Array<ProductCategoryValue>>();

    // If nameList is not provided, get all product category values
    // Otherwise, get product category values with the provided names
    const httpParams = productCategoryNames ? this.eMapUtilsService.generateParamsWithArray(productCategoryNames, 'productCategoryNames') : null;
    // Get product category values
    this.productCategoryValueService
      .getAllCategoryValue(httpParams)
      .subscribe((data: Map<string, Array<ProductCategoryValue>>) => {
        this.productCategoryValuesMap = this.sortProductCategoryValues(data);
      }, _ => {
        const message = 'Failed to load product category values. Problem with associating Product Data in CP Binder';
        this.bannerNotificationsService.error(message);
      });
  }

  private sortProductCategoryValues(data: Map<string, Array<ProductCategoryValue>>): Map<string, Array<ProductCategoryValue>> {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        data[key] = data[key].sort((a, b) => a.name.localeCompare(b.name));
      }
    }
    return data;
  }

  private loadMindMap() {
    let loadArchive = false;
    this.route.params.subscribe(params => {
      const queryParams = this.route.snapshot.queryParams;
      if (queryParams && queryParams.hasOwnProperty('mapsManagerVersionArchiveId')) {
        this.mapsManagerVersionArchiveId = Number(queryParams.mapsManagerVersionArchiveId);
        loadArchive = true;
      }
      this.mapsManagerVersionId = params.id;
      this.spinnerService.start();
      (!loadArchive ?
          this.loadMindMapVersionNodeData(this.mapsManagerVersionId) :
          this.loadMapsManagerVersionArchive(this.mapsManagerVersionId)).subscribe((data) => {
        this.spinnerService.stop();
        this.mapsManagerVersionName = data['name'];
        this.finalized = loadArchive ? true : data['finalized'];
        const nodeList: Array<MindMapVersionNode> = !loadArchive ?
            data['mindMapVersionNodes'].map(m => new MindMapVersionNode(m)) :
            this.getMapsManagerVersionArchive(this.mapsManagerVersionArchiveId, data['mapsManagerVersionArchives']);
        nodeList.forEach(n => {
          if (n['image']) {
            n['image'] = `${this.imageBaseUrl}${n['image']}`;
          }
        });
        this.myDiagram.diagram.model.addNodeDataCollection(nodeList);
        // Manually call the model change event because adding the nodes doesn't trigger it for some reason
        this.myDiagram.modelChange.emit({
          modifiedNodeData: this.myDiagram.diagram.model.nodeDataArray,
          insertedNodeKeys: this.myDiagram.diagram.model.nodeDataArray.map(n => n.key)
        });
        // Wrap these in setTimeout so that they are called after the diagram is updated with the new data
        setTimeout(() => {
          this.myDiagram.diagram.isModified = false;
          this.zoomToFit();
          this.updateDiagramState({ skipsDiagramUpdate: false });
          this.myDiagram.diagram.undoManager.isEnabled = true;  // Re-enable the undo manager
        }, 1);
      }, () => {
        this.spinnerService.stop();
        this.bannerNotificationsService.error('MindMap Data Not Available');
      });
    });
  }

  private showRichContentEditor(obj: go.GraphObject) {
    const node = obj.part;
    const selectedNodeWidth = node.actualBounds.width;
    const selectedNodeHeight = node.actualBounds.height;
    const modalRef = this.modalService.open(RichContentModalComponent,
        {centered: true, size: 'lg', windowClass: 'rich-content-modal'});
    if (node) {
      if (node.data && !node.data.isRichText) {
        let finalText = UtilsService.replaceUnicodeToHtml(node.data.text);
        finalText = `<p><span style="font-family: 'sans-serif', sans-serif;font-size: 13px;">` + finalText + `</span></p>`;
        modalRef.componentInstance.editorContent = finalText;
      } else {
        modalRef.componentInstance.editorContent = node.data.html;
      }
    }
    modalRef.componentInstance.saveSubscription$.subscribe((html) => {
      if (node) {
        node.diagram.startTransaction('Change Rich Content');
        if (!node.data.width || node.data.width < 10) {
          node.data.width = selectedNodeWidth;
        }
        if (!node.data.height) {
          node.diagram.model.setDataProperty(node.data, 'height', selectedNodeHeight);
        }

        node.diagram.model.setDataProperty(node.data, 'isRichText', true);
        node.diagram.model.setDataProperty(node.data, 'html', html);
        node.diagram.model.setDataProperty(node.data, 'text', this.getHtmlText(html));
        node.diagram.commitTransaction('Change Rich Content');
      }
    });
  }

  private getHtmlText(htmlStrVal: string) {
    const span = document.createElement('span');
    span.innerHTML = htmlStrVal;
    return span.textContent || span.innerText;
  }

  private saveError(error: any, ...args: Array<any>) {
    Swal.fire({
      title: 'Error Saving Map',
      html: `<div style="white-space: pre-wrap">${this.getSaveErrorMessage(error, args)}</div>`,
      footer: `<div class="px-3">${error.name} ${error.status}: ${error.error ? error.error.message : error.message}</div>`,
      buttonsStyling: false,
      showConfirmButton: true,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-secondary',
      },
    });
  }

  private getSaveErrorMessage(error, ...args: Array<any>): string {
    let message = `Something went wrong when saving the map.
    WARNING: If you continue working or refresh the browser, you may lose progress.
    Please try to save the map again manually. If the error persists, contact the eMaps team at <a href="mailto:${CONFIG.contactEmail}">${CONFIG.contactEmail}</a>.`;

    if (!error.error) {
      return message;
    }

    switch (error.error.code) {
      case CONFIG.errors.milestoneCategoryValueMissingErrorCode:
        message = `The milestone value ${args[0]} is invalid.
        Please try again, and if this error persists contact the eMaps team at <a href="mailto:${CONFIG.contactEmail}">${CONFIG.contactEmail}</a>.`;
        break;
    }

    return message;
  }
}

interface DiagramState {
  nodeList?: Array<MindMapVersionNode>;
  skipsDiagramUpdate?: boolean;
}
