<div class="toolbox-parent">
  <div id="toolbox-menu" class="toolbox-button toolbox-button-linked toolbox-button-menu"></div>
  <div id="toolbox-search" class="toolbox-button toolbox-button-linked toolbox-button-search"></div>
  <div id="toolbox-chart" class="toolbox-button toolbox-button-linked toolbox-button-chart"></div>
  <div id="toolbox-legend" class="toolbox-button toolbox-button-linked toolbox-button-legend"></div>
  <div id="toolbox-navigator" class="toolbox-button toolbox-button-navigator"></div>
</div>
<div class="toolbox-swap">
    <div class="toolbox-button"></div>
</div>
