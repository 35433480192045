import { Injectable } from '@angular/core';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { CONFIG } from '../../config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CategoryValueResource } from '../../shared/models/resources/categoryValueResource.model';
import { catchError } from 'rxjs/operators';


@Injectable()
export class CategorizationService extends AbstractCRUD<CategoryValueResource> implements CRUDService<CategoryValueResource>, Searcher {

  private metadataPath = CONFIG.metadataPath;

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getDisplayName(item: any): string {
    return item.name;
  }

  public getResourcePath() {
    return '/node/service/categorization';
  }

  public getCategorizationPath(mapVersionId: string) {
    return super.getApiPath().concat('/').concat(mapVersionId);
  }

  public getCategorization(mapVersionId: string, httpParams: HttpParams) {
    return this.getHTTP().get(this.getCategorizationPath(mapVersionId), { params: httpParams })
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public putCategorization(mapVersionId: string, body: object) {
    return this.putForm(this.getCategorizationPath(mapVersionId), body);
  }

  public putForm(apiUrl: string, body: object) {
    return this.getHTTP().put(apiUrl, body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }
}
