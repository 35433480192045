import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { ToolboxAllPanelServices } from '../../services/toolboxAllPanel.services';
import { MapService } from '../../../core/data-services/map.service';
import {DesignIntegrationService} from '../../services/designIntegration.service';
import {SimpleMapResource} from '../../models/resources/simpleMapResource.model';

@Component({
  selector: 'emap-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss'],
  providers: [JqueryCommonServices],
  encapsulation: ViewEncapsulation.None
})
export class MenuPanelComponent implements OnInit {
  private MENU_PANEL_RESULTS_LIST_CLASS = 'menu-panel-results-list';
  private MENU_PANEL_MAP_NAME_CLASS = 'menu-panel-map-name';
  private MENU_PANEL_RESULTS_ARROW_CLASS = 'menu-panel-results-arrow';
  private MENU_PANEL_RESULTS_ARROW_UP_CLASS = 'menu-panel-results-arrow-up';
  private MENU_PANEL_RESULTS_ARROW_DOWN_CLASS = 'menu-panel-results-arrow-down';
  private MENU_PANEL_RESULTS_ARROW_VISIBILITY_COLLAPSE_CLASS = 'menu-panel-results-arrow-visibility-collapse';
  private MAP_ID_ACCESSOR = 'mapId';

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private toolboxAllPanelServices: ToolboxAllPanelServices,
    private mapService: MapService,
    private designIntegrationServices: DesignIntegrationService,
  ) { }

  ngOnInit() {
    this.getAllMaps();
    this.menuPanelArrowEventHandler();
  }

  private getAllMaps() {
    this.mapService.getAllMaps().subscribe((value: SimpleMapResource[]) => {
      this.designIntegrationServices.activeMapList = value;
      this.updateMenuEventHandler();
    });
  }

  private updateMenuEventHandler() {
    this.designIntegrationServices.activeMapList.forEach((mapResource: SimpleMapResource) => {
      this.jqueryCommonServices.selector(this.MENU_PANEL_RESULTS_LIST_CLASS.toClass()).append(
        this.jqueryCommonServices.divConstruct(mapResource.name).addClass(this.MENU_PANEL_MAP_NAME_CLASS)
          .data(this.MAP_ID_ACCESSOR, mapResource.id)
      );
    });
    this.mapNameClickEventHandler();
  }

  private mapNameClickEventHandler() {
    this.jqueryCommonServices.clickEvent(this.MENU_PANEL_MAP_NAME_CLASS.toClass(), (event) => {
      const mapId = this.jqueryCommonServices.selector(event.target).data(this.MAP_ID_ACCESSOR);
      this.toolboxAllPanelServices.mapIdSelectionEventEmitter.next({'mi': mapId});
    });
  }

  private menuPanelArrowEventHandler() {
    this.jqueryCommonServices.clickEvent(this.MENU_PANEL_RESULTS_ARROW_CLASS.toClass(), (event) => {
      const isUpArrow =  this.jqueryCommonServices.selector(event.target).hasClass(this.MENU_PANEL_RESULTS_ARROW_UP_CLASS);
      const menuPanelResultsListSelector = this.jqueryCommonServices.selector(this.MENU_PANEL_RESULTS_LIST_CLASS.toClass());
      const menuListHeight = menuPanelResultsListSelector.height();
      const currentScrollTop = menuPanelResultsListSelector.scrollTop();
      this.toggleArrowVisibilityState(isUpArrow);
      this.jqueryCommonServices.animate(
        this.MENU_PANEL_RESULTS_LIST_CLASS.toClass(),
        {scrollTop: !isUpArrow ? (currentScrollTop + menuListHeight) : (currentScrollTop - menuListHeight)},
                      this.checkScrollBoundaryEventHandler.bind(this)
      );
    });
  }

  private toggleArrowVisibilityState(isUpArrow: boolean) {
    if (isUpArrow) {
      this.jqueryCommonServices.selector(this.MENU_PANEL_RESULTS_ARROW_DOWN_CLASS.toClass())
        .removeClass(this.MENU_PANEL_RESULTS_ARROW_VISIBILITY_COLLAPSE_CLASS);
    }else {
      this.jqueryCommonServices.selector(this.MENU_PANEL_RESULTS_ARROW_UP_CLASS.toClass())
        .removeClass(this.MENU_PANEL_RESULTS_ARROW_VISIBILITY_COLLAPSE_CLASS);
    }
  }

  private checkScrollBoundaryEventHandler() {
    const menuPanelResultsListSelector = this.jqueryCommonServices.selector(this.MENU_PANEL_RESULTS_LIST_CLASS.toClass());
    const maxScrollHeight = menuPanelResultsListSelector.prop('scrollHeight') - menuPanelResultsListSelector.innerHeight();
    const currentScrollTop = menuPanelResultsListSelector.scrollTop();
    if (currentScrollTop === 0) {
      this.jqueryCommonServices.selector(this.MENU_PANEL_RESULTS_ARROW_UP_CLASS.toClass())
        .addClass(this.MENU_PANEL_RESULTS_ARROW_VISIBILITY_COLLAPSE_CLASS);
    } else if (currentScrollTop === maxScrollHeight) {
      this.jqueryCommonServices.selector(this.MENU_PANEL_RESULTS_ARROW_DOWN_CLASS.toClass())
        .addClass(this.MENU_PANEL_RESULTS_ARROW_VISIBILITY_COLLAPSE_CLASS);
    }
  }
}
