import { BaseEntity } from '../../xform-compat';
import { BasicUser } from './basicUser.model';
import { MapsManagerVersion } from './mapsManagerVersion.model';


export class MapsManager implements BaseEntity {
  id: number;
  name: string;
  mapsManagerVersions: Array<MapsManagerVersion>;
  mapsManagerVersionCount: number;
  mapsManagerVersionArchiveCount: number;
  updatedBy: BasicUser;
  created: string;
  updated: string;
  active: boolean;

  constructor(mapsManager: MapsManager = {} as MapsManager) {
    this.id = mapsManager.id || null;
    this.name = mapsManager.name || null;
    this.mapsManagerVersions = mapsManager.mapsManagerVersions || null;
    this.mapsManagerVersionCount = mapsManager.mapsManagerVersionCount || 0;
    this.mapsManagerVersionArchiveCount = mapsManager.mapsManagerVersionArchiveCount || 0;
    this.updatedBy = mapsManager.updatedBy || null;
    this.created = mapsManager.created || null;
    this.updated = mapsManager.updated || null;
    this.active = mapsManager.active || null;
  }
}
