import { Injectable } from '@angular/core';
import { ExceptionService } from '../../xform-compat';
import { CONFIG } from '../../config';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FinalizationService {

    private apiPath = CONFIG.apiUrl;

    constructor(
        private exceptionService: ExceptionService,
        private http: HttpClient,
    ) { }

    public getDisplayName(item: any): string {
        return item.name;
    }

    public getResourcePath() {
        return '/core/service';
    }

    public getFinalizationPath(mapVersionId: string) {
        return this.apiPath.concat(this.getResourcePath()).concat('/finalize/').concat(mapVersionId);
    }

    public getFinalization(mapVersionId: string) {
        return this.http.get(this.getFinalizationPath(mapVersionId))
          .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
    }
}
