import { BaseEntity } from '../../xform-compat';
import { BasicUser } from './basicUser.model';
import { NodeProduct } from './nodeProduct.model';
import { RmsProductResource } from './resources/rms/rmsProductResource.model';
import {ProductCategoryValue} from "./productCategoryValue.model";


export class Product implements BaseEntity {
  id: number;
  name: string;
  productCategoryValues: Array<ProductCategoryValue>;
  rmsProductId: number;
  nodeProduct: Array<NodeProduct>;
  createdBy: BasicUser;
  created: string;
  updated: string;
  active: boolean;
  searchResults: RmsProductResource[];
  companies: Array<string>;
  rmsSynonym: number;
  rmsRename: number;
  rmsExternalName: string;
  isIgnored: boolean;
  edmGUID: string;
  companyEdmGUIDs: Array<string>;

  constructor(product: Product = {} as Product) {
    this.id = product.id || null;
    this.name = product.name || null;
    this.productCategoryValues = product.productCategoryValues || null;
    this.rmsProductId = product.rmsProductId || null;
    this.nodeProduct = product.nodeProduct || null;
    this.createdBy = product.createdBy || null;
    this.created = product.created || null;
    this.updated = product.updated || null;
    this.active = product.active || null;
    this.searchResults = product.searchResults || null;
    this.companies = product.companies || null;
    this.rmsSynonym = product.rmsSynonym || null;
    this.rmsRename = product.rmsRename || null;
    this.rmsExternalName = product.rmsExternalName || '';
    this.isIgnored = product.isIgnored || null;
    this.edmGUID = product.edmGUID || null;
    this.companyEdmGUIDs = product.companyEdmGUIDs || null;
    this.productCategoryValues = product.productCategoryValues || null;
  }
}
