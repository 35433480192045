import { Component, OnDestroy, OnInit } from '@angular/core';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { AuthoringIntegrationService } from '../../services/authoringIntegration.service';
import { DestroyerAbstract } from '../../../core/components/destroyer.abstract';

@Component({
    selector: 'emap-authoring-toolbox',
    templateUrl: './authoring-toolbox.component.html',
    styleUrls: ['./authoring-toolbox.component.scss'],
    providers: [JqueryCommonServices]
})
export class AuthoringToolboxComponent extends DestroyerAbstract implements OnInit, OnDestroy {

    private AUTHORING_TOOLBOX_PARENT_CLASS = 'authoring-toolbox-parent';
    private AUTHORING_TOOLBOX_PARENT_HIDDEN_CLASS = 'authoring-toolbox-parent-hidden';

    private AUTHORING_TOOLBOX_BUTTON_CLASS = 'authoring-toolbox-button';
    private AUTHORING_TOOLBOX_BUTTON_ACTIVE_CLASS = 'authoring-toolbox-button-active';
    private AUTHORING_TOOLBOX_BUTTON_DISABLED_CLASS = 'authoring-toolbox-button-disabled';

    private AUTHORING_TOOLBOX_LEFT_ALIGN_BUTTON_CLASS = 'authoring-toolbox-left-align';
    private AUTHORING_TOOLBOX_RIGHT_ALIGN_BUTTON_CLASS = 'authoring-toolbox-right-align';
    private AUTHORING_TOOLBOX_RESIZE_BOX_BUTTON_CLASS = 'authoring-toolbox-box-resize';
    private AUTHORING_TOOLBOX_RESIZE_WIDTH_BUTTON_CLASS = 'authoring-toolbox-box-resize-width';
    private AUTHORING_TOOLBOX_RESIZE_HEIGHT_BUTTON_CLASS = 'authoring-toolbox-box-resize-height';
    private AUTHORING_TOOLBOX_HIERARCHY_BUTTON_CLASS = 'authoring-toolbox-hierarchy';
    private AUTHORING_TOOLBOX_DELETE_BUTTON_CLASS = 'authoring-toolbox-delete';
    private AUTHORING_TOOLBOX_EDIT_BUTTON_CLASS = 'authoring-toolbox-draw-mode-edit';

    private AUTHORING_TOOLBOX_ICON_ID_PREFIX = 'authoring-toolbox-';

    private eventHandlersToGC: Array<string> = [];

    constructor(
        private jqueryCommonServices: JqueryCommonServices,
        private authoringIntegrationService: AuthoringIntegrationService,
    ) {
        super();
    }

    ngOnInit() {
        this.initAuthoringToolboxIconClickHandler();
        this.initAuthoringToolboxVisibilitySubscription();
        this.initNodeLayerSelectionEventHandler();
        this.loadAuthoringToolboxState();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        for (const handlerSelector of this.eventHandlersToGC) {
            // TODO: only disable the handler we created
            this.jqueryCommonServices.selector(handlerSelector).off();
        }
    }

    loadAuthoringToolboxState() {
        this.setVisible(this.authoringIntegrationService.authoringToolboxModel.isVisible);
        this.setToolboxHierarchyButtonActive(this.authoringIntegrationService.authoringToolboxModel.isHierarchyModeActive);
        this.handleNodeLayerSelectionChange();
    }

    private initAuthoringToolboxIconClickHandler() {
        this.eventHandlersToGC.push(this.AUTHORING_TOOLBOX_BUTTON_CLASS.toClass());
        this.jqueryCommonServices.clickEvent(this.AUTHORING_TOOLBOX_BUTTON_CLASS.toClass(), (event) => {
            if (!event.hasOwnProperty('target')) { return; }
            const elementId = (event as any).target.id;
            const elementRouteName = elementId.replace(this.AUTHORING_TOOLBOX_ICON_ID_PREFIX, '');
            if (!this.isButtonEnabled(elementId.toId())) { return; }
            switch (elementRouteName) {
                case 'hierarchy':
                    this.setToolboxHierarchyButtonActive(!this.authoringIntegrationService.authoringToolboxModel.isHierarchyModeActive);
                    break;
                default:
                    break;
            }
            this.authoringIntegrationService.authoringToolboxClickEventHandler.next(event);
        });
    }

    initAuthoringToolboxVisibilitySubscription() {
        this.customSubscribe(
            this.authoringIntegrationService.authoringToolboxVisibilityEventHandler,
            () => this.setVisible(this.authoringIntegrationService.authoringToolboxModel.isVisible)
        );
    }

    initNodeLayerSelectionEventHandler() {
        this.customSubscribe(
            this.authoringIntegrationService.nodeLayerSelectionEventHandler,
            () => this.handleNodeLayerSelectionChange()
        );
    }

    handleNodeLayerSelectionChange() {
        this.setLeftAlignButtonEnabled(false);
        this.setRightAlignButtonEnabled(false);
        this.setResizeBoxButtonEnabled(false);
        this.setResizeHeightButtonEnabled(false);
        this.setResizeWidthButtonEnabled(false);
        this.setDeleteButtonEnabled(false);
        this.setEditButtonEnabled(false);

        const primary = this.authoringIntegrationService.nodeLayerModel.primarySelectionId;
        const secondary = this.authoringIntegrationService.nodeLayerModel.secondarySelectionIds;

        if (primary) {
            if (!secondary.size) {
                this.setDeleteButtonEnabled(true);
                this.setEditButtonEnabled(true);
            } else {
                this.setLeftAlignButtonEnabled(true);
                this.setRightAlignButtonEnabled(true);
                this.setResizeBoxButtonEnabled(true);
                this.setResizeHeightButtonEnabled(true);
                this.setResizeWidthButtonEnabled(true);
            }
        }
    }

    setVisible(state: boolean) {
        const toolboxParentSelector = this.jqueryCommonServices.selector(this.AUTHORING_TOOLBOX_PARENT_CLASS.toClass());
        toolboxParentSelector.toggleClass(this.AUTHORING_TOOLBOX_PARENT_HIDDEN_CLASS, !state);
        this.setToolboxHierarchyButtonActive(state);
    }

    setToolboxHierarchyButtonActive(state: boolean) {
        this.authoringIntegrationService.authoringToolboxModel.isHierarchyModeActive = state;
        const hierarchyButtonSelector = this.jqueryCommonServices.selector(this.AUTHORING_TOOLBOX_HIERARCHY_BUTTON_CLASS.toId());
        hierarchyButtonSelector.toggleClass(this.AUTHORING_TOOLBOX_BUTTON_ACTIVE_CLASS, state);
    }

    setDeleteButtonEnabled(state: boolean) {
        this.setButtonEnabled(state, this.AUTHORING_TOOLBOX_DELETE_BUTTON_CLASS.toId());
    }

    setEditButtonEnabled(state: boolean) {
        this.setButtonEnabled(state, this.AUTHORING_TOOLBOX_EDIT_BUTTON_CLASS.toId());
    }

    setLeftAlignButtonEnabled(state: boolean) {
        this.setButtonEnabled(state, this.AUTHORING_TOOLBOX_LEFT_ALIGN_BUTTON_CLASS.toId());
    }

    setRightAlignButtonEnabled(state: boolean) {
        this.setButtonEnabled(state, this.AUTHORING_TOOLBOX_RIGHT_ALIGN_BUTTON_CLASS.toId());
    }

    setResizeBoxButtonEnabled(state: boolean) {
        this.setButtonEnabled(state, this.AUTHORING_TOOLBOX_RESIZE_BOX_BUTTON_CLASS.toId());
    }

    setResizeHeightButtonEnabled(state: boolean) {
        this.setButtonEnabled(state, this.AUTHORING_TOOLBOX_RESIZE_HEIGHT_BUTTON_CLASS.toId());
    }

    setResizeWidthButtonEnabled(state: boolean) {
        this.setButtonEnabled(state, this.AUTHORING_TOOLBOX_RESIZE_WIDTH_BUTTON_CLASS.toId());
    }

    isButtonEnabled(elemId: string) {
        const buttonSelector = this.jqueryCommonServices.selector(elemId);
        return !buttonSelector.hasClass(this.AUTHORING_TOOLBOX_BUTTON_DISABLED_CLASS);
    }

    setButtonEnabled(state: boolean, elemId: string) {
        const buttonSelector = this.jqueryCommonServices.selector(elemId);
        buttonSelector.toggleClass(this.AUTHORING_TOOLBOX_BUTTON_DISABLED_CLASS, !state);
    }
}
