import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthoringHeaderModel } from '../models/authoringHeader.model';
import { AuthoringToolboxModel } from '../models/authoringToolbox.model';
import { NodeLayerModel } from '../models/nodeLayer.model';


@Injectable()
export class AuthoringIntegrationService {

  private _authoringHeaderModel = new AuthoringHeaderModel();
  private _authoringToolboxModel = new AuthoringToolboxModel();
  private _nodeLayerModel = new NodeLayerModel();

  private _authoringToolboxVisibilityEventHandler: Subject<object> = new Subject<object>();
  private _authoringToolboxClickEventHandler: Subject<object> = new Subject<object>();

  private _authoringHeaderIconClickEventHandler: Subject<object> = new Subject<object>();
  private _authoringSaveIconClickEventHandler: Subject<object> = new Subject<object>();
  private _authoringHierarchyIconClickEventHandler: Subject<object> = new Subject<object>();
  private _authoringEditIconClickEventHandler: Subject<object> = new Subject<object>();
  private _authoringLegendIconClickEventHandler: Subject<object> = new Subject<object>();
  private _authoringChartIconClickEventHandler: Subject<object> = new Subject<object>();
  private _nodeLayerSelectionEventHandler: Subject<object> = new Subject<object>();
  private _nodeLayerUnsavedChangesEventHandler: Subject<object> = new Subject<object>();
  private _nodeLayerLoadEventEmitter: Subject<object> = new Subject<object>();
  private _nodeLayerToggleIncludeNodeEventEmitter: Subject<object> = new Subject<object>();

  private _authoringZoomScrollIconClickEventHandler: Subject<object> = new Subject<object>();
  private _authoringZoomFullIconClickEventHandler: Subject<object> = new Subject<object>();
  private _authoringZoom100IconClickEventHandler: Subject<object> = new Subject<object>();

  get authoringHeaderModel(): AuthoringHeaderModel {
    return this._authoringHeaderModel;
  }

  get authoringToolboxModel(): AuthoringToolboxModel {
    return this._authoringToolboxModel;
  }

  get nodeLayerModel(): NodeLayerModel {
    return this._nodeLayerModel;
  }

  get authoringToolboxVisibilityEventHandler(): Subject<object> {
      return this._authoringToolboxVisibilityEventHandler;
  }

  get authoringToolboxClickEventHandler(): Subject<object> {
    return this._authoringToolboxClickEventHandler;
  }

  get authoringHeaderIconClickEventHandler(): Subject<object> {
    return this._authoringHeaderIconClickEventHandler;
  }

  get authoringSaveIconClickEventHandler(): Subject<object> {
    return this._authoringSaveIconClickEventHandler;
  }

  get authoringHierarchyIconClickEventHandler(): Subject<object> {
    return this._authoringHierarchyIconClickEventHandler;
  }

  get authoringEditIconClickEventHandler(): Subject<object> {
    return this._authoringEditIconClickEventHandler;
  }

  get authoringLegendIconClickEventHandler(): Subject<object> {
    return this._authoringLegendIconClickEventHandler;
  }

  get authoringChartIconClickEventHandler(): Subject<object> {
    return this._authoringChartIconClickEventHandler;
  }

  get nodeLayerSelectionEventHandler(): Subject<object> {
    return this._nodeLayerSelectionEventHandler;
  }

  get authoringZoomScrollIconClickEventHandler(): Subject<object> {
    return this._authoringZoomScrollIconClickEventHandler;
  }

  set authoringZoomScrollIconClickEventHandler(value: Subject<object>) {
    this._authoringZoomScrollIconClickEventHandler = value;
  }

  get authoringZoomFullIconClickEventHandler(): Subject<object> {
    return this._authoringZoomFullIconClickEventHandler;
  }

  set authoringZoomFullIconClickEventHandler(value: Subject<object>) {
    this._authoringZoomFullIconClickEventHandler = value;
  }

  get authoringZoom100IconClickEventHandler(): Subject<object> {
    return this._authoringZoom100IconClickEventHandler;
  }

  set authoringZoom100IconClickEventHandler(value: Subject<object>) {
    this._authoringZoom100IconClickEventHandler = value;
  }

  get nodeLayerUnsavedChangesEventHandler(): Subject<object> {
    return this._nodeLayerUnsavedChangesEventHandler;
  }

  set nodeLayerUnsavedChangesEventHandler(value: Subject<object>) {
    this._nodeLayerUnsavedChangesEventHandler = value;
  }

  get nodeLayerLoadEventEmitter(): Subject<object> {
    return this._nodeLayerLoadEventEmitter;
  }

  set nodeLayerLoadEventEmitter(value: Subject<object>) {
    this._nodeLayerLoadEventEmitter = value;
  }

  get nodeLayerToggleIncludeNodeEventEmitter(): Subject<object> {
    return this._nodeLayerToggleIncludeNodeEventEmitter;
  }

  set nodeLayerToggleIncludeNodeEventEmitter(value: Subject<object>) {
    this._nodeLayerToggleIncludeNodeEventEmitter = value;
  }
}
