import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { FuzzySearchServices } from '../../services/fuzzySearch.services';
import { SearchFilterKeyboardServices } from '../../services/searchFilterKeyboard.services';
import { ToolboxAllPanelServices } from '../../services/toolboxAllPanel.services';
import { DesignIntegrationService } from '../../services/designIntegration.service';
import { DesignActionEventsService } from '../../services/designActionEvents.service';
import * as _ from 'lodash';
import {NodeSearchResource} from '../../models/resources/nodeSearchResource.model';
import {NodeCategoryValueService} from '../../../core/data-services/nodeCategoryValue.service';
import * as $ from 'jquery';


@Component({
  selector: 'emap-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss'],
  providers: [FuzzySearchServices],
  encapsulation: ViewEncapsulation.None
})
export class SearchPanelComponent implements OnInit {
  private SEARCH_PANEL_RESULTS_TABLE_CLASS = 'search-panel-results-table';
  private SEARCH_PANEL_RESULTS_TABLE_HEADER_CLASS = 'global-panel-results-table-header';
  private SEARCH_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS = 'global-panel-results-table-header-icon';
  private SEARCH_PANEL_RESULTS_TABLE_HEADER_MAP_NAME_CLASS = 'search-panel-results-table-header-map-name';
  private SEARCH_PANEL_RESULTS_TABLE_HEADER_MAP_COUNT_CLASS = 'search-panel-results-table-header-map-count';
  private SEARCH_PANEL_RESULTS_TABLE_NODE_LIST_CLASS = 'search-panel-results-table-node-list';
  private SEARCH_PANEL_RESULTS_TABLE_NODE_NAME_CLASS = 'search-panel-results-table-node-name';
  private SEARCH_PANEL_FILTER_SELECTIONS_CLASS = 'search-panel-filter-selections';
  private SEARCH_PANEL_FILTER_SEARCH_RESULTS_METADATA_CLASS = 'search-panel-filter-search-results-metadata';
  private SEARCH_PANEL_FILTER_SELECTIONS_CATEGORY_CLASS = 'search-panel-filter-selections-category';
  private SEARCH_PANEL_FILTER_SELECTIONS_CATEGORY_VALUE_CLASS = 'search-panel-filter-selections-category-value';
  private SEARCH_PANEL_FILTER_SELECTIONS_CATEGORY_VALUE_CLOSE_CLASS  = 'search-panel-filter-selections-category-value-close';
  private SEARCH_PANEL_RESULTS_NOT_FOUND_CLASS = 'search-panel-results-not-found';
  private SEARCH_PANEL_FILTER_BUTTON_CLASS = 'search-panel-filter-button span';
  private SEARCH_INPUT_ID = 'search-input';
  private SEARCH_MAP_ID_PREFIX_NAME = 'search_map_id_';
  private SEARCH_NODE_ID_PREFIX_NAME = 'search_node_id_';
  private SEARCH_RESULTS_NOT_FOUND_TEXT = 'Search Results Not Found';
  private KEYBOARD_TEXT = 'keyboard';

  private flatMapNodeList: any;

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private fuzzySearchServices: FuzzySearchServices,
    private searchFilterKeyboardServices: SearchFilterKeyboardServices,
    private toolboxAllPanelServices: ToolboxAllPanelServices,
    private designIntegrationService: DesignIntegrationService,
    private designActionEventsService: DesignActionEventsService,
    private nodeCategoryValueService: NodeCategoryValueService,
  ) { }

  ngOnInit() {
    this.initSearchCategoryValues();
    this.filterEventHandler();
    this.constructFilterSelectionPane();
    this.filterButtonClickEventHandler();
    this.keyboardOpenClickEventHandler();
  }

  private initializeAccordionOnTable() {
    const customAccordionOption: any = { event: 'none' };
    this.jqueryCommonServices.initiateAccordion(this.SEARCH_PANEL_RESULTS_TABLE_CLASS.toClass(), customAccordionOption);
  }

  private refreshAccordionTable() {
    this.jqueryCommonServices.accordionRefresh(this.SEARCH_PANEL_RESULTS_TABLE_CLASS.toClass());
  }

  private initSearchCategoryValues() {
    this.nodeCategoryValueService.getAllNodeCategoryValue().subscribe((data: Array<NodeSearchResource>) => {
      this.fuzzySearchServices.nodeSearchResourceList = data;
      this.fuzzySearchServices.initFuzzySearch();
      this.initializeAccordionOnTable();
      this.searchInputChangeHandler();
      this.triggerSearchInputChange();
    });
  }

  private searchInputChangeHandler() {
    this.jqueryCommonServices.debouncedChangeHandler(this.SEARCH_INPUT_ID.toId(), this.triggerSearchHandler.bind(this));
  }

  private triggerSearchInputChange() {
    this.jqueryCommonServices.triggerChangeEvent(this.SEARCH_INPUT_ID.toId());
  }

  private triggerSearchHandler(callEvent: any) {
    const currentInputVal = callEvent.target.value;
    if (typeof currentInputVal !== 'string') { return; }
    this.searchFilterKeyboardServices.fuzzySearchObject = (currentInputVal === '') ?
      this.fuzzySearchServices.nodeSearchResourceList : this.fuzzySearchServices.fuzzySearchValue(currentInputVal);
    this.flatMapNodeList = this.searchFilterKeyboardServices.flattenNodeObject();
    this.constructSearchAccordion();
    this.constructFilterSelectionPane();
    this.searchFilterKeyboardServices.searchResultChangeEmitter.next(true);
    this.searchFilterKeyboardServices.keyboardChangeEmitter.next(true);
  }

  private constructSearchAccordion() {
    const searchResultElement = $(this.SEARCH_PANEL_RESULTS_TABLE_CLASS.toClass());
    searchResultElement.empty();
    const autoFillData = [];
    let totalMapCount = 0;
    let totalNodeResultCount = 0;
    this.flatMapNodeList.forEach((eachMap) => {
      const mapFilterCheck = eachMap[5]; // this index will be present if the filter has been selected
      const mapId = eachMap[1];
      const mapVersionId = eachMap[4];
      const mapNodeCount = (mapFilterCheck >= 0 && mapFilterCheck != null) ? mapFilterCheck : eachMap[2];
      if (mapNodeCount < 1) { return; }
      const headerDiv = this.jqueryCommonServices.divConstruct()
                          .addClass(this.SEARCH_PANEL_RESULTS_TABLE_HEADER_CLASS)
                          .data('mapVersionId', mapVersionId)
                          .data('mapId', mapId);
      headerDiv.append(this.jqueryCommonServices.divConstruct().addClass(this.SEARCH_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS));
      headerDiv.append(this.jqueryCommonServices.divConstruct(eachMap[0]).addClass(this.SEARCH_PANEL_RESULTS_TABLE_HEADER_MAP_NAME_CLASS));
      headerDiv.append(this.jqueryCommonServices
        .divConstruct(mapNodeCount + this.jqueryCommonServices.singularOrPluralCheck(' result', mapNodeCount))
        .addClass(this.SEARCH_PANEL_RESULTS_TABLE_HEADER_MAP_COUNT_CLASS)
      );
      headerDiv.attr('id', this.SEARCH_MAP_ID_PREFIX_NAME + mapId);
      const nodeListDiv = this.jqueryCommonServices.divConstruct().addClass(this.SEARCH_PANEL_RESULTS_TABLE_NODE_LIST_CLASS);
      const currentMapNodeList = eachMap[3];
      let currentMapNodeCount = 0;
      const currentNodeIdList = [];
      currentMapNodeList.forEach((eachNode) => {
        const currentNodeName = eachNode[0]; // this index will be present if the filter has been selected for this node
        const currentNodeId = eachNode[1];
        const nodeFilterState = eachNode[3];
        if (!nodeFilterState) { return; }
        if (autoFillData.length < 4) { autoFillData.push(currentNodeName); }
        currentMapNodeCount += 1;
        currentNodeIdList.push(currentNodeId);
        nodeListDiv.append(this.jqueryCommonServices.divConstruct(currentNodeName)
          .addClass(this.SEARCH_PANEL_RESULTS_TABLE_NODE_NAME_CLASS)
          .attr('id', this.SEARCH_NODE_ID_PREFIX_NAME + currentNodeId)
          .data('mapVersionId', mapVersionId)
          .data('mapId', mapId)
        );
      });
      if (currentMapNodeCount > 0) {
        totalNodeResultCount += currentMapNodeCount;
        totalMapCount += 1;
      }
      searchResultElement.append(headerDiv).append(nodeListDiv);
      this.designIntegrationService.currentMapNodeListTable[this.SEARCH_MAP_ID_PREFIX_NAME + mapId] = currentNodeIdList;
    });
    if (_.isEmpty(this.designIntegrationService.initialMapNodeListTable)) {
      this.designIntegrationService.initialMapNodeListTable = _.clone(this.designIntegrationService.currentMapNodeListTable);
    }
    this.constructSearchResultMetadata(totalMapCount, totalNodeResultCount);
    this.searchFilterKeyboardServices.keyboardData = autoFillData;
    this.refreshAccordionTable();
    this.initializeDesignActionEvents();
  }

  private filterEventHandler() {
    this.searchFilterKeyboardServices.filterResultChangeEmitter.subscribe(() => {
      this.searchFilterKeyboardServices.filterSelectionDataHandler();
      this.constructSearchAccordion();
      this.constructFilterSelectionPane();
      this.filterRemoveHandler();
      this.searchFilterKeyboardServices.keyboardChangeEmitter.next(true);
    });
  }

  private constructFilterSelectionPane() {
    const currentFilterSelectionObject = this.searchFilterKeyboardServices.filterDataState;
    const searchPanelFilterCategorySelectionDiv = this.jqueryCommonServices.selector(this.SEARCH_PANEL_FILTER_SELECTIONS_CLASS.toClass());
    const filterSelectionCategoryIdList = Object.keys(currentFilterSelectionObject);
    this.jqueryCommonServices.selector(this.SEARCH_PANEL_FILTER_SELECTIONS_CATEGORY_CLASS.toClass()).remove();
    if (filterSelectionCategoryIdList.length === 0) {
      searchPanelFilterCategorySelectionDiv.append(
        this.jqueryCommonServices.divConstruct('None').addClass(this.SEARCH_PANEL_FILTER_SELECTIONS_CATEGORY_CLASS)
      );
      return;
    }
    filterSelectionCategoryIdList.forEach((eachCategoryId) => {
      const currentCategoryValueIdList = currentFilterSelectionObject[eachCategoryId];
      currentCategoryValueIdList.forEach((eachCategoryValueId) => {
        const currentCategoryValueName = this.searchFilterKeyboardServices.filterData[eachCategoryId]['cv'][eachCategoryValueId];
        const searchPanelFilterCategoryDiv = this.jqueryCommonServices.divConstruct()
          .addClass(this.SEARCH_PANEL_FILTER_SELECTIONS_CATEGORY_CLASS);
        searchPanelFilterCategoryDiv
          .append(this.jqueryCommonServices.divConstruct(currentCategoryValueName)
            .addClass(this.SEARCH_PANEL_FILTER_SELECTIONS_CATEGORY_VALUE_CLASS))
          .append(this.jqueryCommonServices.divConstruct().addClass(this.SEARCH_PANEL_FILTER_SELECTIONS_CATEGORY_VALUE_CLOSE_CLASS));
        searchPanelFilterCategoryDiv.data({ci: Number(eachCategoryId), cvi: eachCategoryValueId});
        searchPanelFilterCategorySelectionDiv.append(searchPanelFilterCategoryDiv);
      });
    });
  }

  private filterRemoveHandler() {
    this.jqueryCommonServices.clickEvent(this.SEARCH_PANEL_FILTER_SELECTIONS_CATEGORY_CLASS.toClass(), (event) => {
      const filterRemoveData  = this.jqueryCommonServices.selector(event.currentTarget).data();
      this.jqueryCommonServices.selector(event.currentTarget).remove();
      this.searchFilterKeyboardServices.filterResultRemoveEmitter.next(filterRemoveData);
    });
  }

  private constructSearchResultMetadata(totalMapCount: number, totalNodeResultCount: number) {
    const searchResultsMetadataVal = totalNodeResultCount + this.jqueryCommonServices.singularOrPluralCheck(' result', totalNodeResultCount)
                                      + ' on ' + totalMapCount + this.jqueryCommonServices.singularOrPluralCheck(' map', totalMapCount);
    const searchPanelResultsMetadataSelector = this.jqueryCommonServices.selector(this.SEARCH_PANEL_FILTER_SEARCH_RESULTS_METADATA_CLASS.toClass());
    const searchPanelResultsNotFoundSelector = this.jqueryCommonServices.selector(this.SEARCH_PANEL_RESULTS_NOT_FOUND_CLASS.toClass());
    searchPanelResultsMetadataSelector.empty();
    if ((totalMapCount === 0) && (totalNodeResultCount === 0)) {
      searchPanelResultsNotFoundSelector.text(this.SEARCH_RESULTS_NOT_FOUND_TEXT);
      return;
    }else {
      searchPanelResultsNotFoundSelector.empty();
    }
    searchPanelResultsMetadataSelector.text(searchResultsMetadataVal);
  }

  private filterButtonClickEventHandler() {
    this.jqueryCommonServices.clickEvent(this.SEARCH_PANEL_FILTER_BUTTON_CLASS.toClass(), (event) => {
      this.toolboxAllPanelServices.filterButtonClickEventEmitter.next(true);
    });
  }

  private keyboardOpenClickEventHandler() {
    this.jqueryCommonServices.clickEvent(this.SEARCH_INPUT_ID.toId(), () => {
      this.toolboxAllPanelServices.searchInputClickEventEmitter.next(true);
    });
    this.jqueryCommonServices.selector(this.SEARCH_INPUT_ID.toId()).blur(event => {
      let defaultVal = false;
      if (event.relatedTarget != null) { defaultVal = (event.relatedTarget.className.indexOf(this.KEYBOARD_TEXT) > -1); }
      this.toolboxAllPanelServices.searchInputClickEventEmitter.next(defaultVal);
    });
  }

  private initializeDesignActionEvents() {
    this.designActionEventsService.mapNameTapActionEvent(this.jqueryCommonServices.selector(this.SEARCH_PANEL_RESULTS_TABLE_HEADER_CLASS.toClass()));
    this.designActionEventsService.endNodeTapActionEvent(this.jqueryCommonServices.
    selector(this.SEARCH_PANEL_RESULTS_TABLE_NODE_NAME_CLASS.toClass()));
  }
}
