import { BasicUser } from './basicUser.model';
import { UserTenant } from './userTenant.model';
import { UserAttributes } from './interfaces/userAttributes.interface';
import { Searchable } from './interfaces/searchable.interface';
import { BaseEntity } from '../../xform-compat';


export class User implements BaseEntity, Searchable {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  emailAddress: string;
  superUser: boolean;
  attributes: UserAttributes;
  createdBy: BasicUser;
  userTenants: Array<UserTenant>;
  created: string;
  updated: string;
  active: boolean;
  confirmed: boolean;
  profileImage: string;
  profileImageBase64: string;
  twoFactorRequired: boolean;

  constructor(user?: any) {
    this.id = user.id || null;
    this.firstName = user.firstName || null;
    this.lastName = user.lastName || null;
    this.fullName = user.fullName || null;
    this.emailAddress = user.emailAddress || null;
    this.superUser = user.superUser || false;
    this.attributes = user.attributes ? user.attributes : {};
    this.createdBy = user.createdBy || null;
    this.userTenants = user.userTenants ? user.userTenants : [];
    this.created = user.created || null;
    this.updated = user.updated || null;
    this.active = user.active || null;
    this.confirmed = user.confirmed || null;
    this.profileImage = user.profileImage || null;
    this.profileImageBase64 = null;
    this.twoFactorRequired = user.twoFactorRequired || false;
  }

  public setUserTenants(userTenants: Array<UserTenant>) {
    this.userTenants = userTenants;
  }

  public setSuperUser(isSuperUser: boolean) {
    this.superUser = isSuperUser;
  }

  public getDisplayName() {
    return this.fullName;
  }

}

