export class JqueryAccordionOptions {
  beforeActivate: any;
  collapsible: boolean;
  active: boolean;
  icons: boolean;
  heightStyle: string;
  event: string;

  constructor(option: any = {}) {
    this.collapsible = option.collapsible || true;
    this.active = option.active || false;
    this.icons = option.icons || false;
    this.heightStyle = option.heightStyle || 'content';
    this.event = option.event || 'click';
  }
}
