export class PaginatedList<T> {
  totalPages: number;
  totalRecords: number;
  numberOfPageRecords: number;
  next: number;
  previous: number;
  records: Array<T>;
  pageNumber?: number;

  constructor() {
    this.totalPages = 0;
    this.totalRecords = 0;
    this.numberOfPageRecords = 0;
    this.next = 0;
    this.previous = 0;
    this.records = [];
  }
}

