<div class="keyboard-panel-parent">
  <div class="keyboard-panel-child panel-left-slide-out panel-left-slide-in">
      <div class="keyboard-panel-close keyboard-panel-close-left">
          <div class="keyboard-panel-close-icon"></div>
      </div>
      <div class="keyboard-panel-child-search-and-keys">
          <div class="keyboard-panel-child-search-panel"></div>
      </div>
      <div class="keyboard-panel-close keyboard-panel-close-right">
          <div class="keyboard-panel-close-icon"></div>
      </div>
  </div>
</div>