import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthoringIntegrationService } from '../../services/authoringIntegration.service';
import { DesignIntegrationService } from '../../services/designIntegration.service';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { Node } from '../../models/node.model';
import { NodeHierarchy } from '../../models/nodeHierarchy.model';
import { GlobalIntegrationServices } from '../../services/globalIntegration.services';
import { NodeHierarchyService } from '../../services/nodeHierarchy.service';
import { JsPlumbCommonService } from '../../services/jsPlumbCommon.service';
import { NodeLayerIntegrationService } from '../../services/nodeLayerIntegration.service';
import { NodeGenericService } from '../../services/nodeGeneric.service';
import { NodeLayerAbstract } from './nodeLayer.abstract';
import { NodeLayerService } from '../../../core/data-services/nodeLayer.service';
import { MAP_VIEW_STATE } from '../../enums';
import { AnimationCommonServices } from '../../services/animationCommon.services';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BannerNotificationsService, SpinnerService } from '../../../xform-compat';
import { MapVersionNode } from '../../models/mapVersionNode.model';

@Component({
    selector: 'emap-node-layer',
    templateUrl: './node-layer.component.html',
    styleUrls: ['./node-layer.component.scss'],
    providers: [JqueryCommonServices, JsPlumbCommonService, NodeGenericService, NodeLayerService],
    encapsulation: ViewEncapsulation.None,
})
export class NodeLayerComponent extends NodeLayerAbstract implements OnInit, OnDestroy {
    @Input() nodeLayerEvent$: EventEmitter<any> = new EventEmitter<any>();
    private nodes: any;
    constructor(
        nodeLayerIntegrationService: NodeLayerIntegrationService,
        jqueryCommonServices: JqueryCommonServices,
        private animationCommonServices: AnimationCommonServices,
        private ais: AuthoringIntegrationService,
        private dis: DesignIntegrationService,
        private gis: GlobalIntegrationServices,
        private nodeHierarchyService: NodeHierarchyService,
        private jsPlumbCommonService: JsPlumbCommonService,
        private nodeGenericService: NodeGenericService,
        private nodeLayerService: NodeLayerService,
        private route: ActivatedRoute,
        private spinnerService: SpinnerService,
        private bannerNotificationsService: BannerNotificationsService,
        private translateService: TranslateService,
    ) {
        super(nodeLayerIntegrationService, jqueryCommonServices);
    }

    ngOnInit() {
        this.resetStateVariables();
        this.init();
        this.initAuthoringHeaderSubscriptions();
        this.initAuthoringToolboxSubscriptions();
        this.initGlobalIntegrationSubscriptions();
        this.initNodeLayerIntegrationSubscriptions();
        this.initAuthoringIntegrationSubscriptions();
        this.initDesignIntegrationSubscriptions();
    }

    init() {
        this.gis.currentMapViewState = MAP_VIEW_STATE.Authoring;
        this.nodeLayerEvent$.subscribe(data => {
            this.nodes = data['mapVersionNodes'];
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    resetStateVariables() {
        this.nodeLayerIntegrationService.nodes = new Map();
        this.nodeLayerIntegrationService.deletedNodes = new Map();
        this.nodeLayerIntegrationService.elementIdToNodeId = new Map();
        this.nodeHierarchyService.resetConnections();

        this.nodeGenericService.resetSelectionNodeBounds();
        this.nodeGenericService.clearNodeSelectionFollowerIds();

        this.ais.nodeLayerModel.primarySelectionId = null;
        this.ais.nodeLayerModel.secondarySelectionIds.clear();
        this.nodeGenericService.emitUnsavedChangesEvent(false);
        this.nodeGenericService.emitNodeLayerSelectionChange();
    }

    private reload() {
        this.teardown();
        this.setup();
    }

    private teardown() {
        if ((this.gis.currentMapViewState === MAP_VIEW_STATE.Authoring)
          && this.jsPlumbCommonService.isReady()) {
            this.jsPlumbCommonService.reset();
        }
        this.getNodeElements().off();
        this.getNodeElements().remove();
    }

    private setup() {
        this.resetStateVariables();
        if (this.gis.currentMapViewState === MAP_VIEW_STATE.Authoring) {
            this.jsPlumbCommonService.initialize(this.postInitJsPlumbCommonService.bind(this));
        } else {
            this.initNodeLayerContent();
        }
    }

    private postInitJsPlumbCommonService() {
        this.jsPlumbCommonService.setZoom(this.gis.ratio);
        this.bindJsPlumbSubscriptions();
        this.initNodeLayerContent();
    }

    private initNodeLayerContent() {
        this.postInitNodeLayerContent(this.nodes);
    }

    private generateNodeLayer() {
        const updatedNodes = new Map(this.nodeLayerIntegrationService.nodes);
        return [...Array.from(updatedNodes.values()), ...Array.from(this.nodeLayerIntegrationService.deletedNodes.values())];
    }

    private saveNodeLayer() {
        const nodeLayer = this.generateNodeLayer();
        this.spinnerService.start();
        this.nodeLayerService.putNodeLayerByMapVersionId(this.gis.currentMapVersionId, nodeLayer).subscribe((event) => {
            this.spinnerService.stop();
            this.bannerNotificationsService.success(this.translateService.instant('AUTHORING.NODE_LAYER.SAVE_SUCCESS'));
            this.nodeGenericService.emitUnsavedChangesEvent(false);
        }, () => {
            this.spinnerService.stop();
            this.bannerNotificationsService.error(this.translateService.instant('AUTHORING.NODE_LAYER.SAVE_FAILURE'));
        });
    }

    postInitNodeLayerContent(mapVersionNodes: Array<MapVersionNode>) {
        this.nodeGenericService.initNodeLayer(mapVersionNodes);
        this.initAllIncludedConnections();
        this.nodeGenericService.setCurrentMode();
        if (this.gis.currentMapViewState === MAP_VIEW_STATE.Authoring) {
            this.getNodeElements().addClass(this.NODE_LAYER_CHILD_SHOW_CLASS);
            this.getNodeElements().tooltip({show: {effect: 'none', delay: 0}, track: true});
            this.ais.nodeLayerLoadEventEmitter.next();
        }
        this.dis.initialHammerPressEventEmitter.next('node-layer-end-node');
    }

    bindJsPlumbSubscriptions() {
        const that = this;
        this.jsPlumbCommonService.onConnectionDrag(function() {
            // TODO: look for a 'connectionDragStart' type binding for jsPlumb to improve performance
            that.gis.currentZpanCommonService.setPanningEnabled(false);
        });
        this.jsPlumbCommonService.onConnectionDragStop(function() {
            that.gis.currentZpanCommonService.setPanningEnabled(true);
        });
        this.jsPlumbCommonService.onConnectionDragAbort(function() {
            that.gis.currentZpanCommonService.setPanningEnabled(true);
        });
        this.customSubscribe(this.gis.zoomCompleteEventEmitter, (value) => {
            // NOTE: This corrects the connection dragging offset
            this.jsPlumbCommonService.setZoom(this.gis.ratio);
        });
        this.initJsPlumbConnectionPreCreationSubscription();
        this.initJsPlumbConnectionPostCreationSubscription();
    }

    initAuthoringHeaderSubscriptions() {
        this.customSubscribe(this.ais.authoringSaveIconClickEventHandler, (value) => {
            this.handleHeaderSaveClick();
        });
        this.customSubscribe(this.ais.authoringHierarchyIconClickEventHandler, (value) => {
            this.handleHeaderHierarchyClick();
        });
        this.customSubscribe(this.ais.authoringEditIconClickEventHandler, (value) => {
            this.handleHeaderEditClick();
        });
    }

    initAuthoringToolboxSubscriptions() {
        this.customSubscribe(this.ais.authoringToolboxClickEventHandler, (event) => {
            if (!event.hasOwnProperty('target')) { return; }
            const elementId = (event as any).target.id;
            const elementRouteName = elementId.replace('authoring-toolbox-', '');
            switch (elementRouteName) {
                case 'left-align':
                    this.handleToolboxLeftAlignClick();
                    this.nodeGenericService.emitUnsavedChangesEvent(true);
                    break;
                case 'right-align':
                    this.handleToolboxRightAlignClick();
                    this.nodeGenericService.emitUnsavedChangesEvent(true);
                    break;
                case 'box-resize':
                    this.handleToolboxResizeBoxClick();
                    this.nodeGenericService.emitUnsavedChangesEvent(true);
                    break;
                case 'box-resize-height':
                    this.handleToolboxResizeHeightClick();
                    this.nodeGenericService.emitUnsavedChangesEvent(true);
                    break;
                case 'box-resize-width':
                    this.handleToolboxResizeWidthClick();
                    this.nodeGenericService.emitUnsavedChangesEvent(true);
                    break;
                case 'hierarchy':
                    this.handleToolboxHierarchyClick();
                    break;
                case 'delete':
                    this.handleToolboxDeleteClick();
                    this.nodeGenericService.emitUnsavedChangesEvent(true);
                    break;
                default:
                    break;
            }
        });
    }

    initGlobalIntegrationSubscriptions() {
        this.customSubscribe(this.gis.mapImageLoadEventEmitter, (value) => {
            this.nodeLayerIntegrationService.mapImageWidth = this.gis.currentMapImageSelector.width();
            this.nodeLayerIntegrationService.mapImageHeight = this.gis.currentMapImageSelector.height();
            this.reload();
        });
    }

    initAuthoringIntegrationSubscriptions() {
        this.customSubscribe(this.ais.nodeLayerToggleIncludeNodeEventEmitter, (value) => {
            this.handleToggleIncludeNodeEvent(value['nodeId'].toString());
        });
    }

    initDesignIntegrationSubscriptions() {
        this.customSubscribe(this.dis.showCookieTrailEventEmitter, (value) => {
            this.handleCookieTrailEvent(value.toString());
        });
        this.customSubscribe(this.dis.mapSwapEventEmitter, () => {
            this.reload();
        });
    }

    initNodeLayerIntegrationSubscriptions() {
        this.customSubscribe(this.nodeLayerIntegrationService.nodeLayerClickEventHandler, (value) => {
            this.handleNodeClickEvent(value);
        });
    }

    initJsPlumbConnectionPreCreationSubscription() {
        this.jsPlumbCommonService.onConnectBeforeDrop(function(info) {
            return (info.sourceId !== info.targetId);
        });
    }

    initJsPlumbConnectionPostCreationSubscription() {
        const that = this;
        this.jsPlumbCommonService.onConnect(function(info, connectEvent) {
            const parentNodeId = that.elementIdToNodeId(info.sourceId);
            const childNodeId = that.elementIdToNodeId(info.targetId);
            that.nodeHierarchyService.createConnectionEntry(parentNodeId, childNodeId);
            const isUserAction = (typeof connectEvent !== 'undefined') && (connectEvent.isTrusted);
            if (isUserAction) {
                that.nodeLayerIntegrationService.nodes.get(parentNodeId).nodeHierarchiesByParentNode.push(
                    new NodeHierarchy({'parentNodeId': parentNodeId, 'childNodeId': childNodeId}));
                that.nodeGenericService.emitUnsavedChangesEvent(true);
            }
            info.connection.bind('click', (function (conn, clickEvent) {
                if (!that.ais.authoringHeaderModel.isHierarchyModeActive) { return; }
                that.deleteConnection(conn, true);
                that.nodeGenericService.emitUnsavedChangesEvent(true);
            }));
        });
    }

    handleHeaderSaveClick() {
        this.saveNodeLayer();
    }

    handleHeaderHierarchyClick() {
        this.nodeGenericService.clearNodeSelections();
        this.nodeGenericService.setCurrentMode();
    }

    handleHeaderEditClick() {
        this.nodeGenericService.clearNodeSelections();
        this.nodeGenericService.setCurrentMode();
    }

    handleToolboxHierarchyClick() {
        const elems = this.getNodeElements();
        if (this.ais.authoringToolboxModel.isHierarchyModeActive) {
            this.jsPlumbCommonService.showConnections(elems);
        } else {
            this.jsPlumbCommonService.hideConnections(elems);
        }
    }

    handleToolboxLeftAlignClick() {
        if (!this.ais.nodeLayerModel.primarySelectionId) { return; }
        if (!this.ais.nodeLayerModel.secondarySelectionIds) { return; }
        const primaryNode = this.nodeLayerIntegrationService.nodes.get(this.ais.nodeLayerModel.primarySelectionId);
        this.ais.nodeLayerModel.secondarySelectionIds.forEach((nodeId) => {
            const node = this.nodeLayerIntegrationService.nodes.get(nodeId);
            const nodeElem = this.getNodeElement(nodeId);
            this.nodeGenericService.moveNodeNormalized(nodeElem, primaryNode.leftPosition, node.topPosition);
        });
        this.jsPlumbCommonService.repaint();
    }

    handleToolboxRightAlignClick() {
        if (!this.ais.nodeLayerModel.primarySelectionId) { return; }
        if (!this.ais.nodeLayerModel.secondarySelectionIds) { return; }
        const primaryNode = this.nodeLayerIntegrationService.nodes.get(this.ais.nodeLayerModel.primarySelectionId);
        this.ais.nodeLayerModel.secondarySelectionIds.forEach((nodeId) => {
            const node = this.nodeLayerIntegrationService.nodes.get(nodeId);
            const nodeElem = this.getNodeElement(nodeId);
            this.nodeGenericService.moveNodeNormalized(nodeElem, primaryNode.leftPosition + primaryNode.width - node.width, node.topPosition);
        });
        this.jsPlumbCommonService.repaint();
    }

    handleToolboxResizeBoxClick() {
        if (!this.ais.nodeLayerModel.primarySelectionId) { return; }
        if (!this.ais.nodeLayerModel.secondarySelectionIds) { return; }
        const primaryNode = this.nodeLayerIntegrationService.nodes.get(this.ais.nodeLayerModel.primarySelectionId);
        this.ais.nodeLayerModel.secondarySelectionIds.forEach((nodeId) => {
            const nodeElem = this.getNodeElement(nodeId);
            this.nodeGenericService.resizeNodeNormalized(nodeElem, primaryNode.width, primaryNode.height);
        });
        this.jsPlumbCommonService.repaint();
    }

    handleToolboxResizeHeightClick() {
        if (!this.ais.nodeLayerModel.primarySelectionId) { return; }
        if (!this.ais.nodeLayerModel.secondarySelectionIds) { return; }
        const primaryNode = this.nodeLayerIntegrationService.nodes.get(this.ais.nodeLayerModel.primarySelectionId);
        this.ais.nodeLayerModel.secondarySelectionIds.forEach((nodeId) => {
            const node = this.nodeLayerIntegrationService.nodes.get(nodeId);
            const nodeElem = this.getNodeElement(nodeId);
            this.nodeGenericService.resizeNodeNormalized(nodeElem, node.width, primaryNode.height);
        });
        this.jsPlumbCommonService.repaint();
    }

    handleToolboxResizeWidthClick() {
        if (!this.ais.nodeLayerModel.primarySelectionId) { return; }
        if (!this.ais.nodeLayerModel.secondarySelectionIds) { return; }
        const primaryNode = this.nodeLayerIntegrationService.nodes.get(this.ais.nodeLayerModel.primarySelectionId);
        this.ais.nodeLayerModel.secondarySelectionIds.forEach((nodeId) => {
            const node = this.nodeLayerIntegrationService.nodes.get(nodeId);
            const nodeElem = this.getNodeElement(nodeId);
            this.nodeGenericService.resizeNodeNormalized(nodeElem, primaryNode.width, node.height);
        });
        this.jsPlumbCommonService.repaint();
    }

    handleToolboxDeleteClick() {
        const nodeId = this.ais.nodeLayerModel.primarySelectionId;
        if (nodeId === null) { return; }

        this.nodeGenericService.unsetPrimarySelection();
        this.nodeGenericService.removeAllFromSecondarySelection();
        this.nodeGenericService.emitNodeLayerSelectionChange();

        this.deleteNode(nodeId);
    }

    handleNodeClickEvent(value: any) {
        if (!this.ais.authoringHeaderModel.isEditModeActive) { return; }
        switch (value.clickType) {
            case 'primary':
                this.handleNodePrimaryClickEvent(value.nodeId);
                break;
            case 'secondary':
                this.handleNodeSecondaryClickEvent(value.nodeId);
                break;
            case 'hierarchy':
                this.handleNodeHierarchyClickEvent(value.nodeId);
                break;
            default:
                break;
        }
        this.nodeGenericService.emitNodeLayerSelectionChange();
    }

    handleNodePrimaryClickEvent(id: string) {
        this.nodeGenericService.removeAllFromSecondarySelection();
        const oldId = this.ais.nodeLayerModel.primarySelectionId;
        if (oldId) {
            this.nodeGenericService.unsetPrimarySelection();
        }
        if (oldId !== id) {
            this.nodeGenericService.setPrimarySelection(id);
        }
    }

    handleNodeSecondaryClickEvent(id: string) {
        if (this.ais.nodeLayerModel.primarySelectionId === id) { return; }
        if (this.ais.nodeLayerModel.secondarySelectionIds.has(id)) {
            this.nodeGenericService.removeFromSecondarySelection(id);
        } else {
            this.nodeGenericService.addToSecondarySelection(id);
        }
    }

    handleNodeHierarchyClickEvent(id: string) {
        const descendantNodeIds = this.nodeHierarchyService.getDescendantNodeIds(id);
        if (this.ais.nodeLayerModel.primarySelectionId !== null) {
            descendantNodeIds.delete(this.ais.nodeLayerModel.primarySelectionId);
        }
        if (!this.ais.nodeLayerModel.secondarySelectionIds.has(id)) {
            descendantNodeIds.forEach(descendantNodeId => this.nodeGenericService.addToSecondarySelection(descendantNodeId));
        } else {
            descendantNodeIds.forEach(descendantNodeId => this.nodeGenericService.removeFromSecondarySelection(descendantNodeId));
        }
    }

    handleCookieTrailEvent(endNodeId: string) {
        this.dis.returnCookieTrailEventEmitter.next(
            this.nodeIdsToElementIds(this.nodeHierarchyService.getAncestorNodeIds(endNodeId).reverse())
        );
    }

    handleToggleIncludeNodeEvent(nodeId: string) {
        const node = this.nodeLayerIntegrationService.nodes.get(nodeId);
        const nodeElem = this.getNodeElement(nodeId);
        const neverPlaced = (node.width === 0);  // FIXME: hacky way to check whether the node has been placed before
        if (node.isIncluded) {
            if (neverPlaced) {
                this.nodeGenericService.placeNodeAtViewportCenter(nodeElem);
            }
            this.initAllIncludedConnectionsForNode(node);
        } else {
            this.deleteAllConnectionsForNode(node);
        }
        this.nodeGenericService.setCurrentMode(false);
    }

    initAllIncludedConnections() {
        const that = this;
        Array.from(this.nodeLayerIntegrationService.nodes.values()).forEach(
            (node) => node.nodeHierarchiesByParentNode.forEach((connection) => that.initConnectionIfIncluded(connection))
        );
    }

    initAllIncludedConnectionsForNode(node: MapVersionNode) {
        this.nodeLayerIntegrationService.nodes.forEach((n) => n.nodeHierarchiesByParentNode.forEach((connection) => {
            if ((node.id === connection.parentNodeId) || (node.id === connection.childNodeId)) {
                this.initConnectionIfIncluded(connection);
            }
        }));
    }

    initConnectionIfIncluded(connection: NodeHierarchy) {
        const parentNode = this.nodeLayerIntegrationService.nodes.get(connection.parentNodeId.toString());
        const childNode = this.nodeLayerIntegrationService.nodes.get(connection.childNodeId.toString());
        if (!parentNode || !childNode) {
            return;  // nodes can have connections to nodes in other map versions!
        }
        if (parentNode.isIncluded && childNode.isIncluded) {
            this.initConnection(connection);
        }
    }

    initConnection(connection: NodeHierarchy) {
        const parentNodeId = connection.parentNodeId.toString();
        const childNodeId = connection.childNodeId.toString();
        const parentNodeElem = this.getNodeElement(parentNodeId);
        const childNodeElem = this.getNodeElement(childNodeId);
        if (this.jsPlumbCommonService.isReady()) {
            this.jsPlumbCommonService.createConnection(parentNodeElem, childNodeElem);  // NOTE: triggers jsPlumb "onConnect" event
        } else {
            this.nodeHierarchyService.createConnectionEntry(parentNodeId, childNodeId);
        }
    }

    deleteAllConnectionsForNode(node: MapVersionNode) {
        const nodeId = node.id.toString();
        const nodeElem = this.getNodeElement(nodeId);
        if (this.jsPlumbCommonService.isReady()) { this.jsPlumbCommonService.deleteConnectionsForElement(nodeElem); }
        this.nodeHierarchyService.deleteAllConnectionsForNode(nodeId);
    }

    deleteConnection(conn: any, permanent: boolean = false) {
        const parentNodeElemId = conn.sourceId;
        const childNodeElemId = conn.targetId;
        const parentNodeId = this.elementIdToNodeId(parentNodeElemId);
        const childNodeId = this.elementIdToNodeId(childNodeElemId);

        if (this.jsPlumbCommonService.isReady()) { this.jsPlumbCommonService.deleteConnection(conn); }
        this.nodeHierarchyService.deleteConnectionEntry(parentNodeId, childNodeId);
        if (permanent) {
            this.nodeLayerIntegrationService.nodes.get(parentNodeId).nodeHierarchiesByParentNode =
                this.nodeLayerIntegrationService.nodes.get(parentNodeId).nodeHierarchiesByParentNode.filter(
                    connection => connection.childNodeId.toString() !== childNodeId);
        }
    }

    deleteNode(nodeId: string) {
        const nodeElemId = this.nodeIdToElementId(nodeId);
        const nodeElemSelector = this.jqueryCommonService.selector(nodeElemId.toId());

        if (this.jsPlumbCommonService.isReady()) { this.jsPlumbCommonService.deleteConnectionsForElement(nodeElemSelector); }
        this.nodeHierarchyService.deleteAllConnectionsForNode(nodeId);

        nodeElemSelector.off();
        nodeElemSelector.remove();

        this.nodeLayerIntegrationService.elementIdToNodeId.delete(nodeElemId);
        const node = this.nodeLayerIntegrationService.nodes.get(nodeId);
        node.isDeleted = true;
        this.nodeLayerIntegrationService.deletedNodes.set(nodeId, node);
        this.nodeLayerIntegrationService.nodes.delete(nodeId);
    }
}
