import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapRoutingModule, routedComponents } from './map-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { NotificationsSettingsService } from '../../core/data-services/notificationSettings.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { MapDesignComponent } from './map-design/map-design.component';
import { BulkMapDeployComponent } from './bulk-map-deploy/bulk-map-deploy.component';
import { MapCoreComponent } from './map-core/map-core.component';


@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    MapRoutingModule,
    FileUploadModule,
  ],
  declarations: [
    routedComponents,
    MapDesignComponent,
    BulkMapDeployComponent,
    MapCoreComponent,
  ],
  providers: [NgbActiveModal, NotificationsSettingsService],
  exports: [MapCoreComponent]
})
export class MapModule { }
