
export class AuthoringHeaderModel {
    _isHierarchyModeActive: boolean;
    _isEditModeActive: boolean;

    constructor(map: AuthoringHeaderModel = {} as AuthoringHeaderModel) {
        this._isHierarchyModeActive = map.isHierarchyModeActive || false;
        this._isEditModeActive = map.isEditModeActive || false;
    }

    get isHierarchyModeActive(): boolean {
        return this._isHierarchyModeActive;
    }

    set isHierarchyModeActive(id: boolean) {
        this._isHierarchyModeActive = id;
    }

    get isEditModeActive(): boolean {
        return this._isEditModeActive;
    }

    set isEditModeActive(id: boolean) {
        this._isEditModeActive = id;
    }
}
