import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class NodeHierarchyService {

    private connections = new Map();

    constructor() { }

    static getDefault(map: Map<any, any>, key: any, defaultValue: any) {
        if (!map.has(key)) { return defaultValue; }
        return map.get(key);
    }

    static invertMapping<T>(map: Map<T, Set<T>>) {
        const invertedMap = new Map<T, Set<T>>();
        map.forEach((children, parent) => {
            children.forEach(child => {
                invertedMap.set(child, (NodeHierarchyService.getDefault(invertedMap, child, new Set())).add(parent));
            });
        });
        return invertedMap;
    }

    public createConnectionEntry(parentNodeId: string, childNodeId: string) {
        if (!this.connections.has(parentNodeId)) {
            this.connections.set(parentNodeId, new Set());
        }
        this.connections.get(parentNodeId).add(childNodeId);
    }

    public getConnectionEntries(parentNodeId: string) {
        return NodeHierarchyService.getDefault(this.connections, parentNodeId, new Set());
    }

    public deleteConnectionEntry(parentNodeId: string, childNodeId: string) {
        this.connections.get(parentNodeId).delete(childNodeId);
    }

    public deleteAllConnectionsForNode(nodeId: string) {
        this.connections.delete(nodeId);
        this.connections.forEach(nodes => nodes.delete(nodeId));
    }

    public resetConnections() {
        this.connections = new Map();
    }

    public hasChildren(id: string) {
        return NodeHierarchyService.getDefault(this.connections, id, new Set()).size > 0;
    }

    public hasParents(id: string) {
        return NodeHierarchyService.getDefault(NodeHierarchyService.invertMapping(this.connections), id, new Set()).size > 0;
    }

    public hasAnyConnections(id: string) {
        return this.hasParents(id) || this.hasChildren(id);
    }

    public getDescendantNodeIds(id: string) {
        return this.bfsGraphTraversal(id, this.connections);
    }

    public getAncestorNodeIds(id: any) {
        // TODO: maintain inverted mapping
        return this.dfsGraphTraversal(id, NodeHierarchyService.invertMapping(this.connections), new Set());
    }

    private dfsGraphTraversal<T>(start: T, edges: Map<T, Set<T>>, visited: Set<T>) {
        visited.add(start);
        const next = _.difference(Array.from(NodeHierarchyService.getDefault(edges, start, {})), Array.from(visited));
        if (next.length === 0) {
            return [start];
        } else {
            return [start].concat(this.dfsGraphTraversal(next[0], edges, visited));
        }
    }

    private bfsGraphTraversal<T>(start: T, edges: Map<T, Set<T>>): Set<T> {
        const visited: Set<T> = new Set([start]);
        const neighbors: T[] = [start];
        while (neighbors.length > 0) {
            const next = _.filter(Array.from(NodeHierarchyService.getDefault(edges, neighbors.shift(), {})), node => !visited.has(node));
            next.forEach(node => visited.add(node));
            neighbors.push(...next);
        }
        return visited;
    }
}
