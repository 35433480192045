export class Image {
  id: number;
  fileName: string;
  imageType: string;
  s3Reference: string;
  title: string;
  height: number;
  width: number;

  constructor(obj: Image = {} as Image) {
    this.id = obj.id;
    this.fileName = obj.fileName;
    this.imageType = obj.imageType;
    this.s3Reference = obj.s3Reference;
    this.title = obj.title;
    this.height = obj.height;
    this.width = obj.width;
  }
}
