import { Component, OnInit } from '@angular/core';
import { ToolboxAllPanelServices } from '../../services/toolboxAllPanel.services';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';


@Component({
  selector: 'emap-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss']
})
export class ToolboxComponent implements OnInit {
  private TOOLBOX_PARENT_CLASS = 'toolbox-parent';
  private TOOLBOX_BUTTON_CLASS = 'toolbox-button';
  private TOOLBOX_BUTTON_LINKED_CLASS = 'toolbox-button-linked';
  private TOOLBOX_SWAP_CLASS = 'toolbox-swap';
  private TOOLBOX_NAVIGATOR_ID = 'toolbox-navigator';
  private TOOLBOX_ACTIVE_CLASS = 'active';
  private TOOLBOX_SWITCHED_CLASS = 'toolbox-switched';
  private NAVIGATION_WINDOW_ID = 'navigator';

  constructor(
    private toolboxAllPanelServices: ToolboxAllPanelServices,
    private jqueryCommonServices: JqueryCommonServices
  ) { }

  ngOnInit() {
    this.updateToolboxDimensions();
    this.toggleButtonActiveState();
    this.swapToolboxHandler();
    this.windowResizeEventHandler();
    this.triggerWindowResize();
  }

  private updateToolboxDimensions() {
    this.toolboxAllPanelServices.toolboxWidth = this.jqueryCommonServices.widthHeight(this.TOOLBOX_PARENT_CLASS.toClass())['width'];
  }

  private toggleButtonActiveState() {
    this.jqueryCommonServices.clickEvent(this.TOOLBOX_BUTTON_LINKED_CLASS.toClass(), (event) => {
      const currentTarget = event.currentTarget;
      const currentActiveState = this.jqueryCommonServices.selector(currentTarget).hasClass(this.TOOLBOX_ACTIVE_CLASS);
      this.jqueryCommonServices.selector(this.TOOLBOX_BUTTON_LINKED_CLASS.toClass()).removeClass(this.TOOLBOX_ACTIVE_CLASS);
      this.jqueryCommonServices.selector(currentTarget).addClass(!currentActiveState ? this.TOOLBOX_ACTIVE_CLASS : '');
      this.toolboxAllPanelServices.toolboxButtonClickEventEmitter.next({event: event, activeState: !currentActiveState});
    });
    this.jqueryCommonServices.clickEvent(this.TOOLBOX_NAVIGATOR_ID.toId(), event => {
      this.jqueryCommonServices.selector(this.TOOLBOX_NAVIGATOR_ID.toId()).toggleClass(this.TOOLBOX_ACTIVE_CLASS);
      this.toolboxAllPanelServices.navigatorButtonClickEventEmitter.next({targetElement: this.NAVIGATION_WINDOW_ID.toId()});
    });
  }

  private swapToolboxHandler() {
    this.jqueryCommonServices.clickEvent(this.TOOLBOX_SWAP_CLASS.toClass(), (event) => {
      const toolBoxSwapSelector = this.jqueryCommonServices.selector(this.TOOLBOX_PARENT_CLASS.toClass().concat(',')
        .concat(this.TOOLBOX_SWAP_CLASS.toClass()));
      toolBoxSwapSelector.toggleClass(this.TOOLBOX_SWITCHED_CLASS);
      const swapSelectedState = toolBoxSwapSelector.hasClass(this.TOOLBOX_SWITCHED_CLASS);
      this.toolboxAllPanelServices.swapState = swapSelectedState;
      this.toolboxAllPanelServices.swapButtonClickEventEmitter.next(swapSelectedState);
    });
  }

  private windowResizeEventHandler() {
    this.jqueryCommonServices.selector(window).on('resize', (event) => {
      this.jqueryCommonServices.selector(this.TOOLBOX_PARENT_CLASS.toClass().concat(' ')
        .concat(this.TOOLBOX_BUTTON_CLASS.toClass())).css({'top': (this.jqueryCommonServices.selector(window).height() / 4)});
    });
  }

  private triggerWindowResize() {
    this.jqueryCommonServices.selector(window).trigger('resize');
  }
}
