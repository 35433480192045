import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../core/guards/auth.guard';
import { SharedModule } from '../../shared/shared.module';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { MediaListComponent } from './media/media-list/media-list.component';

export const routedComponents = [
  UserListComponent, UserDetailComponent
];

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/admin' },
  {path: 'users', component: UserListComponent, canLoad: [AuthGuard], canActivate: [AuthGuard]},
  {path: 'users/:id', component: UserDetailComponent, canLoad: [AuthGuard], canActivate: [AuthGuard]},
  {path: 'media', component: MediaListComponent, canLoad: [AuthGuard], canActivate: [AuthGuard]},
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AdminRoutingModule {}
