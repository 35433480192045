import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { EventsHubService } from '../../../../core/events-hub.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageService } from '../../../../core/data-services/image.service';
import { RmsFormAbstract } from '../../../../core/components/rms-form.abstract';
import { Image } from '../../../../shared/models/image.model';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { BannerNotificationsService, SpinnerService } from '../../../../xform-compat';


@Component({
  selector: 'emap-media-form',
  templateUrl: './media-form.component.html',
  styleUrls: ['./media-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaFormComponent extends RmsFormAbstract<Image> implements OnInit, OnDestroy {

  public _image: Image;
  public uploader: FileUploader;
  public currentImageCategory: string;
  @Input() imageCategory: Array<string>;
  @ViewChild('imageUploadModal', {static: true}) modal: ElementRef;

  constructor(
    private imageService: ImageService,
    spinnerService: SpinnerService,
    bannerNotificationsService: BannerNotificationsService,
    eventsHubService: EventsHubService,
    modalService: NgbModal,
    translateService: TranslateService
  ) {
    super(imageService, eventsHubService, spinnerService, bannerNotificationsService, modalService, translateService);
    this.initImageUploader();
  }

  ngOnInit() {
    super.subscribeModal(this.modal, { windowClass: 'mediaUploadModalClass' });
  }

  ngOnDestroy(): void {
    super.unsubscribeModal();
  }

  onModalOpens(isEdit: boolean, record?: Image) {
    if (record) {
      this._image = record;
    } else {
      this._image = new Image();
    }
  }

  resourceChecker(form: object): Image {
    return undefined;
  }

  updateImageCategory(event: any) {
    this.currentImageCategory = event.target.value.split(':')[1].trim().toLowerCase();
    this.imageService.currentImageCategory = this.currentImageCategory;
    this.imageService.setFileUploadPath();
  }

  private initImageUploader() {
    this.imageService.initImageUploadService();
    this.uploader = this.imageService.getImageUploader();
    this.checkImageCategoryBeforeUpload();
    this.uploadAllImageEvent();
  }

  private checkImageCategoryBeforeUpload() {
    this.uploader.onBeforeUploadItem = (item) => {
      if (!this.currentImageCategory) {
        super.getNotifications()
          .error(super.getTranslateService().instant('ERRORS.IMAGE_CATEGORY_SELECT_ERROR'));
      }
    };
  }

  private uploadAllImageEvent() {
    this.uploader.onCompleteAll = () => {
      this.closeModal();
      this.uploader.clearQueue();
      this.currentImageCategory = null;
      this.onRecordProcessed.emit(true);
    };
    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      super.getNotifications().error(JSON.parse(response).message);
    };
  }
}
