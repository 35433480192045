<div class="container-fluid admin-page">
    <div class="row">
        <div class="col-3 d-flex p-2 pl-4 text-center mind-map-header-title page-header-title">
            <span [hidden]="!mapsManagerVersionName"
                  class="mind-map-header-dynamic-name page-header-title-name">{{mapsManagerVersionName}}</span>
        </div>
        <div class="col-9 mind-map-header">
            <div [hidden]="finalized" id="mind-map-header-save" ngbTooltip="Save Map" class="mind-map-header-buttons control-icons" (click)="save()"></div>
            <div id="download-map" ngbTooltip="Download SVG" class="mind-map-header-buttons control-icons" (click)="downloadSVG()"></div>
            <div id="zoom-to-fit" ngbTooltip="Zoom To Fit" class="mind-map-header-buttons control-icons" (click)="zoomToFit(true)"></div>
            <div id="auto-save" class="auto-save-text ml-2 pl-2 pr-2"
                 [hidden]="!counterMessage"
                 ngbTooltip="Every change you make will auto save every 5 minutes" placement="top">
                {{"MINDNODE.AUTO_SAVE" | translate}} {{secsToMinSec(counterMessage) + "s"}}
            </div>
            <div class="mind-map-header-search">
                <span class="mind-map-header-search-icon mt-2 ml-2 fa fa-search"></span>
                <input #searchInput id="mind-map-header-search-input" class="mind-map-header-search-input ml-2 pl-4 pt-1"
                       type="text" placeholder="Search">
                <span *ngIf="searchResults" class="mind-map-search-count">{{ curSearchNumSelected }}/{{ searchResults.count }}</span>
                <span *ngIf="enableClearSearchButton"
                      id='mind-map-header-search-close'
                      class="mind-map-header-search-icon mt-2 ml-2 fa fa-close"
                      (click)="searchInput.value = ''; searchDiagram()"></span>
            </div>
            <div id="preview-icon" ngbTooltip="Preview On / Off" placement="left" class="mind-map-header-preview d-flex" (click)="togglePreview()">
                <span [hidden]="!previewToggleOn" class="preview-icon preview-icon-on"></span>
                <span [hidden]="previewToggleOn" class="preview-icon preview-icon-off"></span>
            </div>
            <div id="open-side-panel" ngbTooltip="Open Side Panel" placement="left" class="mind-map-header-buttons open-side-panel"
                 (click)="toggleOpenPanel()">
                <span class="open-side-panel-icon" [class.active]="isOpenPanel"></span>
            </div>
        </div>
        <div [hidden]="isProdEnv && !finalized" class="mind-map-header-alert pl-4"
             [class.archive-notify]="finalized">
            <span class="fa fa-exclamation-circle"></span>
            <span *ngIf="!finalized">
                Warning: this is a non-production environment, please do not make updates here.
            Use this <a target="_blank" href="https://emaps.racap.com">link</a> to go to eMaps production.
            </span>
            <span *ngIf="finalized">
                Warning: You are viewing the ARCHIVED version of this map.
                Please make edits on the live version.
            </span>
        </div>
    </div>
    <div class="row">
        <div class="mind-map-side-panel" *ngIf="isOpenPanel">
            <div class="container node-edits p-3">
                <div *ngIf="myDiagram.diagram.selection.count > 0">
                    {{ myDiagram.diagram.selection.count }} Node{{ myDiagram.diagram.selection.count > 1 ? 's' : '' }} Selected
                </div>
                <ngb-accordion #acc="ngbAccordion" [activeIds]="NODE_EDITS_ACTIVE_IDS">
                    <ngb-panel id="node-edits-panel-0">
                        <ng-template ngbPanelTitle>
                            <div class="node-edits-headers">
                                <span>Text Styles</span>
                                <span class="fa fa-chevron-up pl-2"></span>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="node-edits-text-style">
                                <div class="node-edits-text-style-fonts">
                                    <div class="text-style-blocks" (click)="nodeGroupEdit('increase-font-size')">
                                        <span class="text-style-icons icon-font-size"></span>
                                        <span class="text-style-icon-text">Increase Font Size</span>
                                    </div>
                                    <div class="text-style-blocks" (click)="nodeGroupEdit('decrease-font-size')">
                                        <span class="text-style-icons icon-font-size"></span>
                                        <span class="text-style-icon-text">Decrease Font Size</span>
                                    </div>
                                    <div class="text-style-blocks" (click)="nodeGroupEdit('bold-font')" [ngClass]="{ 'active': getSelectedNodesProp('font', 'TEXT')?.includes('bold') }">
                                        <span class="text-style-icons icon-bold"></span>
                                        <span class="text-style-icon-text">Bold</span>
                                    </div>
                                    <div class="text-style-blocks" (click)="nodeGroupEdit('underline-font')" [ngClass]="{ 'active': getSelectedNodesProp('isUnderline', 'TEXT') }">
                                        <span class="text-style-icons icon-underline"></span>
                                        <span class="text-style-icon-text">Underline</span>
                                    </div>
                                    <div class="text-style-blocks" (click)="nodeGroupEdit('strikethrough-font')" [ngClass]="{ 'active': getSelectedNodesProp('isStrikethrough', 'TEXT') }">
                                        <span class="text-style-icons icon-strikethrough"></span>
                                        <span class="text-style-icon-text">Strikethrough</span>
                                    </div>
                                </div>
                                <div class="break-line"></div>
                                <div class="color-panel">
                                    <div class="color-block" *ngFor="let color of COLORS_LIST | keyvalue" [ngClass]="{ 'active': getSelectedNodesProp('stroke', 'TEXT') === color.value }"
                                         (click)="nodeGroupEdit('colorChange', 'text-stroke', color.value)">
                                        <div class="color-icons" [ngClass]="'color-icon-' + color.key"></div>
                                        <div class="color-text">{{color.key}}</div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="node-edits-panel-1">
                        <ng-template ngbPanelTitle>
                            <div class="node-edits-headers">
                                <span>Node Styles</span>
                                <span class="fa fa-chevron-up pl-2"></span>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="node-edits-node-style">
                                <div class="node-block node-width">
                                    <span class="node-text">Node Width</span>
                                    <div class="text-input">
                                        <input id="width-change"
                                               [value]="getSelectedNodesProp('width') || ''"
                                               (keyup)="nodeGroupEdit('width-change', '', $event.target.value)"
                                               placeholder="Width in pixels">
                                    </div>
                                </div>
                                <div class="break-line"></div>
                                <div class="node-block node-outline">
                                    <span class="node-text">Outline Color</span>
                                    <div class="color-panel">
                                        <div class="color-block" *ngFor="let color of COLORS_LIST | keyvalue" [ngClass]="{ 'active': getSelectedNodesProp('nodeStroke') === color.value }"
                                             (click)="nodeGroupEdit('colorChange', 'stroke', color.value)">
                                            <div class="color-icons" [ngClass]="'color-icon-' + color.key"></div>
                                            <div class="color-text">{{color.key}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="break-line"></div>
                                <div class="node-block node-background">
                                    <span class="node-text">Background Color</span>
                                    <div class="color-panel">
                                        <div class="color-block" *ngFor="let color of COLORS_LIST | keyvalue" [ngClass]="{ 'active': getSelectedNodesProp('nodeBackground') === color.value }"
                                             (click)="nodeGroupEdit('colorChange', 'background', color.value)">
                                            <div class="color-icons" [ngClass]="'color-icon-' + color.key"></div>
                                            <div class="color-text">{{color.key}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>

        <gojs-overview
                [divClassName]="'mind-map-overview'"
                [initOverview]="initOverview"
                [observedDiagram]="observedDiagram"
                [hidden]="!previewToggleOn">
        </gojs-overview>

        <!--   Diagram Element    -->
        <div class="position-relative w-100 h-100">
            <gojs-diagram
                id="mind-map-diagram"
                #diagramEle
                [divClassName]="'mind-map-diagram'"
                [initDiagram]="initDiagram"
                [nodeDataArray]="diagramState.nodeList"
                [skipsDiagramUpdate]="diagramState.skipsDiagramUpdate"
                (modelChange)="modelChangeHandler($event)">
            </gojs-diagram>

            <div class="mind-map-context-menu-parent">
                <ul id="mind-map-context-menu" class="menu">
                    <div class="menu-item-header">Actions</div>
                    <li id="new-node" class="menu-item" (pointerdown)="cxcommand($event)">
                        <span class="menu-item-icon new-node-icon"></span>New Node
                    </li>
                    <li id="new-end-node" class="menu-item" (pointerdown)="cxcommand($event)">
                        <span class="menu-item-icon new-node-icon"></span>New End Node
                    </li>
                    <li id="cp-builder" class="menu-item" (pointerdown)="cxcommand($event)">
                        <span class="menu-item-icon end-node-icon-builder"></span>Comp/Product Builder
                    </li>
                    <li id="milestone-builder" class="menu-item" (pointerdown)="cxcommand($event)">
                        <span class="menu-item-icon end-node-icon-builder"></span>Milestone Builder
                    </li>
                    <li id="copy" class="menu-item" (pointerdown)="cxcommand($event)">
                        <span class="menu-item-icon end-node-icon-copy-milestone"></span>Copy
                    </li>
                    <li id="paste" class="menu-item" (pointerdown)="cxcommand($event)">
                        <span class="menu-item-icon node-action-icon-paste"></span>Paste
                    </li>
                    <li id="undo" class="menu-item" (pointerdown)="cxcommand($event)">
                        <span class="menu-item-icon undo-icon"></span>Undo {{ myDiagram.diagram?.model?.undoManager?.transactionToUndo?.name }}
                    </li>
                    <li id="redo" class="menu-item" (pointerdown)="cxcommand($event)">
                        <span class="menu-item-icon redo-icon"></span>Redo {{ myDiagram.diagram?.model?.undoManager?.transactionToRedo?.name }}
                    </li>

                    <div id="styling-header" class="menu-item-header">
                        <div class="menu-item-line-separator"></div>
                        Format/Styling
                    </div>
                    <li id="node-styling" class="menu-item"
                        (mouseenter)="toggleSubMenu(nodeStyling, true)"
                        (mouseleave)="toggleSubMenu(nodeStyling, false)">
                        <span class="menu-item-icon node-styling-icon"></span>
                        Node Styling
                        <span class="menu-item-right-icon fa fa-angle-right"></span>
                        <ul class="menu" #nodeStyling>
                            <div class="menu-item-header">Node Border</div>
                            <li class="menu-item" (pointerdown)="cxcommand($event, 'node-style-stroke', '#FA0606')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-red"></span>Red
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-stroke', '#7706FA')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-purple"></span>Purple
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-stroke', '#0042BE')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-blue"></span>Blue
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-stroke', '#498800')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-green"></span>Green
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-stroke', '#F4DA17')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-yellow"></span>Yellow
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-stroke', '#EB8444')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-orange"></span>Orange
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-stroke', '#000000')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-black"></span>Black
                            </li>
                            <div class="menu-item-line-separator"></div>
                            <div class="menu-item-header">Node Background</div>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-background', '#ffff')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-white"></span>White
                            </li>
                            <li class="menu-item" (pointerdown)="cxcommand($event, 'node-style-background', '#FA0606')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-red"></span>Red
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-background', '#7706FA')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-purple"></span>Purple
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-background', '#0042BE')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-blue"></span>Blue
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-background', '#498800')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-green"></span>Green
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-background', '#F4DA17')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-yellow"></span>Yellow
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-background', '#EB8444')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-orange"></span>Orange
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'node-style-background', '#000000')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-black"></span>Black
                            </li>
                        </ul>
                    </li>
                    <li id="node-actions" class="menu-item"
                        (mouseenter)="toggleSubMenu(nodeActions, true)"
                        (mouseleave)="toggleSubMenu(nodeActions, false)">
                        <span class="menu-item-icon node-actions-icon"></span>
                        Node Actions
                        <span class="menu-item-right-icon fa fa-angle-right"></span>
                        <ul class="menu" #nodeActions>
                            <div class="menu-item-header">Node Actions</div>
                            <li id='node-actions-delete' class="menu-item" (pointerdown)="cxcommand($event)">
                                <span class="menu-item-icon node-action-icon-delete"></span>Delete
                            </li>
                            <div class="menu-item-line-separator"></div>
                            <li id='node-actions-add-image' class="menu-item" (pointerdown)="cxcommand($event)">
                                <span class="menu-item-icon node-action-icon-add-image"></span>Add Image
                            </li>
                        </ul>
                    </li>
                    <li id="text-styling" class="menu-item" (pointerdown)="cxcommand($event)"
                        (mouseenter)="toggleSubMenu(textStyling, true)"
                        (mouseleave)="toggleSubMenu(textStyling, false)">
                        <span class="menu-item-icon text-styling-icon"></span>
                        Text Styling
                        <span class="menu-item-right-icon fa fa-angle-right"></span>
                        <ul class="menu" #textStyling>
                            <div class="menu-item-header">Text Styling</div>
                            <li id="text-style-bigger" class="menu-item" (pointerdown)="cxcommand($event)">
                                <span class="menu-item-icon text-styling-icon-font-size"></span>Increase Font Size
                            </li>
                            <li id="text-style-smaller" class="menu-item" (pointerdown)="cxcommand($event)">
                                <span class="menu-item-icon text-styling-icon-font-size"></span>Decrease Font Size
                            </li>
                            <li id="text-style-bold-or-normal" class="menu-item" (pointerdown)="cxcommand($event)">
                                <span class="menu-item-icon text-styling-icon-bold"></span>Bold
                            </li>
                            <li id="text-style-underline" class="menu-item" (pointerdown)="cxcommand($event)">
                                <span class="menu-item-icon text-styling-icon-underline"></span>Underline
                            </li>
                            <li id="text-style-strikethrough" class="menu-item" (pointerdown)="cxcommand($event)">
                                <span class="menu-item-icon text-styling-icon-strikethrough"></span>Strikethrough
                            </li>
                            <li class="menu-item" (pointerdown)="cxcommand($event, 'text-style-stroke', 'default')">
                                <span class="menu-item-icon menu-color-icon"></span>Default
                            </li>
                            <li class="menu-item" (pointerdown)="cxcommand($event, 'text-style-stroke', '#FA0606')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-red"></span>Red
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'text-style-stroke', '#7706FA')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-purple"></span>Purple
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'text-style-stroke', '#0042BE')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-blue"></span>Blue
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'text-style-stroke', '#498800')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-green"></span>Green
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'text-style-stroke', '#F4DA17')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-yellow"></span>Yellow
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'text-style-stroke', '#EB8444')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-orange"></span>Orange
                            </li>
                            <li class="menu-item"
                                (pointerdown)="cxcommand($event, 'text-style-stroke', '#000000')">
                                <span class="menu-item-icon menu-color-icon menu-color-icon-black"></span>Black
                            </li>
                        </ul>
                    </li>
                    <li class="menu-item" id="layout"
                        (mouseenter)="toggleSubMenu(layout, true)"
                        (mouseleave)="toggleSubMenu(layout, false)">
                        <span class="menu-item-icon node-layout-icon"></span>
                        Layout
                        <span class="menu-item-right-icon fa fa-angle-right"></span>
                        <ul class="menu" #layout>
                            <li id="forward-layout" class="menu-item" (pointerdown)="cxcommand($event)">
                                <span class="menu-item-icon node-layout-icon-forward"></span>Forward
                            </li>
                            <li id="reverse-layout" class="menu-item" (pointerdown)="cxcommand($event)">
                                <span class="menu-item-icon node-layout-icon-reverse"></span>Reverse
                            </li>
                        </ul>
                    </li>
                    <li id="all-layout" class="menu-item" (pointerdown)="cxcommand($event)">
                        <span class="menu-item-icon node-layout-icon"></span>Auto-Layout
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<ng-template #imageUploadModal let-c="close" let-d="dismiss">
    <form #imageUploadForm="ngForm" [class.submitted]="imageUploadForm.submitted"
          (ngSubmit)="processRecord(imageUploadForm)" novalidate>
        <div class="modal-header">
            <h4 class="modal-title">{{"MEDIA.ADD_IMAGE" | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="d()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <div class="form-group">
                        <label for="imageFileUpload">{{"MEDIA.SELECT_IMAGE_FILES" | translate}}:
                            <span class="error" *ngIf="imageUploadForm.submitted">
                              <span *ngIf="uploader.queue[0] == null">{{"ERRORS.IS_REQUIRED" | translate}}</span>
                          </span>
                        </label>
                        <input id="imageFileUpload" name="imageFileUpload" type="file" ng2FileSelect
                               [uploader]="uploader" multiple #imageFileUpload accept="image/png">
                    </div>
                </div>
                <div class="col-xs-12 col-md-12">
                    <table class="table">
                        <thead>
                        <tr>
                            <th width="40%">Name</th>
                            <th>Size</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of uploader.queue">
                            <td><strong>{{ item?.file?.name }}</strong></td>
                            <td *ngIf="uploader.options.isHTML5"
                                nowrap>{{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB
                            </td>
                            <td class="text-center">
                                <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                                <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
                                <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                            </td>
                            <td nowrap>
                                <button type="button" class="btn btn-success btn-xs"
                                        (click)="item.upload()"
                                        [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                    <span class="glyphicon glyphicon-upload"></span> Upload
                                </button>
                                <button type="button" class="btn btn-warning btn-xs"
                                        (click)="item.cancel()" [disabled]="!item.isUploading">
                                    <span class="glyphicon glyphicon-ban-circle"></span> Cancel
                                </button>
                                <button type="button" class="btn btn-danger btn-xs"
                                        (click)="item.remove()">
                                    <span class="glyphicon glyphicon-trash"></span> Remove
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div> Queue progress:
                        <div class="progress" style="">
                            <div class="progress-bar" role="progressbar"
                                 [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                        </div>
                    </div>
                    <br>
                    <button type="button" class="btn btn-success btn-s"
                            (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
                        <span class="glyphicon glyphicon-upload"></span> Upload all
                    </button>
                    <button type="button" class="btn btn-warning btn-s"
                            (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
                        <span class="glyphicon glyphicon-ban-circle"></span> Cancel all
                    </button>
                    <button type="button" class="btn btn-danger btn-s"
                            (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                        <span class="glyphicon glyphicon-trash"></span> Remove all
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="c()">{{"GENERICS.CLOSE" | translate}}</button>
        </div>
    </form>
</ng-template>
