import { BaseEntity } from '../../xform-compat';
import { BasicUser } from './basicUser.model';
import { UserTenant } from './userTenant.model';
import { UserAttributes } from './interfaces/userAttributes.interface';

export class RmsUser implements BaseEntity {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  emailAddress: string;
  superUser: boolean;
  attributes: UserAttributes;
  createdBy: BasicUser;
  userTenants: Array<UserTenant>;
  created: string;
  updated: string;
  active: boolean;
  confirmed: boolean;
  profileImage: string;
  profileImageBase64: string;
  twoFactorRequired: boolean;
  isLead: boolean;
  maxSessions: number;
  phone: string;
  resetPasswordInfo?: any;

  constructor(user: any = {}) {
    this.id = user.id || null;
    this.firstName = user.firstName || null;
    this.lastName = user.lastName || null;
    this.fullName = user.fullName || null;
    this.emailAddress = user.emailAddress || null;
    this.superUser = user.superUser || false;
    this.attributes = user.attributes ? user.attributes : {};
    this.createdBy = user.createdBy || null;
    this.userTenants = user.userTenants ? user.userTenants : [];
    this.created = user.created || null;
    this.updated = user.updated || null;
    this.active = user.active || null;
    this.confirmed = user.confirmed || null;
    this.profileImage = user.profileImage || null;
    this.profileImageBase64 = null;
    this.twoFactorRequired = user.twoFactorRequired || false;
    this.isLead = user.isLead || null;
    this.maxSessions = user.maxSessions || null;
    this.phone = user.phone || null;
    this.resetPasswordInfo = user.resetPasswordInfo || null;
  }

  public setUserTenants(userTenants: Array<UserTenant>) {
    this.userTenants = userTenants;
  }

  public setSuperUser(isSuperUser: boolean) {
    this.superUser = isSuperUser;
  }

  public getDisplayName() {
    return this.fullName;
  }
}
