import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {RmsFormAbstract} from '../../../core/components/rms-form.abstract';
import {EventsHubService} from '../../../core/events-hub.service';
import {NgbModal, NgbTabChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {CONFIG} from '../../../config';
import {MapsManagerVersion} from '../../../shared/models/mapsManagerVersion.model';
import {MapsManagerVersionService} from '../../../core/data-services/mapsManagerVersion.service';
import {BundleService} from '../../../core/data-services/bundle.service';
import {MapsManagerService} from '../../../core/data-services/mapsManager.service';
import {MapsManager} from '../../../shared/models/mapsManager.model';
import {BannerNotificationsService, SpinnerService} from '../../../xform-compat';
import {MindMapVersionNodeService} from '../../../core/data-services/mindMapVersionNode.service';


@Component({
  selector: 'emap-maps-manager-form',
  templateUrl: './maps-manager-form.component.html',
  styleUrls: ['./maps-manager-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapsManagerFormComponent extends RmsFormAbstract<MapsManagerVersion> implements OnInit, OnDestroy {

  @ViewChild('mapsManagerModal', {static: true}) mapsManagerModal: ElementRef;
  @ViewChild('mindNodeFileCheckValidationModal', {static: true}) mindNodeFileCheckValidationModal: ElementRef;

  public currentMapsManagerSelection: string;
  public currentTabSelected = 'upload';
  public mapsManagerNameExists = false;
  public mapsManagerVersionNameExists = false;
  public mapsManagerNameList: Array<string>;
  public mapsManagerVersionNameSet: Set<string>;
  public mapsManagerVersion: MapsManagerVersion;
  public mapsManagerValidationModalReference: any;
  public uploader: any;
  public currentBundleFileName: any;
  public mindNodeFileExistsCheck = false;
  private bundleSettings = CONFIG.bundleSettings;
  private mapsManagerData: MapsManager = new MapsManager();

  constructor(
    private mapsManagerService: MapsManagerService,
    private mapsManagerVersionService: MapsManagerVersionService,
    private mindMapVersionNodeService: MindMapVersionNodeService,
    spinnerService: SpinnerService,
    bannerNotificationsService: BannerNotificationsService,
    eventsHubService: EventsHubService,
    modalService: NgbModal,
    translateService: TranslateService,
    private bundleService: BundleService,
    private mapModalService: NgbModal
  ) {
    super(mapsManagerVersionService, eventsHubService, spinnerService, bannerNotificationsService, modalService, translateService);
  }

  ngOnInit() {
    this.purgeLocalCache();
    this.getMapNames();
    super.subscribeModal(this.mapsManagerModal);
    this.bundleService.initBundleUploadService();
    this.uploader = this.bundleService.getBundleUploader();
    this.bundleUploadHandler();
  }

  ngOnDestroy(): void {
    this.unsubscribeModal();
  }

  public getMapNames() {
    this.mapsManagerService.getNames().subscribe(value => {
      this.mapsManagerNameList = value;
    });
    this.mapsManagerVersionService.getNames().subscribe((value) => {
      this.mapsManagerVersionNameSet = new Set(value);
    });
  }

  onModalOpens(isEdit: boolean, record: MapsManagerVersion | undefined) {
    this.mapsManagerVersion = record ? record : new MapsManagerVersion();
  }

  public addNewMapsManagerName = (mapsManagerName: string) => {
    this.mapsManagerData.name = mapsManagerName;
    this.mapsManagerNameExists = this.mapsManagerNameList.includes(mapsManagerName);
    this.mapsManagerNameList = [...this.mapsManagerNameList, mapsManagerName];
  }

  public validateVersionName(mapsManagerVersionName: any) {
    this.mapsManagerVersionNameExists = this.mapsManagerVersionNameSet.has(mapsManagerVersionName);
  }

  public validateMapName(mapsManagerName: any) {
    this.mapsManagerNameExists = this.mapsManagerNameList.includes(mapsManagerName);
  }

  public clickSubmitForm(form: any) {
    if (this.currentTabSelected === 'upload') {
      this.uploadExistingMapsManager(form);
    } else if (this.currentTabSelected === 'create') {
      this.createNewMapsManager(form);
    }
  }

  public createNewMapsManager(form: any) {
    if (!form.valid ||
      this.mapsManagerNameExists ||
      this.mapsManagerVersionNameExists) {
      return;
    }
    this.addNewMapsManagerName(this.currentMapsManagerSelection);
    this.mapsManagerService
      .saveMapsManager(this.mapsManagerData)
      .subscribe((mapsManagerData: any) => {
        this.mapsManagerVersion.mapsManagerId = mapsManagerData.id;
        this.mapsManagerVersion.interactive = true;
        this.mapsManagerVersionService.saveMapsManagerVersion(this.mapsManagerVersion)
          .subscribe(mapsManagerVersionData => {
            this.mindMapVersionNodeService.createNewMindMapVersionNode(mapsManagerVersionData.id)
              .subscribe(value => {
                super.getNotifications().success('MAPS_MANAGER_CREATED');
                this.closeModal();
                this.currentMapsManagerSelection = null;
                this.onRecordProcessed.emit(true);
              }, error => {
                super.getNotifications().error(error);
              });
          }, error => {
            super.getNotifications().error(error);
          });
      }, error => {
        super.getNotifications().error(error);
      });
  }

  public uploadExistingMapsManager(form: any) {
    if (
      !form.valid ||
      this.uploader.queue.length === 0 ||
      this.currentMapsManagerSelection == null ||
      this.mapsManagerVersionNameExists ||
      this.mapsManagerNameExists
    ) {
      return;
    }
    super.getSpinner().start();
    this.currentBundleFileName = this.uploader.queue[0].file.name;
    this.bundleService.startUpload();
  }

  public bundleUploadHandler() {
    const parentThis = this;
    this.bundleService.uploadCompleteCallback((fileList) => {
      super.getSpinner().stop();
      this.mindNodeFileExistsCheck = this.mindNodeFileExists(fileList);
      parentThis.closeModal();
      this.mapsManagerValidationModalReference = this.mapModalService.open(this.mindNodeFileCheckValidationModal);
    });
    this.bundleService.uploadErrorCallback(() => {
      parentThis.bundleService.bundleUploadErrorHandler();
      super.getSpinner().stop();
    });
  }

  public createBundle() {
    this.getSpinner().start();
    this.handleMapName();
    this.mapsManagerService.saveMapsManager(this.mapsManagerData)
      .subscribe(mapsManagerData => {
        this.mapsManagerVersion.mapsManagerId = mapsManagerData.id;
        this.mapsManagerVersion.interactive = this.mindNodeFileExistsCheck;
        this.mapsManagerVersionService.saveMapsManagerVersion(this.mapsManagerVersion)
          .subscribe(
            (mapsManagerVersionData: any) => {
              const bundleDetails = {
                bundleFileName: this.currentBundleFileName,
                mapsManagerVersionId: mapsManagerVersionData.id
              };
              if (this.mapsManagerVersion.interactive) {
                this.bundleService.saveMindMapVersionNode(bundleDetails)
                  .subscribe(mapsManagerVersion1 => {
                    this.bundleService.saveImageAndMapVersionNode(bundleDetails)
                      .subscribe(mapsManagerVersion2 => {
                        this.closeMindNodeValidationModal();
                        this.onRecordProcessed.emit(true);
                        this.getSpinner().stop();
                      }, error => this.handleError(error));
                  }, error => this.handleError(error));
              } else {
                this.bundleService.saveImageAndMapVersionNode(bundleDetails)
                  .subscribe(mapsManagerVersion2 => {
                    this.closeMindNodeValidationModal();
                    this.onRecordProcessed.emit(true);
                    this.getSpinner().stop();
                  }, error => this.handleError(error));
              }
            }, error => this.handleError(error));
      }, error => this.handleError(error));
  }

  public closeModal() {
    this.uploader.clearQueue();
    super.getSpinner().stop();
    super.closeModal();
  }

  public closeMindNodeValidationModal() {
    this.mapsManagerValidationModalReference.close();
  }

  private purgeLocalCache() {
    this.mapsManagerNameList = [];
    this.mapsManagerVersionNameSet = new Set<string>();
    this.currentMapsManagerSelection = null;
  }

  private mindNodeFileExists(filesList: Array<string>) {
    let mindNodePresent = false;
    filesList.forEach(eachFile => {
      if (eachFile.split('.')[1] === this.bundleSettings.fileExtension) {
        mindNodePresent = true;
      }
    });
    return mindNodePresent;
  }

  private handleMapName() {
    if (!this.mapsManagerData.name && this.currentMapsManagerSelection) {
      this.mapsManagerData.name = this.currentMapsManagerSelection;
    }
  }

  private handleError(error) {
    this.getSpinner().stop();
    super.getNotifications().error(error);
  }

  tabChange($event: NgbTabChangeEvent) {
    if ($event.nextId === 'create-new-map-tab') {
      this.currentTabSelected = 'create';
    } else if ($event.nextId === 'upload-map-tab') {
      this.currentTabSelected = 'upload';
    }
  }

  resourceChecker(form: object): MapsManagerVersion {
    return undefined;
  }
}
