import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PasswordResetService } from '../../../core/data-services/passwordReset.service';
import { BannerNotificationsService, SpinnerService } from '../../../xform-compat';


@Component({
  selector: 'xform-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  constructor(
    private spinnerService: SpinnerService,
    private passwordResetService: PasswordResetService,
    private bannerNotificationsService: BannerNotificationsService,
    private translateService: TranslateService
  ) {}

  public onSubmit(form: any) {
    this.spinnerService.start();

    if (form.invalid) {
      this.spinnerService.stop();
      return false;
    }

    this.passwordResetService.forgotPassword(form.value)
      .subscribe(
        () => {
          this.spinnerService.stop();
          this.bannerNotificationsService.success(this.translateService.instant('MESSAGES.EMAIL_SENT'));
        },
        (error) => {
          this.spinnerService.stop();
          this.bannerNotificationsService.error(error.data.message);
        }
      );
  }

}
