import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CONFIG } from '../../config';
import { Map } from '../../shared/models/map.model';
import { FileUploadService } from '../services/fileUpload.service';
import { AuthenticationService } from '../services/authentication.service';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { TranslateService } from '@ngx-translate/core';
import { AbstractCRUD, BannerNotificationsService, CRUDService, ExceptionService, UtilsService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BundleService extends AbstractCRUD<Map> implements CRUDService<Map> {

  private fileUploader: FileUploadService;
  private authenticationService: AuthenticationService;
  private bannerNotificationsService: BannerNotificationsService;
  private translateService: TranslateService;

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService,
    fileUploaderService: FileUploadService,
    authenticationService: AuthenticationService,
    bannerNotificationsService: BannerNotificationsService,
    translateService: TranslateService
  ) {
    super(exceptionService, http, utilsService);
    this.fileUploader = fileUploaderService;
    this.authenticationService = authenticationService;
    this.bannerNotificationsService = bannerNotificationsService;
    this.translateService = translateService;
  }

  public getResourcePath() {
    return '/bundle/service';
  }

  private getBundleUploadPath() {
    return super.getApiPath().concat('/getFilesList');
  }

  private saveMindMapVersionNodePath() {
    return super.getApiPath().concat('/saveMindMapVersionNode');
  }

  public saveMindMapVersionNode(body: any) {
    return super.getHTTP().post(this.saveMindMapVersionNodePath(), body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  private saveImageAndMapVersionNodePath() {
    return super.getApiPath().concat('/saveImageAndMapVersionNode');
  }

  public saveImageAndMapVersionNode(body: any) {
    return super.getHTTP().post(this.saveImageAndMapVersionNodePath(), body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public getBundleUploader(): FileUploader {
    return this.fileUploader.uploader;
  }

  private setBundleUploadUrl(): void {
    this.fileUploader.uploader.options.url = this.getBundleUploadPath();
  }

  private setAuthTokenHeader(): void {
    this.fileUploader.uploader.authTokenHeader = CONFIG.authHeader;
    this.fileUploader.uploader.authToken = CONFIG.authPreamble + this.authenticationService.getTokenInfo().accessToken;
  }

  private fileUploadErrorHandler(): void {
    const parentThis = this;
    this.fileUploader.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any) => {
      if (!((filter.name === 'mimeType') && (parentThis.isBundleFile(item.name)))) {
        const errorMsg = 'ERRORS.BUNDLE_FILE_EXTENSION';
        const params = { type: item.type };
        this.bannerNotificationsService.error(this.translateService.instant(errorMsg, params));
        this.fileUploader.uploader.clearQueue();
      }
    };
  }

  public startUpload() {
    const errorMsg = 'ERRORS.NO_BUNDLE_FILE';
    if (this.fileUploader.uploader.queue.length > 0) {
      this.fileUploader.uploader.queue[0].upload();
    }else {
      this.bannerNotificationsService.error(this.translateService.instant(errorMsg));
    }
  }

  public uploadCompleteCallback(callback) {
    const parentThis = this;
    this.fileUploader.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (item.isSuccess && status === 200) {
        if (typeof callback === 'function') {
          callback(JSON.parse(response));
        }
      }else {
        parentThis.bundleUploadErrorHandler();
      }
    };
  }

  public uploadErrorCallback(callback) {
    this.fileUploader.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
      if (typeof callback === 'function') {
        callback();
      }
    };
  }

  private isBundleFile(fileName: String): boolean {
    return fileName.split('.')[1] === CONFIG.emapBundleFileExtension;
  }

  public bundleUploadErrorHandler() {
    const errorMsg = 'ERRORS.BUNDLE_UPLOAD_ERROR';
    this.bannerNotificationsService.error(this.translateService.instant(errorMsg));
  }

  public initBundleUploadService() {
    this.setBundleUploadUrl();
    this.setAuthTokenHeader();
    this.fileUploadErrorHandler();
  }

  private getRequest(apiUrl: string, httpParams?: HttpParams) {
    return this.getHTTP().get(apiUrl, { params: httpParams })
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }
}
