import {
  Component, ComponentFactoryResolver,
  ComponentRef,
  Input, OnDestroy,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {NodesManagerFilter, ProductData} from "../nodes-manager-list/nodes-manager-list.component";
import {PRODUCT_CATEGORY} from "../../../shared/enums";
import {Sort} from '@angular/material/sort';
import {NodesManagerFilterComponent} from "../nodes-manager-filter/nodes-manager-filter.component";

@Component({
  selector: 'emap-nodes-manager-products',
  templateUrl: './nodes-manager-products.component.html',
  styleUrls: ['./nodes-manager-products.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NodesManagerProductsComponent implements OnInit, OnDestroy {

  @Input() productDataList: Array<ProductData>;

  public columnHeaderList: Array<string> = Object.keys(PRODUCT_CATEGORY).map(key => PRODUCT_CATEGORY[key]);
  public filterMapActive: Map<string, Array<string>> = new Map<string, Array<string>>();
  private filterMap: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
  private filterComponentInstance: ComponentRef<NodesManagerFilterComponent> = null;
  private currentFilterColumn: string;

  constructor(
      private viewContainerRef: ViewContainerRef,
      private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit() {
    this.initializeFilterMap();
  }

  ngOnDestroy() {
    if (this.filterComponentInstance) {
      this.filterComponentInstance.destroy();
    }
  }

  public getCategoryValue(productData: ProductData, columnHeader: string) {
    if (columnHeader === 'RoA') {
      columnHeader = 'Route of Administration';
    }
    return productData.productCategoryMap.has(columnHeader) ? productData.productCategoryMap.get(columnHeader).join(', ') : '';
  }

  public shouldShowManualInputIcon(productData: ProductData, columnHeader: string) {
    return (columnHeader === 'RoA' || columnHeader === 'Route of Administration') && productData.manualRoA;
  }

  public filterData(event: Event, elementId: string) {
    event.stopPropagation();
    if (this.filterComponentInstance) {
      this.filterComponentInstance.destroy();
      this.filterComponentInstance = null;
      if (this.currentFilterColumn !== elementId) {
        this.openFilterPanel(elementId);
      }
    } else {
      this.openFilterPanel(elementId);
    }
  }

  private openFilterPanel(elementId: string) {
    const element: HTMLElement = document.getElementById(elementId);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NodesManagerFilterComponent);
    this.filterComponentInstance = this.viewContainerRef.createComponent(componentFactory);
    this.filterComponentInstance.instance.filterMap = this.filterMap.get(elementId);
    this.filterComponentInstance.instance.close.subscribe(() => {
      this.filterComponentInstance.destroy();
      this.filterComponentInstance = null;
    })
    const filterComponentElement: HTMLElement = this.filterComponentInstance.location.nativeElement;
    filterComponentElement.className = 'filter-panel';
    filterComponentElement.style.left = String(element.offsetLeft) + 'px';
    filterComponentElement.style.top = String(element.offsetTop + 44) + 'px';
    element.parentElement.parentElement.parentElement.append(filterComponentElement);
    this.currentFilterColumn = elementId;
  }

  public sortData(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }
    const direction = sort.direction === 'asc' ? 1 : -1;
    switch (sort.active) {
      case 'companyName':
        this.productDataList.sort((a, b) => {
          if (a.companyName === '') return 1 * direction;
          if (b.companyName === '') return -1 * direction;
          if (a.companyName.toUpperCase() < b.companyName.toUpperCase()) return -1 * direction;
          if (a.companyName.toUpperCase() > b.companyName.toUpperCase()) return 1 * direction;
          return 0;
        });
        break;
      case 'productName':
        this.productDataList.sort((a, b) => {
          if (a.productName === '') return 1 * direction;
          if (b.productName === '') return -1 * direction;
          if (a.productName.toUpperCase() < b.productName.toUpperCase()) return -1 * direction;
          if (a.productName.toUpperCase() > b.productName.toUpperCase()) return 1 * direction;
          return 0;
        });
        break;
      default:
        this.productDataList.sort((a, b) => {
          if (this.getCategoryValue(a, sort.active) === '') return 1 * direction;
          if (this.getCategoryValue(b, sort.active) === '') return -1 * direction;
          if (this.getCategoryValue(a, sort.active) < this.getCategoryValue(b, sort.active)) return -1 * direction;
          if (this.getCategoryValue(a, sort.active) > this.getCategoryValue(b, sort.active)) return 1 * direction;
          return 0;
        });
    }
  }

  public getProductDataList() {
    const productData: Array<ProductData> = new Array<ProductData>();
    this.setFilterMapActive();
    if (this.activeFilters()) {
      this.productDataList.forEach(value => {
        if (this.includeProductData(value)) {
          productData.push(value);
        }
      });
      return productData;
    } else {
      return this.productDataList;
    }
  }

  private includeProductData(productData: ProductData) {
    if (!(this.filterMapActive.get('Company').length === 0 ||
        this.filterMapActive.get('Company').includes(productData.companyName))) {
      return false;
    }
    if (!(this.filterMapActive.get('Product').length === 0 ||
        this.filterMapActive.get('Product').includes(productData.productName))) {
      return false;
    }
    if (!(this.filterMapActive.get('Modality').length === 0 || (productData.productCategoryMap.has('Modality') &&
        productData.productCategoryMap.get('Modality').some(r => this.filterMapActive.get('Modality').includes(r))))) {
      return false;
    }
    if (!(this.filterMapActive.get('Target').length === 0 || (productData.productCategoryMap.has('Target') &&
        productData.productCategoryMap.get('Target').some(r => this.filterMapActive.get('Target').includes(r))))) {
      return false;
    }
    if (!(this.filterMapActive.get('Class').length === 0 || (productData.productCategoryMap.has('Class') &&
        productData.productCategoryMap.get('Class').some(r => this.filterMapActive.get('Class').includes(r))))) {
      return false;
    }
    if (!(this.filterMapActive.get('RoA').length === 0 || (productData.productCategoryMap.has('Route of Administration') &&
        productData.productCategoryMap.get('Route of Administration').some(r => this.filterMapActive.get('RoA').includes(r))))) {
      return false;
    }
    return this.filterMapActive.get('Dosing').length === 0 || (productData.productCategoryMap.has('Dosing') &&
        productData.productCategoryMap.get('Dosing').some(r => this.filterMapActive.get('Dosing').includes(r)));
  }

  private setFilterMapActive() {
    this.filterMapActive = new Map<string, Array<string>>();
    this.filterMapActive.set('Company', new Array<string>());
    this.filterMapActive.set('Product', new Array<string>());
    this.columnHeaderList.forEach(columnHeader => this.filterMapActive.set(columnHeader, new Array<string>()));
    for (const [key1, value1] of this.filterMap.entries()) {
      for (const [key2, value2] of value1.entries()) {
        if (value2) {
          this.filterMapActive.get(key1).push(key2);
        }
      }
    }
  }

  private activeFilters(): boolean {
    if (this.filterMapActive) {
      for (const value of this.filterMapActive.values()) {
        if (value.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  public getFilterList() {
    const filterList = new Array<NodesManagerFilter>();
    this.filterMapActive.get('Company').forEach(filter => filterList.push({category: 'Company', value: filter}));
    this.filterMapActive.get('Product').forEach(filter => filterList.push({category: 'Product', value: filter}));
    this.columnHeaderList.forEach(columnHeader => {
      this.filterMapActive.get(columnHeader).forEach(filter => filterList.push({category: columnHeader, value: filter}));
    });
    return filterList;
  }

  public removeFilter(filter: NodesManagerFilter) {
    this.filterMap.get(filter.category).set(filter.value, false);
  }

  private initializeFilterMap() {
    this.filterMap = new Map<string, Map<string, boolean>>();
    this.filterMap.set('Company', new Map<string, boolean>());
    this.filterMap.set('Product', new Map<string, boolean>());
    this.columnHeaderList.forEach(columnHeader => this.filterMap.set(columnHeader, new Map<string, boolean>()));
    this.productDataList.forEach(productData => {
      this.filterMap.get('Company').set(productData.companyName, false);
      this.filterMap.get('Product').set(productData.productName, false);
      this.columnHeaderList.forEach(columnHeader => {
        if (productData.productCategoryMap.has(columnHeader === 'RoA' ? 'Route of Administration' : columnHeader)) {
          productData.productCategoryMap.get(columnHeader === 'RoA' ? 'Route of Administration' : columnHeader).forEach(value => {
            this.filterMap.get(columnHeader).set(value, false);
          });
        }
      });
    });
  }

    protected readonly PRODUCT_CATEGORY = PRODUCT_CATEGORY;
}
