<ng-template #userModal let-c="close" let-d="dismiss">
  <form #userForm="ngForm" [class.submitted]="userForm.submitted"
        (ngSubmit)="processRecord(userForm)" novalidate>
    <div class="modal-header">
      <h4 class="modal-title">{{"USERS.USER" | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <div class="form-group">
            <label for="firstName">{{"USERS.FIRST_NAME" | translate}}:
              <span class="error" *ngIf="userForm.submitted">
            <span *ngIf="firstName.errors?.required">{{"ERRORS.IS_REQUIRED" | translate}}</span>
            <span *ngIf="firstName.errors?.pattern">{{"ERRORS.INVALID_PATTERN" | translate}}</span>
          </span>
            </label>
            <input id="firstName" name="firstName" [(ngModel)]="user.firstName" #firstName="ngModel" class="form-control"
                   [pattern]="basicStringPattern" required>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="form-group">
            <label for="lastName">{{"USERS.LAST_NAME" | translate}}:
              <span class="error" *ngIf="userForm.submitted">
            <span *ngIf="lastName.errors?.required">{{"ERRORS.IS_REQUIRED" | translate}}</span>
            <span *ngIf="lastName.errors?.pattern">{{"ERRORS.INVALID_PATTERN" | translate}}</span>
          </span>
            </label>
            <input id="lastName" name="lastName" [(ngModel)]="user.lastName" #lastName="ngModel" class="form-control"
                   [pattern]="basicStringPattern" required>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="form-group">
            <label for="jobTitle">{{"FORMS.JOB_TITLE" | translate}}:</label>
            <input id="jobTitle" name="jobTitle" [(ngModel)]="jobTitle" class="form-control">
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="form-group">
            <label for="emailAddress">{{"FORMS.EMAIL_ADDRESS" | translate}}:
              <span class="error" *ngIf="userForm.submitted">
                <span *ngIf="emailAddress.errors?.required">{{"ERRORS.IS_REQUIRED" | translate}}</span>
                <span *ngIf="emailAddress.errors?.email">{{"ERRORS.INVALID_PATTERN" | translate}}</span>
              </span>
            </label>
            <input type="email" email id="emailAddress" name="emailAddress" [(ngModel)]="user.emailAddress" #emailAddress="ngModel"
                   class="form-control" required>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="form-group">
            <label for="confirmEmail">{{"FORMS.CONFIRM_EMAIL_ADDRESS" | translate}}:
              <span class="error" *ngIf="userForm.submitted">
                <span *ngIf="!emailAddress.errors?.required && !confirmEmail.valid">{{"ERRORS.EMAIL_MISMATCH" | translate}}</span>
              </span>
            </label>
            <input type="email" id="confirmEmail" name="confirmEmail" [(ngModel)]="user.confirmEmail" #confirmEmail="ngModel" class="form-control"
                   xform-validate-equal="emailAddress" required>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="form-group">
            <label for="phone">{{"USERS.PHONE" | translate}}:</label>
            <input id="phone" name="phone" [(ngModel)]="user.phone" #phone="ngModel" class="form-control" required>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="form-group">
            <label for="maxAuthSessions">{{"USERS.MAX_SESSIONS" | translate}}:
              <span class="error" *ngIf="userForm.submitted">
                <span *ngIf="maxAuthSessions.errors?.required">{{"ERRORS.IS_REQUIRED" | translate}}</span>
              </span>
            </label>
            <input type="number" id="maxAuthSessions" name="maxAuthSessions"
                   [(ngModel)]="maxAuthSessions" class="form-control" required>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="form-group">
            <div class="checkbox emap-m-top-label-height">
              <input type="checkbox" class="form-control" name="isAdmin" id="isAdmin" [(ngModel)]="isAdmin">
              <label for="isAdmin">{{"FORMS.ADMINISTRATOR" | translate}}</label>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="form-group">
            <div class="checkbox">
              <input type="checkbox" class="form-control" name="isLead" id="isLead" ngModel [value]="user.isLead">
              <label for="isLead">{{"USERS.IS_LEAD" | translate}}</label>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-xs-12 col-md-6 item-picker-list">
          <label for="searchRoles">{{"ROLES.ADD_REMOVE" | translate}}</label>
          <input (input)="filter($event.target.value)" class="form-control" type="text"
                 [placeholder]="'ROLES.SEARCH' | translate" style="margin-bottom: 7px;" id="searchRoles">
          <emap-tree-view-select
                  [loading]="false"
                  [items]="treeViewItems"
                  [selectGroup]="'selectGroup'"
                  (onSelect)="checkRole($event)">
          </emap-tree-view-select>
        </div>
        <div class="col-xs-12 col-md-6 item-picker-list">
          <label>{{"ROLES.ADDED" | translate}} ({{treeViewItemsSelected.length}})</label>
          <div class="bordered-box selected-items">
            <ul>
              <li *ngFor="let item of treeViewItemsSelected; let i = index" (click)="removeRole(item, i)">
                {{item.label}}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <button type="button" class="btn btn-primary" (click)="addCheckedRoles()">
            {{"ROLES.ADD_ROLES" | translate}} ({{treeViewItemsChecked.length}})
          </button>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="c()">{{"GENERICS.CLOSE" | translate}}</button>
      <button type="submit" class="btn btn-primary" [disabled]="loading">
        {{(isEditMode ? "GENERICS.EDIT" : "GENERICS.CREATE") | translate}}
      </button>
    </div>
  </form>
</ng-template>
