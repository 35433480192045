
import {finalize} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BannerNotificationsService, LocalStorageService, SpinnerService } from '../../../../xform-compat';
import { CONFIG } from '../../../../config';
import { RmsUser, UserGroup } from '../../../../shared/models';
import { RmsListAbstract } from '../../../../core/components/rms-list.abstract';
import { EventsHubService } from '../../../../core/events-hub.service';
import { UserService } from '../../../../core/data-services/user.service';
import { TemplateModalService } from '../../../../core/services/template-modal.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { PasswordResetService } from '../../../../core/data-services/passwordReset.service';
import { UserGroupsService } from '../../../../core/data-services/userGroups.service';
import { AuthorizationService } from '../../../../core/services/authorization.service';

/**
 * Component class for User Detail and User Profile Settings
 */
@Component({
  selector: 'rms-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent extends RmsListAbstract<RmsUser> implements OnDestroy, OnInit {
  private largePage = CONFIG.pagination.singleLargePage;
  private loggedUser:  any;
  private userSettingSubscription: Subscription;
  public activeColor = CONFIG.toggleSwitch.activeColor;
  public bgColor = CONFIG.toggleSwitch.bgColor;
  public dateFormat = CONFIG.dateTimeFormatAlternative;
  public defaultImage = CONFIG.defaultProfileImage;
  public isPersonalProfile: boolean;
  public settings: any;
  public size = CONFIG.toggleSwitch.size;
  public user: RmsUser;
  public userGroups: UserGroup;

  constructor(
    eventsHubService: EventsHubService,
    userService: UserService,
    spinnerService: SpinnerService,
    bannerNotificationService: BannerNotificationsService,
    translateService: TranslateService,
    templateModalService: TemplateModalService,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService,
    private passwordResetService: PasswordResetService,
    private router: Router,
    private route: ActivatedRoute,
    private userGroupService: UserGroupsService,
    public authorizationService: AuthorizationService
  ) {
    super(eventsHubService, userService, spinnerService, translateService, bannerNotificationService, templateModalService);
    this.settings = {
      displayOverviewPage: false,
      hideNonOverviewNotes: false
    };
    this.loggedUser = this.localStorageService.get('loggedUser');
  }

  ngOnInit() {
    super.translateAlertMsgs('User');
    this.route.params.subscribe(
      params => {
        const userId = (params.id) ? params.id : this.authenticationService.getLoggedUser().id;
        this.isPersonalProfile = (!params.id);
        this.loadUserDetails(userId);
      }
    );
    this.getUserDetails();
  }

  ngOnDestroy(): void {
    this.userSettingSubscription.unsubscribe();
  }

  /**
   * Gets the details of the current user
   */
  private getUserDetails(): void {
    this.userSettingSubscription = this.userGroupService.getUserSettings(this.loggedUser.id).subscribe(settings => {
      this.settings = settings;
    });
  }

  /**
   * Updates the settings of the current user
   * @param settings
   * @param target
   * @param e
   */
  public updateSettings(settings: any, target: string, e?: any): void {
    this.settings[target] = e;
    this.userSettingSubscription = this.userGroupService.updateUserSettings(this.loggedUser.id, settings).subscribe();
  }

  getDefaultSort(): string {
    return '';
  }

  getRecordType(): string {
    return 'RmsUser';
  }

  /**
   * Get current user data
   * @param {number} id
   */
  private loadUserDetails (id: number) {
    super.getSpinnerService().start();
    this.loadUserGroup(id);
    if ( this.authorizationService.hasPermission([this.PERMISSIONS.UserRead]) ) {
      super.getResourceService().getRecord(id, { show: 'all', extrafields: 'useraudit' })
        .pipe(finalize(() => super.getSpinnerService().stop()))
        .subscribe(
          (user: RmsUser) => this.user = new RmsUser(user),
          (error) => super.getNotifications().error(error)
        );
    } else {
      super.getSpinnerService().stop();
    }
  }

  private loadUserGroup (id: number) {
    if ( this.authorizationService.hasPermission([this.PERMISSIONS.UserGroupRead]) ) {
      this.userGroupService.getRecords({userid: id, pagesize: this.largePage}, true)
        .subscribe(
          value => this.userGroups = value,
          error => super.getNotifications().error(error)
        );
    }
  }

  public onRecordEdited () {
    super.getResourceService().getRecord(this.user.id, {show: 'all'})
      .pipe(finalize(() => super.getSpinnerService().stop()))
      .subscribe(
        (user) => this.user = user,
        (error) => super.getNotifications().error(error)
      );
  }

  public sendPasswordResetLink () {
    super.getSpinnerService().start();
    const userEmail = this.user && this.user.emailAddress ? this.user.emailAddress : this.localStorageService.get('loggedUser').emailAddress;
    this.passwordResetService.forgotPassword({ emailAddress: userEmail }).pipe(
      finalize(() => super.getSpinnerService().stop()))
      .subscribe(
        () => super.getNotifications().success(
          super.getTranslate().instant('MESSAGES.EMAIL_SENT', { value: 'User' })
        ),
        (error) => super.getNotifications().error(error)
      );
  }

  /**
   * Delete User after confirm
   * @param {RmsUser} record
   */
  public deleteUser(record: RmsUser) {
    super.getResourceService().deleteRecord(record)
      .subscribe(
        () => {
          this.router.navigate(['/users']);
          super.getNotifications().success(super.getTranslate().instant('MESSAGES.DELETED', {value: 'User'}));
        },
        (error) => super.getNotifications().error(error)
      );
  }

}
