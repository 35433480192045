<div class="search-panel-parent">
    <div class="search-panel-body">
        <div class="search-panel-input">
            <input id="search-input" placeholder="Search here">
        </div>
        <div class="search-panel-filter">
            <div class="search-panel-filter-body">
                <div class="search-panel-filter-search-results-metadata"></div>
                <div class="search-panel-filter-button"><span>FILTERS</span></div>
                <div class="search-panel-filter-selections">
                    <div class="search-panel-filter-selections-placeholder">Filters: </div>
                </div>
            </div>
        </div>
        <div class="global-panel-results">
            <div class="search-panel-results-table global-panel-results-table"></div>
            <div class="search-panel-results-not-found"></div>
        </div>
    </div>
</div>