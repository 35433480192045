import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MapsManagerVersionService } from '../../../core/data-services/mapsManagerVersion.service';
import { ActivatedRoute } from '@angular/router';
import { MapsManagerVersion } from '../../../shared/models/mapsManagerVersion.model';
import {GlobalIntegrationServices} from '../../../shared/services/globalIntegration.services';


@Component({
  selector: 'emap-map-core',
  templateUrl: './map-core.component.html',
  styleUrls: ['./map-core.component.scss']
})
export class MapCoreComponent implements OnInit {
  @Output() mapImageLoadEvent$: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private mapsManagerVersionService: MapsManagerVersionService,
    private gis: GlobalIntegrationServices,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.loadMapVersionNode(params.id).subscribe((mapsManagerVersion: MapsManagerVersion) => {
        this.gis.currentMapVersionId = mapsManagerVersion.id;
        this.mapImageLoadEvent$.emit({
          mapImage: mapsManagerVersion.mapImage,
          navigatorImage: mapsManagerVersion.navigatorImage,
          mapVersionNodes: mapsManagerVersion.mapVersionNodes,
          mapVersionNodeHierarchies: mapsManagerVersion.mapVersionNodeHierarchies
        });
      });
    });
  }

  private loadMapVersionNode(id: any) {
    return this.mapsManagerVersionService.getById(id, { 'mapVersionNode': true });
  }
}
