<div class="row no-gutters authoring-header-parent">
    <div class="authoring-header-child z-index-2">
        <div class="authoring-header-left-side-panel">
            <div id="authoring-header-id-save" class="authoring-header-panel-icons authoring-header-panel-icons-save authoring-header-panel-button-disable" title="Save Changes"></div>
            <div id="authoring-header-id-hierarchy" class="authoring-header-panel-icons authoring-header-panel-icons-hierarchy"></div>
            <div id="authoring-header-id-edit" class="authoring-header-panel-icons authoring-header-panel-icons-edit"></div>
            <div id="authoring-header-id-legend" class="authoring-header-panel-icons authoring-header-panel-icons-legend"></div>
            <div id="authoring-header-id-chart" class="authoring-header-panel-icons authoring-header-panel-icons-chart"></div>
        </div>
        <div class="authoring-header-right-side-panel">
            <div id="authoring-header-id-home" class="authoring-header-panel-icons authoring-header-panel-icons-home"></div>
            <div id="authoring-header-id-navigator" class="authoring-header-panel-icons authoring-header-panel-icons-navigator"></div>
            <div id="authoring-header-id-zoom-100" class="authoring-header-panel-icons authoring-header-panel-icons-zoom-100"></div>
            <div id="authoring-header-id-zoom-full" class="authoring-header-panel-icons authoring-header-panel-icons-zoom-full"></div>
            <div id="authoring-header-id-zoom-scroll" class="authoring-header-panel-icons authoring-header-panel-icons-zoom-scroll"></div>
        </div>
        <div class="authoring-header-right-side-panel-scroll slider-display-none">
            <div id="authoring-header-zoom-slider"></div>
        </div>
    </div>
</div>