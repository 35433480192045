import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImageService } from '../../../core/data-services/image.service';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { ChartItemService } from '../../../core/data-services/chartItem.service';
import { ChartItem } from '../../models/chartItem.model';
import { environment } from '../../../../environments/environment';
import { DesignIntegrationService } from '../../services/designIntegration.service';
import { GlobalIntegrationServices } from '../../services/globalIntegration.services';
import { BannerNotificationsService, UtilsService } from '../../../xform-compat';
import * as $ from 'jquery';


@Component({
  selector: 'emap-chart-panel',
  templateUrl: './chart-panel.component.html',
  styleUrls: ['./chart-panel.component.scss'],
  providers: [ChartItemService],
  encapsulation: ViewEncapsulation.None,
})
export class ChartPanelComponent implements OnInit {
  private CHART_PANEL_RESULTS_TABLE_CLASS = 'chart-panel-results-table';
  private GLOBAL_PANEL_RESULTS_TABLE_HEADER_CLASS = 'global-panel-results-table-header';
  private GLOBAL_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS = 'global-panel-results-table-header-icon';
  private CHART_PANEL_RESULTS_TABLE_HEADER_MAP_NAME_CLASS = 'chart-panel-results-table-header-map-name';
  private CHART_PANEL_RESULTS_TABLE_NODE_LIST_CLASS = 'chart-panel-results-table-node-list';
  private CHART_PANEL_RESULTS_TABLE_NODE_LIST_IMAGE_CLASS = 'chart-panel-results-table-node-list-image';
  private CHART_PANEL_RESULTS_IMAGE_CLASS = 'chart-panel-results-image';

  private chartItemData: Array<ChartItem> = [];
  // private aws = environment.aws;

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private globalIntegrationServices: GlobalIntegrationServices,
    private imageService: ImageService,
    private bannerNotificationsService: BannerNotificationsService,
    private translateService: TranslateService,
    private chartItemService: ChartItemService,
    private designIntegrationService: DesignIntegrationService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.getChartPanelData();
  }

  private getChartPanelData() {
    const chartItemsParams = {
      mapsManagerId: this.globalIntegrationServices.currentMapId,
    };
    this.chartItemService.getChartItem(this.utilsService.generateParams(chartItemsParams))
      .subscribe(
        value => {
          this.chartItemData = value;
          this.constructChartPanel();
          this.initializeAccordionOnTable();
          this.chartImageClickEventHandler();
        },
        error => {
          this.bannerNotificationsService
            .error(this.translateService.instant('AUTHORING.CHART.ERRORS.CHART_IMAGE_ERROR'));
        }
      );
  }

  private initializeAccordionOnTable() {
    ($(this.CHART_PANEL_RESULTS_TABLE_CLASS.toClass()) as any).accordion({
      collapsible: true,
      icons: false,
      heightStyle: 'content'
    });
  }

  private constructChartPanel() {
    const chartPanelResultsTableSelector = this.jqueryCommonServices.selector(this.CHART_PANEL_RESULTS_TABLE_CLASS.toClass());
    this.chartItemData.forEach(eachChartItem => {
      chartPanelResultsTableSelector.append(this.constructChartHeader(eachChartItem));
      chartPanelResultsTableSelector.append(this.constructChartImage(eachChartItem));
    });
  }

  private constructChartHeader(eachChartItem: ChartItem) {
    const headerDivSelector = this.jqueryCommonServices.divConstruct().addClass(this.GLOBAL_PANEL_RESULTS_TABLE_HEADER_CLASS);
    const iconDivSelector = this.jqueryCommonServices.divConstruct().addClass(this.GLOBAL_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS);
    const headerNameDivSelector = this.jqueryCommonServices.divConstruct(eachChartItem.title.toUpperCase())
      .addClass(this.CHART_PANEL_RESULTS_TABLE_HEADER_MAP_NAME_CLASS);
    return headerDivSelector.append(iconDivSelector).append(headerNameDivSelector);
  }

  private constructChartImage(chartItem: ChartItem) {
    const chartPanelTableNodeListSelector = this.jqueryCommonServices.divConstruct().addClass(this.CHART_PANEL_RESULTS_TABLE_NODE_LIST_CLASS);
    const chartPanelImageSelector = this.jqueryCommonServices.divConstruct().addClass(this.CHART_PANEL_RESULTS_TABLE_NODE_LIST_IMAGE_CLASS);
    const imageId = chartItem.image.id;
    chartPanelImageSelector.append(this.jqueryCommonServices.selector('<img>')
      .attr('class', this.CHART_PANEL_RESULTS_IMAGE_CLASS)
      // .attr('src', this.aws.s3PrivateDomain.concat(chartItem.image.s3Reference))
      .data('id', chartItem.image.id)
      .data('fileName', chartItem.image.fileName)
      .data('title', chartItem.title)
      .data('s3Reference', chartItem.image.s3Reference));
    return chartPanelTableNodeListSelector.append(chartPanelImageSelector);
  }

  private chartImageClickEventHandler() {
    this.jqueryCommonServices.clickEvent(this.CHART_PANEL_RESULTS_IMAGE_CLASS.toClass(), (event) => {
      const targetImageSelector = event.target;
      if (targetImageSelector) {
        const imageData = this.jqueryCommonServices.selector(targetImageSelector).data();
        this.designIntegrationService.chartImageClickEventHandler.next(imageData);
      }
    });
  }
}
