import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {debounce} from 'lodash';

@Component({
  selector: 'emap-nodes-manager-filter',
  templateUrl: './nodes-manager-filter.component.html',
  styleUrls: ['./nodes-manager-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NodesManagerFilterComponent implements OnInit {

  @Input() filterMap: Map<string, boolean>;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  public filterList: Array<string>;
  public searchTerm: string;

  constructor() {}

  ngOnInit() {
    this.search = debounce(this.search, 300);
    this.resetFilterList();
  }

  public search() {
    this.resetFilterList();
    this.filterList = this.filterList.filter(value => value.toUpperCase().includes(this.searchTerm.toUpperCase()));
  }

  public toggleFilter(filter: string, state: boolean) {
    this.filterMap.set(filter, state);
  }

  public toggleAll(state: boolean) {
    this.filterList.forEach(filter => this.filterMap.set(filter, state));
  }

  public closePanel() {
    this.close.emit();
  }

  private resetFilterList() {
    this.filterList = Array.from(this.filterMap.keys());
  }
}
