export class ZpanOptions {
  public parentElement: any;
  public dblClickZoomOnly: boolean;
  public dblClickZoomLevel: number;
  public dblClickZoomReturnThreshold: number;
  public screenLimits: object;
  public zoomToFitMargin: object;

  constructor(option: ZpanOptions = {} as ZpanOptions) {
    this.parentElement = option.parentElement || null;
    this.dblClickZoomOnly = option.dblClickZoomOnly || false;
    this.dblClickZoomLevel = option.dblClickZoomLevel || 2;
    this.dblClickZoomReturnThreshold = option.dblClickZoomReturnThreshold || 0.5;
    this.screenLimits = option.screenLimits || null;
    this.zoomToFitMargin = option.zoomToFitMargin || null;
  }
}
