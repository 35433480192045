import {
  AfterViewInit,
  Component,
  ContentChild, ElementRef,
  Input,
  OnInit,
  QueryList,
  TemplateRef, ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlignService} from '../../../core/data-services/align.service';
import {AuthorizationService} from '../../../core/services/authorization.service';
import {MapsManagerVersionService} from '../../../core/data-services/mapsManagerVersion.service';
import {MapsManager} from '../../../shared/models/mapsManager.model';
import {MapsManagerVersion} from '../../../shared/models/mapsManagerVersion.model';
import {BannerNotificationsService, SpinnerService} from '../../../xform-compat';
import {PERMISSIONS} from '../../../shared/enums';
import {MapsManagerService} from '../../../core/data-services/mapsManager.service';
import {FileUploader} from "ng2-file-upload";
import {TvPublisherComponent} from "../tv-publisher/tv-publisher.component";
import {DeploymentService} from "../../../core/data-services/deployment.service";
import {IndicationManagerComponent} from "../indication-manager/indication-manager.component";
import {MapVersionIndication} from "../../../shared/models/mapsManagerVersionIndication.model";
import {MapsManagerIntegrationService} from "../../../shared/services/maps-manager-integration.service";

@Component({
  selector: 'emap-maps-manager-version-action-list',
  templateUrl: './maps-manager-version-action-list.component.html',
  styleUrls: ['./maps-manager-version-action-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapsManagerVersionActionListComponent implements OnInit {

  @Input() mapsManagerVersion: MapsManagerVersion;
  @Input() isVersionPage: boolean;
  @Input() refresh: () => void;
  @Input() mapsManager: MapsManager;
  @Input() uploader: FileUploader;

  @ViewChild('subMenu') subMenu: ElementRef;

  public PERMISSIONS = PERMISSIONS;

  constructor(
    private mapsManagerVersionService: MapsManagerVersionService,
    private mapsManagerService: MapsManagerService,
    private alignService: AlignService,
    private authorizationService: AuthorizationService,
    private bannerNotificationsService: BannerNotificationsService,
    private spinnerService: SpinnerService,
    private modalService: NgbModal,
    private router: Router,
    private deploymentService: DeploymentService,
    private mapsManagerIntegrationService: MapsManagerIntegrationService
  ) { }

  ngOnInit() {
    this.mapsManagerIntegrationService.$subMenuOpened.subscribe((eleId) => {
      if (eleId !== this.subMenu.nativeElement.id) {
        this.subMenu.nativeElement.classList.remove('active');
      }
    })
  }

  showTemplateModal(content: TemplateRef<any>) {
    this.modalService
      .open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'})
      .result.then((result) => {
        if (result && result.type) {
          switch (result.type) {
            case 'version':
              this.createNewVersion(result);
              break;
            case 'indesign':
              this.uploadIndesign(result);
              break;
            case 'delete':
              this.deleteMapsManagerVersion();
              break;
            case 'deleteMap':
              this.deleteMapsManager();
              break;
            default:
              break;
          }
        }
    }, () => {
    });
  }

  showPublishModal() {
    const modalRef = this.modalService
        .open(TvPublisherComponent, {ariaLabelledBy: 'modal-basic-title', size: 'xl'});
    modalRef.componentInstance.mapsManager = this.mapsManager;

    modalRef.result.then((result) => {
      if (result) {
        this.spinnerService.start();
        this.deploymentService.updateMapsToTvs(result).subscribe(
            () => {
              this.bannerNotificationsService.success('Publication request successful', null, 2);
              this.refresh();
              },
            error => this.bannerNotificationsService.error(error),
            () => this.spinnerService.stop());
      }
    })

  }

  uploadIndesign(result: { path: Blob }) {
    this.spinnerService.start();
    this.alignService.uploadFile(this.mapsManagerVersion.id, result.path).subscribe(data => {
      this.refresh();
      this.spinnerService.stop();
    }, error => {
      this.spinnerService.stop();
      this.bannerNotificationsService.error(error);
    });
  }

  deleteMapsManager() {
    this.spinnerService.start();
    this.mapsManagerService
      .deleteRecord(this.mapsManager)
      .subscribe(data => {
        this.spinnerService.stop();
        this.refresh();
      }, error => {
        this.spinnerService.stop();
        this.bannerNotificationsService.error(error);
      });
  }

  deleteMapsManagerVersion() {
    this.spinnerService.start();
    this.mapsManagerVersionService.deleteRecord(this.mapsManagerVersion)
      .subscribe(data => {
        this.spinnerService.stop();
        this.refresh();
      }, error => {
        this.bannerNotificationsService.error(error);
      });
  }

  createNewVersion(result: { type: string, name: string, desc: string }) {
    this.spinnerService.start();
    this.mapsManagerVersionService.createNewVersion(this.mapsManagerVersion, result).subscribe(data => {
      this.refresh();
      this.spinnerService.stop();
    }, error => this.bannerNotificationsService.error(error));
  }

  viewNodesTable() {
    if (!this.mapsManagerVersion.interactive) {
      return;
    }
    this.router.navigate([`/nodesManager/list/${this.mapsManagerVersion.id}/${this.mapsManagerVersion.name}`]);
  }

  openMindMapCore() {
    if (!this.mapsManagerVersion.interactive) {
      return;
    }
    const mindMapCoreUrl = `/mindMap/core/${this.mapsManagerVersion.id}`;
    window.open(mindMapCoreUrl);
  }

  openMapCore() {
    if (this.mapsManagerVersion.mapImage == null) {
      return;
    }
    const mindMapCoreUrl = `/map/core/${this.mapsManagerVersion.id}?panel=onr`;
    window.open(mindMapCoreUrl);
  }

  islastVersion() {
    const isNotFinalized = this.mapsManagerVersion.finalized == null;
    if (this.mapsManager && this.mapsManager.mapsManagerVersionCount) {
      return (this.mapsManager && this.mapsManager.mapsManagerVersionCount < 2) || isNotFinalized;
    }
    return (this.mapsManager && this.mapsManager.mapsManagerVersions.length < 2) || isNotFinalized;
  }

  canEdit(permission: string): boolean {
    return this.authorizationService.hasPermission([permission]);
  }

  editIndications() {
    const modalRef = this.modalService
        .open(IndicationManagerComponent, {ariaLabelledBy: 'modal-basic-title', size: 'lg'});
    modalRef.componentInstance.mapsManagerVersion = this.mapsManagerVersion;

    modalRef.result.then((result: Array<MapVersionIndication>) => {
      if (result) {
        this.mapsManagerVersion.indications = result;
        this.mapsManagerVersion.mapsManagerId = this.mapsManager.id;
        this.mapsManagerVersionService.updateMapsManagerVersion(this.mapsManagerVersion).subscribe();
      }
    });
  }
}
