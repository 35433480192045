import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExceptionService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PasswordExpiredService {

  private apiUrl = '/api/uaa/authorization/changepassword';

  constructor(
    private http: HttpClient,
    private exceptionService: ExceptionService
  ) { }

  public resetPassword (body: object) {
    return this.http.post(this.apiUrl, body)
      .subscribe(ex => this.exceptionService.catchBadResponse(ex));
  }

}
