
export class AuthoringToolboxModel {
    _isVisible: boolean;
    _isHierarchyModeActive: boolean;

    constructor(map: AuthoringToolboxModel = {} as AuthoringToolboxModel) {
        this._isVisible = map.isVisible || false;
        this._isHierarchyModeActive = map.isHierarchyModeActive || false;
    }

    get isVisible(): boolean {
        return this._isVisible;
    }

    set isVisible(id: boolean) {
        this._isVisible = id;
    }

    public get isHierarchyModeActive(): boolean {
        return this._isHierarchyModeActive;
    }

    public set isHierarchyModeActive(id: boolean) {
        this._isHierarchyModeActive = id;
    }
}
