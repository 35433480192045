import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { SystemAdminParentComponent } from './parent/system-admin-parent.component';
import { ApiDocsComponent } from './api-docs/api-docs.component';
import { AuthGuard } from '../../core/guards/auth.guard';


export const routedComponents = [
  SystemAdminParentComponent,
  ApiDocsComponent
];

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/system-administration' },
  {
    path: 'system-administration', component: SystemAdminParentComponent, canLoad: [AuthGuard], canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'roles' },
      { path: 'docs', component: ApiDocsComponent }
    ]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SystemAdminRoutingModule {}
