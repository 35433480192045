import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(text: any, highlightValue: any): any {
        if (!text || !highlightValue) {
            return text;
        }
        const re = new RegExp(highlightValue, 'gi');
        const match = text.match(re);
        if (!match) {
            return text;
        }
        const replacedValue = text.replace(re, '<span class="highlight">' + match[0] + '</span>');
        return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
    }
}
