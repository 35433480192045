import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './core/services/authentication.service';
import { TemplateModalService } from './core/services/template-modal.service';
import { CONFIG } from './config';


@Component({
  selector: 'emap-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  public translations: any;

  constructor(
    private translate: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    this.translate.setDefaultLang(CONFIG.defaultLang);
    this.translate.use(this.authenticationService.getLang());
  }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.authenticationService.validateToken();
    }
  }
}
