import { BaseEntity } from '../../xform-compat';
import { BasicUser } from './basicUser.model';


export class RemoteServer implements BaseEntity {
  id: number;
  name: string;
  heartbeat: string;
  active: boolean;
  

  constructor(remoteServer: RemoteServer = {} as RemoteServer) {
    this.id = remoteServer.id || null;
    this.name = remoteServer.name || null;
    this.heartbeat = remoteServer.heartbeat || null;
    this.active = remoteServer.active || null;
  }
}
