import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractCRUD, CRUDService, ExceptionService, UtilsService } from '../../xform-compat';
import { MapsManager } from '../../shared/models/mapsManager.model';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MapsManagerService extends AbstractCRUD<MapsManager> implements CRUDService<MapsManager> {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/map/service/mapsmanager';
  }

  private saveMapsManagerPath() {
    return super.getApiPath();
  }

  public saveMapsManager(body: MapsManager) {
    return super.getHTTP().post(this.saveMapsManagerPath(), body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public getNames() {
    return super.getHTTP().get(`${super.getApiPath()}/names`)
        .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }
}
