<xform-page [sidebar]="false" [bgColor]="'white-bg'">
  <section class="container-fluid public-form">
    <div class="row justify-content-md-center m-top-100">
      <form #resetPasswordForm="ngForm" class="col-xs-12 col-md-6 col-lg-4"
            [class.submitted]="resetPasswordForm.submitted" (ngSubmit)="onSubmit(resetPasswordForm)">
        <h1 class="text-center">{{"PASSWORD.RESET_PASSWORD" | translate}}</h1>
        <p class="sub-title text-center">{{"PASSWORD.RESET_SUBTITLE" | translate}}</p>

        <div class="form-group">
          <label for="password">{{"FORMS.PASSWORD" | translate}}
            <span class="error" *ngIf="resetPasswordForm.submitted">
              <span *ngIf="password.errors?.required">{{'ERRORS.IS_REQUIRED' | translate}}</span>
            </span>
          </label>
          <input type="password" class="form-control" name="password" id="password" ngModel #password="ngModel" required>
        </div>

        <div class="form-group">
          <label for="confirmPassword">{{"FORMS.CONFIRM_PASSWORD" | translate}}
            <span class="error" *ngIf="resetPasswordForm.submitted">
              <span *ngIf="!password.errors?.required && !confirmPassword.valid">{{'ERRORS.MISMATCH' | translate}}</span>
            </span>
          </label>
          <input type="password" class="form-control" name="confirmPassword" id="confirmPassword" ngModel #confirmPassword="ngModel" required
                 xform-validate-equal="password">
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block">{{"FORMS.SUBMIT" | translate}}</button>
        </div>
      </form>

    </div>
  </section>
</xform-page>
