import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { Role } from '../../shared/models';


@Injectable()
export class RoleService extends AbstractCRUD<Role> implements CRUDService<Role>, Searcher {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath(): string {
    return '/rms/roles';
  }

  public getDisplayName(item: any): string {
    return item.name;
  }

}
