import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RmsListAbstract } from '../../../../core/components/rms-list.abstract';
import { Image } from '../../../../shared/models/image.model';
import { TranslateService } from '@ngx-translate/core';
import { EventsHubService } from '../../../../core/events-hub.service';
import { TemplateModalService } from '../../../../core/services/template-modal.service';
import { ImageService } from '../../../../core/data-services/image.service';
import { environment } from '../../../../../environments/environment';
import { AuthorizationService } from '../../../../core/services/authorization.service';
import { AuthoringIntegrationService } from '../../../../shared/services/authoringIntegration.service';
import { JqueryCommonServices } from '../../../../shared/services/jqueryCommon.services';
import { BannerNotificationsService, SpinnerService } from '../../../../xform-compat';
import { CONFIG } from '../../../../config';


@Component({
  selector: 'emap-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaListComponent extends RmsListAbstract<Image> implements OnInit {

  public imageCategoryList: Array<string>;
  public preSelectedTab: string;
  private currentImageCategory: string;
  public s3Url = CONFIG.aws.s3[environment.env];
  private MEDIA_LIST_FILTER_INPUT_ID = 'media-list-filter-input';
  private currentImageFilterText = '';

  constructor(
    imageService: ImageService,
    eventsHubService: EventsHubService,
    spinnerService: SpinnerService,
    translateService: TranslateService,
    bannerNotificationService: BannerNotificationsService,
    templateModalService: TemplateModalService,
    public authorizationService: AuthorizationService,
    private authoringIntegrationService: AuthoringIntegrationService,
    private jqueryCommonServices: JqueryCommonServices
  ) {
    super(eventsHubService, imageService, spinnerService, translateService, bannerNotificationService, templateModalService);
  }

  ngOnInit() {
    this.initTabTypes();
    super.ngOnInit();
  }

  initTabTypes() {
    this.imageCategoryList = ['Legend', 'Chart'];
    this.preSelectedTab = this.imageCategoryList[0];
    this.currentImageCategory = this.preSelectedTab;
  }

  public updateCategory(selectedCategory: string) {
    this.currentImageCategory = selectedCategory;
  }

  public showMasterLegendModal() {
    this.authoringIntegrationService.authoringLegendIconClickEventHandler.next();
  }

  getDefaultSort(): string {
    return 'fileName';
  }

  getRecordType(): string {
    return 'name';
  }

  public recordAdded() {
    const imageFilterObject = { category: this.currentImageCategory };
    if (this.currentImageFilterText !== '') {
      imageFilterObject['fileName'] = this.currentImageFilterText;
    }
    this.setRecordsPerPage(imageFilterObject);
  }

  triggerImageFilterHandler() {
    const currentInputVal = this.jqueryCommonServices.selector(this.MEDIA_LIST_FILTER_INPUT_ID.toId()).val();
    if (typeof currentInputVal !== 'string') { return; }
    this.currentImageFilterText = currentInputVal;
    this.recordAdded();
  }
}
