import { LegendItem } from '../legendItem.model';


export class CreateLegendResource {
  mapId: number;
  isMaster: boolean;
  createLegendItemResourceList: Array<LegendItem>;

  constructor(createLegendResource: CreateLegendResource = {} as CreateLegendResource) {
    this.mapId = createLegendResource.mapId;
    this.isMaster = createLegendResource.isMaster;
    this.createLegendItemResourceList = createLegendResource.createLegendItemResourceList;
  }
}
