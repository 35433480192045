import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { SharedModule } from '../../shared/shared.module';
import { MapDesignComponent } from './map-design/map-design.component';
import { MapCoreComponent } from './map-core/map-core.component';

const routes: Routes = [
  {path: 'design', component: MapDesignComponent, canLoad: [AuthGuard], canActivate: [AuthGuard]},
  {path: 'core/:id', component: MapCoreComponent, canLoad: [AuthGuard], canActivate: [AuthGuard]},
];

export const routedComponents = [
  MapDesignComponent
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class MapRoutingModule { }
