import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { ZpanCommonServices } from '../../services/zpanCommon.services';
import { GlobalIntegrationServices } from '../../services/globalIntegration.services';
import { AuthoringIntegrationService } from '../../services/authoringIntegration.service';
import { ZpanOptions } from '../../models/zpanOptions.model';
import { Image } from '../../models/image.model';
import { environment } from '../../../../environments/environment';
import { CONFIG } from '../../../config';
import { MapVersionNode } from '../../models/mapVersionNode.model';
import { MapVersionNodeHierarchy } from '../../models/mapVersionNodeHierarchy.model';
import {BannerNotificationsService, SpinnerService} from "../../../xform-compat";


@Component({
  selector: 'emap-map-image',
  templateUrl: './map-image.component.html',
  styleUrls: ['./map-image.component.scss'],
  providers: [ZpanCommonServices]
})
export class MapImageComponent implements OnInit {
  @Input() mapImageLoadEvent$: EventEmitter<any> = new EventEmitter<any>();
  @Input() nodeLayerLoadEvent$: EventEmitter<any> = new EventEmitter<any>();
  private imageBaseUrl = CONFIG.aws.s3[environment.env];
  private MAIN_MAP_IMAGE_CLASS = 'main-map-image';
  private navigatorImage: Image;
  private mapVersionNodes: Array<MapVersionNode>;
  private mapVersionNodeHierarchies: Array<MapVersionNodeHierarchy>;

  constructor(
    private jcs: JqueryCommonServices,
    private zPanCommonServices: ZpanCommonServices,
    private ais: AuthoringIntegrationService,
    private gis: GlobalIntegrationServices,
    private spinnerService: SpinnerService,
    private bannerService: BannerNotificationsService
  ) { }

  ngOnInit() {
    this.initImageLoad();
  }

  private initImageLoad() {
    this.mapImageLoadEvent$.subscribe(data => {
      this.navigatorImage = data['navigatorImage'];
      this.mapVersionNodes = data['mapVersionNodes'];
      this.mapVersionNodeHierarchies = data['mapVersionNodeHierarchies'];
      this.bindMapImage();
      this.authoringHeaderIconBinding();
    });
  }

  private bindMapImage() {
    this.spinnerService.start();
    const mapImagePathUrl = `${this.imageBaseUrl}${this.navigatorImage.s3Reference}`;
    this.jcs.imageLoaderEvent(this.MAIN_MAP_IMAGE_CLASS.toClass(), mapImagePathUrl,
      this.gis.mapImageClassName, () => {
      this.spinnerService.stop();
        this.bindZpanService();
        this.initZpan();
        this.nodeLayerLoadEvent$.next({
          mapVersionNodes: this.mapVersionNodes,
          mapVersionNodeHierarchies: this.mapVersionNodeHierarchies
        });
        this.gis.mapImageLoadEventEmitter.next();
      }, (err) => {
      this.spinnerService.stop();
      this.bannerService.error(err);
    });
  }

  private bindZpanService() {
    this.zPanCommonServices.currentPanSelector = this.jcs.selector(this.MAIN_MAP_IMAGE_CLASS.toClass());
    this.gis.currentZpanCommonService = this.zPanCommonServices;
    this.gis.currentMapImageSelector = this.jcs.selector(this.MAIN_MAP_IMAGE_CLASS.toClass());
  }

  private initZpan() {
    this.zPanCommonServices.startZpan(this.constructZpanOptions());
    this.zPanCommonServices.zoomToFit();
  }

  private constructZpanOptions() {
    const zPanOptions = new ZpanOptions();
    zPanOptions.parentElement = this.jcs.selector('body');
    zPanOptions.screenLimits = {
      marginLeft: 0,
      marginRight: 500,
      marginTop: 0,
      marginBottom: 0
    };
    zPanOptions.zoomToFitMargin = {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 100,
    };
    return zPanOptions;
  }

  private authoringHeaderIconBinding() {
    this.ais.authoringZoomFullIconClickEventHandler.subscribe(value => {
      this.zPanCommonServices.zoomToFit();
    });
    this.ais.authoringZoom100IconClickEventHandler.subscribe(value => {
      this.customZoom100();
    });
    this.ais.authoringZoomScrollIconClickEventHandler.subscribe(value => {
      const zoomVal = (value as any).zoomVal;
      if (!zoomVal) { return; }
      this.zPanCommonServices.zoomToVal(zoomVal);
    });
  }

  private customZoom100() {
    const currentPrimarySelection = this.ais.nodeLayerModel.primarySelectionId;
    if (currentPrimarySelection) {
      const nodeElement = ('node_' + currentPrimarySelection).toId();
      const targetSelector = this.jcs.selector(nodeElement);
      const targetPosition = targetSelector.offset();
      const currentWidthHalf = targetSelector.width() / 2 * this.gis.ratio;
      const currentHeightHalf = targetSelector.height() / 2 * this.gis.ratio;
      this.zPanCommonServices
        .zoomToPosition(targetPosition.top + currentHeightHalf, targetPosition.left + currentWidthHalf, false);
    } else {
      this.zPanCommonServices.zoom100();
    }
  }
}
