import {BaseEntity} from '../../xform-compat';
import {ProductCompanyInstance} from './ProductCompanyInstance';
import {Milestone} from './milestone.model';
import {ProductCategoryValue} from './productCategoryValue.model';


export class MindMapVersionNode implements BaseEntity {
  id: number;
  key: string;
  loc: string;
  dir: string;
  font: string;
  scale: number;
  width: number;
  height: number;
  parent: string;
  data: string;
  text: string;
  html: string;
  stroke: string;
  nodeStroke: string;
  nodeBackground: string;
  milestoneLabel: string;
  comments: string;
  style: string;
  isEndNode: boolean;
  isMilestone: boolean;
  isRichText: boolean;
  isUnderline: boolean;
  created: string;
  updated: string;
  active: boolean;
  productCompanyInstances: Array<ProductCompanyInstance>;
  productCategoryValues: Array<ProductCategoryValue>;
  milestones: Array<Milestone>;
  mapsManagerVersionID: number;

  constructor(mindMapVersionNode: MindMapVersionNode = {} as MindMapVersionNode) {
    this.id = mindMapVersionNode.id || null;
    this.key = mindMapVersionNode.key || '';
    this.loc = mindMapVersionNode.loc || null;
    this.dir = mindMapVersionNode.dir || null;
    this.font = mindMapVersionNode.font || null;
    this.scale = mindMapVersionNode.scale || null;
    this.width = mindMapVersionNode.width || null;
    this.height = mindMapVersionNode.height || null;
    this.parent = mindMapVersionNode.parent || '';
    this.data = mindMapVersionNode.data || null;
    this.text = mindMapVersionNode.text || null;
    this.html = mindMapVersionNode.html || null;
    this.stroke = mindMapVersionNode.stroke || null;
    this.nodeStroke = mindMapVersionNode.nodeStroke || null;
    this.nodeBackground = mindMapVersionNode.nodeBackground || null;
    this.milestoneLabel = mindMapVersionNode.milestoneLabel || null;
    this.comments = mindMapVersionNode.comments || null;
    this.style = mindMapVersionNode.style || null;
    this.isEndNode = mindMapVersionNode.isEndNode || false;
    this.isMilestone = mindMapVersionNode.isMilestone || false;
    this.isRichText = mindMapVersionNode.isRichText || false;
    this.isUnderline = mindMapVersionNode.isUnderline || false;
    this.created = mindMapVersionNode.created || null;
    this.updated = mindMapVersionNode.updated || null;
    this.active = mindMapVersionNode.active || null;
    this.mapsManagerVersionID = mindMapVersionNode.mapsManagerVersionID || null;
    this.productCompanyInstances = mindMapVersionNode.productCompanyInstances
      ? mindMapVersionNode.productCompanyInstances.map(pci => new ProductCompanyInstance(pci))
      : null;
    this.productCategoryValues = mindMapVersionNode.productCategoryValues || null;
    this.milestones = mindMapVersionNode.milestones
      ? mindMapVersionNode.milestones.map(m => new Milestone(m))
      : null;
  }
}
