import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Company} from '../../../../shared/models/company.model';
import {Product} from '../../../../shared/models/product.model';
import {DataBindService} from '../../../../core/data-services/data-bind.service';
import {debounce} from 'lodash';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MILESTONE_CATEGORY, PRODUCT_CATEGORY} from '../../../../shared/enums';
import {ProductCategoryValue} from '../../../../shared/models/productCategoryValue.model';

@Component({
  selector: 'emap-data-binding-modal',
  templateUrl: './data-binding-modal.component.html',
  styleUrls: ['./data-binding-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataBindingModalComponent implements OnInit, AfterViewInit {

  @Input() companyProductPairs: Array<{ companies: Array<Company>, products: Array<Product> }> = [{companies: [], products: []}];
  @Input() companyProductLabel: string = this.formattedCompanyProductLabel;
  @Input() productCategoryValuesMap: Map<string, Array<ProductCategoryValue>>;
  @Input() productCategoryValues: Array<ProductCategoryValue>;
  @Input() milestones: Array<MilestoneBuilderValues> = [];
  @Input() activePage = 'companyProduct';
  @Input() finalized;
  public companyProductHasError = false;
  public milestoneHasError = false;
  public formErrors: Map<number, any> = new Map<number, any>();
  public companySearchResults: Array<Company> = [];
  public productSearchResults: Array<Product> = [];
  public milestoneCategoryValues: any = {};
  public milestoneHasNote: Map<number, boolean> = new Map<number, boolean>();
  public indications: Array<any> = [];
  public dosingSearchResults: Array<ProductCategoryValue> = [];
  public roaSearchResults: Array<ProductCategoryValue> = [];
  public searchingForCompanies = -1;
  public searchingForProducts = -1;
  public submitted = false;
  public readonly MILESTONE_CATEGORIES = MILESTONE_CATEGORY;
  public readonly MILESTONE_CATEGORIES_DB = ['phase', 'trial', 'event', 'date', 'indications'];
  public readonly _SEARCH_DEBOUNCE_TIME = 500;

  constructor(
    public activeModal: NgbActiveModal,
    private dataBindService: DataBindService
  ) {
  }

  ngOnInit() {
    this.onCompanySearchChange = debounce(this.onCompanySearchChange, this._SEARCH_DEBOUNCE_TIME);
    this.onProductSearchChange = debounce(this.onProductSearchChange, this._SEARCH_DEBOUNCE_TIME);
    this.parseAllProductCategoryValues();
    this.parseExistingProductCategoryValues();
    if (!this.companyProductPairs) {
      this.companyProductPairs = [{companies: [], products: []}];
    }
    if (!this.milestones) {
      this.milestones = [];
    }

    for (let i = 0; i < this.companyProductPairs.length; i++) {
      this.getProducts(i);
    }

    for (let i = 0; i < this.milestones.length; i++) {
      this.milestoneHasNote.set(i, !!this.milestones[i].note);
    }
    const milestoneCategoryValueFilter = {'source': 'eMaps'};
    this.dataBindService.getMilestoneCategoryValues(milestoneCategoryValueFilter).subscribe((data: any) => {
      if (data) {
        this.milestoneCategoryValues = data;
      }
    });

    this.dataBindService.getIndications().subscribe((data: Array<any>) => {
      if (data) {
        this.indications = data;
      }
    });

    this.moveModalToTop(null);
  }

  ngAfterViewInit() {
    const modals = document.querySelectorAll('ngb-modal-window');
    const modalBackgrounds = document.querySelectorAll('ngb-modal-backdrop');

    for (let i = 0; i < modals.length; i++) {
      const el = modals.item(i);
      (el as HTMLElement).style.pointerEvents = 'none';
    }

    for (let i = 0; i < modalBackgrounds.length; i++) {
      const el = modalBackgrounds.item(i);
      (el as HTMLElement).style.display = 'none';
    }
  }

  onCompanySearchChange(term: string, pairIndex: number) {
    this.companySearchResults = [];
    this.searchingForCompanies = pairIndex;
    const searchResults = [];
    this.dataBindService.getCompanyAutocompleteEDM(term)
      .subscribe((companies: Array<Company>) => {
        if (companies) {
          companies.forEach(c => searchResults.push(new Company(c)));
          this.companySearchResults = searchResults;
          this.searchingForCompanies = -1;
        }
      }, () => {
        this.companySearchResults = [];
        this.searchingForCompanies = -1;
      });
  }

  onProductSearchChange(term: string, pairIndex: number) {
    if (this.companyProductPairs[pairIndex].companies.length === 0 && term) {
      this.getProducts(pairIndex, term);
    }
  }

  // This function handles adding and removing the Dosing and ROA product category values
  onProductCategoryValueChange(productCategoryValue: any, action: 'add' | 'remove') {
    const labelIsClean = !this.companyProductLabelDirty;
    switch (action) {
      case 'add':
        // Only add the product category value if it doesn't already exist
        if (this.productCategoryValues.filter(pcv => pcv.name === productCategoryValue.name).length === 0) {
          this.productCategoryValues.push(productCategoryValue);
        }
        break;
      case 'remove':
        // Only remove the product category value if it exists
        if (this.productCategoryValues.filter(pcv => pcv.name === productCategoryValue.label).length > 0) {
          const idx = this.productCategoryValues.findIndex(pcv => pcv.name === productCategoryValue.label);
          this.productCategoryValues.splice(idx, 1);
        }
        break;
    }
    this.nestedSortProductCategoryValues();
    // This is not a clean style of doing this, but it works for now. I had to do this because of the V1 version design.
    // TODO: Clean this up with better handlers and constructors (Cleaning this entire file/design takes a lot of time)
    if (labelIsClean) {
      this.companyProductLabel = this.formattedCompanyProductLabel;
    }
  }

  onCompanySelect(company: any, pairIndex: number) {
    const labelIsClean = !this.companyProductLabelDirty;
    this.companyProductPairs[pairIndex].companies.push(company);
    this.getProducts(pairIndex);

    if (labelIsClean) {
      this.companyProductLabel = this.formattedCompanyProductLabel;
    }
  }

  onCompanyRemove(company: any, pairIndex: number) {
    if (company) {
      const labelIsClean = !this.companyProductLabelDirty;
      const foundCompanyIdx = this.companyProductPairs[pairIndex].companies.findIndex(c => c.name === company.label);
      this.companyProductPairs[pairIndex].companies.splice(foundCompanyIdx, 1);
      if (this.companyProductPairs[pairIndex].companies.length > 0) {
        this.getProducts(pairIndex);
      } else {
        this.productSearchResults = [];
      }

      if (labelIsClean) {
        this.companyProductLabel = this.formattedCompanyProductLabel;
      }
    }
  }

  onProductSelect(product: Product, pairIndex: number) {
    const labelIsClean = !this.companyProductLabelDirty;
    this.companyProductPairs[pairIndex].products.push(product);
    for (const pcv of product.productCategoryValues) {
      if ((pcv.productCategory === PRODUCT_CATEGORY.RoA || pcv.productCategory === PRODUCT_CATEGORY.Dosing)
        && pcv.name) {
        this.onProductCategoryValueChange(pcv, 'add');
      }
    }
    this.companySearchResults = [];

    if (labelIsClean) {
      this.companyProductLabel = this.formattedCompanyProductLabel;
    }
  }

  onProductRemove(product: any, pairIndex: number) {
    if (product) {
      const labelIsClean = !this.companyProductLabelDirty;
      const foundProductIdx = this.companyProductPairs[pairIndex].products.findIndex(p => p.name === product.label);
      this.companyProductPairs[pairIndex].products.splice(foundProductIdx, 1);
      for (let pcv of product.value.productCategoryValues) {
        this.onProductCategoryValueChange(pcv, 'remove');
      }

      if (labelIsClean) {
        this.companyProductLabel = this.formattedCompanyProductLabel;
      }
    }
  }

  getProducts(pairIndex: number, term?: string) {
    const searchResults = [];
    this.searchingForProducts = pairIndex;
    this.dataBindService
      .getProductAutocompleteEDM(term || '', this.companyProductPairs[pairIndex].companies)
      .subscribe((products: Array<Product>) => {
        if (products) {
          products.forEach(p => searchResults.push(new Product(p)));
          this.productSearchResults = searchResults;
          this.searchingForProducts = -1;
        }
      }, () => {
        this.productSearchResults = [];
        this.searchingForProducts = -1;
      });
  }

  closeModal(modal: NgbActiveModal) {
    const value: any = {
      companyProductPairs: this.companyProductPairs,
      milestones: this.milestones,
      productCategoryValues: this.productCategoryValues,
    };
    this.companyProductHasError = false;
    this.milestoneHasError = false;
    this.formErrors.clear();
    this.submitted = true;
    // Prepare company-product pairs for saving
    for (let i = 0; i < this.companyProductPairs.length; i++) {
      const pair = this.companyProductPairs[i];
      if ((!pair.companies || pair.companies.length === 0) && (!pair.products || pair.products.length === 0)) {
        this.formErrors.set(i, {companyProductPairBlank: true});
        this.companyProductHasError = true;
        return false;
      }
    }
    value.milestones.forEach((milestone, i) => {
      if (!this.isMilestoneValid(milestone)) {
        this.formErrors.set(i, {milestoneBlank: true});
        this.milestoneHasError = true;
      }
    });
    if (!this.companyProductHasError && !this.milestoneHasError) {
      value.dataLabel = this.companyProductLabel;
      value.milestoneLabel = this.formattedMilestoneLabel;
      modal.close(value);
    }
  }

  noFilterSearchFn(term: string, item: any): boolean {
    return true;
  }

  moveModalToTop(event: MouseEvent) {
    document.querySelectorAll('ngb-modal-window').forEach((el: HTMLElement) => {
      el.style.zIndex = '1050';
    });
    if (event) {
      ((event.target as HTMLElement).closest('ngb-modal-window') as HTMLElement).style.zIndex = '1051';
    }
  }

  get formattedCompanyProductLabel(): string {
    const formattedPairs: Array<string> = [];

    for (const companyProductPair of this.companyProductPairs) {
      if (companyProductPair.companies.length > 0 || companyProductPair.products.length > 0) {
        const companyNames = companyProductPair.companies.map(c => c.name);
        const productNames = companyProductPair.products.map(p => p.name);
        formattedPairs.push(`${companyNames.join('/')} (${productNames.join(' + ')})`);
      }
    }
    let label = formattedPairs.join(' + ');
    if (this.productCategoryValues && this.productCategoryValues.length > 0) {
      // sometimes the pcv's are not in order, so we sort them here, so that the preview code is always the same
      const currPCV = this.productCategoryValues;
      label += ` ${currPCV.map(pcv => pcv.name).join(' : ')}`;
    }

    return label;
  }

  get formattedMilestoneLabel(): string {
    const milestoneLabels: Array<string> = [];
    this.milestones.map((m, i) => {
      const vals: Array<string> = [];
      this.MILESTONE_CATEGORIES_DB.forEach(key => {
        if (m[key]) {
          if (typeof m[key] === 'string') {
            vals.push(m[key]);
          } else if (m[key].join && m[key].length > 0) {
            vals.push(m[key].join(' : '));
          }
        }
      });

      let milestoneLabel = vals.join(' : ');
      if (this.milestoneHasNote.has(i) && this.milestoneHasNote.get(i) && m.note) {
        const placeNoteAsterisk = m.note.charAt(0) === '*' ? '' : '*';
        milestoneLabel += ` : ${placeNoteAsterisk}${m.note}`;
      }

      milestoneLabels.push(milestoneLabel);
    });
    return milestoneLabels.join('\n');
  }

  get companyProductLabelDirty(): boolean {
    return this.companyProductLabel !== this.formattedCompanyProductLabel;
  }

  private sortByName(dataList: Array<any>) {
    return dataList
      .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
  }

  private nestedSortProductCategoryValues() {
    const dosingPCV = this.productCategoryValues.filter(pcv => pcv.productCategory === PRODUCT_CATEGORY.Dosing);
    const roaPCV = this.productCategoryValues.filter(pcv => pcv.productCategory === PRODUCT_CATEGORY.RoA);
    this.sortByName(dosingPCV);
    this.sortByName(roaPCV);
    this.productCategoryValues = [...dosingPCV, ...roaPCV];
  }

  private isMilestoneValid(milestone: any) {
    return milestone.date ||
      milestone.trial ||
      milestone.event ||
      milestone.phase ||
      milestone.indications ||
      (milestone.indications && milestone.indications.length === 0);
  }

  private parseExistingProductCategoryValues() {
    // Base case - check if there are any product category values or set to empty array
    if (!this.productCategoryValues) {
      this.productCategoryValues = [];
    }
    this.nestedSortProductCategoryValues();
  }

  private parseAllProductCategoryValues() {
    if (this.productCategoryValuesMap) {
      for (const [key, value] of Object.entries(this.productCategoryValuesMap)) {
        if (key === PRODUCT_CATEGORY.Dosing) {
          this.dosingSearchResults = value;
        } else if (key === PRODUCT_CATEGORY.RoA) {
          this.roaSearchResults = value;
        }
      }
    }
  }

  pcvSearchFn(pcvTerm: string, categoryName: string) {
    const searchResults = this.productCategoryValuesMap[categoryName];
    if (searchResults && searchResults.length > 0) {
      if (categoryName === PRODUCT_CATEGORY.Dosing) {
        this.dosingSearchResults = searchResults.filter(pcv => pcv.name.toLowerCase().includes(pcvTerm.toLowerCase()));
      } else if (categoryName === PRODUCT_CATEGORY.RoA) {
        this.roaSearchResults = searchResults.filter(pcv => pcv.name.toLowerCase().includes(pcvTerm.toLowerCase()));
      }
    }
  }

  pcvListPredicate(item: ProductCategoryValue, category: PRODUCT_CATEGORY): boolean {
    return item.productCategory === category;
  }
}

interface MilestoneBuilderValues {
  date?: string;
  trial?: string;
  event?: string;
  indications?: Array<string>;
  phase?: any;
  note?: string;
}
