import { finalize, tap } from 'rxjs/operators';
import swal from 'sweetalert2';
import { TokenInfo, User } from '../../shared/models';
import { Injectable } from '@angular/core';
import { EventsHubService } from '../events-hub.service';
import { BannerNotificationsService, LocalStorageService } from '../../xform-compat';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { LoggerService } from '../data-services/logger.service';
import { UserService } from '../data-services/user.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private loggedUser: User;
  private tokenInfo: TokenInfo;

  constructor(
    private eventsHubService: EventsHubService,
    private loggerService: LoggerService,
    private storage: LocalStorageService,
    private router: Router,
    private translateService: TranslateService,
    private eventsHub: EventsHubService,
    private userService: UserService,
    private bannerNotificationService: BannerNotificationsService
  ) {
    this.tokenInfo = this.getTokenInfo();

    const lang = this.getLang();
    if (lang) { this.translateService.use(lang); }

    this.eventsHubService.profileUpdated$.subscribe(
      () => this.refreshLoggedUserInfo()
    );
  }

  public setTokenInfoFromOkta(tokenInfo: TokenInfo): void {
    this.tokenInfo = tokenInfo;
    this.storage.set('tokenInfo', this.tokenInfo);
  }

  public userEmail(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loggerService.getLoginRedirectUrl(email)
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
            this.eventsHub.setLoggedIn(false);
          });
    });
  }

  public login(credentials: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loggerService.login(credentials)
        .subscribe(
          (token) => {
            this.tokenInfo = new TokenInfo(token);
            this.storage.set('tokenInfo', this.tokenInfo);
            resolve(this.tokenInfo);
          },
          (error) => {
            reject(error);
            this.eventsHub.setLoggedIn(false);
          });
    });

  }

  /**
   * Promise to get info from the logger service
   * Attempts to get refresh token data
   * updates the user session info
   * @param {TokenInfo} tokenInfo: cached user session data
   * @returns {Observable<any>}: resolves or rejects the promise
   */
  public refreshToken (tokenInfo: TokenInfo): Observable<any> {
    return this.loggerService.refreshToken(tokenInfo.refreshToken).pipe(
      tap(response => {
        this.tokenInfo = new TokenInfo(response);
        this.storage.set('tokenInfo', this.tokenInfo);
        // Backend forces a refresh of the token when permission changes, we do a request for the new permissions.
        this.refreshLoggedUserInfo();
      })
    );
  }

  /**
   * Gets the user data
   */
  private refreshLoggedUserInfo(): void {
    this.userService.getLoggedUser().subscribe(
      (user: User) => {
        this.setLoggedUser(user);
        this.loggedUser = this.getLoggedUser();
      },
      (error) => {
        this.bannerNotificationService.error(error);
      });
  }

  public logout (redirectUrl?: string, expired?: boolean) {
    swal.close();
    const tokenInfo = this.getTokenInfo();
    if (tokenInfo) {
      this.loggerService.validateToken(tokenInfo.accessToken).pipe(
        finalize(() => this.vacate(redirectUrl, expired)))
        .subscribe(() => this.loggerService.logout().subscribe());
    } else {
      this.vacate(redirectUrl, expired);
    }
  }

  public isLoggedIn() {
    return !!(this.getTokenInfo());
  }

  public getLoggedUser(): User {
    return this.storage.get('loggedUser');
  }

  public setLoggedUser(user: User) {
    this.storage.set('loggedUser', user);
  }

  /**
   * Check if the permissions stored have changed
   * @param oldLoggedUserInfo Previous stored user data
   * @param newLoggedUserInfo Refreshed user data
   * @returns {boolean} If there are changes on permissions
   */
  public permissionsChanged(oldLoggedUserInfo, newLoggedUserInfo): boolean {
    if (  !oldLoggedUserInfo || !newLoggedUserInfo || oldLoggedUserInfo.superUser !== newLoggedUserInfo.superUser
      || oldLoggedUserInfo.userTenants[0].administrator !== newLoggedUserInfo.userTenants[0].administrator) {
      return true;
    }
    // Check permission ids
    const oldPermissions = new Set();
    const newPermissions = new Set();
    oldLoggedUserInfo.userTenants[0].tenantRoles.forEach(function (role) {
      role.permissions.forEach(permission => oldPermissions.add(permission.id));
    });
    newLoggedUserInfo.userTenants[0].tenantRoles.forEach(function (role) {
      role.permissions.forEach(permission => newPermissions.add(permission.id));
    });
    // newPermissions = [1,3,4]; oldPermissions = [1,4,3] => "1,3,4" !== "1,3,4"
    return (Array.from(newPermissions).sort().toString() !== Array.from(oldPermissions).sort().toString());
  }

  public setLang(userLang: string, defaultLang?: string) {
    this.storage.set('lang', userLang || defaultLang);
  }

  public getLang(): string {
    return this.getLoggedUser() ?
      this.getLoggedUser().attributes.language || this.translateService.getDefaultLang() :
      this.translateService.getDefaultLang();
  }

  public getTokenInfo(): TokenInfo {
    return this.storage.get('tokenInfo') || null;
  }

  public validateToken() {
    const tokenInfo = this.getTokenInfo();
    if (tokenInfo) {
      this.loggerService.validateToken(tokenInfo.accessToken)
        .subscribe(
          () => {},
          () => this.vacate()
        );
    } else {
      this.vacate();
    }
  }

  private vacate(redirectUrl?: string, expired?: boolean) {
    this.eventsHub.setLoggedIn(false);
    this.loggedUser = null;
    if (!redirectUrl) {
      this.router.navigate(['/']);
    } else {
      if (!this.router.url.includes('redirect')) {
        const queryParams = {
          redirectTo: redirectUrl,
          expired: (expired)
        };
        this.router.navigate(['/login'], { queryParams: queryParams });
      }
    }
    this.clearLocalStorage();
  }

  public clearLocalStorage(): void {
    this.storage.delete('loggedUser');
    this.storage.delete('notifications');
    this.storage.delete('tokenInfo');
  }

}
