import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { ImageService } from '../../../core/data-services/image.service';
import { AuthoringIntegrationService } from '../../services/authoringIntegration.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartItem } from '../../models/chartItem.model';
import { Image } from '../../models/image.model';
import { ChartItemService } from '../../../core/data-services/chartItem.service';
import { environment } from '../../../../environments/environment';
import { CreateChartResource } from '../../models/resources/createChartResource.model';
import { HttpParams } from '@angular/common/http';
import { GlobalIntegrationServices } from '../../services/globalIntegration.services';
import { BannerNotificationsService, UtilsService } from '../../../xform-compat';
import { CONFIG } from '../../../config';


@Component({
  selector: 'emap-authoring-chart',
  templateUrl: './authoring-chart.component.html',
  styleUrls: ['./authoring-chart.component.scss'],
  providers: [ChartItemService],
  encapsulation: ViewEncapsulation.None
})
export class AuthoringChartComponent implements OnInit, OnDestroy {

  private AUTHORING_CHART_DIALOG_ID = 'authoring-chart-dialog';
  private AUTHORING_CHART_DIALOG_MAIN_CLASS = 'authoring-chart-dialog-main';
  private AUTHORING_CHART_DIALOG_NEW_SECTION_TEXT_CLASS = 'authoring-chart-dialog-new-section-text';
  private AUTHORING_CHART_DIALOG_ACCORDION_MAIN_CLASS = 'authoring-chart-dialog-accordion-main';
  private AUTHORING_CHART_DIALOG_ACCORDION_CANCEL_CLASS = 'authoring-chart-dialog-accordion-cancel';
  private AUTHORING_CHART_DIALOG_ACCORDION_HEADER_CLASS = 'authoring-chart-dialog-accordion-header';
  private AUTHORING_CHART_DIALOG_ACCORDION_PANEL_CLASS = 'authoring-chart-dialog-accordion-panel';
  private AUTHORING_CHART_DIALOG_ACCORDION_HEADER_TEXT_CLASS = 'authoring-chart-dialog-accordion-header-text';
  private AUTHORING_CHART_DIALOG_ACCORDION_TABLE_CLASS = 'authoring-chart-dialog-accordion-table';
  private AUTHORING_CHART_DIALOG_ACCORDION_TABLE_ROW_CLASS = 'authoring-chart-dialog-accordion-table-row';
  private AUTHORING_CHART_DIALOG_ACCORDION_HEADER_DELETE_CLASS =  'authoring-chart-dialog-accordion-header-delete';
  private AUTHORING_CHART_DIALOG_ACCORDION_HEADER_EDIT_CLASS = 'authoring-chart-dialog-accordion-header-edit';
  private AUTHORING_CHART_DIALOG_ACCORDION_HEADER_ENTER_CLASS = 'authoring-chart-dialog-accordion-header-enter';
  private AUTHORING_CHART_DIALOG_ACCORDION_TABLE_ROW_UPLOAD_CLASS = 'authoring-chart-dialog-accordion-table-row-upload';
  private AUTHORING_CHART_DIALOG_ACCORDION_SAVE_CLASS = 'authoring-chart-dialog-accordion-save';
  private GLOBAL_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS = 'global-panel-results-table-header-icon';
  private AUTHORING_CHART_UPLOAD_DIALOG_ID = 'authoring-chart-upload-dialog';
  private AUTHORING_CHART_UPLOAD_DIALOG_FILTER_IMAGE_INPUT_ID = 'authoring-chart-upload-dialog-filter-image-input';
  private AUTHORING_CHART_UPLOAD_DIALOG_FILTER_BUTTON_ID = 'authoring-chart-upload-dialog-filter-button';
  private AUTHORING_CHART_UPLOAD_DIALOG_SELECT_PARENT_ID = 'authoring-chart-upload-dialog-select-parent';
  private AUTHORING_CHART_UPLOAD_DIALOG_SELECT_ID = 'authoring-chart-upload-dialog-select';
  private AUTHORING_CHART_UPLOAD_DIALOG_ACCORDION_UPDATE_CLASS = 'authoring-chart-upload-dialog-accordion-update';
  private AUTHORING_CHART_UPLOAD_DIALOG_ACCORDION_CANCEL_CLASS = 'authoring-chart-upload-dialog-accordion-cancel';
  private SYMBOL_DISPLAY_NONE_CLASS = 'symbol-display-none';


  private CHART_PANEL_WIDTH = 800;
  private CHART_UPLOAD_DIALOG_PANEL_HEIGHT = 500;
  private IMAGE_DROP_DOWN_SCROLL_HEIGHT = 200;
  private CHART_EDITOR_TITLE = 'Chart Editor';
  private CHART_UPLOAD_EDITOR_TITLE = 'Upload Chart Image';

  private chartItemData: Array<ChartItem> = [];
  private chartImageReferenceData: Array<Image> = [];
  private currentParentRowSelector: any;
  private currentChartFilterVal: string;
  private s3url = CONFIG.aws.s3[environment.env];

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private authoringIntegrationService: AuthoringIntegrationService,
    private globalIntegrationServices: GlobalIntegrationServices,
    private imageService: ImageService,
    private bannerNotificationsService: BannerNotificationsService,
    private translateService: TranslateService,
    private chartItemService: ChartItemService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.initChart();
  }

  ngOnDestroy(): void {
    this.destroyDialogInstance(this.AUTHORING_CHART_DIALOG_ID.toId());
  }

  private initChart() {
    this.globalIntegrationServices.mapImageLoadEventEmitter.subscribe(() => {
      this.getChartItemData();
      this.getChartImageData();
      this.authoringChartIconClickHandler();
      this.addNewChartSectionClickEventHandler();
      this.clickCancelEventHandler();
      this.clickSaveEventHandler();
    });
  }

  private getChartItemData() {
    const chartItemsParams = {
      mapsManagerId: this.globalIntegrationServices.currentMapId,
    };
    this.chartItemService.getChartItem(this.utilsService.generateParams(chartItemsParams))
      .subscribe(
        value => { this.chartItemData = value; },
        error => { this.bannerNotificationsService
          .error(this.translateService.instant('AUTHORING.CHART.ERRORS.CHART_ITEM_ERROR')); }
      );
  }

  private getChartImageData() {
    const params = new HttpParams().set('category', 'chart');
    this.imageService.getAllImages(params)
      .subscribe(
        data => { this.chartImageReferenceData  = data; },
        error => this.bannerNotificationsService.error(this.translateService.instant('AUTHORING.CHART.ERRORS.CHART_IMAGE_ERROR'))
      );
  }

  private authoringChartIconClickHandler() {
    this.authoringIntegrationService.authoringChartIconClickEventHandler.subscribe(value => {
      this.authoringChartDialogInit();
    });
  }

  private authoringChartDialogInit() {
    const chartDialogOptions = {
      dialogClass: this.AUTHORING_CHART_DIALOG_MAIN_CLASS,
      width: this.CHART_PANEL_WIDTH,
      autoResize: true,
      height: 'auto',
      title: this.CHART_EDITOR_TITLE,
      open: this.chartDialogOpenEvent(),
      close: (event, ui) => { this.destroyDialogInstance(this.AUTHORING_CHART_DIALOG_ID.toId()); }
    } as any;
    this.jqueryCommonServices.dialog(this.AUTHORING_CHART_DIALOG_ID.toId(), chartDialogOptions);
  }

  private chartDialogOpenEvent() {
    const customAccordionOptions = { header: this.AUTHORING_CHART_DIALOG_ACCORDION_HEADER_CLASS.toClass() } as any;
    const customSortableOptions = { items: this.AUTHORING_CHART_DIALOG_ACCORDION_PANEL_CLASS.toClass() } as any;
    this.jqueryCommonServices
      .initiateAccordion(this.AUTHORING_CHART_DIALOG_ACCORDION_MAIN_CLASS.toClass(), customAccordionOptions)
      .sortable(customSortableOptions);
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_DIALOG_ACCORDION_TABLE_CLASS).sortable();
    this.bindChartItemData();
  }

  private bindChartItemData() {
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_DIALOG_ACCORDION_MAIN_CLASS.toClass()).empty();
    this.chartItemData.forEach((eachChartItemData) => {
      this.addNewChartSection(eachChartItemData);
    });
  }

  private addNewChartSectionClickEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_DIALOG_NEW_SECTION_TEXT_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_CHART_DIALOG_NEW_SECTION_TEXT_CLASS.toClass(), (event) => {
      this.addNewChartSection();
      this.jqueryCommonServices.selector(this.AUTHORING_CHART_DIALOG_ACCORDION_TABLE_CLASS.toClass()).sortable();
    });
  }

  private addNewChartSection(currentChartItem?: ChartItem) {
    const accordionMainClass = '.authoring-chart-dialog-accordion-main.global-panel-results-table.ui-accordion';
    const accordionMainSelector = this.jqueryCommonServices.selector(accordionMainClass);
    const sampleHeaderText = 'Sample Header';
    const accordionNewPanel = this.jqueryCommonServices.divConstruct()
      .addClass(this.AUTHORING_CHART_DIALOG_ACCORDION_PANEL_CLASS)
      .addClass('chart-data');
    const chartTitleDescription = currentChartItem ? currentChartItem.title : null;
    const currentChartItemImage = currentChartItem ? currentChartItem.image : null;
    accordionNewPanel
      .append(this.constructChartHeader(chartTitleDescription ? chartTitleDescription : sampleHeaderText))
      .append(this.constructChartItem(currentChartItemImage));
    accordionMainSelector.append(accordionNewPanel);
    this.jqueryCommonServices.accordionRefresh(accordionMainSelector);
    this.overrideAccordionHeaderKeyPress();
    this.elementRefreshEvents();
  }

  private clickCancelEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_DIALOG_ACCORDION_CANCEL_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_CHART_DIALOG_ACCORDION_CANCEL_CLASS.toClass(), (event) => {
      this.jqueryCommonServices.selector(this.AUTHORING_CHART_DIALOG_ID.toId()).dialog('destroy');
    });
  }

  private destroyDialogInstance(element: any) {
    const chartDialogSelector = this.jqueryCommonServices.selector(element);
    if (chartDialogSelector.dialog('instance')) {
      chartDialogSelector.dialog('destroy');
    }
  }

  private constructChartHeader(headerText: string) {
    const newHeaderSelector = this.jqueryCommonServices.divConstruct().addClass(this.AUTHORING_CHART_DIALOG_ACCORDION_HEADER_CLASS);
    const enterClass = 'authoring-chart-dialog-accordion-header-enter symbol-display-none chart-header-margin fa fa-check icon';
    const editClass = 'authoring-chart-dialog-accordion-header-edit chart-header-margin fa fa-pencil icon';
    const deleteClass = 'authoring-chart-dialog-accordion-header-delete chart-header-margin fa fa-trash icon';
    newHeaderSelector
      .append(this.jqueryCommonServices.divConstruct().addClass(enterClass))
      .append(this.jqueryCommonServices.divConstruct().addClass(editClass))
      .append(this.jqueryCommonServices.divConstruct().addClass(deleteClass))
      .append(this.jqueryCommonServices.divConstruct().addClass(this.GLOBAL_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS));
    if (headerText) {
      newHeaderSelector
        .append(this.jqueryCommonServices.divConstruct().addClass(this.AUTHORING_CHART_DIALOG_ACCORDION_HEADER_TEXT_CLASS)
          .append('<input value="' + headerText + '" readonly>'));
    }
    return newHeaderSelector;
  }

  private overrideAccordionHeaderKeyPress() {
    this.jqueryCommonServices.selector('.authoring-chart-dialog-accordion-header-text input').keydown(event => {
      event.stopImmediatePropagation();
    });
  }

  private elementRefreshEvents() {
    this.chartUploadImageIconClickEventHandler();
    this.chartRowDeleteClickEventHandler();
    this.chartRowEditClickEventHandler();
  }

  private constructChartItem(currentImage?: Image) {
    const newChartItem = this.jqueryCommonServices.divConstruct().addClass(this.AUTHORING_CHART_DIALOG_ACCORDION_TABLE_CLASS);
    newChartItem.append(this.constructChartItemRow(currentImage));
    return newChartItem;
  }

  private constructChartItemRow(currentImage?: Image) {
    const chartRowSelector = this.jqueryCommonServices.divConstruct().addClass(this.AUTHORING_CHART_DIALOG_ACCORDION_TABLE_ROW_CLASS);
    chartRowSelector.append(this.constructChartRowImage(currentImage)).append(this.constructChartRowUploadButton());
    return chartRowSelector;
  }

  private constructChartRowImage(currentImage?: Image) {
    const chartRowImageSelector = this.jqueryCommonServices.divConstruct()
      .addClass('authoring-chart-dialog-accordion-table-row-symbol authoring-chart-dialog-accordion-table-row-elem');
    const imageElement = this.jqueryCommonServices.selector('<img>');
    if (currentImage) {
      imageElement.attr('src', this.s3url.concat(currentImage.s3Reference));
      this.jqueryCommonServices.selector(imageElement).data('image', currentImage);
    }
    chartRowImageSelector.append(imageElement);
    return chartRowImageSelector;
  }

  private constructChartRowUploadButton() {
    const chartRowDescriptionSelector = this.jqueryCommonServices.divConstruct()
      .addClass(this.AUTHORING_CHART_DIALOG_ACCORDION_TABLE_ROW_UPLOAD_CLASS)
      .addClass('authoring-chart-dialog-accordion-table-row-elem');
    chartRowDescriptionSelector
      .append(this.jqueryCommonServices.divConstruct('Upload Image').addClass('authoring-chart-dialog-table-row-upload-button'));
    return chartRowDescriptionSelector;
  }

  private chartUploadImageIconClickEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_DIALOG_ACCORDION_TABLE_ROW_UPLOAD_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_CHART_DIALOG_ACCORDION_TABLE_ROW_UPLOAD_CLASS.toClass(), (event) => {
      if (!event.hasOwnProperty('target')) { return; }
      const eventTargetSelector = this.jqueryCommonServices.selector(event.target);
      const parentRowSelector = eventTargetSelector.parent().parent();
      const uploadImageDialogOptions = {
        title: this.CHART_UPLOAD_EDITOR_TITLE,
        height: this.CHART_UPLOAD_DIALOG_PANEL_HEIGHT,
        open: this.initiateImageDropDown.bind(this),
        close: (e, ui) => { this.toggleDialogDisplayState(this.AUTHORING_CHART_DIALOG_ID.toId(), true); }
      } as any;
      this.currentParentRowSelector = parentRowSelector;
      this.jqueryCommonServices.dialog(this.AUTHORING_CHART_UPLOAD_DIALOG_ID.toId(), uploadImageDialogOptions);
    });
  }

  private initiateImageDropDown() {
    this.addSelectOptionElement();
    this.bindImageDropDownData();
    const imageDropDownOption = { height: this.IMAGE_DROP_DOWN_SCROLL_HEIGHT };
    this.toggleDialogDisplayState(this.AUTHORING_CHART_DIALOG_ID.toId(), false);
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_UPLOAD_DIALOG_SELECT_ID.toId()).ddslick(imageDropDownOption);
    this.updateCurrentImageSelection();
    this.cancelCurrentImageSelection();
  }

  private addSelectOptionElement() {
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_UPLOAD_DIALOG_SELECT_PARENT_ID.toId()).empty()
      .append('<select id="' + this.AUTHORING_CHART_UPLOAD_DIALOG_SELECT_ID + '"></select>');
  }

  public filterImageDropDownImages() {
    this.currentChartFilterVal = this.jqueryCommonServices.selector(this.AUTHORING_CHART_UPLOAD_DIALOG_FILTER_IMAGE_INPUT_ID.toId()).val();
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_UPLOAD_DIALOG_SELECT_PARENT_ID.toId()).empty();
    this.addSelectOptionElement();
    this.bindImageDropDownData();
    const imageDropDownOption = { height: this.IMAGE_DROP_DOWN_SCROLL_HEIGHT };
    this.toggleDialogDisplayState(this.AUTHORING_CHART_DIALOG_ID.toId(), false);
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_UPLOAD_DIALOG_SELECT_ID.toId()).ddslick(imageDropDownOption);
    this.updateCurrentImageSelection();
    this.cancelCurrentImageSelection();
  }

  private bindImageDropDownData() {
    const chartOptionSelector = this.jqueryCommonServices.selector(this.AUTHORING_CHART_UPLOAD_DIALOG_SELECT_ID.toId());
    chartOptionSelector.empty();
    chartOptionSelector.ddslick('destroy');
    this.chartImageReferenceData.forEach((eachChartImage) => {
      if (typeof (this.currentChartFilterVal) === 'undefined' ||
        (eachChartImage.fileName.toLowerCase().includes(this.currentChartFilterVal.toLowerCase()))) {
        chartOptionSelector.append(
          this.jqueryCommonServices.selector('<option>')
            .attr('value', eachChartImage.id)
            .attr('data-imagesrc', this.s3url.concat(eachChartImage.s3Reference))
            .attr('data-description', eachChartImage.fileName)
        );
      }
    });
  }

  private updateCurrentImageSelection() {
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_UPLOAD_DIALOG_ACCORDION_UPDATE_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_CHART_UPLOAD_DIALOG_ACCORDION_UPDATE_CLASS.toClass(), (event) => {
      const currentDropDownSelection = this.jqueryCommonServices.selector(this.AUTHORING_CHART_UPLOAD_DIALOG_SELECT_ID.toId()).data('ddslick');
      if (!currentDropDownSelection) { return; }
      const selectedData = currentDropDownSelection['selectedData'];
      const imageSelector = this.jqueryCommonServices.selector(this.currentParentRowSelector).find('img');
      imageSelector.attr('src', selectedData['imageSrc']);
      const currentImage = new Image();
      currentImage.id = selectedData['value'];
      currentImage.s3Reference = selectedData['imageSrc'].replace(this.s3url, '');
      imageSelector.data('image', currentImage);
      this.jqueryCommonServices.selector(this.AUTHORING_CHART_UPLOAD_DIALOG_ID.toId()).dialog('close');
      this.toggleDialogDisplayState(this.AUTHORING_CHART_DIALOG_ID.toId(), true);
    });
  }

  private cancelCurrentImageSelection() {
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_UPLOAD_DIALOG_ACCORDION_CANCEL_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_CHART_UPLOAD_DIALOG_ACCORDION_CANCEL_CLASS.toClass(), (event) => {
      this.jqueryCommonServices.selector(this.AUTHORING_CHART_UPLOAD_DIALOG_ID.toId()).dialog('close');
      this.toggleDialogDisplayState(this.AUTHORING_CHART_DIALOG_ID.toId(), true);
    });
  }

  private toggleDialogDisplayState(uiElement: any, state: boolean) {
    const dialogElement = this.jqueryCommonServices.selector(uiElement).dialog('instance')['uiDialog'];
    state ? dialogElement.show() : dialogElement.hide();
  }

  private chartRowEditClickEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_DIALOG_ACCORDION_HEADER_EDIT_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_CHART_DIALOG_ACCORDION_HEADER_EDIT_CLASS.toClass(), (event) => {
      if (!event.hasOwnProperty('target')) { return; }
      event.stopImmediatePropagation();
      const parent = this.jqueryCommonServices.selector(event.target).parent();
      const inputSelector = this.jqueryCommonServices.selector(parent).find('input');
      inputSelector.removeAttr('readonly');
      inputSelector.val('');
      inputSelector.focus();
      const inputEnterSelector = this.jqueryCommonServices.selector(parent).find(this.AUTHORING_CHART_DIALOG_ACCORDION_HEADER_ENTER_CLASS.toClass());
      inputEnterSelector.removeClass(this.SYMBOL_DISPLAY_NONE_CLASS);
      this.jqueryCommonServices.clickEvent(inputEnterSelector, (e) => {
        inputSelector.attr('readonly', true);
        inputEnterSelector.addClass(this.SYMBOL_DISPLAY_NONE_CLASS);
        e.stopImmediatePropagation();
      });
    });
  }

  private chartRowDeleteClickEventHandler() {
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_DIALOG_ACCORDION_HEADER_DELETE_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_CHART_DIALOG_ACCORDION_HEADER_DELETE_CLASS.toClass(), (event) => {
      if (!event.hasOwnProperty('target')) { return ; }
      const parentSelector = this.jqueryCommonServices.selector(event.target).parent().parent();
      parentSelector.remove();
    });
  }

  private clickSaveEventHandler() {
    const symbolTextInputClass = 'input';
    const symbolImageInputClass = 'img';
    this.jqueryCommonServices.selector(this.AUTHORING_CHART_DIALOG_ACCORDION_SAVE_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.AUTHORING_CHART_DIALOG_ACCORDION_SAVE_CLASS.toClass(), (event) => {
      let chartItemValidationCheck = true;
      const newChartItems = [];
      this.jqueryCommonServices.selector('.chart-data').each((index, eachChartData) => {
        const currentCharItem = new ChartItem();
        const currentElementSelector = this.jqueryCommonServices.selector(eachChartData);
        const imagePath = currentElementSelector.find(symbolImageInputClass);
        const rowDescription = currentElementSelector.find(symbolTextInputClass);
        currentCharItem.title = rowDescription.val();
        currentCharItem.image = imagePath.data('image');
        if (typeof currentCharItem.image === 'undefined') {
          chartItemValidationCheck = false;
          alert('Please select an image at index : ' + index);
        }
        newChartItems.push(currentCharItem);
      });
      this.chartItemData = newChartItems;
      const createChartResource = new CreateChartResource();
      createChartResource.mapId = this.globalIntegrationServices.currentMapId;
      createChartResource.createChartItemResourceList = this.chartItemData;
      if (chartItemValidationCheck) {
        this.chartItemService.postChartItemData(createChartResource).subscribe(
          value => { this.bannerNotificationsService.success(this.translateService.instant('AUTHORING.CHART.SAVE_SUCCESS')); },
          error => { this.bannerNotificationsService.error(this.translateService.instant('AUTHORING.CHART.ERRORS.CHART_ITEM_SAVE_ERROR')); }
        );
        this.destroyDialogInstance(this.AUTHORING_CHART_DIALOG_ID.toId());
      }
    });
  }
}
