import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PasswordExpiration } from '../../shared/models';
import { AbstractCRUD, CRUDService, ExceptionService, UtilsService } from '../../xform-compat';


@Injectable()
export class PasswordExpirationService extends AbstractCRUD<PasswordExpiration> implements CRUDService<PasswordExpiration> {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath(): string {
    return '/uaa/passwordexpirationevents';
  }
}
