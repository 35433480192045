<div *ngIf="notification.status" class="banner-notification align-items-center" [ngClass]="notification.type">
  <div class="pull-left banner-message">
    <p>
      <b>{{"NOTIFICATIONS.NOTICE" | translate}}:</b>
      <span>{{notification.text}}</span>
      <a *ngIf="notification.link" name="notification" title="{{translations['NOTIFICATIONS.VIEW_MORE']}}" href="{{notification.link}}"
         target="_blank">{{"NOTIFICATIONS.VIEW_MORE" | translate}}</a>
    </p>
  </div>
  <a class="banner-close pull-right" title="{{translations['NOTIFICATIONS.CLOSE']}}" (click)="close()"><b>X</b></a>
</div>
