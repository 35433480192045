import {BaseEntity} from '../../xform-compat';
import {MilestoneCategoryValue} from './milestoneCategoryValue.model';
import {Synonym} from './synonym.model';

export class Milestone implements BaseEntity {
  id: number;
  source: string;
  milestoneCategoryValues: Array<MilestoneCategoryValue>;
  synonyms: Array<Synonym>;

  constructor(milestone: any = {}) {
    this.id = milestone.id || null;
    this.source = milestone.source || null;
    this.milestoneCategoryValues = milestone.milestoneCategoryValues
      ? milestone.milestoneCategoryValues.map(mcv => new MilestoneCategoryValue(mcv))
      : null;
    this.synonyms = milestone.synonyms
      ? milestone.synonyms.map(s => new Synonym(s))
      : null;
  }
}
