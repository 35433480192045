<div class="modal-header">
    <h4 class="modal-title">Unsaved Changes!</h4>
    <button type="button" class="close" aria-label="Close" (click)="onDismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-title-border"></div>
</div>
<div class="modal-body">
    You are about to navigate away from this map.
    Please make sure you save all of your changes before you go.
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default btn-leave-without-saving" (click)="onConfirmation(false)">
        {{"GENERICS.LEAVE_WITHOUT_SAVING" | translate}}
    </button>
    <button type="button" class="btn btn-default btn-save-changes" (click)="onConfirmation(true)">
        {{"GENERICS.SAVE_CHANGES" | translate}}
    </button>
</div>
