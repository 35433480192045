import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

import { routedComponents, SystemAdminRoutingModule } from './system-admin-routing.module';


@NgModule({
  imports: [
    SharedModule,
    SystemAdminRoutingModule
  ],
  declarations: [routedComponents]
})
export class SystemAdminModule {}
