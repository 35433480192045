import { BaseEntity } from '../../xform-compat';

export class NodeHierarchy implements BaseEntity {
    id: number;
    parentNodeId: number;
    childNodeId: number;

    constructor(map: any = {}) {
        this.id = map.id || null;
        this.parentNodeId = map.parentNodeId || null;
        this.childNodeId = map.childNodeId || null;
    }
}
