import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ZpanCommonServices } from './zpanCommon.services';
import { MAP_IMAGE_ACCESSOR, MAP_VIEW_STATE } from '../enums';
import { environment } from '../../../environments/environment';
import { CONFIG } from '../../config';


@Injectable()
export class GlobalIntegrationServices {
  private aws = CONFIG.aws;

  private _ratio = 1.0;
  private _zoomData: any;
  private _marginLeftBase = 0; // production
  private _marginRightBase = 450; // production
  private _marginTop = 0;
  private _marginBottom = 200;
  private _mapImageClassName = 'mapImage';

  private _scaledWidth: number;
  private _scaledHeight: number;

  private _marginLeft = this._marginLeftBase;
  private _marginRight = this._marginRightBase;

  private _currentZpanCommonService: ZpanCommonServices;
  private _currentMapImageSelector: any;
  private _currentMapViewState: MAP_VIEW_STATE;

  private _currentMapId = 1;
  private _currentMapVersionId = 1;  // TODO: update on load and on map change
  private _mapImagesIndex = new Map<number, Map<MAP_IMAGE_ACCESSOR, string>>();

  private _zoomCompleteEventEmitter: Subject<object> = new Subject<object>();
  private _mapImageLoadEventEmitter: Subject<object> = new Subject<object>();
  private _mapImageIndexLoadEventEmitter: Subject<object> = new Subject<object>();

  private _bulkMapDeployOpenEventHandler: Subject<object> = new Subject<object>();

  get ratio(): number {
    return this._ratio;
  }

  set ratio(value: number) {
    this._ratio = value;
  }

  get mapImageClassName(): string {
    return this._mapImageClassName;
  }

  get scaledHeight(): number {
    return this._scaledHeight;
  }

  set scaledHeight(value: number) {
    this._scaledHeight = value;
  }

  get scaledWidth(): number {
    return this._scaledWidth;
  }

  set scaledWidth(value: number) {
    this._scaledWidth = value;
  }

  get zoomData(): any {
    return this._zoomData;
  }

  set zoomData(value: any) {
    this._zoomData = value;
  }

  get marginLeftBase(): number {
    return this._marginLeftBase;
  }

  set marginLeftBase(value: number) {
    this._marginLeftBase = value;
  }

  get marginRightBase(): number {
    return this._marginRightBase;
  }

  set marginRightBase(value: number) {
    this._marginRightBase = value;
  }

  get marginTop(): number {
    return this._marginTop;
  }

  set marginTop(value: number) {
    this._marginTop = value;
  }

  get marginBottom(): number {
    return this._marginBottom;
  }

  set marginBottom(value: number) {
    this._marginBottom = value;
  }

  get marginLeft(): any {
    return this._marginLeft;
  }

  set marginLeft(value: any) {
    this._marginLeft = value;
  }

  get marginRight(): number {
    return this._marginRight;
  }

  set marginRight(value: number) {
    this._marginRight = value;
  }

  get currentZpanCommonService(): ZpanCommonServices {
    return this._currentZpanCommonService;
  }

  set currentZpanCommonService(value: ZpanCommonServices) {
    this._currentZpanCommonService = value;
  }

  get currentMapImageSelector(): any {
    return this._currentMapImageSelector;
  }

  set currentMapImageSelector(value: any) {
    this._currentMapImageSelector = value;
  }

  get currentMapViewState(): MAP_VIEW_STATE {
    return this._currentMapViewState;
  }

  set currentMapViewState(value: MAP_VIEW_STATE) {
    this._currentMapViewState = value;
  }

  get currentMapId(): number {
    return this._currentMapId;
  }

  set currentMapId(value: number) {
    this._currentMapId = value;
  }

  get currentMapVersionId(): number {
    return this._currentMapVersionId;
  }

  set currentMapVersionId(value: number) {
    this._currentMapVersionId = value;
  }

  get mapImagesIndex(): Map<number, Map<MAP_IMAGE_ACCESSOR, string>> {
    return this._mapImagesIndex;
  }

  set mapImagesIndex(value: Map<number, Map<MAP_IMAGE_ACCESSOR, string>>) {
    this._mapImagesIndex = value;
  }

  get zoomCompleteEventEmitter(): Subject<object> {
    return this._zoomCompleteEventEmitter;
  }

  get mapImageLoadEventEmitter(): Subject<object> {
    return this._mapImageLoadEventEmitter;
  }

  get mapImageIndexLoadEventEmitter(): Subject<object> {
    return this._mapImageIndexLoadEventEmitter;
  }

  get bulkMapDeployOpenEventHandler(): Subject<object> {
    return this._bulkMapDeployOpenEventHandler;
  }

  set bulkMapDeployOpenEventHandler(value: Subject<object>) {
    this._bulkMapDeployOpenEventHandler = value;
  }

  public getMarginLeftUnscaled() {
    return (this.marginLeft / this.ratio);
  }

  public getMarginRightUnscaled() {
    return (this.marginRight / this.ratio);
  }

  public getMarginTopUnscaled() {
    return (this.marginTop / this.ratio);
  }

  public getMarginBottomUnscaled() {
    return (this.marginBottom / this.ratio);
  }

  public getMapVersionImageSource(mapVersionId: number, imageType: MAP_IMAGE_ACCESSOR) {
    const mapBucketReference = this._mapImagesIndex.get(Number(mapVersionId)).get(imageType);
    return this.aws.s3[environment.env].concat(mapBucketReference);
  }

  public getMapVersionImageSourceDynamic(mapVersionId: number) {
    const imageType = (this._currentMapViewState === MAP_VIEW_STATE.Authoring) ? MAP_IMAGE_ACCESSOR.Nav : MAP_IMAGE_ACCESSOR.Png;
    const mapBucketReference = this._mapImagesIndex.get(Number(mapVersionId)).get(imageType);
    return this.aws.s3[environment.env].concat(mapBucketReference);
  }

}
