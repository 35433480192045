import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'xform-system-admin-parent',
  templateUrl: './system-admin-parent.component.html'
})
export class SystemAdminParentComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
