import { Injectable } from '@angular/core';
import { CONFIG } from '../../config';
import { HttpClient } from '@angular/common/http';
import { CategoryResource } from '../../shared/models/resources/categoryResource.model';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CategoryService extends AbstractCRUD<CategoryResource> implements CRUDService<CategoryResource>, Searcher {

    private metadataPath = CONFIG.metadataPath;

    constructor(
        exceptionService: ExceptionService,
        http: HttpClient,
        utilsService: UtilsService
    ) {
        super(exceptionService, http, utilsService);
    }

    public getDisplayName(item: any): string {
        return item.name;
    }

    public getResourcePath() {
        return '/node/service/rms/category';
    }

    public getAllRmsCategoryPath() {
        return super.getApiPath().concat('/all');
    }

    public getAllRmsCategory() {
        return this.getHTTP().get(this.getAllRmsCategoryPath())
          .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }
}
