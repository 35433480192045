import {BaseEntity} from '../../xform-compat';
import {MILESTONE_CATEGORY} from '../enums';

export class MilestoneCategoryValue implements BaseEntity {
  id: number;
  name: string;
  milestoneCategory: MILESTONE_CATEGORY;

  constructor(milestoneCategoryValue: any = {}) {
    this.id = milestoneCategoryValue.id || null;
    this.name = milestoneCategoryValue.name || null;
    this.milestoneCategory = milestoneCategoryValue.milestoneCategory || null;
  }
}
