import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {EqualValidator} from '../core/directives/equal-validator.directive';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {PageComponent} from './components/page/page.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {ToolboxComponent} from './components/toolbox/toolbox.component';
import {TypeAheadComponent} from './components/type-ahead/type-ahead.component';
import {UserPasswordResetComponent} from './components/user-password-reset/user-password-reset.component';
import {NoContentComponent} from './components/no-content/no-content.component';
import {TreeViewSelectComponent} from './components/tree-view-select/tree-view-select.component';
import {RmsTzFormatPipe} from './pipes/rms-tz-format.pipe';
import {GlobalPanelComponent} from './components/global-panel/global-panel.component';
import {SearchPanelComponent} from './components/search-panel/search-panel.component';
import {FilterPanelComponent} from './components/filter-panel/filter-panel.component';
import {KeyboardComponent} from './components/keyboard/keyboard.component';
import {LegendPanelComponent} from './components/legend-panel/legend-panel.component';
import {ChartPanelComponent} from './components/chart-panel/chart-panel.component';
import {InsightPanelComponent} from './components/insight-panel/insight-panel.component';
import {MapImageComponent} from './components/map-image/map-image.component';
import {MenuPanelComponent} from './components/menu-panel/menu-panel.component';
import {AuthoringToolboxComponent} from './components/authoring-toolbox/authoring-toolbox.component';
import {AuthoringHeaderComponent} from './components/authoring-header/authoring-header.component';
import {AuthoringLegendComponent} from './components/authoring-legend/authoring-legend.component';
import {AuthoringChartComponent} from './components/authoring-chart/authoring-chart.component';
import {NodeLayerComponent} from './components/node-layer/node-layer.component';
import {NavigatorComponent} from './components/navigator/navigator.component';
import {DrugInformationTableComponent} from './components/drug-information-table/drug-information-table.component';
import {ChartDisplayComponent} from './components/chart-display/chart-display.component';
import {AuthoringOnrComponent} from './components/authoring-onr/authoring-onr.component';
import {MomentModule} from 'ngx-moment';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {UiSwitchModule} from 'ngx-ui-switch';
import {NgSelectModule} from '@ng-select/ng-select';
import {RichTextEditorComponent} from './components/rich-text-editor/rich-text-editor.component';
import {OrderByPipe} from "./pipes/order-by.pipe";
import {HighlightPipe} from "./pipes/highlight.pipe";
import { FilterPipe } from './pipes/filter.pipe';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MomentModule,
        NgbModule,
        RouterModule,
        SweetAlert2Module,
        NgSelectModule,
        TranslateModule.forChild(),
        UiSwitchModule,
    ],
    exports: [
        AuthoringToolboxComponent,
        AuthoringHeaderComponent,
        AuthoringLegendComponent,
        AuthoringChartComponent,
        ChartPanelComponent,
        CommonModule,
        EqualValidator,
        FilterPanelComponent,
        FormsModule,
        GlobalPanelComponent,
        KeyboardComponent,
        LegendPanelComponent,
        MomentModule,
        NgbModule,
        NgSelectModule,
        NoContentComponent,
        OrderByPipe,
        PageComponent,
        PaginationComponent,
        RmsTzFormatPipe,
        RouterModule,
        SearchPanelComponent,
        SidebarComponent,
        SweetAlert2Module,
        ToolboxComponent,
        TranslateModule,
        TreeViewSelectComponent,
        TypeAheadComponent,
        UiSwitchModule,
        UserPasswordResetComponent,
        InsightPanelComponent,
        MapImageComponent,
        MenuPanelComponent,
        NodeLayerComponent,
        NavigatorComponent,
        ChartDisplayComponent,
        AuthoringOnrComponent,
        RichTextEditorComponent,
        HighlightPipe,
        FilterPipe
    ],
    declarations: [
        AuthoringToolboxComponent,
        AuthoringHeaderComponent,
        AuthoringLegendComponent,
        AuthoringChartComponent,
        ChartPanelComponent,
        EqualValidator,
        FilterPanelComponent,
        GlobalPanelComponent,
        KeyboardComponent,
        LegendPanelComponent,
        NoContentComponent,
        OrderByPipe,
        PageComponent,
        PaginationComponent,
        RmsTzFormatPipe,
        SearchPanelComponent,
        SidebarComponent,
        ToolboxComponent,
        TreeViewSelectComponent,
        TypeAheadComponent,
        UserPasswordResetComponent,
        InsightPanelComponent,
        MapImageComponent,
        MenuPanelComponent,
        NodeLayerComponent,
        NavigatorComponent,
        DrugInformationTableComponent,
        NavigatorComponent,
        ChartDisplayComponent,
        AuthoringOnrComponent,
        RichTextEditorComponent,
        HighlightPipe,
        FilterPipe
    ]
})
export class SharedModule {
}
