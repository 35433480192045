import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

import { AuthRoutingModule, routedComponents } from './authentication-routing.module';
import { LoggerService } from '../../core/data-services/logger.service';
import { DuoSecurityService } from '../../core/data-services/duoSecurity.service';
import { TwoFactorComponent } from './two-factor/two-factor.component';


@NgModule({
  imports: [
    SharedModule,
    AuthRoutingModule
  ],
  declarations: [
    routedComponents,
    TwoFactorComponent
  ],
  providers: [LoggerService, DuoSecurityService]
})
export class AuthenticationModule {
}
