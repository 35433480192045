import { Injectable } from '@angular/core';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { CategoryValueResource } from '../../shared/models/resources/categoryValueResource.model';
import { CONFIG } from '../../config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AdminCategoryValueService extends AbstractCRUD<CategoryValueResource> implements CRUDService<CategoryValueResource>, Searcher {

    private metadataPath = CONFIG.metadataPath;

    constructor(
        exceptionService: ExceptionService,
        http: HttpClient,
        utilsService: UtilsService
    ) {
        super(exceptionService, http, utilsService);
    }

    public getDisplayName(item: any): string {
        return item.name;
    }

    public getResourcePath() {
        return '/core/service/admin/categoryvalue';
    }

    public getPutResourcePath() {
        return '/api/core/service/admin/categoryvalue';
    }

    public putCategoryValue(body: object) {
        return this.getHTTP().put(this.getPutResourcePath(), body)
            .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }
}
