import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventsHubService } from '../../../core/events-hub.service';
import { AuthorizationService } from '../../../core/services/authorization.service';
import { ItemMenu } from '../../models/interfaces/itemMenu.interface';
import { MENU } from '../../menu';

@Component({
  selector: 'xform-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public menu: Array<ItemMenu>;
  public openMobileMenu: boolean;

  constructor(
    private eventsHubService: EventsHubService,
    private router: Router,
    private authorizationService: AuthorizationService
  ) {
    this.openMobileMenu = false;
    this.eventsHubService.isMenuOpen$.subscribe(
      (response) => {
        this.openMobileMenu = response;
      }
    );
  }

  ngOnInit() {
    this.menu = this.authorizationService.filterMenu(MENU);
  }

  public checkSubmenu(item: ItemMenu) {
    let keepOpen: boolean;
    keepOpen = !!item['subMenu'].length;
    this.eventsHubService.setMenuState(keepOpen);
    setTimeout(() => { // Delay on Mobile view to let the menu close before load the page
      this.router.navigate([item.link]);
    }, 250);
  }

  public closeMenu() {
    this.eventsHubService.setMenuState(false);
  }

}
