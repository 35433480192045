import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExceptionService, UtilsService } from '../../xform-compat';
import { CONFIG } from '../../config';
import { NotificationCategory, ChannelType } from '../../shared/models';
import { catchError } from 'rxjs/operators';


/**
 * Resource service, API integration layer for User Notifications
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationsSettingsService {
  private notificationsPath =  `${CONFIG.apiUrl}/notifications`;

  constructor(
    private utilsService: UtilsService,
    private exceptionService: ExceptionService,
    private http: HttpClient
  ) { }

  public editNotification(id: number, params?: any) {
    const httpParams = this.utilsService.generateParams(params);

    return this.http.put(`${this.notificationsPath}/${id}`, { params: httpParams })
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getCategories(params?: any) {
    const httpParams = this.utilsService.generateParams(params);

    return this.http.get<Array<NotificationCategory>>(`${this.notificationsPath}/categories`, { params: httpParams })
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getCategory(id: number, params?: any) {
    const httpParams = this.utilsService.generateParams(params);

    return this.http.get(`${this.notificationsPath}/categories/${id}`, { params: httpParams })
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getTypes(params?: any) {
    const httpParams = this.utilsService.generateParams(params);

    return this.http.get(`${this.notificationsPath}/types`, { params: httpParams })
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getType(id: number, params?: any) {
    const httpParams = this.utilsService.generateParams(params);

    return this.http.get(`${this.notificationsPath}/types/${id}`, { params: httpParams })
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getUserTypes(id: number, params?: any) {
    const httpParams = this.utilsService.generateParams(params);

    return this.http.get(`${this.notificationsPath}/users/${id}/types`, { params: httpParams })
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public updateUserNotifications(userId: number, notification: any) {

    return this.http.put(`${this.notificationsPath}/users/${userId}/types`, notification)
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getChannelTypes() {
    return this.http.get<Array<ChannelType>>(`${this.notificationsPath}/channels`)
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

}
