<div class="container-fluid">
    <div class="row">
        <div class="col">
            <ul ngbNav #nav="ngbNav" class="detail-tabs">
                <li *ngFor="let milestoneType of milestoneTypeList; let i = index" [ngbNavItem]="i">
                    <a ngbNavLink>{{ milestoneType }}</a>
                    <ng-template ngbNavContent>
                        <div class="p-2 selected-filters">
                            <div class="selected-filters-title">
                                Selected Filters:
                            </div>
                            <div *ngIf="filterMapActive" class="selected-filters-body">
                                <div *ngFor="let filter of getFilterList()" class="selected-filters-filter">
                                    <i class="fa fa-times"
                                       (click)="removeFilter(filter)"></i><span>{{filter.category}}</span>|<span
                                        class="bold">{{filter.value}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="p-2">
                            <table matSort (matSortChange)="sortData($event)"
                                   class="table table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th mat-sort-header="companyProductLabel" id="Company/Product">
                                        Company/Product
                                        <i class="filter-icon" (click)="filterData($event, 'Company/Product')"></i>
                                    </th>
                                    <th mat-sort-header="{{ columnHeader }}"
                                        *ngFor="let columnHeader of columnHeaderList"
                                        class="table-header" id="{{columnHeader}}">
                                        {{ columnHeader }}
                                        <i class="filter-icon" (click)="filterData($event, columnHeader)"></i>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let milestoneData of getMilestoneDataList(milestoneType)">
                                    <td>{{ milestoneData.companyProductLabel }}</td>
                                    <td *ngFor="let columnHeader of columnHeaderList">
                                        {{ getCategoryValue(milestoneData, columnHeader) }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>
