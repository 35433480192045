import { NgModule } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../../shared/shared.module';
import { AdminRoutingModule, routedComponents } from './admin-routing.module';
import { UserFormComponent } from './user/user-form/user-form.component';
import { NotificationsSettingsService } from '../../core/data-services/notificationSettings.service';
import { MediaListComponent } from './media/media-list/media-list.component';
import { MediaFormComponent } from './media/media-form/media-form.component';
import { FileUploadModule } from 'ng2-file-upload';


@NgModule({
  imports: [
    SharedModule,
    AdminRoutingModule,
    FileUploadModule,
  ],
  declarations: [
    routedComponents,
    UserFormComponent,
    MediaListComponent,
    MediaFormComponent,
  ],
  providers: [NgbActiveModal, NotificationsSettingsService],
  exports: [MediaListComponent, MediaFormComponent]
})
export class AdminModule { }
