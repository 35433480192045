<div class="container-fluid admin-page">
  <div class="row">
    <div class="col text-center admin-header">
      <h1>{{"USERS.USER_MANAGEMENT" | translate}}</h1>
      <button class="btn btn-primary position-absolute float-right btn-right-float"
              (click)="showModal()" *ngIf="authorizationService.hasPermission([PERMISSIONS.UserCreate])">
        + {{"USERS.ADD_USER" | translate}}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>{{"USERS.FIRST_NAME" | translate}}</th>
            <th>{{"USERS.LAST_NAME" | translate}}</th>
            <th>{{"USERS.LIST.EMAIL" | translate}}</th>
            <th>{{"ROLES.ROLE_S" | translate}}</th>
            <th>{{"USERS.JOB_TITLE" | translate}}</th>
            <th>{{"DATE.CREATED" | translate}}</th>
            <th>{{"USERS.LAST_LOGIN" | translate}}</th>
            <th class="table-actions-column"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of list.records">
            <td>
              <a [routerLink]="['./', item.id]">{{item.firstName}}</a>
            </td>
            <td>{{item.lastName}}</td>
            <td>{{item.emailAddress}}</td>
            <td>
              <ng-container *ngIf="item.userTenants.length > 0">
                <span *ngFor="let subItem of item.userTenants[0].tenantRoles; let isLast = last">{{subItem.description}}{{isLast ? '' : ', '}}</span>
              </ng-container>
            </td>
            <td>{{item.jobTitle}}</td>
            <td>{{item.created | amDateFormat: dateFormatConfig}}</td>
            <td>{{item.attributes.lastLogin | amDateFormat: dateFormatConfig}}</td>
            <td class="text-center">
              <button class="btn btn-link table-action table-action-edit" (click)="showModal(item)"
                      *ngIf="authorizationService.hasPermission([PERMISSIONS.UserUpdate])">
                <i class="fa fa-pencil"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <emap-pagination [pagination]="pagination"
                    [totalRecords]="list.totalRecords"
                    (onPageChanged)="getRecords()"
                    (onRecordsPerPageChanged)="setRecordsPerPage()">
  </emap-pagination>

  <rms-user-form (onRecordProcessed)="recordAdded()"></rms-user-form>

</div>
