import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { LocalStorageService, SpinnerService } from '../xform-compat';
import { EventsHubService } from './events-hub.service';
import { User } from '../shared/models';
import { TemplateModalService } from './services/template-modal.service';
import { UserService } from './data-services/user.service';
import { AuthenticationService } from './services/authentication.service';
import Swal from "sweetalert2";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private accessTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshingInProgress: boolean;

  constructor(
    private spinnerService: SpinnerService,
    private eventHub: EventsHubService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private templateModalService: TemplateModalService,
    private userService: UserService,
    public authService: AuthenticationService,
    private authenticationService: AuthenticationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authRequest = this.addAuthorizationHeader(req);

    return next.handle(authRequest).pipe(
      catchError(err => {

        if (err.status !== 500 && err.status !== 401 && err.status !== 0
          && !err.error.authCode && !err.url.includes('notifications/notifyerror')) {
          // tracking all global errors
          const error = new Error(err.message);
        }

        if ((err instanceof HttpErrorResponse || HttpEventType.Sent) && err.status === 401) {

          if (err.url.includes('oauth/token')) {
            return this.logout(err);
          }

          const token = this.localStorageService.get('tokenInfo');

          // refresh token and send request again
          if (token) {
            return this.refreshTokenAndRetryRequest(authRequest, next);
          }

          return this.logout(err);

        } else {
          this.spinnerService.stop();
          this.eventHub.setUnauthorizedError(false);
        }

        return observableThrowError(err);
      })
    );
  }

  private addAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {

    const tokenInfo = this.localStorageService.get('tokenInfo');

    if (tokenInfo && request.url.includes('/api')) {
      const req = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${tokenInfo.accessToken}`)
      });
      return req;
    }
    return request;
  }

  private refreshTokenAndRetryRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(null);

      const oldLoggedUserInfo = this.localStorageService.get('loggedUser');

      return this.authService.refreshToken(this.localStorageService.get('tokenInfo')).pipe(
        switchMap((token) => {

          this.checkForPermissionsChanges(oldLoggedUserInfo);
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(token);
          if (token) {
            // repeat failed request with new token
            return next.handle(this.addAuthorizationHeader(request));
          }
          return this.logout('');
        })
      );

    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(() => {
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(request));
        })
      );
    }
  }

  private logout(err): Observable<HttpEvent<any>> {
    this.refreshingInProgress = false;
    this.authService.clearLocalStorage();
    this.authService.logout();
    this.eventHub.stopHeartBeat();
    this.spinnerService.stop();

    return observableThrowError(err);
  }

  /**
   * Warn the user if there are changes on permissions that page will be reloaded
   */
  checkForPermissionsChanges(oldLoggedUserInfo): void {
    if (!oldLoggedUserInfo) {
      // This should only happen when the user is logging in from Okta.
      // Do not check for permission changes in this case.
      return;
    }
    this.userService.getLoggedUser().subscribe((newLoggedUserInfo: User) => {
      if (this.authenticationService.permissionsChanged(oldLoggedUserInfo, newLoggedUserInfo)) {
        Swal.fire(this.templateModalService.getInactivityOptions(
          this.translateService.instant('KEEP_ALIVE.REFRESH_WARNING_TITLE'),
          this.translateService.instant('KEEP_ALIVE.REFRESH_WARNING_MESSAGE')
        )).then(() => {
          setTimeout(() => location.reload(false), 15000);
        });
      }
    }, () => {});
  }
}
