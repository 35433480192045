import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { MapsManagerListComponent } from './maps-manager-list/maps-manager-list.component';


const routes: Routes = [
  { path: 'list', component: MapsManagerListComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MapsManagerRoutingModule {}
