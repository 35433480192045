import { Role } from './role.model';


export class UserTenant {
  id: number;
  name: string;
  administrator: boolean;
  tenantRoles: Array<any>;
  availableRoles: Array<Role>;

  constructor(userTenant?: any) {
    this.id = userTenant ? userTenant.id || null : null;
    this.name = userTenant ? userTenant.name || null : null;
    this.administrator = userTenant ? userTenant.administrator || false : false;
    this.tenantRoles = userTenant ? userTenant.tenantRoles || [ { id: null } ] : [ { id: null } ];
    this.availableRoles = [];
  }
}

