<section>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-12">
        <h5 class="text-uppercase">{{"PASSWORD.RESET_PASSWORD" | translate}}</h5>
      </div>
    </div>
  </div>
  <div class="mx-3 mt-1">
    <div class="container-fluid">
      <div class="row white-bg py-4 px-2 b-all rounded align-items-center">
        <div class="col-8">
          <p class="mb-0" *ngIf="email!=SentStatus.failed && user.active" [innerHTML]="'PASSWORD.RESET_YOUR_PASSWORD' | translate"></p>
          <p class="mb-0" *ngIf="email!=SentStatus.failed && !user.active">
            <span [innerHTML]="'PASSWORD.INACTIVE_INFO' | translate"></span>
            <small>{{lastSent}}</small>
          </p>
          <p class="mb-0 failed-text" *ngIf="email==SentStatus.failed" [innerHTML]="'PASSWORD.FAIL_SENT_INFO' | translate"></p>
        </div>
        <div class="col-4 text-right white-bg">
          <button type="button" *ngIf="email==SentStatus.ready && user.active" (click)="resetPassword()" class="btn btn-secondary text-uppercase">
            {{"PASSWORD.SEND_RESET_LINK" | translate}}
          </button>
          <button type="button" *ngIf="email==SentStatus.ready && !user.active" (click)="resendActivation()" class="btn btn-secondary text-uppercase">
            {{"PASSWORD.RESEND_RESET_LINK" | translate}}
          </button>
          <button type="button" *ngIf="email==SentStatus.sent" class="btn btn-sent text-uppercase"><i class="fa fa-check" aria-hidden="true"></i>
            {{"PASSWORD.LINK_SUCCESS_SENT" | translate}}
          </button>
          <button type="button" *ngIf="email==SentStatus.failed" class="btn btn-outline-danger text-uppercase" disabled><i class="fa fa-exclamation"
                                                                                                                           aria-hidden="true"></i>
            {{"PASSWORD.LINK_FAIL_SENT" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
