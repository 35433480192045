import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthoringIntegrationService } from '../../services/authoringIntegration.service';
import { NodeLayerIntegrationService } from '../../services/nodeLayerIntegration.service';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { ActivatedRoute, Router } from '@angular/router';
import { Node } from '../../models/node.model';
import { GlobalIntegrationServices } from '../../services/globalIntegration.services';
import { ROUTE_PATHS } from '../../enums';
import { ZpanCommonServices } from '../../services/zpanCommon.services';
import { BannerNotificationsService } from '../../../xform-compat';
import { MapVersionNode } from '../../models/mapVersionNode.model';


@Component({
  selector: 'emap-authoring-onr',
  templateUrl: './authoring-onr.component.html',
  styleUrls: ['./authoring-onr.component.scss'],
  providers: [JqueryCommonServices, ZpanCommonServices],
  encapsulation: ViewEncapsulation.None
})
export class AuthoringOnrComponent implements OnInit, OnDestroy {

  private ONR_PANEL_CLASS = 'onr-panel';
  private ONR_PANEL_OPEN_CLASS = 'onr-panel-open';
  private NODE_LAYER_CHILD_ELEMENT_PREFIX = 'node_';
  private NODE_LAYER_CHILD_CLASS = 'node-layer-child';
  private NODE_LAYER_CHILD_EXCLUDED_CLASS = 'node-layer-child-excluded';
  private NODE_LAYER_CHILD_PRIMARY_SELECTION_CLASS = 'node-layer-primary-selection';
  private NODE_LAYER_CHILD_SECONDARY_SELECTION_CLASS = 'node-layer-secondary-selection';

  public ready = false;
  public node: MapVersionNode = null;
  public orphanNodes: Map<string, MapVersionNode> = new Map();
  public excludedNodes: Map<string, MapVersionNode> = new Map();

  constructor(
    private globalIntegrationServices: GlobalIntegrationServices,
    public authoringIntegrationService: AuthoringIntegrationService,
    private nodeLayerIntegrationService: NodeLayerIntegrationService,
    private jqueryCommonServices: JqueryCommonServices,
    private bannerNotificationsService: BannerNotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    private zpanCommonServices: ZpanCommonServices,
  ) { }

  ngOnInit() {
    this.initRouteSubscription();
    this.subscribeSelectionEventHandler();
    this.subscribeNodeLayerLoadEventEmitter();
  }

  ngOnDestroy() {
    this.zpanCommonServices.overflowAuto();  // allow scrolling again
  }

  private initRouteSubscription() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.panel === 'onr') {
        this.jqueryCommonServices.selector(this.ONR_PANEL_CLASS.toClass()).show();
        this.togglePanelOpen(true);
      }
    });
  }

  private subscribeNodeLayerLoadEventEmitter() {
    const that = this;
    this.authoringIntegrationService.nodeLayerLoadEventEmitter.subscribe(event => {
      const nodes = that.nodeLayerIntegrationService.nodes;
      nodes.forEach(node => {
        switch (node.isIncluded) {
          case null:
            that.orphanNodes.set(node.id.toString(), node);
            break;
          case false:
            that.excludedNodes.set(node.id.toString(), node);
            break;
          default:
            break;
        }
      });
      that.ready = true;
    });
  }

  private subscribeSelectionEventHandler() {
    const that = this;
    this.authoringIntegrationService.nodeLayerSelectionEventHandler.subscribe(event => {
      const secondaryIds = that.authoringIntegrationService.nodeLayerModel.secondarySelectionIds;
      const nodes = that.nodeLayerIntegrationService.nodes;
      const nodeId = ((typeof nodes === 'undefined') || (secondaryIds.size > 0))
        ? null : that.authoringIntegrationService.nodeLayerModel.primarySelectionId;

      that.showNodeDetail(nodeId);
    });
  }

  public togglePanelOpen(state?: boolean) {
    this.jqueryCommonServices.selector(this.ONR_PANEL_CLASS.toClass()).toggleClass(this.ONR_PANEL_OPEN_CLASS, state);
  }

  private emitUnsavedChangesEvent() {
    this.authoringIntegrationService.nodeLayerModel.hasUnsavedChanges = true;
    this.authoringIntegrationService.nodeLayerUnsavedChangesEventHandler.next();
  }

  private showNodeDetail(id?) {
    this.node = (id === null) ? null : this.nodeLayerIntegrationService.nodes.get(id.toString());
  }

  private setNodeLayerSelection(id: string) {
    this.authoringIntegrationService.nodeLayerModel.primarySelectionId = id;
    this.authoringIntegrationService.nodeLayerModel.secondarySelectionIds.clear();

    this.jqueryCommonServices.selector(this.NODE_LAYER_CHILD_CLASS.toClass())
      .removeClass(this.NODE_LAYER_CHILD_PRIMARY_SELECTION_CLASS)
      .removeClass(this.NODE_LAYER_CHILD_SECONDARY_SELECTION_CLASS);
    this.jqueryCommonServices.selector(this.NODE_LAYER_CHILD_ELEMENT_PREFIX.concat(id).toId())
      .addClass(this.NODE_LAYER_CHILD_PRIMARY_SELECTION_CLASS);
  }

  private toggleIsIncluded(id: string, isIncluded: boolean) {
    this.orphanNodes.delete(id);
    if (isIncluded) {
      this.excludedNodes.delete(id);
    } else {
      this.excludedNodes.set(id, this.nodeLayerIntegrationService.nodes.get(id));
    }
    this.nodeLayerIntegrationService.nodes.get(id).isIncluded = isIncluded;
    this.jqueryCommonServices.selector(this.NODE_LAYER_CHILD_ELEMENT_PREFIX.concat(id).toId())
      .toggleClass(this.NODE_LAYER_CHILD_EXCLUDED_CLASS, !isIncluded);

    // trigger NodeLayer to place the node on the map
    this.authoringIntegrationService.nodeLayerToggleIncludeNodeEventEmitter.next({ 'nodeId': id });
  }

  public values(map: Map<string, MapVersionNode>) {
    const valArr = Array.from(map.values());
    valArr.sort((a, b) => a.name.localeCompare(b.name));
    return valArr;
  }

  public handleAddClick(id: string) {
    this.toggleIsIncluded(id, true);
    this.emitUnsavedChangesEvent();
  }

  public handleExcludeClick(id: string) {
    this.toggleIsIncluded(id, false);
    this.emitUnsavedChangesEvent();
  }

  public handleTableRowClick(id: string) {
    this.showNodeDetail(id);
    this.setNodeLayerSelection(id);
  }

  public save() {
    this.authoringIntegrationService.authoringSaveIconClickEventHandler.next();
  }

  public submit() {
    if (this.authoringIntegrationService.nodeLayerModel.hasUnsavedChanges) {
      this.bannerNotificationsService.error('There are unsaved changes; please save your changes first.');
      return;
    }
    this.bannerNotificationsService.success('Orphan Node Reconciliation completed.');
    this.routeToNextPage();
  }

  public routeToNextPage() {
    const nextPath = ROUTE_PATHS.PVC + this.globalIntegrationServices.currentMapVersionId.toString();
    this.router.navigate([nextPath]);
  }
}
