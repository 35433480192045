<section class="container-fluid">
  <div class="row justify-content-md-center m-top-100">
    <div class="col-xs-12 col-md-6 col-lg-4 text-center">
      <h1>404</h1>

      <h2>Page not found</h2>
    </div>

  </div>
</section>
