import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../core/guards/auth.guard';
import {MindMapCoreComponent} from './mind-map-core/mind-map-core.component';
import {MindMapCanDeactivateGuard} from './mind-map-deactivate.guard';


const routes: Routes = [
    {path: 'core/:id', component: MindMapCoreComponent, canLoad: [AuthGuard], canActivate: [AuthGuard], canDeactivate: [MindMapCanDeactivateGuard]},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MindMapRoutingModule {
}
