import { Component, OnInit } from '@angular/core';
import { BannerNotificationsService } from '../../../xform-compat';
import { TokenInfo, User } from '../../../shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { CONFIG } from '../../../config';
import { EventsHubService } from '../../../core/events-hub.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { UserService } from '../../../core/data-services/user.service';

@Component({
  selector: 'rms-two-factor',
  templateUrl: './okta-login.component.html'
})
export class OktaLoginComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private bannerNotificationsService: BannerNotificationsService,
    private eventsHubService: EventsHubService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  // The Okta Login UI component will receive a URL parameter called token which is a refresh token
  // received from RMS server.  There will be no access token, the UI will have to submit a request
  // for that
  public ngOnInit() {
    const refreshToken = this.route.snapshot.queryParams['token'];
    if (!refreshToken) {
      this.bannerNotificationsService.error('Invalid login flow');
    } else {
      // Set the local tokenInfo to have the received refresh token
      const tokenInfo = new TokenInfo({refresh_token: refreshToken});
      this.authenticationService.setTokenInfoFromOkta(tokenInfo);

      this.userService.getLoggedUser()
        .subscribe(
          (user: User) => {
            this.authenticationService.setLoggedUser(user);
            this.authenticationService.setLang(user.attributes.language);
            this.eventsHubService.setLoggedIn(true);
            this.translateService.use(user.attributes.language || CONFIG.defaultLang);
            const url = ['/'];
            this.router.navigate(url);
          },
          (error) => {
            this.bannerNotificationsService.error(error);
          }
        );
    }
  }

}
