import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { ConfigFile } from './models/configFile.interface';
import { TranslateService } from '@ngx-translate/core';
import { XformModuleConfig } from './models/xformModuleConfig.interface';


export let XFORM_CONFIG = new InjectionToken<ConfigFile>('');

export * from './components/banner-notifications/banner-notifications.service';
export * from './components/banner-notifications/banner-notification.model';
export * from './components/banner-notifications/banner-notifications.abstract';

export * from './components/spinner/spinner.service';
export * from './components/spinner/spinner.abstract';

export * from './data-services/CRUD.interface';
export * from './data-services/CRUD.abstract';

export * from './models/baseEntity.interface';
export * from './models/configFile.interface';
export * from './models/paginatedList.model';
export * from './models/searcher.interface';
export * from './models/xformModuleConfig.interface';

export * from './services/sanitize.service';
export * from './services/exception.service';
export * from './services/local-storage.service';
export * from './services/utils.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: []
})
export class XFORMModule {
  public static forRoot(config: XformModuleConfig = {}): ModuleWithProviders<XFORMModule> {
    return {
      ngModule: XFORMModule,
      providers: [
        config.translationService || TranslateService,
        { provide: XFORM_CONFIG, useValue: config.configFile }
      ]
    };
  }
}



