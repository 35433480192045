<div class="container-fluid admin-page">
    <div class="row">
        <div class="col text-center admin-header">
            <h1>{{"MEDIA.MEDIA_MANAGEMENT" | translate}}</h1>
            <div class="media-button-row">
                <button class="btn btn-primary float-left btn-right-float"
                        (click)="showMasterLegendModal()">
                    + {{"MEDIA.MASTER_LEGEND" | translate}}
                </button>
                <button class="btn btn-primary float-right btn-right-float"
                        (click)="showModal()">
                    + {{"MEDIA.ADD_IMAGE" | translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <ngb-tabset class="detail-tabs tabs-api-docs no-border" [activeId]="preSelectedTab" (tabChange)="updateCategory($event.nextId)">
            <ngb-tab *ngFor="let category of imageCategoryList" [id]="category">
                <ng-template ngbTabTitle>{{ category | uppercase }}</ng-template>
                <ng-template ngbTabContent>
                    <div class="row p-3">
                        <div class="col-xl-10 d-flex">
                            <input id="media-list-filter-input" class="form-control col-xl-2" type="text" placeholder="Enter Image Name">
                            <button class="btn btn-primary ml-4" (click)="triggerImageFilterHandler()">
                                {{"GENERICS.FILTER_IMAGE" | translate}}
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>{{"MEDIA.NAME" | translate}}</th>
                                        <th>{{"MEDIA.CATEGORY" | translate}}</th>
                                        <th>{{"MEDIA.S3_REFERENCE" | translate}}</th>
                                        <th class="table-actions-column">{{"GENERICS.DELETE" | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of list.records">
                                        <td>{{item.fileName}}</td>
                                        <td>{{item.imageCategory.category.toUpperCase()}}</td>
                                        <td>
                                            <a href="{{ s3Url.concat(item.s3Reference)}}" target="_blank">{{item.fileName}}</a>
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-link table-action table-action-delete" (click)="deleteRecord(item, recordAdded.bind(this))">
                                                <i class="fa fa-close"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <emap-pagination [pagination]="pagination"
                                     [totalRecords]="list.totalRecords"
                                     (onPageChanged)="recordAdded()"
                                     (onRecordsPerPageChanged)="recordAdded()">
                    </emap-pagination>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    </div>
    <emap-authoring-legend [isMaster]="true"></emap-authoring-legend>
    <emap-media-form [imageCategory]="imageCategoryList" (onRecordProcessed)="recordAdded()"></emap-media-form>
</div>
