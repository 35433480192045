import { Injectable } from '@angular/core';
import { AbstractMorphActionServices } from './abstractMorphAction.services';
import { HammerCommonServices } from './hammerCommon.services';
import { AnimationCommonServices } from './animationCommon.services';
import { DesignIntegrationService } from './designIntegration.service';
import { JqueryCommonServices } from './jqueryCommon.services';
import { GlobalIntegrationServices } from './globalIntegration.services';


@Injectable()
export class MorphActionServices extends AbstractMorphActionServices {

  private EMAP_PARENT_DIV_ID = 'parent-div';
  private EMAP_MAIN_MAP_IMAGE = 'main-map-image';
  private SEARCH_PANEL_RESULTS_TABLE_HEADER_CLASS = 'global-panel-results-table-header';
  private SEARCH_PANEL_RESULTS_TABLE_NODE_NAME_CLASS = 'search-panel-results-table-node-name';
  private NODE_LAYER_END_NODE_CLASS = 'node-layer-end-node';
  private HIGHLIGHT_PANEL_CLASS = 'morph-action-highlight';
  private HIGHLIGHT_NODES_CLASS = 'morph-action-highlight-border';
  private NODE_LAYER_CHILD_CLASS = 'node-layer-child';

  constructor(
    hammerCommonServices: HammerCommonServices,
    designIntegrationService: DesignIntegrationService,
    jqueryCommonServices: JqueryCommonServices,
    globalIntegrationServices: GlobalIntegrationServices,
    animationCommonServices: AnimationCommonServices,
  ) {
    super(hammerCommonServices, designIntegrationService, jqueryCommonServices, globalIntegrationServices, animationCommonServices);
  }

  public endNodeSingleTapEventHandler(hammerManager) {
    hammerManager.on('singletap', (event) => {
      const mapVersionId = this.getTargetSelector(event.target, this.SEARCH_PANEL_RESULTS_TABLE_NODE_NAME_CLASS).data('mapVersionId');
      const mapId = this.getTargetSelector(event.target, this.SEARCH_PANEL_RESULTS_TABLE_NODE_NAME_CLASS).data('mapId');
      if (this.isCurrentMapVersion(mapVersionId)) {
        this.endNoneSingleTapCurrentMapHandler(event, this.getCssNodeId(event.target));
      } else {
        this.endNoneSingleTapCrossMapHandler(event, mapId, mapVersionId, this.getCssNodeId(event.target));
      }
    });
  }

  private endNoneSingleTapCurrentMapHandler(event, nodeId: any) {
    this.removeHighlights(this.HIGHLIGHT_PANEL_CLASS)
      .then(this.highlightTarget(event.target, true))
      .then(this.removeHighlights(this.HIGHLIGHT_NODES_CLASS)
      .then(this.highlightTarget(nodeId, false)))
      .then(this.zoomToNode(nodeId, false));
  }

  private endNoneSingleTapCrossMapHandler(event: any, mapId: number, mapVersionId: number, nodeId: any) {
    const isZoomToFit = this.currentZpanCommonService.isZoomToFit();
    this.removeHighlights(this.HIGHLIGHT_PANEL_CLASS)
      .then(this.highlightTarget(event.target, true))
      .then(this.loadImageAndMapSlideOut(this.globalIntegrationServices.getMapVersionImageSourceDynamic(mapVersionId), isZoomToFit)
      .then(() => this.switchImageSource(this.mapImageClassName.toClass(), this.globalIntegrationServices.getMapVersionImageSourceDynamic(mapId))
      .then(() => this.moveImageTo(this.getImageSourceOffset())
      .then(this.removeHighlights(this.HIGHLIGHT_NODES_CLASS)
      .then(this.highlightTarget(nodeId, false)
      .then(() => this.mapRightSlideIn()
      .then(() => this.zoomToNode(nodeId, false)
      .then(() => this.updateCurrentMap(mapId, mapVersionId)))))))));
  }

  public endNodeDoubleTapEventHandler(hammerManager) {
    hammerManager.on('doubletap', (event) => {
      const mapVersionId = this.getTargetSelector(event.target, this.SEARCH_PANEL_RESULTS_TABLE_NODE_NAME_CLASS).data('mapVersionId');
      const mapId = this.getTargetSelector(event.target, this.SEARCH_PANEL_RESULTS_TABLE_NODE_NAME_CLASS).data('mapId');
      if (this.isCurrentMapVersion(mapVersionId)) {
        this.endNodeDoubleTapCurrentMapHandler(event, this.getCssNodeId(event.target));
      } else {
        this.endNodeDoubleTapCrossMapHandler(event, mapId, mapVersionId, this.getCssNodeId(event.target));
      }
    });
  }

  private endNodeDoubleTapCurrentMapHandler(event: any, nodeId: any) {
    const isZoomToFit = this.currentZpanCommonService.isZoomToFit();
    this.greyOutTargetPage(this.EMAP_PARENT_DIV_ID.toId(), this.NODE_LAYER_CHILD_CLASS.toClass())
      .then(this.greyOutTargetPage(this.EMAP_MAIN_MAP_IMAGE.toId(), this.NODE_LAYER_CHILD_CLASS.toClass()))
      .then(this.removeHighlights(this.HIGHLIGHT_PANEL_CLASS))
      .then(this.highlightTarget(event.target, true))
      .then(this.removeHighlights(this.HIGHLIGHT_NODES_CLASS)
      .then(this.highlightTrail(event.target)
      .then(this.mapZoomToNode(nodeId, false, isZoomToFit))))
      .then((greyOutClassName) => this.removeGreyOut(greyOutClassName));
  }

  private endNodeDoubleTapCrossMapHandler(event: any, mapId: number, mapVersionId: number, nodeId: any) {
    const isZoomToFit = this.currentZpanCommonService.isZoomToFit();
    this.removeHighlights(this.HIGHLIGHT_PANEL_CLASS)
      .then(this.highlightTarget(event.target, true))
      .then(this.loadImageAndMapSlideOut(this.globalIntegrationServices.getMapVersionImageSourceDynamic(mapVersionId), isZoomToFit)
      .then(() => this.switchImageSource(this.mapImageClassName.toClass(), this.globalIntegrationServices.getMapVersionImageSourceDynamic(mapId))
      .then(() => this.moveImageTo(this.getImageSourceOffset())
      .then(() => this.greyOutTargetPage(this.EMAP_PARENT_DIV_ID.toId(), this.NODE_LAYER_CHILD_CLASS.toClass())
      .then(this.greyOutTargetPage(this.EMAP_MAIN_MAP_IMAGE.toId(), this.NODE_LAYER_CHILD_CLASS.toClass())
      .then((greyOutClassName) => this.removeGreyOut(greyOutClassName))))
      .then(this.removeHighlights(this.HIGHLIGHT_NODES_CLASS)
      .then(this.highlightTrail(event.target)
      .then(() => this.mapRightSlideIn()
      .then(() => this.zoomToNode(nodeId, false)
      .then(() => this.updateCurrentMap(mapId, mapVersionId)))))))));
  }

  public mapNameSingleTapEventHandler(hammerManager) {
    hammerManager.on('singletap', (event) => {
      this.accordionAction(event, this.SEARCH_PANEL_RESULTS_TABLE_HEADER_CLASS);
    });
  }

  public mapNameDoubleTapEventHandler(hammerManager) {
    hammerManager.on('doubletap', (event) => {
      const htmlMapId = this.getTargetSelector(event.target, this.SEARCH_PANEL_RESULTS_TABLE_HEADER_CLASS).attr('id');
      const mapVersionId = this.getTargetSelector(event.target, this.SEARCH_PANEL_RESULTS_TABLE_NODE_NAME_CLASS).data('mapVersionId');
      const mapId = this.getTargetSelector(event.target, this.SEARCH_PANEL_RESULTS_TABLE_NODE_NAME_CLASS).data('mapId');
      if (this.isMapFull(htmlMapId)) { return; }
      if (this.isCurrentMapVersion(mapVersionId)) {
        this.mapNameDoubleTapCurrentMapHandler();
      } else {
        this.mapNameDoubleTapCrossMapHandler(mapId, mapVersionId);
      }
    });
  }

  private mapNameDoubleTapCurrentMapHandler() {
    this.greyOutTargetPage(this.EMAP_PARENT_DIV_ID.toId(), this.NODE_LAYER_CHILD_CLASS.toClass())
      .then(this.greyOutTargetPage(this.EMAP_MAIN_MAP_IMAGE.toId(), this.NODE_LAYER_CHILD_CLASS.toClass()))
      .then(this.removeHighlights(this.HIGHLIGHT_PANEL_CLASS)
      .then(this.removeHighlights(this.HIGHLIGHT_NODES_CLASS)
      .then(this.highlightTarget(this.NODE_LAYER_END_NODE_CLASS.toClass(), false))))
      .then(this.zoomToFit())
      .then((greyOutClassName) => this.removeGreyOut(greyOutClassName));
  }

  private mapNameDoubleTapCrossMapHandler(mapId: number, mapVersionId: number) {
    const isZoomToFit = this.currentZpanCommonService.isZoomToFit();
    this.zoomToFit()
      .then(this.loadImageAndMapSlideOut(this.globalIntegrationServices.getMapVersionImageSourceDynamic(mapVersionId), isZoomToFit)
      .then(() => this.switchImageSource(this.mapImageClassName.toClass(), this.globalIntegrationServices.getMapVersionImageSourceDynamic(mapVersionId))
      .then(() => this.moveImageTo(this.getImageSourceOffset())
      .then(() => this.greyOutTargetPage(this.EMAP_PARENT_DIV_ID.toId(), this.NODE_LAYER_CHILD_CLASS.toClass())
      .then(this.greyOutTargetPage(this.EMAP_MAIN_MAP_IMAGE.toId(), this.NODE_LAYER_CHILD_CLASS.toClass())
      .then((greyOutClassName) => this.removeGreyOut(greyOutClassName))))
      .then(this.removeHighlights(this.HIGHLIGHT_PANEL_CLASS)
      .then(this.removeHighlights(this.HIGHLIGHT_NODES_CLASS)
      .then(this.highlightTarget(this.NODE_LAYER_END_NODE_CLASS.toClass(), false)
      .then(() => this.mapRightSlideIn()
      .then(() => this.updateCurrentMap(mapId, mapVersionId)))))))));
  }

  public endNodePressEventHandler(hammerManager) {
    hammerManager.on('press', (event) => {
      // TODO: Test data only
      const data = {
        'company': 'XenoPort',
        'asset': 'XP23829',
        'phase': 'Phase 3',
        'route of delivery': 'IV',
        'dosage': 'Q3M',
        'moa': 'PARPi, CD20',
        'active indications': 'Psoriasis, Acne, Derm, Rheumatoid Arthritis',
        'map address': 'Psoriasis | Acne | SYSTEMIC (20% of patients) | Less E ca- cious | Reduce sebum production | Female use only | PO | used o ' +
          '-label | inhibit endogenous androgen production in ovaries | decrease andrgoen-stimulated se- bum production | block excessive white ' +
          'blood cell in ux | QQW'
      };
      if (this.globalIntegrationServices.ratio >= 2) {
        this.placeDrugInfoTable(data, event.target);
      }
    });
  }

  public endNodePressUpEventHandler(hammerManager) {
    hammerManager.on('pressup', (event) => {
      this.removeDrugInfoTable();
    });
  }
}
