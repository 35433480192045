import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { RmsFormAbstract } from '../../../../core/components/rms-form.abstract';
import { RmsUser, TreeViewItem } from '../../../../shared/models';
import { UserService } from '../../../../core/data-services/user.service';
import { EventsHubService } from '../../../../core/events-hub.service';
import { CONFIG } from '../../../../config';
import { RoleService } from '../../../../core/data-services/role.service';
import { BannerNotificationsService, SpinnerService } from '../../../../xform-compat';

/**
 * Component class to create and edit User
 */
@Component({
  selector: 'rms-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent extends RmsFormAbstract<RmsUser> implements OnInit, OnDestroy {

  public user: RmsUser;
  public basicStringPattern = CONFIG.basicStringPattern;
  public isAdmin: boolean;
  public jobTitle: string;
  public maxAuthSessions: number;
  private treeViewItems: Array<TreeViewItem>;
  private initialTreeViewItems: Array<TreeViewItem>;
  private treeViewItemsChecked: Array<TreeViewItem> = [];
  private treeViewItemsSelected: Array<TreeViewItem> = [];
  private largePageSize = CONFIG.pagination.singleLargePage;
  /**
   * Get Modal Element Reference
   */
  @ViewChild('userModal', {static: true}) modal: ElementRef;

  constructor(
    userService: UserService,
    spinnerService: SpinnerService,
    bannerNotificationsService: BannerNotificationsService,
    eventsHubService: EventsHubService,
    modalService: NgbModal,
    translateService: TranslateService,
    private roleService: RoleService
  ) {
    super(userService, eventsHubService, spinnerService, bannerNotificationsService, modalService, translateService);
  }

  ngOnInit() {
    /**
     * Call Subscriber method from parent class for modal trigger event
     */
    super.subscribeModal(this.modal);
    this.roleService.getRecords({pagesize: this.largePageSize}, true).subscribe(
      (roles) => {
        this.treeViewItems = [];
        roles.forEach(role => {
          this.treeViewItems.push(new TreeViewItem({
            raw: role,
            label: role.description,
            id: role.id,
            type: 'role'
          }));
        });
        this.initialTreeViewItems = this.treeViewItems;
      },
      (error) => super.getNotifications().error(error)
    );
  }

  ngOnDestroy () {
    super.unsubscribeModal();
  }

  /**
   * Implement abstract method from parent class
   * @param {boolean} isEdit: flag to instantiate object for create or assign existing record
   * @param {User} record, optional param to pre populate edit modal form
   */
  onModalOpens(isEdit: boolean, record?: RmsUser) {
    if (record) {
      this.treeViewItemsSelected = [];
      this.user = record;
      this.isAdmin = record.userTenants[0].administrator;
      this.jobTitle = record.attributes.jobTitle;
      this.maxAuthSessions = record.attributes.maxAuthSessions;
      record.userTenants[0].tenantRoles.forEach(item => {
        this.treeViewItemsSelected.push(new TreeViewItem({
          raw: item,
          label: item.description,
          id: item.id,
          type: 'role'
        }));
      });
      this.treeViewItemsSelected.forEach(item => {
        this.treeViewItems.forEach((subItem, index) => {
          if (item.label === subItem.label) {
            this.treeViewItems.splice(index, 1);
          }
        });
      });
    } else {
      this.user = new RmsUser();
    }
  }

  /**
   * Abstract implementation to modify data before submission process
   * @param {RmsUser} rmsUser: data type to evaluate
   * @returns {RmsUser}: updated data type sent to the parent class
   */
  resourceChecker(rmsUser: RmsUser): RmsUser {
    rmsUser.attributes = {};
    rmsUser.userTenants = [];
    rmsUser.attributes.jobTitle = this.jobTitle;
    rmsUser.attributes.maxAuthSessions = this.maxAuthSessions;
    const userRoles = this.treeViewItemsSelected.map(item => {
      return {id: item.id};
    });
    return (this.isEditMode) ? this.transformEditData(rmsUser, userRoles) : this.transformCreateData(rmsUser, userRoles);
  }

  /**
   * If is create new record, transform on create
   * @param {RmsUser} rmsUser: form data
   * @param {Array<object>} userRoles: list of roles
   * @returns {RmsUser} send back to the resource checker
   */
  private transformCreateData (rmsUser: RmsUser, userRoles: Array<object>) {
    rmsUser.userTenants.push({
      id: CONFIG.defaultTenantId,
      name: null,
      tenantRoles: userRoles,
      administrator: this.isAdmin,
      availableRoles: null
    });
    return rmsUser;
  }

  /**
   * If is edit, transform edited data
   * @param {RmsUser} rmsUser: form data to transform
   * @param {Array<object>} userRoles: list of roles to edit
   * @returns {RmsUser} send back to the resource checker
   */
  private transformEditData (rmsUser: RmsUser, userRoles: Array<object>) {
    rmsUser.userTenants.push({
      id: this.user.userTenants[0].id,
      name: this.user.userTenants[0].name,
      tenantRoles: userRoles,
      administrator: this.isAdmin,
      availableRoles: this.user.userTenants[0].availableRoles
    });
    return rmsUser;
  }

  /**
   * On add roles from multi select component
   * process the list of checked and adds to a new list to process on submission
   * removes the selected items from the original list
   * clears the checked list
   */
  public addCheckedRoles () {
    this.treeViewItemsSelected = this.treeViewItemsChecked;
    this.treeViewItems = this.treeViewItemsChecked.filter(checked => {
      this.treeViewItems.forEach(item => {
        return item.id !== checked.id;
      });
    });
    this.treeViewItemsChecked = [];
  }

  /**
   * Process the check event creating a temporal list
   * @param {TreeViewItem} value: item in the list
   */
  public checkRole (value: TreeViewItem) {
    if (this.treeViewItemsChecked.indexOf(value) > -1) {
      return;
    }
    this.treeViewItemsChecked.push(value);
  }

  /**
   * Deletes the role from the selected roles list
   * @param {TreeViewItem} item: selected item in the list
   * @param {number} index: position in the list to delete
   */
  public removeRole (item: TreeViewItem, index: number) {
    this.treeViewItemsSelected.splice(index, 1);
    item.isChecked = false;
    this.treeViewItems.push(item);
  }

  /**
   * Searches and filter on keyup, in the available roles list
   * @param {string} term
   */
  public filter(term: string) {
    if (!term.length) {
      this.treeViewItems = this.initialTreeViewItems;
      return;
    }
    this.treeViewItems = this.initialTreeViewItems.filter(i =>  i.label.toLowerCase().includes(term));
  }
}
