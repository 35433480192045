import { Injectable } from '@angular/core';
import { AbstractCRUD, CRUDService, ExceptionService, UtilsService } from '../../xform-compat';
import { HttpClient } from '@angular/common/http';
import { Holiday } from '../../shared/models';

/**
 * Data Service for Holiday
 */
@Injectable()
export class HolidayService extends AbstractCRUD<Holiday> implements CRUDService<Holiday> {
  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  getResourcePath(): string {
    return '/rms/holidays';
  }

}
