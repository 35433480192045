<xform-page [sidebar]="false" [bgColor]="'white-bg'">
  <section class="container-fluid public-form">
    <div class="row justify-content-md-center m-top-100">
      <form #forgotPasswordForm="ngForm" class="col-xs-12 col-md-6 col-lg-4"
            [class.submitted]="forgotPasswordForm.submitted" (ngSubmit)="onSubmit(forgotPasswordForm)">
        <h1 class="text-center">{{"FORGOT_PASSWORD.FORGOT_PASSWORD" | translate}}</h1>
        <p class="sub-title text-center">{{"FORGOT_PASSWORD.SUBTITLE" | translate}}</p>

        <div class="form-group">
          <label for="emailAddress">{{"FORMS.EMAIL" | translate}}
            <span class="error" *ngIf="forgotPasswordForm.submitted">
              <span *ngIf="email.errors?.required">{{'ERRORS.IS_REQUIRED' | translate}}</span>
              <span *ngIf="!email.errors?.required && email.errors?.email">{{'ERRORS.IS_INVALID' | translate}}</span>
            </span>
          </label>
          <input type="email" name="emailAddress" id="emailAddress" class="form-control" ngModel #email="ngModel" required email />
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block">{{"FORMS.SUBMIT" | translate}}</button>
        </div>
      </form>
    </div>
  </section>
</xform-page>
