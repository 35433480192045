import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Pagination } from '../../models';

@Component({
  selector: 'emap-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() configDropdownCounter?: any;
  @Input() pagination: Pagination;
  @Input() totalRecords: number;
  @Input() noPadding = false;
  @Input() showTotalMessage = false;
  @Input() viewAllRecords = false;
  @Input() entityType: string;
  @Output() onPageChanged = new EventEmitter<Pagination>();
  @Output() onRecordsPerPageChanged = new EventEmitter<Pagination>();

  ngOnInit() {
    if (this.viewAllRecords) {
      this.pagination.allowedRanges.push({ range: 'ALL' });
    }
    this.onPageChanged.emit(this.pagination);
    if ( this.configDropdownCounter ) {
      this.configDropdownCounter.dropdownPosition = this.configDropdownCounter.dropdownPosition ?
        this.configDropdownCounter.dropdownPosition : 'auto';
    } else {
      this.configDropdownCounter = {};
      this.configDropdownCounter.dropdownPosition = 'auto';
    }
  }

  public emitPageChange() {
    this.onPageChanged.emit(this.pagination);
  }

  public emitRecordsPerPageChange() {
    this.onRecordsPerPageChanged.emit(this.pagination);
  }

}
