<div class="modal-body">
    <emap-rich-text-editor [elementId]="'notesEditorId'"
                           [content]="editorContent"
                           (onEditorChange)="editorChanged($event)">
    </emap-rich-text-editor>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default btn-leave-without-saving" (click)="onConfirmation(false)">
        {{"GENERICS.LEAVE_WITHOUT_SAVING" | translate}}
    </button>
    <button type="button" class="btn btn-default btn-save-changes" (click)="onConfirmation(true)">
        {{"GENERICS.SAVE_CHANGES" | translate}}
    </button>
</div>
