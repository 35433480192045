import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

import { HeaderComponent } from './header.component';
import { HeaderSearchComponent } from './search/header-search.component'
import { HeaderNotificationComponent } from './notification/header-notification.component'


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    HeaderComponent,
    HeaderSearchComponent,
    HeaderNotificationComponent
  ],
  exports: [HeaderComponent]
})

export class HeaderModule {
}
