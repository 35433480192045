import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCRUD, BaseEntity, CRUDService, ExceptionService, UtilsService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';


@Injectable()
export class ReportService extends AbstractCRUD<BaseEntity> implements CRUDService<BaseEntity> {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/core/service';
  }

  public getReportPath() {
    return super.getApiPath().concat('/reports');
  }

  public getAuthorReportPath() {
    return this.getReportPath().concat('/author');
  }

  public getAuthorReportByMapVersionId(mapVersionId: number): any {
    const mapVersionReportPath = this.getAuthorReportPath().concat('/').concat(String(mapVersionId));
    return this.getRequest(mapVersionReportPath)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  private getRequest(apiUrl: string) {
    return this.getHTTP().get(apiUrl)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  private postRequest(apiUrl: string, body: object) {
    return this.getHTTP().post(apiUrl, body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  private updateRequest(apiUrl: string, body: any) {
    return this.getHTTP().put(apiUrl, body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }
}
