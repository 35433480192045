import { BaseEntity } from '../../xform-compat';
import {Company} from './company.model';
import {Product} from './product.model';

export class ProductCompanyInstance implements BaseEntity {
  id: number;
  mindmapVersionNodeID: number;
  companies: Array<Company>;
  products: Array<Product>;

  constructor(productCompanyInstance: ProductCompanyInstance = {} as ProductCompanyInstance) {
    this.id = productCompanyInstance.id || null;
    this.mindmapVersionNodeID = productCompanyInstance.mindmapVersionNodeID || null;
    this.companies = productCompanyInstance.companies || null;
    this.products = productCompanyInstance.products || null;
  }
}
