
export class NodeLayerModel {
    _primarySelectionId: string;
    _secondarySelectionIds: Set<string>;
    _hasUnsavedChanges: boolean;

    constructor(map: NodeLayerModel = {} as NodeLayerModel) {
        this._primarySelectionId = map.primarySelectionId || null;
        this._secondarySelectionIds = map.secondarySelectionIds || new Set();
        this._hasUnsavedChanges = map.hasUnsavedChanges || false;
    }

    get primarySelectionId(): string {
        return this._primarySelectionId;
    }

    set primarySelectionId(value: string) {
        this._primarySelectionId = value;
    }

    get secondarySelectionIds(): Set<string> {
        return this._secondarySelectionIds;
    }

    get hasUnsavedChanges(): boolean {
        return this._hasUnsavedChanges;
    }

    set hasUnsavedChanges(value: boolean) {
        this._hasUnsavedChanges = value;
    }
}
