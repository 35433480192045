<header [ngClass]="{'logged-out':!loggedIn}" class="z-index-2">
  <div class="row no-gutters">
    <div class="col-1 d-md-none navbar-dark" *ngIf="loggedIn">
      <button class="navbar-toggler mt-2" type="button" (click)="openMenu(!isMenuOpen)">
        <i class="fs-30" [ngClass]="isMenuOpen ? 'close-icon' : 'fa fa-bars'" aria-hidden="true"></i>
      </button>
    </div>
    <!-- logo -->
    <div class="col-2 logo-col">
      <a [routerLink]="['/login']">
        <img src="../../../assets/images/logo.svg" alt="RA Capital Logo">
      </a>
    </div>
    <!-- top bar except the logo -->
    <div class="col-9 col-md-10 d-flex align-items-stretch justify-content-end bb-1" *ngIf="loggedIn">
      <nav class="menu">
        <ul>
          <li *ngFor="let item of menu" [routerLinkActive]="['active']" [ngClass]="item.subMenu ? 'has-children' : null">
            <a [routerLink]="item.link">{{item.textKey | translate}} <i *ngIf="item.icon" class="fa fa-{{item.icon}}"></i></a>
            <ul class="sub-menu" *ngIf="item.subMenu">
              <li *ngFor="let subItem of item.subMenu" [routerLinkActive]="['active']">
                <a [routerLink]="subItem.link">{{subItem.textKey | translate}}</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- <xform-header-search class="d-none d-md-flex"></xform-header-search> -->
      <xform-header-notification class="d-none d-md-flex"></xform-header-notification>
      <div ngbDropdown class="d-flex profile header-element">
        <div class="d-flex text-left align-items-center profile-info justify-content-end">
          <div class="name col-11 d-none d-md-inline">{{loggedUser?.fullName}}</div>
          <i class="fa fa-chevron-down icon open-icon col-1 text-center text-md-left" aria-hidden="true"></i>
          <div class="close-icon col-1 text-center text-md-left"></div>
        </div>
        <button class="header-transparent-button" ngbDropdownToggle></button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" routerLink="/system-administration">{{"HEADER.ADMINISTRATION" | translate}}</a>
          <a class="dropdown-item" [routerLink]="['/users', loggedUser?.id]">{{"HEADER.MY_PROFILE" | translate}}</a>
          <a class="dropdown-item" (click)="logout()">{{"HEADER.LOGOUT" | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</header>
