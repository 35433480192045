import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { CONFIG } from '../../config';
import { User, UserMetadata } from '../../shared/models';
import { Map } from '../../shared/models/map.model';
import { catchError } from 'rxjs/operators';


@Injectable()
export class MapService extends AbstractCRUD<Map> implements CRUDService<Map>, Searcher {

  private metadataPath = CONFIG.metadataPath;

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/map/service';
  }

  public getAllMapsPath() {
    return super.getApiPath().concat('/maps/all');
  }

  public getAllMaps() {
    return this.getRequest(this.getAllMapsPath());
  }

  public getAllMapVersionsPath() {
    return super.getApiPath().concat('/mapversions/all');
  }

  public getAllMapVersions() {
    return this.getRequest(this.getAllMapVersionsPath());
  }

  public getAllMapNamesPath() {
    return super.getApiPath().concat('/names/all');
  }

  public getAllMapNames() {
    return this.getRequest(this.getAllMapNamesPath());
  }

  public getDisplayName(item: any): string {
    return item.fullName;
  }

  private getRequest(apiUrl: string) {
    return this.getHTTP().get(apiUrl)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  private postRequest(apiUrl: string, body: any) {
    return this.getHTTP().post(apiUrl, body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }
}
