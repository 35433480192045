import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform<T>(arr: Array<T>, callback: Function, ...args: any[]): Array<T> {
    return arr.filter((item) => callback(item, ...args));
  }

}
