import { Injectable, Sanitizer, SecurityContext } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class SanitizeService {

  constructor(
    private sanitizer: Sanitizer
  ) { }

  public sanitizeURL(url: string) {
    return this.sanitizer.sanitize(SecurityContext.URL, url);
  }

  public sanitizeBase64Image(base64Img: string) {
    return this.sanitizer?.sanitize(SecurityContext.URL, `data:image/png;base64,${base64Img}`);
  }

  public sanitizeHTML(html: string) {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }
}
