import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {RmsListAbstract} from '../../../core/components/rms-list.abstract';
import {TranslateService} from '@ngx-translate/core';
import {EventsHubService} from '../../../core/events-hub.service';
import {TemplateModalService} from '../../../core/services/template-modal.service';
import {AuthorizationService} from '../../../core/services/authorization.service';
import {MapsManagerService} from '../../../core/data-services/mapsManager.service';
import {FileUploader} from 'ng2-file-upload';
import {FileUploadService} from '../../../core/services/fileUpload.service';
import {CONFIG} from '../../../config';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {MapsManager} from '../../../shared/models/mapsManager.model';
import {MapsManagerVersionService} from '../../../core/data-services/mapsManagerVersion.service';
import {BannerNotificationsService, SpinnerService} from '../../../xform-compat';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, finalize} from 'rxjs/operators';
import {RemoteServerService} from '../../../core/data-services/remoteServer.service';
import {RemoteServer} from '../../../shared/models/remoteServer.model';
import { Sort } from '@angular/material/sort';
import {MapsManagerVersion} from '../../../shared/models/mapsManagerVersion.model';
import {MapsManagerIntegrationService} from "../../../shared/services/maps-manager-integration.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'emap-maps-manager-list',
  templateUrl: './maps-manager-list.component.html',
  styleUrls: ['./maps-manager-list.component.scss'],
  providers: [MapsManagerService, MapsManagerVersionService],
  encapsulation: ViewEncapsulation.None
})
export class MapsManagerListComponent extends RmsListAbstract<MapsManager> implements OnInit, OnDestroy {

  public uploader: FileUploader;
  public mapsManagerSearchTerm: any;
  public mapsManagerVersionHistoryMode = false;
  public mapsManagerArchiveMode = false;
  public selectedMapsManagerId: number;
  public selectedMapsManagerVersion: MapsManagerVersion;
  public mapsManagerSearchTypeahead$: Subject<string> = new Subject();
  public remoteServers: Array<RemoteServer>;
  public mapsManagerVersionPublishedStatuses: Map<number, string> = new Map<number, string>();
  private subscriptions: Array<Subscription> = [];

  constructor(
    private mapsManagerVersionService: MapsManagerVersionService,
    private authenticationService: AuthenticationService,
    private remoteServerService: RemoteServerService,
    private mapsManagerIntegrationService: MapsManagerIntegrationService,
    private router: Router,
    private route: ActivatedRoute,
    mapsManagerService: MapsManagerService,
    fileUploadService: FileUploadService,
    bannerNotificationService: BannerNotificationsService,
    spinnerService: SpinnerService,
    translateService: TranslateService,
    eventsHubService: EventsHubService,
    templateModalService: TemplateModalService,
    public authorizationService: AuthorizationService,
  ) {
    super(eventsHubService, mapsManagerService, spinnerService, translateService, bannerNotificationService, templateModalService);
    this.uploader = fileUploadService.uploader;
    this.uploader.options.url = '/api/image/service/upload/mapsmanager';
    this.uploader.authTokenHeader = CONFIG.authHeader;
    this.uploader.authToken = CONFIG.authPreamble + this.authenticationService.getTokenInfo().accessToken;

    this.subscriptions.push(this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.route.queryParams.subscribe(params => {
          if (params.mapHistory) {
            this.mapsManagerVersionHistoryMode = true;
            this.selectedMapsManagerId = Number(params.mapHistory);
          } else {
            this.mapsManagerVersionHistoryMode = false;
          }
          this.refresh();
        });
      }
    }));
  }

  ngOnInit() {
    this.searchMapsManagerList();
    this.fetchRemoteServers();

    this.subscriptions.push(this.eventsHubService.reloadRecords$.subscribe(() => {
      this.setMapsVersionPublishedStatuses();
    }));
  }

  ngOnDestroy() {
    this.removeSubscriptions();
  }

  refresh() {
    if (this.mapsManagerVersionHistoryMode) {
      this.mapsManagerVersionHistory(this.selectedMapsManagerId);
    } else {
      this.recordAdded();
    }
  }

  recordAdded() {
    this.mapsManagerVersionHistoryMode = false;
    this.mapsManagerArchiveMode = false;
    const filterObj: any = {isLatest: true};
    if (this.mapsManagerSearchTerm) {
      filterObj.name = this.mapsManagerSearchTerm;
    }
    this.getMapsManagerList(filterObj);
    this.router.navigate([]);
  }

  searchMapsManagerList() {
    this.mapsManagerSearchTypeahead$
      .pipe(debounceTime(600))
      .subscribe(mapsManagerName => {
        const filterObj = {isLatest: true, name: mapsManagerName};
        this.getMapsManagerList(filterObj);
      });
  }

  mapsManagerVersionHistory(mapsManagerId: number) {
    this.selectedMapsManagerId = mapsManagerId;
    const filterObj = {ids: mapsManagerId};
    this.mapsManagerVersionHistoryMode = true;
    this.getMapsManagerList(filterObj);
    this.router.navigate([], {queryParams: {mapHistory: mapsManagerId}});
  }

  getArchive(mapsManagerVersionId: number) {
    this.mapsManagerArchiveMode = true;
    const filterObj = {'mapsManagerVersionArchive': true};
    this.getSpinnerService().start();
    this.mapsManagerVersionService
      .getById(mapsManagerVersionId, filterObj)
      .pipe(finalize(() => this.getSpinnerService().stop()))
      .subscribe((data: MapsManagerVersion) => {
        this.selectedMapsManagerVersion = data;
        if (this.selectedMapsManagerVersion && this.selectedMapsManagerVersion.mapsManagerVersionArchives) {
          this.selectedMapsManagerVersion.mapsManagerVersionArchives
            .sort((a, b) => {
              return b.id - a.id;
            });
        }
      }, error => {
        this.selectedMapsManagerVersion = null;
        super.getNotifications().error('Error. No archive found for this version.');
      });
  }

  sortTable(e: Sort) {
    let direction = 'ASC';
    if (e.direction === 'desc') { direction = 'DESC'; }
    const params = {'isLatest': true, 'sort': e.active, 'direction': direction};
    this.getMapsManagerList(params);
  }

  formatTimestamp(timestamp: string): string {
    const timeData = timestamp.split(' ');
    const hrmins = timeData[1].split(':');
    return `${timeData[0]}\n${hrmins[0]}:${hrmins[1]}`;
  }

  getDefaultSort(): string {
    return 'name';
  }

  getRecordType(): string {
    return '';
  }

  editMapsManagerVersion(eachMapsManagerVersion: MapsManagerVersion) {
    eachMapsManagerVersion.isEdit = true;
  }

  saveMapsManagerVersion(eachMapsManagerVersion: MapsManagerVersion, mapsManagerId: number) {
    eachMapsManagerVersion.mapsManagerId = mapsManagerId;
    this.mapsManagerVersionService
      .updateRecord(eachMapsManagerVersion)
      .subscribe(_ => {
        super.getNotifications().success('MAPS_MANAGER_VERSION_UPDATED');
        eachMapsManagerVersion.isEdit = false;
      }, error => {
        super.getNotifications().error(error);
      });
  }

  openMindMapArchive(mapsManagerVersionId: number, mapsManagerVersionArchiveId: number) {
    const mindMapCoreUrl = `/mindMap/core/${mapsManagerVersionId}?mapsManagerVersionArchiveId=${mapsManagerVersionArchiveId}`;
    window.open(mindMapCoreUrl);
  }

  getSelectedMapManagerName() {
    return this.list.records.find(mapsManager => mapsManager.id === this.selectedMapsManagerId)?.name;
  }

  private getMapsManagerList(filterObj: any) {
    this.setRecordsPerPage(filterObj);
  }

  private fetchRemoteServers() {
    this.remoteServerService.getAllRemoteServers().subscribe(data => {
      if (data) {
        this.remoteServers = data.map(r => new RemoteServer(r));
        this.mapsManagerIntegrationService.remoteServers = this.remoteServers;
      }
    });
  }

  private removeSubscriptions() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private setMapsVersionPublishedStatuses() {
    this.list.records.forEach(mapsManager => {
      const statuses: Set<string> = new Set<string>();
      mapsManager.mapsManagerVersions.forEach(mapsManagerVersion => {
        if (mapsManagerVersion.remoteServerDeploys) {
          mapsManagerVersion.remoteServerDeploys.forEach(deploy => {
            statuses.add(deploy.publishedState);
          });
        }
        this.mapsManagerVersionPublishedStatuses.set(
            mapsManagerVersion.id,
            statuses.size === 0 ? 'unpublished' : Array.from(statuses).join(', ')
        );
      });
    });
  }
}
