import {AnimationCommonServices } from './animationCommon.services';
import {DesignIntegrationService} from './designIntegration.service';
import * as Promise from 'bluebird';
import {HammerCommonServices} from './hammerCommon.services';
import {JqueryCommonServices} from './jqueryCommon.services';
import {GlobalIntegrationServices} from './globalIntegration.services';
import {MAP_IMAGE_ACCESSOR} from '../enums';


export class AbstractMorphActionServices {

  constructor(
    private hammerCommonServices: HammerCommonServices,
    private designIntegrationService: DesignIntegrationService,
    private jqueryCommonServices: JqueryCommonServices,
    protected globalIntegrationServices: GlobalIntegrationServices,
    private animationCommonServices: AnimationCommonServices,
  ) { }

  public greyOutTargetPage(targetName, excludingTarget) {
    return this.animationCommonServices.greyOutTargetPage(targetName, excludingTarget);
  }

  public removeGreyOut(greyOutClassName) {
    return this.animationCommonServices.removeGreyOut(greyOutClassName);
  }

  public highlightTarget(target: any, isSolid: boolean) {
    return this.animationCommonServices.highlightTarget(target, isSolid);
  }

  public highlightTrail(target: any) {
    return new Promise((resolve) => {
      this.designIntegrationService.returnCookieTrailEventEmitter.subscribe((value) => {
        this.animationCommonServices.highlightTargetList(value);
      });
      this.designIntegrationService.showCookieTrailEventEmitter.next(target.id.split('_').pop());
      resolve();
    });
  }

  public removeHighlights(targetClass) {
    return this.animationCommonServices.removeHighlights(targetClass);
  }

  public zoomToNode(target: any, disableAnimation: boolean) {
    return this.animationCommonServices.zoomToPosition(target, disableAnimation);
  }

  public zoomToFit() {
    return this.animationCommonServices.zoomToFit();
  }

  public mapRightSlideIn() {
    return this.animationCommonServices.rightSlideIn();
  }

  public mapRightSlideOut() {
    return this.animationCommonServices.rightSlideOut();
  }

  public loadImage(imageSrc: string) {
    return this.animationCommonServices.loadImage(imageSrc);
  }

  public switchImageSource(targetSelector: any, source: string) {
    return this.animationCommonServices.updateSource(targetSelector, source);
  }

  public moveImageTo(offset: any) {
    return this.animationCommonServices.moveTo(offset, true);
  }

  public accordionAction(event, resultsTableHeaderClass) {
    this.animationCommonServices.accordionAction(event, resultsTableHeaderClass);
  }

  public mapSlideOut(zoomToFit: boolean) {
    if (zoomToFit) {
      return this.mapRightSlideOut();
    } else {
      return this.zoomToFit().then(() => this.mapRightSlideOut());
    }
  }

  public mapZoomToNode(target: any, disableAnimation: boolean, zoomToFit: boolean) {
    if (zoomToFit) {
      return this.zoomToNode(target, disableAnimation);
    } else {
      return this.zoomToFit().then(() => this.zoomToNode(target, disableAnimation));
    }
  }

  public loadImageAndMapSlideOut(imageSrc: string, zoomToFit: boolean) {
    return Promise.join(this.loadImage(imageSrc), this.mapSlideOut(zoomToFit), () => {});
  }

  public updateCurrentMap(mapId: number, mapVersionId: number) {
    this.globalIntegrationServices.currentMapId = mapId;
    this.globalIntegrationServices.currentMapVersionId = mapVersionId;
  }

  public isMapFull(html_map_id: string) {
    return this.designIntegrationService.currentMapNodeListTable[html_map_id].length >=
      this.designIntegrationService.initialMapNodeListTable[html_map_id].length;
  }

  public placeDrugInfoTable(data: any, nodeSelector: any) {
    const endNode = this.jqueryCommonServices.selector(nodeSelector);
    this.designIntegrationService.placeDrugInfoTableEventEmitter.next({
      'data': data,
      'endNode': endNode
    });
  }

  public removeDrugInfoTable() {
    this.designIntegrationService.removeDrugInfoTableEventEmitter.next();
  }

  public hammerTapEventHandler(currentSelector: any) {
    return this.hammerCommonServices.hammerTapEventHandler(currentSelector);
  }

  public hammerPressEventHandler(currentSelector: any) {
    return this.hammerCommonServices.hammerPressEventHandler(currentSelector);
  }

  public getTargetSelector(targetDiv, targetClassName) {
    const target = this.jqueryCommonServices.selector(targetDiv);
    return (target.hasClass(targetClassName)) ? target : target.parent();
  }

  public getImageSourceOffset() {
    const width = this.globalIntegrationServices.currentMapImageSelector.width();
    const scaledWidth = this.globalIntegrationServices.scaledWidth;
    const windowWidthHeight = this.jqueryCommonServices.windowWidthHeight();
    const windowWidth = windowWidthHeight.width;
    const height = this.globalIntegrationServices.currentMapImageSelector.height();
    const windowHeight = windowWidthHeight.height;
    return { offsetX: (2 * windowWidth + scaledWidth - width) / 2, offsetY: - (height - windowHeight) / 2 };
  }

  public isCurrentMapVersion(mapVersionId: number) {
    return this.globalIntegrationServices.currentMapVersionId === mapVersionId;
  }

  public getCssNodeId(target: any) {
    const nodeId =  'node_' + target.id.split('_').pop();
    return nodeId.toId();
  }

  get currentZpanCommonService() {
    return this.globalIntegrationServices.currentZpanCommonService;
  }

  get currentMapImageSelector() {
    return this.globalIntegrationServices.currentMapImageSelector;
  }

  get mapImageClassName() {
    return this.globalIntegrationServices.mapImageClassName;
  }
}
