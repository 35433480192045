<div ngbDropdown class="text-right d-flex notification header-element">
  <i class="fa fa-bell icon" aria-hidden="true">
    <div *ngIf="notifications?.actions?.length > 0 || notifications?.mentions?.length > 0" class="dot"></div>
  </i>
  <button class="header-transparent-button" ngbDropdownToggle></button>
  <div class="dropdown-menu">
    <ul class="d-flex flex-column">
      <li class="d-flex">
        <div *ngFor="let title of notifications.titles; first as first"
             [class.selected]="title.type === selectedTitle"
             class="col-6 title d-flex"
             (click)="getMessages(title.type, $event)">
          <div class="count" *ngIf="first">{{notifications.actions.length}}</div>
          <div class="count" *ngIf="!first">{{notifications.mentions.length}}</div>
          <div class="text">{{title.name | uppercase}}</div>
        </div>
      </li>
      <li *ngFor="let message of messages" class="messages d-flex">
        <div class="col-9 text-truncate text">{{message}}</div>
        <a [routerLink]="['/users/list']" class="col-3 link text-uppercase">{{'HEADER.VIEW' | translate}}
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </a>
      </li>
      <li *ngIf="messages.length == 0" class="text-left">
        <small>{{"NOTIFICATIONS.NO_MESSAGES" | translate}}</small>
      </li>
    </ul>
  </div>
</div>
