import {Component, Input, OnInit} from '@angular/core';
import { DeploymentService } from '../../../core/data-services/deployment.service';
import { RemoteServer } from '../../../shared/models/remoteServer.model';
import { BannerNotificationsService, SpinnerService } from '../../../xform-compat';
import {MapsManager} from "../../../shared/models/mapsManager.model";
import {MapsManagerIntegrationService} from "../../../shared/services/maps-manager-integration.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {MapsManagerService} from "../../../core/data-services/mapsManager.service";
import {MapsManagerVersion} from "../../../shared/models/mapsManagerVersion.model";
import {RemoteServerDeploy} from "../../../shared/models/remoteServerDeploy.model";


@Component({
  selector: 'emap-tv-publisher',
  templateUrl: './tv-publisher.component.html',
  styleUrls: ['./tv-publisher.component.scss']
})
export class TvPublisherComponent implements OnInit {

  @Input()
  mapsManager: MapsManager;

  @Input()
  refresh: () => void;

  remoteServers: Array<RemoteServer> = [];
  loadingVersions: boolean;
  stagedServers: Map<number, number> = new Map<number, number>();
  selectedServers: Set<RemoteServer> = new Set<RemoteServer>();
  selectedState: string;

  constructor(
    private bannerNotificationsService: BannerNotificationsService,
    private deploymentService: DeploymentService,
    private mapsManagerService: MapsManagerService,
    private mapsManagerIntegrationService: MapsManagerIntegrationService,
    private modal: NgbActiveModal,
  ) {   }

  ngOnInit() {
    this.remoteServers = this.mapsManagerIntegrationService.remoteServers;
    this.remoteServers.sort((s1, s2) => s1.name.localeCompare(s2.name));
    this.loadVersions();
  }

  public close(reason?: any) {
    this.reset();
    this.modal.close(reason);
  }

  public stageVersionToServers(version: MapsManagerVersion) {
    this.selectedServers.forEach(server => {
      this.stagedServers.set(server.id, version.id);
      const deployResource = new RemoteServerDeploy();
      deployResource.remoteServer = server;
      deployResource.publishedState = this.selectedState;
      version.remoteServerDeploys.push(deployResource);
    });
  }

  public serverSelectChange(serverId: RemoteServer, isChecked: boolean) {
    if (isChecked) {
      this.selectedServers.add(serverId);
    } else {
      this.selectedServers.delete(serverId);
    }
  }

  public removeStagedServer(server: RemoteServer) {
    const versionId = this.stagedServers.get(server.id);
    const version = this.mapsManager.mapsManagerVersions.find(v => v.id === versionId);
    version.remoteServerDeploys.splice(
        version.remoteServerDeploys.findIndex(d => d.remoteServer.id === server.id && !d.created),
        1);
    this.stagedServers.delete(server.id);
  }

  save() {
    const newMapsManagerVersionList = [];
    const versionToServerMap = this.getStagedServersForMapVersions();

    versionToServerMap.forEach((serverVersionIds: Array<number>, versionId: number) => {
      const version = this.mapsManager.mapsManagerVersions.find(v => v.id === versionId);
      newMapsManagerVersionList.push({
        ...version,
        remoteServerIdList: serverVersionIds
      });
    });

    this.stagedServers.clear();
    this.selectedServers.clear();
    this.close(newMapsManagerVersionList);
  }

  public unpublish(deployment: RemoteServerDeploy) {
    return this.deploymentService.unpublishMapsFromTvs([deployment]).subscribe((resp) => {
      if (resp) {
        this.loadVersions();
      }
    });
  }

  public trackById(index: number, mapVersion: MapsManagerVersion) {
    return mapVersion.id;
  }

  private loadVersions() {
    this.loadingVersions = true;
    this.mapsManagerService.getRecord(this.mapsManager.id).subscribe((resp) => {
      if (resp && resp.mapsManagerVersions) {
        this.setMapVersions(resp.mapsManagerVersions.map(v => new MapsManagerVersion(v)));
      } else {
        this.bannerNotificationsService.error('Unable to load map versions');
      }
      this.loadingVersions = false;
    }, (err) => {
      console.error(err);
      this.bannerNotificationsService.error(err);
      this.loadingVersions = false;
    })
  }

  private setMapVersions(versions: Array<MapsManagerVersion>) {
    this.mapsManager.mapsManagerVersions = versions;

    // Sort the versions by most recently updated
    this.mapsManager.mapsManagerVersions.sort((v1, v2) => {
      return v2.updated.localeCompare(v1.updated);
    })
  }

  private getStagedServersForMapVersions() {
    const versionToServerMap = new Map<number, Array<number>>();
    this.stagedServers.forEach((serverId: number, versionId: number) => {
      if (!versionToServerMap.has(serverId)) {
        versionToServerMap.set(serverId, []);
      }
      versionToServerMap.set(serverId, [...versionToServerMap.get(serverId), versionId]);
    });

    return versionToServerMap;
  }

  private reset() {
    this.stagedServers.clear();
    this.selectedServers.clear();
    this.selectedState = null;
    this.loadingVersions = false;
  }
}
