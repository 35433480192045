import { Injectable } from '@angular/core';
import { SweetAlertOptions } from 'sweetalert2';


@Injectable()
export class TemplateModalService {
  constructor() {
  }

  public getConfirmAlertOptions(title: string, subTitle: string, message: string, recordType: string): SweetAlertOptions {
    return {
      title: title,
      buttonsStyling: false,
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Confirm`,
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-success mr-3',
        cancelButton: 'btn btn-warning mr-3',
      },
      html: this.getDeleteTemplate(subTitle, message, recordType)
    };
  }

  public getInactivityOptions(title: string, message: string): SweetAlertOptions {
    return {
      title: title,
      buttonsStyling: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-secondary mr-3',
      },
      html: this.getInactivityTemplate(message)
    };
  }

  public getDeleteTemplate(message: string, record: string, recordName: string) {
    return `
      <div class="body">
         <h2>${message}</h2>
         <div class="details">
           <p><b>${record}:</b>${recordName}</p>
         </div>
      </div>`;
  }

  public getInactivityTemplate(message: string) {
    return `
      <div class="body">
        <div class="details">
          <p><i class="fa fa-clock-o" aria-hidden="true"></i> ${message}</p>
        </div>
      </div>`;
  }

}
