<div cdkDrag cdkDragRootElement=".modal-content" cdkDragBoundary="body"
     (mousedown)="moveModalToTop($event)">
    <div class="data-binding-header modal-header justify-content-start">
        <div class="modal-drag-handle" cdkDragHandle></div>
        <div class="modal-title tab" [ngClass]="{ 'inactive': activePage !== 'companyProduct', 'error': companyProductHasError }" (click)="activePage = 'companyProduct'">Company/Product Builder</div>
        <div class="modal-title tab" [ngClass]="{ 'inactive': activePage !== 'milestone', 'error': milestoneHasError }" (click)="activePage = 'milestone'">Milestone Builder</div>
    </div>
    <div class="data-binding-body-container">
        <!-- Company/Product Builder -->
        <div *ngIf="activePage === 'companyProduct'" class="data-binding-modal-page">
            <!-- Add a hidden element so that the actual input elements do not get auto focus when the model opens -->
            <input type="text" style="display:none" />
            <div class="modal-body company-product-pair-modal">
                <div class="blurb">Search for one or more companies and products to build your <span class="font-weight-bold">Company(Product)</span> pair.</div>
                <div class="mb-4">
                    <div *ngFor="let pair of companyProductPairs; let i = index">
                        <div class="company-product-pair-entry mt-2">
                            <div class="company-product-pair-search-container pr-3">
                                <label for="company-search">Companies</label>
                                <ng-select id="company-search" class="mind-map-data-binding-search" name="mapsManagerCompanySearchInput" placeholder="Search"
                                           [ngModel]="pair.companies"
                                           [items]="companySearchResults"
                                           [multiple]="true"
                                           [bindLabel]="'name'"
                                           [clearable]="false"
                                           [loading]="searchingForCompanies === i"
                                           [searchFn]="noFilterSearchFn"
                                           (search)="onCompanySearchChange($event.term, i)"
                                           (add)="onCompanySelect($event, i)"
                                           (remove)="onCompanyRemove($event, i)"
                                           (close)="companySearchResults = []">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <div class="label-template company">
                                            <span class="label">{{ item.name || item }}</span>
                                            <span class="remove" (click)="clear(item)">x</span>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="company-product-pair-search-container pl-3">
                                <label for="product-search">Products</label>
                                <ng-select id="product-search" class="mind-map-data-binding-search" name="mapsManagerProductSearchInput" placeholder="Search"
                                           [ngModel]="pair.products"
                                           [items]="productSearchResults"
                                           [multiple]="true"
                                           [bindLabel]="'name'"
                                           [clearable]="false"
                                           [loading]="searchingForProducts === i"
                                           [searchFn]="companyProductPairs[i].companies.length === 0 ? noFilterSearchFn : null"
                                           (search)="onProductSearchChange($event.term, i)"
                                           (add)="onProductSelect($event, i)"
                                           (remove)="onProductRemove($event, i)">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <div class="label-template product">
                                            <span class="label">{{ item.name || item }}</span>
                                            <span class="remove" (click)="clear(item)">x</span>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div *ngIf="i > 0" class="data-bind-delete-button">
                                <i class="fa fa-trash" (click)="companyProductPairs.splice(i, 1)"></i>
                            </div>
                        </div>
                        <div class="company-product-pair-entry mt-2">
                            <div class="company-product-pair-search-container pr-3">
                                <label for="product-data-dosing">Dosing</label>
                                <ng-select id="product-data-dosing" class="mind-map-data-binding-search" name="mapsManagerProductCategoryValue" placeholder="Search"
                                           [ngModel]="productCategoryValues | filter:pcvListPredicate:'Dosing'"
                                           [items]="dosingSearchResults"
                                           [multiple]="true"
                                           [bindLabel]="'name'"
                                           [clearable]="false"
                                           [loading]="false"
                                           [searchFn]="noFilterSearchFn"
                                           (search)="pcvSearchFn($event.term, 'Dosing')"
                                           (add)="onProductCategoryValueChange($event, 'add')"
                                           (remove)="onProductCategoryValueChange($event, 'remove')"
                                           (close)="companySearchResults = []">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <div class="label-template pd-dosing">
                                            <span class="label">{{ item.name || item }}</span>
                                            <span class="remove" (click)="clear(item)">x</span>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="company-product-pair-search-container pl-3">
                                <label for="product-data-roa">ROA</label>
                                <ng-select id="product-data-roa"
                                           class="mind-map-data-binding-search"
                                           name="mapsManagerProductCategoryValue"
                                           placeholder="Search"
                                           [ngModel]="productCategoryValues | filter:pcvListPredicate:'Route of Administration'"
                                           [items]="roaSearchResults"
                                           [multiple]="true"
                                           [bindLabel]="'name'"
                                           [clearable]="false"
                                           [loading]="false"
                                           [searchFn]="noFilterSearchFn"
                                           (search)="pcvSearchFn($event.term, 'Route of Administration')"
                                           (add)="onProductCategoryValueChange($event, 'add')"
                                           (remove)="onProductCategoryValueChange($event, 'remove')">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <div class="label-template pd-rod">
                                            <span class="label">{{ item.name || item }}</span>
                                            <span class="remove" (click)="clear(item)">x</span>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div *ngIf="i > 0" class="data-bind-delete-button">
                                <i class="fa fa-trash" (click)="companyProductPairs.splice(i, 1)"></i>
                            </div>
                        </div>

                        <div *ngIf="submitted" class="company-product-error-container">
                            <div *ngIf="formErrors.get(i)?.companyProductPairBlank" class="error">Must have at least one Company or Product</div>
                            <div *ngIf="formErrors.get(i)?.companyProductPairInvalid" class="error">Invalid Company/Product pair</div>
                        </div>
                    </div>
                </div>
                <div class="line-separator"></div>
                <div class="company-product-pair-label">
                    <label for="company-product-label">Preview</label>
                    <div class="blurb">Type inside the text box to add your own text to the node label. Once the label text is edited, it will not update with the above builder unless it is reset.</div>
                    <input type="text" class="form-control w-100" id="company-product-label"
                           [ngClass]="!companyProductLabelDirty ? 'company-product-label-clean' : ''"
                           [(ngModel)]="companyProductLabel"/>
                    <div class="company-product-pair-warning"
                         [ngClass]="companyProductLabelDirty ? 'visible' : 'invisible'">
                        <i class="fa fa-exclamation-circle"></i> The preview text has been edited. Please note this will no longer synchronize with the above fields.
                    </div>
                    <div class="d-flex justify-content-end">
                        <button *ngIf="companyProductLabelDirty" class="btn btn-subtle"
                                (click)="companyProductLabel = formattedCompanyProductLabel">Reset</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Milestone Builder -->
        <div *ngIf="activePage === 'milestone'" class="data-binding-modal-page">
            <!-- Add a hidden element so that the actual input elements do not get auto focus when the model opens -->
            <input type="text" style="display:none" />
            <div class="modal-body">
                <div *ngFor="let milestone of milestones; let i = index" class="mt-4 py-2 pr-2 milestone-entry-container">
                    <div class="milestone-entry">
                        <div class="d-lg-none data-bind-delete-button align-self-end">
                            <i class="fa fa-remove" (click)="milestones.splice(i, 1)"></i>
                        </div>

                        <div class="milestone-value-input-container milestone-value-phase-container">
                            <label for="milestone-phase-{{i}}">{{ MILESTONE_CATEGORIES.Phase }}</label>
                            <ng-select id="milestone-phase-{{i}}"
                                       [(ngModel)]="milestones[i].phase"
                                       [items]="milestoneCategoryValues[MILESTONE_CATEGORIES.Phase]"
                                       [bindLabel]="'name'"
                                       [bindValue]="'name'"
                            ></ng-select>
                        </div>

                        <div class="milestone-value-input-container milestone-value-trial-container">
                            <label for="milestone-trial-{{i}}">{{ MILESTONE_CATEGORIES.TrialType }}</label>
                            <ng-select id="milestone-trial-{{i}}"
                                       [(ngModel)]="milestones[i].trial"
                                       [items]="milestoneCategoryValues[MILESTONE_CATEGORIES.TrialType]"
                                       [bindLabel]="'name'"
                                       [bindValue]="'name'"
                            ></ng-select>
                        </div>

                        <div class="milestone-value-input-container milestone-value-event-container">
                            <label for="milestone-event-{{i}}">{{ MILESTONE_CATEGORIES.Event }}</label>
                            <ng-select id="milestone-event-{{i}}"
                                       [(ngModel)]="milestones[i].event"
                                       [items]="milestoneCategoryValues[MILESTONE_CATEGORIES.Event]"
                                       [bindLabel]="'name'"
                                       [bindValue]="'name'"
                            ></ng-select>
                        </div>

                        <div class="milestone-value-input-container milestone-value-date-container">
                            <label for="milestone-date-{{i}}">{{ MILESTONE_CATEGORIES.Date }}</label>
                            <input type="text" id="milestone-date-{{i}}" class="form-control milestone-value-date-input"
                                   [(ngModel)]="milestones[i].date" />
                        </div>

                        <div class="milestone-value-input-container milestone-value-indications-container">
                            <label for="milestone-indication-{{i}}">{{ MILESTONE_CATEGORIES.Indication }}</label>
                            <ng-select id="milestone-indication-{{i}}"
                                       [(ngModel)]="milestones[i].indications"
                                       [items]="indications"
                                       [bindLabel]="'name'"
                                       [bindValue]="'name'"
                                       [multiple]="true"
                                       [closeOnSelect]="false"
                                       [clearSearchOnAdd]="true"
                            ></ng-select>
                        </div>

                        <div class="d-none d-lg-inline data-bind-delete-button">
                            <i class="fa fa-remove" (click)="milestones.splice(i, 1)"></i>
                        </div>
                    </div>

                    <div class="add-note-container mt-4" *ngIf="!milestoneHasNote.has(i) || !milestoneHasNote.get(i)">
                        <div class="add-note" (click)="milestoneHasNote.set(i, true)"><i class="fa fa-plus-circle"></i> Add Note</div>
                    </div>

                    <div *ngIf="milestoneHasNote.has(i) && milestoneHasNote.get(i)" class="milestone-value-input-container mt-4 d-flex">
                        <div class="flex-grow-1">
                            <label for="milestone-note-{{i}}" class="d-flex align-items-center">
                                <span>Milestone Note</span>
                                <div class="data-bind-delete-button ml-3">
                                    <i class="fa fa-remove d-flex align-items-center" (click)="milestoneHasNote.set(i, false); milestones[i].note = null"></i>
                                </div>
                            </label>
                            <input id="milestone-note-{{i}}" class="form-control" [(ngModel)]="milestones[i].note" maxlength="255" />
                        </div>
                    </div>

                    <div *ngIf="submitted" class="milestone-error-container">
                        <div *ngIf="formErrors.get(i)?.milestoneBlank" class="error">Milestone cannot be blank</div>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-4">
                    <button class="btn btn-subtle" (click)="milestones.push({})">+ New Milestone</button>
                </div>
                <div class="line-separator"></div>
                <div class="milestone-label">
                    <label for="milestone-label">Preview</label>
                    <textarea class="w-100" id="milestone-label" rows="4"
                              [disabled]="true"
                              [ngModel]="formattedMilestoneLabel">
            </textarea>

                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="d-flex justify-content-between w-100">
            <button type="button" class="btn btn-default" (click)="activeModal.dismiss()">{{"GENERICS.CLOSE" | translate}}</button>
            <button [hidden]="finalized" type="button" class="btn btn-primary" (click)="closeModal(activeModal)">Save</button>
        </div>
    </div>
</div>
