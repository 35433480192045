export const CONFIG = {
  contactEmail: 'it@racap.com',
  defaultTenantId: 1,
  apiUrl: '/api',
  wsURL: '/ws/websocket/websocket',
  metadataPath: '/metadata',
  imagesUrl: 'assets/images/',
  i18nPath: 'assets/i18n/',
  duoScriptUrl: 'https://api.duosecurity.com/frame/hosted/Duo-Web-v2.min.js',
  dateFormat: 'yyyy-mm-dd',
  shortDateFormat: 'MM/DD/YYYY',
  dateTimeFormat: 'MM-DD-YYYY [at] hh:mma',
  dateTimeFormatAlternative: 'MM/DD/YYYY hh:mma',
  dateTimeDetailedFormat: 'MM/DD/YYYY hh:mm:ssa',
  dateWeekDay: 'dddd, LL',
  formatEST: 'America/New_York',
  passwordPattern: '(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,}',
  basicStringPattern: '[a-zA-Z ]+',
  storagePrefix: 'rms',
  defaultLang: 'en',
  defaultProfileImage: 'assets/images/default.jpg',
  authHeader: 'Authorization',
  authPreamble: 'Bearer ',
  wsRetryTimeOut: 120000, // time in milliseconds equivalent to 2 minutes
  emapBundleFileExtension: 'eMapBundle',
  oAuth: {
    regular: {
      grantType: 'password',
      clientId: 'rms-trusted-client',
      clientSecret: 'RMSp14BQR3A78ek7d27Hme7X6n7k2m',
      username: '',
      password: ''
    },
    twoFactor: {
      grantType: 'twoFactor',
      appName: 'duo',
      username: '',
      password: '',
      secretCode: ''
    },
    refreshToken: {
      grantType: 'refresh_token'
    },
  },
  select: {
    basic: {
      highlight: false,
      create: false,
      persist: true,
      plugins: ['dropdown_direction'],
      dropdownDirection: 'auto',
      labelField: 'name',
      valueField: 'id',
      searchField: ['name'],
      maxItems: 1
    },
    multiple: {
      highlight: false,
      plugins: ['dropdown_direction', 'remove_button'],
      dropdownDirection: 'auto',
      labelField: 'name',
      valueField: 'id',
      searchField: ['name'],
      maxItems: 100
    }
  },
  toggleSwitch: {
    activeColor: '#55C2C3',
    bgColor: '#F5F5F6',
    size: 'small'
},
  uploadSettings: {
    allowedTypes: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', ''],
    maxFileSize: 200000000000
  },
  pagination: {
    allowedRanges: [{ range: 10 }, { range: 20 }, { range: 30 }, { range: 40 }, { range: 50 }, { range: 75 }, { range: 100 }],
    maxPaginationItems: 4,
    directionLinks: true,
    boundaryLinks: false,
    ellipses: true,
    rotate: true,
    size: '',
    singleLargePage: 200
  },
  bundleSettings: {
    fileExtension: 'xml'
  },
  aws: {
    s3: {
      local: 'https://emap-local-private.s3.amazonaws.com/',
      dev: 'https://emap-dev-private.s3.amazonaws.com/',
      qa: 'https://emap-qa-private.s3.amazonaws.com/',
      uat: 'https://emap-uat-private.s3.amazonaws.com/',
      prod: 'https://emap-prod-private.s3.amazonaws.com/'
    }
  },
  gojs: {
    // tslint:disable-next-line:max-line-length
    licenseKey: '73f943e3bb6628a800ca0d2b113f69ee1bb37b649e801ef65d5242f5eb01691c2b9ded7958838e97d0ad4efa1d7ac2d8cfd56f21c71f053db466878c40e786fae23277b21d5843dca20027c69afc28f5af7c75f6d0a571f78a7e8ca0bba9d18c5fe9f0d757ca0ea42b79'
  },
  errors: {
    authErrorCode: 'BUS_0001',
    twoFactorAuthErrorCode: 'BUS_0051',
    passwordExpiredErrorCode: 'BUS_0058',
    milestoneCategoryValueMissingErrorCode: 'BUS_2076'
  }
};
