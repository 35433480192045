import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CompanyResource } from '../../shared/models/resources/companyResource.model';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CompanyService extends AbstractCRUD<CompanyResource> implements CRUDService<CompanyResource>, Searcher {

    constructor(
        exceptionService: ExceptionService,
        http: HttpClient,
        utilsService: UtilsService
    ) {
        super(exceptionService, http, utilsService);
    }

    public getResourcePath() {
        return '/core/service/recon/company';
    }

    public getDisplayName(item: any): string {
        return item.name;
    }

    public getAllCompanyReconPath(mapVersionId: string) {
        return super.getApiPath().concat('/').concat(mapVersionId);
    }

    public getFuzzySearchForCompanyPath() {
        return super.getApiPath().concat('/search');
    }

    public getRmsCompanyPath(rmsCompanyId: string) {
        return super.getApiPath().concat('/rms/').concat(rmsCompanyId);
    }

    public getAllCompanyRecon(mapVersionId: string) {
        return this.getHTTP().get(this.getAllCompanyReconPath(mapVersionId))
          .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }

    public getFuzzySearchForCompany(companyName: string) {
        const httpParams = new HttpParams().set('companyName', companyName);
        return this.getHTTP().get(this.getFuzzySearchForCompanyPath(), { params: httpParams })
          .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }

    public getRmsCompany(rmsCompanyId: string) {
        return this.getHTTP().get(this.getRmsCompanyPath(rmsCompanyId))
          .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }

    public putCompanyRecon(mapVersionId: string, body: object) {
        return this.getHTTP().put(this.getAllCompanyReconPath(mapVersionId), body)
          .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
    }
}
