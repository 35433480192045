import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SearchFilterKeyboardServices } from '../../services/searchFilterKeyboard.services';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { ToolboxAllPanelServices } from '../../services/toolboxAllPanel.services';
import * as $ from 'jquery';

@Component({
  selector: 'emap-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class KeyboardComponent implements OnInit {
  private SEARCH_INPUT_ID = 'search-input';
  private KEYBOARD_PANEL_CHILD_SEARCH_PANEL_CLASS = 'keyboard-panel-child-search-panel';
  private KEYBOARD_PANEL_CHILD_SEARCH_RESULTS_CLASS = 'keyboard-panel-child-search-results';
  private KEYBOARD_PANEL_CONTAINER_CLASS = 'keyboard-panel-container';
  private KEYBOARD_PANEL_PARENT_CLASS = 'keyboard-panel-parent';
  private KEYBOARD_PANEL_BUTTON_CLASS = 'keyboard-panel-button';
  private KEYBOARD_PANEL_CHILD_CLASS = 'keyboard-panel-child';
  private PANEL_LEFT_SLIDE_OUT_CLASS = 'panel-left-slide-out';
  private KEYBOARD_PANEL_CHILD_SEARCH_AND_KEYS_CLASS = 'keyboard-panel-child-search-and-keys';
  private KEYBOARD_PANEL_CLOSE_CLASS =  'keyboard-panel-close';
  private KEYBOARD_PANEL_SWAP_CLASS =  'keyboard-panel-swap';
  private SWAP_PANEL_SLIDE_CLASS =  'panel-right-slide-in panel-right-slide-out';
  private PANEL_DISPLAY_NONE = 'panel-display-none';
  private TAB_INDEX_TEXT = 'tabindex';

  private virtualKeyboardLayoutOptions = {'English': 'English-custom', 'Greek': 'Greek-custom', 'Special': 'Special-custom'};

  constructor(
    private searchFilterKeyboardServices: SearchFilterKeyboardServices,
    private jqueryCommonServices: JqueryCommonServices,
    private toolboxAllPanelServices: ToolboxAllPanelServices
  ) { }

  ngOnInit() {
    this.initKeyboardCustomLayout();
    this.initKeyboard();
    this.updateKeyboardPanelDimensions();
    this.keyboardCustomKeyHandler();
    this.updateSearchResults();
    this.keyboardOpenEventHandler();
    this.keyboardAnimationCompleteEventHandler();
    this.toolboxSwapButtonClickHandler();
  }

  private updateKeyboardPanelDimensions() {
    this.toolboxAllPanelServices.keyboardPanelWidth = this.jqueryCommonServices.widthHeight(this.KEYBOARD_PANEL_PARENT_CLASS.toClass())['width'];
  }

  private initKeyboard() {
    ($(this.SEARCH_INPUT_ID.toId()) as any).keyboard({
      stayOpen: true,
      alwaysOpen: true,
      reposition : true,
      usePreview : false,
      appendLocally : false,
      layout: 'English-custom',
      appendTo: this.jqueryCommonServices.selector(this.KEYBOARD_PANEL_CHILD_SEARCH_AND_KEYS_CLASS.toClass()),
      position: false,
      css: {
        container: this.KEYBOARD_PANEL_CONTAINER_CLASS,
        buttonDefault: this.KEYBOARD_PANEL_BUTTON_CLASS,
      },
      beforeInsert: function(e, keyboard, el, txt) {return ['ABC', 'αβγδ', '%+=', 'CLEAR', 'SEARCH'].indexOf(txt) > -1 ? '' : txt; }
    });
  }

  private initKeyboardCustomLayout() {
    ($ as any).keyboard.layouts['Greek-custom'] = {
      'lang': ['el'],
      'normal': [
        '# 1 2 3 4 5 6 7 8 9 0 {bksp}',
        '{shift} θ ω ε ρ τ ψ υ ι ο π {shift}',
        '{SEARCH} α σ δ φ γ η ξ κ λ {SEARCH}',
        '( ) ζ χ ς ω β ν μ / \\ -',
        'ABC {space} %+= CLEAR'
      ],
      'shift': [
        '# 1 2 3 4 5 6 7 8 9 0 {bksp}',
        '{shift} Θ Ω Ε Ρ Τ Ψ Υ Ι Ο Π {shift}',
        '{SEARCH} Α Σ Δ Φ Γ Η Ξ Κ Λ {SEARCH}',
        '( ) Ζ Χ Σ Ω Β Ν Μ / \\ -',
        'ABC {space} %+= CLEAR'
      ]
    };

    ($ as any).keyboard.layouts['Special-custom'] = {
      'lang': ['el'],
      'normal': [
        '# 1 2 3 4 5 6 7 8 9 0 {bksp}',
        '{shift} ! @ _ $ % ^ & * + = {shift}',
        '{SEARCH} { } [ ] | : ; \' " {SEARCH}',
        '( ) , . < > ? ~ ` / \\ -',
        'αβγδ {space} ABC CLEAR'
      ]
    };

    ($ as any).keyboard.layouts['English-custom'] = {
      'lang': ['el'],
      'normal': [
        '# 1 2 3 4 5 6 7 8 9 0 {bksp}',
        '{shift} q w e r t y u i o p {shift}',
        '{SEARCH} a s d f g h j k l {SEARCH}',
        '( ) z x c v b n m / \\ -',
        'αβγδ {space} %+= CLEAR'
      ],
      'shift': [
        '# 1 2 3 4 5 6 7 8 9 0 {bksp}',
        '{shift} Q W E R T Y U I O P {shift}',
        '{SEARCH} A S D F G H J K L {SEARCH}',
        '( ) Ζ Χ C V B N M / \\ -',
        'αβγδ {space} %+= CLEAR'
      ]
    };

    ($ as any).extend(($ as any).keyboard.keyaction, {
      SEARCH: function(){
        ($ as any)(this.SEARCH_INPUT_ID.toId()).focusout();
      }.bind(this)
    });
  }

  private keyboardCustomKeyHandler() {
    const parentThis = this;
    $(this.SEARCH_INPUT_ID.toId()).on('keyboardChange', function (e) {
      let triggerCheck = true;
      if (e['action'] === 'αβγδ') {
        parentThis.keyboardRedrawEventHandler('Greek');
        triggerCheck = false;
      }else if (e['action'] === 'ABC') {
        parentThis.keyboardRedrawEventHandler('English');
        triggerCheck = false;
      }else if (e['action'] === '%+=') {
        parentThis.keyboardRedrawEventHandler('Special');
        triggerCheck = false;
      }else if (e['action'] === 'CLEAR') {
        $(parentThis.SEARCH_INPUT_ID.toId()).val('');
      }else if (e['action'] === 'shift') {
        triggerCheck = false;
      }else if (e['action'] === 'SEARCH') {
        parentThis.jqueryCommonServices.selector(parentThis.SEARCH_INPUT_ID.toId()).blur();
      }
      if (triggerCheck) { parentThis.triggerInputChange(); }
    }.bind(this));
  }

  private keyboardRedrawEventHandler(keyboardLayout: string) {
    const virtualKeyboard = ($(this.SEARCH_INPUT_ID.toId()) as any).getkeyboard();
    virtualKeyboard.options.layout = this.virtualKeyboardLayoutOptions[keyboardLayout];
    virtualKeyboard.redraw();
  }

  private updateSearchResults() {
    this.searchFilterKeyboardServices.keyboardChangeEmitter.subscribe((val) => {
      this.jqueryCommonServices.selector(this.KEYBOARD_PANEL_CHILD_SEARCH_PANEL_CLASS.toClass()).empty();
      this.searchFilterKeyboardServices.keyboardData.forEach((eachKeyboardSearchData) => {
        this.jqueryCommonServices.selector(this.KEYBOARD_PANEL_CHILD_SEARCH_PANEL_CLASS.toClass())
          .append(this.jqueryCommonServices.divConstruct(eachKeyboardSearchData)
            .addClass(this.KEYBOARD_PANEL_CHILD_SEARCH_RESULTS_CLASS)
            .attr(this.TAB_INDEX_TEXT, '0'));
      });
      this.autoCompleteHandler();
    });
  }

  private autoCompleteHandler() {
    this.jqueryCommonServices.clickEvent(this.KEYBOARD_PANEL_CHILD_SEARCH_RESULTS_CLASS.toClass(), (event) => {
      const searchInputSelector = this.jqueryCommonServices.selector(this.SEARCH_INPUT_ID.toId());
      searchInputSelector.val(this.jqueryCommonServices.selector(event.currentTarget).text());
      searchInputSelector.focus();
      this.triggerInputChange();
    });
  }

  private triggerInputChange() {
    this.jqueryCommonServices.selector(this.SEARCH_INPUT_ID.toId()).trigger('change');
  }

  private keyboardOpenEventHandler() {
    this.toolboxAllPanelServices.searchInputClickEventEmitter.subscribe(value => {
      this.keyboardLeftCompute(this.toolboxAllPanelServices.swapState);
      const keyboardPanelChildSelector = this.jqueryCommonServices.selector(this.KEYBOARD_PANEL_CHILD_CLASS.toClass());
      keyboardPanelChildSelector.parent().removeClass(this.PANEL_DISPLAY_NONE);
      if (value) {
        keyboardPanelChildSelector.removeClass(this.PANEL_LEFT_SLIDE_OUT_CLASS);
      }else {
        keyboardPanelChildSelector.addClass(this.PANEL_LEFT_SLIDE_OUT_CLASS);
      }
    });
  }

  private keyboardAnimationCompleteEventHandler() {
    this.jqueryCommonServices.animationCompleteEvent(this.KEYBOARD_PANEL_CHILD_CLASS.toClass(), (event) => {
      const filterPanelChildSelector = this.jqueryCommonServices.selector(this.KEYBOARD_PANEL_CHILD_CLASS.toClass());
      if (filterPanelChildSelector.hasClass(this.PANEL_LEFT_SLIDE_OUT_CLASS)) {
        filterPanelChildSelector.parent().addClass(this.PANEL_DISPLAY_NONE);
      }else {
        filterPanelChildSelector.parent().removeClass(this.PANEL_DISPLAY_NONE);
      }
    });
  }

  private toolboxSwapButtonClickHandler() {
    this.toolboxAllPanelServices.swapButtonClickEventEmitter.subscribe(value => {
      this.keyboardLeftCompute(value);
      this.handlerKeyboardPanelCloseOnSwap(value);
      this.swapPanelAnimationHandler(value);
    });
  }

  private keyboardLeftCompute(value: boolean) {
    const toolboxWidth = this.toolboxAllPanelServices.toolboxWidth;
    const globalPanelWidth = this.toolboxAllPanelServices.globalPanelWidth;
    const filterPanelWidth = this.toolboxAllPanelServices.filterPanelOpenState ?  this.toolboxAllPanelServices.filterPanelWidth : 0;
    const keyboardPanelWidth = this.toolboxAllPanelServices.keyboardPanelWidth;
    const windowWidth = this.toolboxAllPanelServices.windowWidth;
    const keyboardPanelCloseIconWidth = this.toolboxAllPanelServices.filterPanelOpenState ?
      this.jqueryCommonServices.widthHeight(this.KEYBOARD_PANEL_CLOSE_CLASS.toClass())['width'] : 0;
    this.jqueryCommonServices.selector(this.KEYBOARD_PANEL_PARENT_CLASS.toClass())
      .css({left: !value ? (toolboxWidth + globalPanelWidth + filterPanelWidth - keyboardPanelCloseIconWidth) :
          (windowWidth - (toolboxWidth + globalPanelWidth + filterPanelWidth + keyboardPanelWidth) +
            keyboardPanelCloseIconWidth)});
  }

  private handlerKeyboardPanelCloseOnSwap(value: boolean) {
    const swapElementSelectorTargetName = this.KEYBOARD_PANEL_CHILD_SEARCH_AND_KEYS_CLASS.toClass() + ',' + this.KEYBOARD_PANEL_CLOSE_CLASS.toClass();
    const swapElementSelector = this.jqueryCommonServices.selector(swapElementSelectorTargetName);
    if (value) {
     swapElementSelector.addClass(this.KEYBOARD_PANEL_SWAP_CLASS);
    }else {
      swapElementSelector.removeClass(this.KEYBOARD_PANEL_SWAP_CLASS);
    }
  }

  private swapPanelAnimationHandler(value: boolean) {
    const keyboardPanelChildSelector = this.jqueryCommonServices.selector(this.KEYBOARD_PANEL_CHILD_CLASS.toClass());
    if (value) {
      keyboardPanelChildSelector.addClass(this.SWAP_PANEL_SLIDE_CLASS);
    }else {
      keyboardPanelChildSelector.removeClass(this.SWAP_PANEL_SLIDE_CLASS);
    }
  }
}
