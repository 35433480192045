import { Injectable } from '@angular/core';

import { TwoFactorInfo } from '../shared/models/interfaces/twoFactorInfo.interface';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class EventsHubService {
  public isLoggedIn$: Subject<boolean> = new Subject();
  public httpUnauthorizedError$: Subject<boolean> = new Subject();
  public isMenuOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public profileUpdated$: Subject<boolean> = new Subject();
  public openModal$: Subject<any> = new Subject();
  public reloadRecords$: Subject<any> =  new Subject();
  public passwordExpired$: BehaviorSubject<any> =  new BehaviorSubject({});
  public treeViewReset$: Subject<boolean> = new Subject();
  public duoSecureError$: BehaviorSubject<TwoFactorInfo> = new BehaviorSubject({
    username: '',
    password: '',
    authCode: '',
    twoFactor: true
  });
  public stopHeartBeat$: Subject<null> = new Subject();

  constructor() {
  }

  public setLoggedIn(value: boolean) {
    this.isLoggedIn$.next(value);
  }

  public setUnauthorizedError(value: boolean) {
    this.httpUnauthorizedError$.next(value);
  }

  public setMenuState(value: boolean) {
    this.isMenuOpen$.next(value);
  }

  public profileUpdated(value: boolean) {
    this.profileUpdated$.next(value);
  }

  /**
   * Subject to detect when form modal is open
   * @param {T} data: type of data to pass in
   */
  public openModal<T> (data?: T) {
    this.openModal$.next(data);
  }

  public reloadRecords () {
    this.reloadRecords$.next();
  }

  public duoSecureError(value: TwoFactorInfo) {
    this.duoSecureError$.next(value);
  }

  public passwordExpired(value: any) {
    this.passwordExpired$.next(value);
  }

  /**
   * Global event handler/detection Subject
   * Used to detect Multi Select tree resets state
   */
  public treeViewReset() {
    this.treeViewReset$.next(true);
  }

  public stopHeartBeat (): void {
    this.stopHeartBeat$.next();
  }
}
