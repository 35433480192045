<div class="authoring-chart-parent">
  <div class="authoring-chart-child">
    <div id="authoring-chart-dialog" class="authoring-chart-dialog-display-none">
      <div class="authoring-chart-dialog-new-section">
        <div class="authoring-chart-dialog-new-section-text">Add New Section</div>
      </div>
      <div class="authoring-chart-dialog-accordion-main global-panel-results">
      </div>
      <div class="global-panel-results">
        <div class="authoring-chart-dialog-accordion-main global-panel-results-table">
          <div class="authoring-chart-dialog-accordion-panel"></div>
        </div>
      </div>
      <div class="authoring-chart-dialog-accordion-form-button">
        <div class="authoring-chart-dialog-accordion-save authoring-chart-dialog-accordion-button center">Save</div>
        <div class="authoring-chart-dialog-accordion-cancel authoring-chart-dialog-accordion-button center">Cancel</div>
      </div>
    </div>
    <div id="authoring-chart-upload-dialog" class="authoring-chart-dialog-display-none">
      <div class="authoring-chart-upload-dialog-filter-image">
          <input id="authoring-chart-upload-dialog-filter-image-input" placeholder="Filter Image Selector" type="text" class="form-control"/>
          <button id="authoring-chart-upload-dialog-filter-button" type="button" class="btn btn-primary btn-xs" (click)="filterImageDropDownImages()">
              <span class="glyphicon glyphicon-upload"></span> Filter
          </button>
      </div>
      <div id="authoring-chart-upload-dialog-select-parent"></div>
      <div class="authoring-chart-upload-dialog-accordion-form-button">
        <div class="authoring-chart-upload-dialog-accordion-update authoring-chart-upload-dialog-accordion-button center">Update</div>
        <div class="authoring-chart-upload-dialog-accordion-cancel authoring-chart-upload-dialog-accordion-button center">Cancel</div>
      </div>
    </div>
  </div>
</div>