export class BannerNotification {
  status: boolean;
  text: string;
  type: string;
  link: string;
  timeout: number;

  constructor(status: boolean, type?: string, text?: string, link?: string, timeout?: number) {
    this.status = status;
    this.type = type;
    this.text = text;
    this.link = link || null;
    this.timeout = timeout || 5;
  }
}
