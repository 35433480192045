import { OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';


import { TemplateModalService } from '../services/template-modal.service';
import { Pagination } from '../../shared/models';
import { CONFIG } from '../../config';
import { EventsHubService } from '../events-hub.service';
import { PERMISSIONS } from '../../shared/enums';
import { BannerNotificationsService, CRUDService, PaginatedList, SpinnerService } from '../../xform-compat';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

/**
 * Abstract to list record types in CRUD Services
 */
export abstract class RmsListAbstract<R> implements OnInit {

  public list: PaginatedList<R>;
  public simpleList: Array<R>;
  public data: R;
  public pagination: Pagination;
  public translations: any;
  public loading: boolean;
  public dateFormatConfig = CONFIG.dateWeekDay;
  public PERMISSIONS = PERMISSIONS;

  constructor(
    protected eventsHubService: EventsHubService,
    private recordsService: CRUDService<R>,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    private bannerNotificationsService: BannerNotificationsService,
    private templateModalService: TemplateModalService
  ) {
    this.list = new PaginatedList<R>();
    this.pagination = new Pagination(CONFIG.pagination);
    this.pagination.sort = this.getDefaultSort();
  }

  ngOnInit() {
    this.translateAlertMsgs(this.getRecordType());
  }

  /**
   ** Methods to use on child classes for shared helper services
   ** Makes available from child clases:
   ** - The CRUD methods from the resource service
   ** - The spinner and banner notification service
   ** - The confirm popup before delete a record
   */
  protected getResourceService() {
    return this.recordsService;
  }
  protected getSpinnerService() {
    return this.spinnerService;
  }
  protected getNotifications() {
    return this.bannerNotificationsService;
  }
  protected getTemplateModalService() {
    return this.templateModalService;
  }

  protected getTranslate() {
    return this.translateService;
  }

  public abstract getDefaultSort(): string;

  public abstract getRecordType(): string;

  protected getDefaultParams(): object {
    return {};
  }

  public getRecords(filter?: any) {

    this.spinnerService.start();

    this.recordsService.getRecords(this.constructHttpParams(filter))
      .pipe(finalize(() => {
        this.spinnerService.stop();
        this.eventsHubService.reloadRecords();
      }))
      .subscribe(
        (result) => this.list = result,
        (error) => this.bannerNotificationsService.error(error)
      );
  }

  public getRecordsList() {
    this.spinnerService.start();

    this.recordsService.getRecords()
      .finally(() => { this.spinnerService.stop(); })
      .subscribe(
        (result) => { this.simpleList = Array.from(result); },
        (error) => this.bannerNotificationsService.error(error)
      );
  }

  public getRecordObject(callback: Function, filter?: any) {
    this.spinnerService.start();
    this.recordsService.getRecords(this.constructHttpParams(filter))
      .finally(() => { this.spinnerService.stop(); })
      .subscribe(
        (result) => {
          this.data = result;
          if (typeof callback === 'function') {
            callback(result);
          }
        },
        (error) => this.bannerNotificationsService.error(error)
      );
  }

  public translateAlertMsgs(recordType?: string) {
    const type = recordType || '';
    const messages = ['GENERICS.DELETE', 'GENERICS.YES', 'GENERICS.CANCEL', 'GENERICS.DELETE_TITLE',
      'MESSAGES.CONFIRM_ACTIVATE', 'MESSAGES.CONFIRM_DEACTIVATE', 'MESSAGES.CONFIRM_DELETE', 'TENANTS.ADD',
      'TENANTS.SUCCESS', 'MESSAGES.CREATED'];
    this.translateService.get(messages, { value: type })
      .subscribe(
        (translations) => {
          this.translations = translations;
        }
      );
  }

  public deleteRecord(record: R, callback?: Function): Subject<boolean> {
    const deleted$: Subject<boolean> = new Subject<boolean>();
    this.recordsService.deleteRecord(record)
      .subscribe(
        () => {
          callback ? callback() : this.getRecords();
          deleted$.next(true);
        },
        (error) => {
          deleted$.next(false);
          this.bannerNotificationsService.error(error);
        }
      );

    return deleted$;
  }

  public filterList(form) {
    this.pagination.page = 1;
    // prevent null values
    for (const key in form.value) {
      if (form.value[key] === null) {
        form.value[key] = '';
      }
    }
    this.getRecords(form.value);
  }

  public setRecordsPerPage(filter?: any) {
    this.getRecords(filter);
  }

  public sort(sort: string) {
    if (sort === this.pagination.sort) {
      this.pagination.direction = (this.pagination.direction === 'ASC') ? 'DESC' : 'ASC';
    } else {
      this.pagination.sort = sort;
      this.pagination.direction = 'ASC';
    }
    this.getRecords();
  }

  /**
   * Alert template message to display with the sweet alert library
   */
  public templateModal(type: string, record: string, recordName: string) {
    return this.templateModalService.getDeleteTemplate(this.translations[`MESSAGES.CONFIRM_${type}`], record, recordName);
  }

  /**
   * Display proper modal for create or edit records
   * @param {T} record: optional param, if not present, it will be created, if exists, will be edited
   */
  public showModal<T>(record?: T) {
    this.eventsHubService.openModal(record);
  }

  private constructHttpParams(filter?: any) {
    const params = {
      page: this.pagination.page - 1,
      pagesize: this.pagination.pagesize,
      sort: this.pagination.sort,
      direction: this.pagination.direction
    };
    Object.assign(params, filter, this.getDefaultParams());
    return params;
  }

}
