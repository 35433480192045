import { Component, Input, OnInit } from '@angular/core';
import { EventsHubService } from '../../../core/events-hub.service';

@Component({
  selector: 'xform-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  @Input() sidebar: boolean;
  @Input() bgColor: string;
  public openMobileMenu: boolean;

  constructor(
    private eventsHubService: EventsHubService
  ) {
    this.sidebar = false;
    this.eventsHubService.isMenuOpen$.subscribe(
      (response) => {
        this.openMobileMenu = response;
      }
    );
  }

  ngOnInit() {
    this.bgColor = (this.bgColor) ? this.bgColor : 'grey-bg';
  }

  public closeMobileMenu() {
    this.eventsHubService.setMenuState(false);
  }

}
