<div class="container-fluid">
    <div class="row">
        <div class="col text-center nodes-manager-parent">
            <div class="float-left p-2 nodes-manager-header page-header-title">
                <span class="header-static-name">Nodes Manager</span>
                <span class="header-dynamic-map-name page-header-title-name">{{ ' | ' + mapsManagerVersionName }}</span>
            </div>
            <div class="col-2 mb-2 pt-2 d-flex float-right nodes-manager-toggle" (click)="returnToMapsManagerPage()">
                <div>{{"MAPS_MANAGER.BACK_TO_MAPS_MANAGER" | translate | uppercase}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <ul ngbNav #nav="ngbNav" [(activeId)]="currentTab" class="detail-tabs">
            <li [id]="PRODUCTS" [ngbNavItem]="0">
                <a ngbNavLink>Products <span>{{ getDataLength(PRODUCTS) }}</span></a>
                <ng-template ngbNavContent>
                    <div class="download-button">
                        <button class="btn btn-default" (click)="downloadTable(PRODUCTS)">Download</button>
                    </div>
                    <emap-nodes-manager-products *ngIf="productDataList"
                                                 [productDataList]="productDataList">
                    </emap-nodes-manager-products>
                </ng-template>
            </li>
            <li [id]="MILESTONES" [ngbNavItem]="1">
                <a ngbNavLink>Milestones <span>{{ getDataLength(MILESTONES) }}</span></a>
                <ng-template ngbNavContent>
                    <div class="download-button">
                        <button class="btn btn-default" (click)="downloadTable(MILESTONES)">Download</button>
                    </div>
                    <emap-nodes-manager-milestones *ngIf="milestoneDataList"
                                                   [milestoneDataList]="milestoneDataList">
                    </emap-nodes-manager-milestones>
                </ng-template>
            </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>
