<div class="filter-panel-parent">
    <div class="filter-panel-child panel-left-slide-out panel-left-slide-in">
        <div class="filter-panel-close filter-panel-close-left">
            <div class="filter-panel-close-icon"></div>
        </div>
        <div class="filter-panel-header-results">
            <div class="filter-panel-header">
                <span>FILTER RESULTS</span>
            </div>
            <div class="global-panel-results filter-panel-results">
                <div class="filter-panel-results-table">
                </div>
            </div>
        </div>
        <div class="filter-panel-close filter-panel-close-right">
            <div class="filter-panel-close-icon"></div>
        </div>
    </div>
</div>