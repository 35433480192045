import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordResetService } from '../../../core/data-services/passwordReset.service';
import { BannerNotificationsService, SpinnerService } from '../../../xform-compat';


@Component({
  selector: 'xform-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  private resetToken: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinnerService: SpinnerService,
    private passwordResetService: PasswordResetService,
    private bannerNotificationsService: BannerNotificationsService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params
      .subscribe(params => {
        this.resetToken = params['token'];
      });
  }

  public onSubmit(form: any) {
    this.spinnerService.start();

    if (form.invalid) {
      this.spinnerService.stop();
      return false;
    }

    const body = form.value;
    body.code = this.resetToken;
    delete body.confirmPassword;
    this.passwordResetService.resetPassword(body)
      .subscribe(
        () => {
          this.spinnerService.stop();
          this.router.navigate(['/login']);
          this.bannerNotificationsService.success(this.translateService.instant('MESSAGES.RESET_PASSWORD'));
        },
        (error) => {
          this.spinnerService.stop();
          this.bannerNotificationsService.error(error.data.message);
        }
      );
  }

}
