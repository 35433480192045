<div class="global-panel-parent">
  <div class="global-panel-child panel-left-slide-out panel-left-slide-in">
    <div class="global-panel-header"></div>
    <div class="global-panel-body">
        <emap-menu-panel class="main-panel menu-main-panel"></emap-menu-panel>
        <emap-search-panel class="main-panel search-main-panel"></emap-search-panel>
        <emap-legend-panel class="main-panel legend-main-panel"></emap-legend-panel>
        <emap-chart-panel class="main-panel chart-main-panel"></emap-chart-panel>
        <emap-insight-panel class="main-panel insight-main-panel"></emap-insight-panel>
    </div>
  </div>
</div>
