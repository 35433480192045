import {Injectable} from '@angular/core';
import {CategoryValueResource} from '../../shared/models/resources/categoryValueResource.model';
import {CONFIG} from '../../config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService} from '../../xform-compat';
import {catchError} from 'rxjs/operators';
import {ProductCategoryValue} from '../../shared/models/productCategoryValue.model';


@Injectable()
export class ProductCategoryValueService extends AbstractCRUD<ProductCategoryValue> implements CRUDService<ProductCategoryValue>, Searcher {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getDisplayName(item: any): string {
    return item.name;
  }

  public getResourcePath() {
    return '/core/service/productCategoryValues';
  }

  public getAllPath() {
    return super.getApiPath().concat('/all');
  }

  public getAllCategoryValue(params: HttpParams) {
    return this.getHTTP().get(super.getApiPath(), {params: params})
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }
}
