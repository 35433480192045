import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { AuthenticationService } from '../../core/services/authentication.service';
import { EventsHubService } from '../../core/events-hub.service';
import { ItemMenu, User } from '../../shared/models';
import { CONFIG } from '../../config';
import { MENU } from '../../shared/menu';
import { AuthorizationService } from '../../core/services/authorization.service';
import { HeartbeatService } from '../../core/data-services/heartbeat.service';
import { TemplateModalService } from '../../core/services/template-modal.service';
import { interval, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BannerNotificationsService, LocalStorageService, SanitizeService } from '../../xform-compat';
import Swal from 'sweetalert2';
import {NavigationEnd, Router} from '@angular/router';
import { GoogleTagManagerService } from '../../core/services/google-tag-manager.service';


@Component({
  selector: 'xform-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public loggedIn: boolean;
  public loggedUser: User;
  public defaultProfileImage: string;
  public isMenuOpen: boolean;
  public menu: Array<ItemMenu>;
  private heartBeat: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private sanitizeService: SanitizeService,
    private eventsHubService: EventsHubService,
    private heartbeatService: HeartbeatService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private templateModalService: TemplateModalService,
    private router: Router,
    private googleTagManagerService: GoogleTagManagerService
  ) {
    this.defaultProfileImage = CONFIG.defaultProfileImage;
    this.isMenuOpen = false;
    this.eventsHubService.isMenuOpen$.subscribe(
      (response) => this.isMenuOpen = response
    );
    this.eventsHubService.profileUpdated$.subscribe(
      () => this.loadLoggedUserData()
    );
  }

  ngOnInit() {
    this.loggedIn = this.authenticationService.isLoggedIn();
    this.eventsHubService.isLoggedIn$.subscribe(
      (loggedIn) => {
        this.loggedIn = (loggedIn);
        this.loadLoggedUserData();
      });
    this.loadLoggedUserData();
    this.menu = MENU;
  }

  private loadLoggedUserData() {
    if (this.loggedIn) {
      this.loggedUser = this.authenticationService.getLoggedUser();
      this.googleTagManagerService.pushOnDataLayer({userId: Number(this.loggedUser.id)});
      /**
       * Time interval to check the heartbeat
       * Gets cached session info to process in the request
       * Heartbeats responds successfully, nothing happens
       * Heartbeat request fails, a refresh token request is fired
       * User is logged out if refresh token fails
       */
      this.heartBeat = interval(15000).subscribe(() => {
        const tokenData = this.localStorageService.get('tokenInfo');
        this.heartbeatService.get().subscribe(() => {},
          () => {
            this.authenticationService.refreshToken(tokenData).subscribe(err => {
              this.logout();
              Swal.fire(this.templateModalService.getInactivityOptions(
                this.translateService.instant('KEEP_ALIVE.SESSION_EXPIRED'),
                this.translateService.instant('KEEP_ALIVE.SESSION_EXPIRED')));
            });
          });
      });
      if (this.loggedUser) {
        this.loggedUser.profileImageBase64 = this.sanitizeService.sanitizeBase64Image(this.loggedUser.profileImage);
      }
    }
  }

  public logout() {
    this.heartBeat.unsubscribe();
    this.authenticationService.logout();
  }

  public openMenu(value: boolean) {
    this.eventsHubService.setMenuState(value);
  }
}
