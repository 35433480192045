import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { DesignIntegrationService } from '../../services/designIntegration.service';
import { GlobalIntegrationServices } from '../../services/globalIntegration.services';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { DestroyerAbstract } from '../../../core/components/destroyer.abstract';

@Component({
  selector: 'emap-drug-information-table',
  templateUrl: './drug-information-table.component.html',
  styleUrls: ['./drug-information-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DrugInformationTableComponent extends DestroyerAbstract implements OnInit {

  private DRUG_INFO_TABLE_PARENT_CLASS = 'drug-info-table-parent';
  private DRUG_INFO_TABLE_CHILD_CLASS = 'drug-info-table-child';
  private DRUG_INFO_TABLE_COLUMN = 'col';
  private DRUG_INFO_TABLE_ROW = 'row';
  private DRUG_INFO_TABLE_ROW_ELEMENT_KEY = 'drug-info-table-row-element-key';
  private DRUG_INFO_TABLE_ROW_ELEMENT_VALUE = 'drug-info-table-row-element-value';
  private MORPH_ACTION_NODE_HIGHLIGHT = 'morph-action-highlight-border';
  private DRUG_INFO_TABLE_FOCUS_HIGHLIGHT = 'drug-info-table-focus-highlight';
  private DRUG_INFO_TABLE_UNSELECTED_HIGHLIGHT = 'morph-action-unselected-highlight';
  private MAX_ROW_ELEMENT_COUNT = 2;
  private MIN_ROW_ELEMENT_COUNT = 1;
  private MAX_ROW_ELEMENT_LENGTH = 25;

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private designIntegrationService: DesignIntegrationService,
    private globalIntegrationServices: GlobalIntegrationServices
  ) {
    super();
  }

  ngOnInit() {
    this.initDrugInfoTableSubscriptions();
  }

  private initDrugInfoTableSubscriptions() {
    this.customSubscribe(this.designIntegrationService.placeDrugInfoTableEventEmitter, (value) => {
      const drugInfoTable = this.constructDrugInfoTable(value['data']);
      this.placeDrugInfoTable(drugInfoTable, value['endNode']);
    });
    this.customSubscribe(this.designIntegrationService.removeDrugInfoTableEventEmitter, () => {
      this.removeDrugInfoTable();
    });
  }

  private placeDrugInfoTable(tableSelector: any, endNode: any) {
    if (endNode.hasClass(this.MORPH_ACTION_NODE_HIGHLIGHT)) {
      tableSelector.addClass(this.DRUG_INFO_TABLE_FOCUS_HIGHLIGHT);
    } else {
      endNode.addClass(this.DRUG_INFO_TABLE_UNSELECTED_HIGHLIGHT);
    }
    const drugInfoTableParent = this.jqueryCommonServices.selector(this.DRUG_INFO_TABLE_PARENT_CLASS.toClass());
    drugInfoTableParent.append(tableSelector).offset(this.getDrugInfoTablePosition(endNode));
  }

  private getDrugInfoTablePosition(endNode: any) {
    const drugInfoTableWidth = this.jqueryCommonServices.selector(this.DRUG_INFO_TABLE_PARENT_CLASS.toClass()).width();
    const endNodePosition = endNode.offset();
    const endNodeWidth = endNode.outerWidth()
    const drugInfoTableTop = endNodePosition.top - this.jqueryCommonServices.selector(this.DRUG_INFO_TABLE_PARENT_CLASS.toClass()).height();
    let drugInfoTableLeft = endNodePosition.left;
    if (endNode.hasClass('node-layer-right-side')) {
      drugInfoTableLeft -= drugInfoTableWidth - endNodeWidth * this.globalIntegrationServices.ratio;
    }
    return {top: drugInfoTableTop, left: drugInfoTableLeft};
  }

  private constructDrugInfoTable(data: any) {
    let currentRowElementsCount = this.MAX_ROW_ELEMENT_COUNT;
    const table = this.jqueryCommonServices.divConstruct().addClass(this.DRUG_INFO_TABLE_CHILD_CLASS);
    let row = this.jqueryCommonServices.divConstruct().addClass(this.DRUG_INFO_TABLE_ROW);
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const rowElement = this.jqueryCommonServices.divConstruct().addClass(this.DRUG_INFO_TABLE_COLUMN);
        const rowElementKey = this.jqueryCommonServices.divConstruct(key).addClass(this.DRUG_INFO_TABLE_ROW_ELEMENT_KEY);
        const rowElementValue = this.jqueryCommonServices.divConstruct(data[key]).addClass(this.DRUG_INFO_TABLE_ROW_ELEMENT_VALUE);
        rowElement.append(rowElementKey).append(rowElementValue);
        const rowLength = data[key].length > this.MAX_ROW_ELEMENT_LENGTH ? this.MAX_ROW_ELEMENT_COUNT : this.MIN_ROW_ELEMENT_COUNT;
        if (rowLength > currentRowElementsCount) {
          table.append(row);
          row = this.jqueryCommonServices.divConstruct().addClass(this.DRUG_INFO_TABLE_ROW).append(rowElement);
          table.append(row);
          currentRowElementsCount = this.MAX_ROW_ELEMENT_COUNT - rowLength;
        } else {
          row.append(rowElement);
          currentRowElementsCount -= rowLength;
        }
      }
    }
    return table;
  }

  private removeDrugInfoTable() {
    this.jqueryCommonServices.selector(this.DRUG_INFO_TABLE_PARENT_CLASS.toClass()).empty();
    this.jqueryCommonServices.selector(this.DRUG_INFO_TABLE_UNSELECTED_HIGHLIGHT.toClass()).removeClass(this.DRUG_INFO_TABLE_UNSELECTED_HIGHLIGHT);
  }
}
