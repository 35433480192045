import { ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BannerNotificationsService } from './banner-notifications.service';
import { BannerNotification } from './banner-notification.model';

export class AbstractBannerNotifications implements OnInit {

  public notification: BannerNotification = new BannerNotification(false);
  public translations: any = [];
  public notifications: Array<BannerNotification> = [];

  constructor(
    private notificationService: BannerNotificationsService,
    private elementRef: ElementRef,
    private translateService: TranslateService
  ) {
    notificationService.notification$.subscribe((notification: BannerNotification) => {
      if (notification.status) {
        this.notifications.push(notification);
        this.notification = notification;
        setTimeout(() => {
          this.notification.status = false;
          this.close();
        }, this.notification.timeout * 1000);
      }
    });
  }

  ngOnInit() {
    this.translateLinkTitle();
  }

  private translateLinkTitle() {
    const messages = ['NOTIFICATIONS.VIEW_MORE', 'NOTIFICATIONS.CLOSE'];
    this.translateService.get(messages).subscribe(translations => this.translations = translations);
  }

  public close() {
    const banners = this.elementRef.nativeElement.querySelectorAll('.banner-notification');
    banners.forEach((bn, i) => this.notifications.splice(i, 1));
    if (!this.notifications.length) {
      this.notificationService.hide();
    }
  }
}
