
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExceptionService, PaginatedList, UtilsService } from '../../xform-compat';
import { EventLog } from '../../shared/models';
import { CONFIG } from '../../config';


@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  private apiPath = CONFIG.apiUrl;

  constructor(
    private exceptionService: ExceptionService,
    private http: HttpClient,
    private utilsService: UtilsService
  ) {
  }

  public getEventLogs(params?: object, onlyRecords?: boolean) {
    const httpParams = this.utilsService.generateParams(params);

    return this.http.get<PaginatedList<EventLog>>(`${this.apiPath}/measures/eventlogs`, { params: httpParams }).pipe(
      map((res) => {
        if (onlyRecords) {
          return <Array<EventLog>> res.records;
        } else {
          return res;
        }
      }),
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }
}
