
import { Injectable } from '@angular/core';
import { Node } from '../models/node.model';
import { Subject } from 'rxjs';
import { MapVersionNode } from '../models/mapVersionNode.model';

@Injectable()
export class NodeLayerIntegrationService {

    private _deletedNodes: Map<string, MapVersionNode>;
    private _nodes: Map<string, MapVersionNode>;
    private _elementIdToNodeId: Map<string, string>;

    private _nodeLayerClickEventHandler: Subject<object> = new Subject<object>();

    private _mapImageWidth = 1;
    private _mapImageHeight = 1;

    get deletedNodes(): Map<string, MapVersionNode> {
        return this._deletedNodes;
    }

    set deletedNodes(value: Map<string, MapVersionNode>) {
        this._deletedNodes = value;
    }

    get nodes(): Map<string, MapVersionNode> {
        return this._nodes;
    }

    set nodes(value: Map<string, MapVersionNode>) {
        this._nodes = value;
    }

    get elementIdToNodeId(): Map<string, string> {
        return this._elementIdToNodeId;
    }

    set elementIdToNodeId(value: Map<string, string>) {
        this._elementIdToNodeId = value;
    }

    get mapImageWidth(): number {
        return this._mapImageWidth;
    }

    set mapImageWidth(value: number) {
        this._mapImageWidth = value;
    }

    get mapImageHeight(): number {
        return this._mapImageHeight;
    }

    set mapImageHeight(value: number) {
        this._mapImageHeight = value;
    }

    get nodeLayerClickEventHandler() {
        return this._nodeLayerClickEventHandler;
    }
}
