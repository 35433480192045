import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MapsManagerVersion } from '../../shared/models/mapsManagerVersion.model';
import { AbstractCRUD, CRUDService, ExceptionService, UtilsService } from '../../xform-compat';
import {catchError, map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MapsManagerVersionService extends AbstractCRUD<MapsManagerVersion> implements CRUDService<MapsManagerVersion> {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/map/service/mapsManagerVersion';
  }

  private getAllPath() {
    return super.getApiPath().concat('/all');
  }

  private saveMapsManagerVersionPath() {
    return super.getApiPath();
  }

  private updateMapsManagerVersionPath(id: number) {
    return `${super.getApiPath()}/${id}`;
  }

  public getById(id: number, params?: any) {
    const httpParams: HttpParams = params;
    return super.getHTTP().get(`${super.getApiPath()}/${id}`, { params: httpParams });
  }

  public saveMapsManagerVersion(body: MapsManagerVersion) {
    return super.getHTTP().post(this.saveMapsManagerVersionPath(), body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public updateMapsManagerVersion(body: MapsManagerVersion) {
    return super.getHTTP().put(this.updateMapsManagerVersionPath(body.id), body)
        .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public getNames() {
    return super.getHTTP().get(`${this.getApiPath()}/names`)
        .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public createNewVersion(originalVersion: MapsManagerVersion, newData: {name: string, desc: string}) {
    return super.getHTTP().post(`${super.getApiPath()}/clone`, {
      id: originalVersion.id,
      name: newData.name,
      description: newData.desc,
      interactive: originalVersion.interactive
    });
  }

  public indicationExists(mapId: number, indicationId: number) {
    const params = new HttpParams().set('indicationId', String(indicationId));
    return super.getHTTP().get(`${super.getApiPath()}/indicationExists/${mapId}`, {params: params})
        .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }
}
