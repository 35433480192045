import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../../core/guards/auth.guard';


const routes: Routes = [
  {
    path: 'dashboard', redirectTo: 'mapsManager/list', canActivate: [AuthGuard], canLoad: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class DashboardRoutingModule {}

export const routedComponents = [DashboardComponent];
