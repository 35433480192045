export class TreeViewItem {
  id: number;
  rootId: number;
  isChild: boolean;
  label: string;
  raw: any;
  parent: TreeViewItem;
  children: Array<TreeViewItem>;
  type: string;
  expanded: boolean;
  isChecked: boolean;

  constructor(item: any) {
    this.id = item.id || null;
    this.rootId = item.rootId || null;
    this.isChild = item.isChild || false;
    this.label = item.label || null;
    this.raw = item.raw || null;
    this.parent = item.parent || null;
    this.children = item.children || null;
    this.type = item.type || null;
    this.expanded = item.expanded || false;
    this.isChecked = item.isChecked || false;
  }
}

