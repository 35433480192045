import { Injectable } from '@angular/core';
import { JqueryCommonServices } from './jqueryCommon.services';
import { GlobalIntegrationServices } from './globalIntegration.services';


@Injectable()
export class ZpanCommonServices {
  private _currentPanSelector: any;
  private _ratio: number;

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private globalIntegrationServices: GlobalIntegrationServices
  ) {}

  public startZpan(zPanOptions: any) {
    if (this.currentPanSelector) {
      this.currentPanSelector.zpan();
      if (zPanOptions) {
        Object.assign(zPanOptions, {
          zoomComplete: this.zoomComplete.bind(this),
          panComplete: this.zoomComplete.bind(this),
        });
        this.currentPanSelector.zpan('option', zPanOptions);
      }
    }
  }

  public overflowAuto() {
    this.jqueryCommonServices.selector('html, body').css({
      overflow: 'auto',
      height: 'auto'
    });
  }

  public setNorm(left: number, top: number, disableAnimation: boolean = true) {
    this.currentPanSelector.zpan('setNorm', { x: left, y: top, disableAnimation: disableAnimation});
  }

  get currentPanSelector(): any {
    return this._currentPanSelector;
  }

  set currentPanSelector(value: any) {
    this._currentPanSelector = value;
  }

  get ratio(): number {
    return this._ratio;
  }

  set ratio(value: number) {
    this._ratio = value;
  }

  public zoomToPosition(top: number, left: number, disableAnimation: boolean) {
    this.currentPanSelector.zpan('zoomToPosition', top, left, disableAnimation);
  }

  public zoomToFit() {
    this.currentPanSelector.zpan('Reset');
  }

  public isZoomToFit() {
    return this.currentPanSelector.zpan('isZoomToFit');
  }

  public zoom100() {
    this.currentPanSelector.zpan('focusToCenter', {zoom: 100});
  }

  public zoomToVal(zoomVal: number) {
    this.currentPanSelector.zpan('zoomTo', {zoom: zoomVal});
  }

  public rightSlideIn() {
    this.currentPanSelector.zpan('rightSlideIn');
  }

  public rightSlideOut() {
    this.currentPanSelector.zpan('rightSlideOut');
  }

  public moveTo(offset, disableAnimation) {
    this.currentPanSelector.zpan('moveTo', offset, disableAnimation);
  }

  private panCompleteEvent() {
    // TODO yet to be implemented. But this is not a showstopper for zpan feature set.
    // will be implemented soon.
  }

  private zoomComplete (event, obj) {
    this.jqueryCommonServices.selector(this.currentPanSelector).zpan('refresh');
    if (typeof obj === 'undefined' || obj === null) { return; }
    this.globalIntegrationServices.ratio = obj.ratio;
    this.globalIntegrationServices.scaledWidth = obj.scaledWidth;
    this.globalIntegrationServices.scaledHeight = obj.scaledHeight;
    this.globalIntegrationServices.zoomData = obj;
    this.globalIntegrationServices.zoomCompleteEventEmitter.next(obj);
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public getMapWidth(margins) {
    let mapWidth;
    if (margins) {
      mapWidth = this.currentPanSelector.innerWidth() +
        this.globalIntegrationServices.getMarginLeftUnscaled() +
        this.globalIntegrationServices.getMarginRightUnscaled();
    } else {
      mapWidth = this.currentPanSelector.innerWidth();
    }
    return mapWidth;
  }

  public getMapHeight(margins) {
    let mapHeight;
    if (margins) {
      mapHeight = this.currentPanSelector.innerHeight() +
        this.globalIntegrationServices.getMarginTopUnscaled() +
        this.globalIntegrationServices.getMarginBottomUnscaled();
    } else {
      mapHeight = this.currentPanSelector.innerHeight();
    }
    return mapHeight;
  }

  public setPanningEnabled(state: boolean) {
    this.currentPanSelector.zpan('option', 'disablePan', !state);
  }

  public getViewportCenter() {
      return {
          'x': this.currentPanSelector.zpan('getViewportCenterX'),
          'y': this.currentPanSelector.zpan('getViewportCenterY'),
      };
  }
}
