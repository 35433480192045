import { Injectable } from '@angular/core';
import { JqueryAccordionOptions } from '../models/jqueryAccordionOptions.model';
import * as _ from 'lodash';
import { JqueryDialogOptions } from '../models/jqueryDialogOptions.model';


@Injectable({
  providedIn: 'root'
})
export class JqueryCommonServices {
  private readonly jqueryAccordionOptions: JqueryAccordionOptions;
  private readonly jqueryDialogOptions: JqueryDialogOptions;
  private jq = ($ as any);
  private readonly THROTTLE_TIMER = 1000;
  private defaultAnimationDuration = 500;

  constructor() {
    this.jqueryAccordionOptions = new JqueryAccordionOptions();
    this.jqueryDialogOptions = new JqueryDialogOptions();
  }

  public selector(targetName: any) {
    return this.jq(targetName);
  }

  public initiateAccordion(targetName: string, customJqueryAccordionOptions?: JqueryAccordionOptions) {
    Object.assign(this.jqueryAccordionOptions, customJqueryAccordionOptions);
    return this.jq(targetName).accordion(this.jqueryAccordionOptions);
  }

  public changeEventHandler(targetName: string, eventHandler: Function) {
    this.jq(targetName).on('change', eventHandler);
  }

  public accordionRefresh(targetName: string) {
    this.jq(targetName).accordion('refresh');
    this.jq(targetName).accordion('option', 'active', 0);
  }

  public divConstruct(value?: string) {
    value = value || '';
    return this.jq('<div>', {text: value});
  }

  public debouncedChangeHandler(targetName: string, eventHandler: Function, throttleTimer?: number) {
    this.jq(targetName).on('change', _.debounce(eventHandler, throttleTimer ? throttleTimer : this.THROTTLE_TIMER));
  }

  public debouncedChangeOnKeyPressHandler(targetName: string, eventHandler: Function, throttleTimer?: number) {
    this.jq(targetName).on('keypress, keyup', _.debounce(eventHandler, throttleTimer ? throttleTimer : this.THROTTLE_TIMER));
  }

  public triggerChangeEvent(targetName: string) {
    this.jq(targetName).trigger('change');
  }

  public clickEvent(targetName: string, callback: Function) {
    this.jq(targetName).on('click', callback);
  }

  public animationCompleteEvent(targetName: any, callback: Function) {
    this.jq(targetName).on('animationend', callback);
  }

  public transitionEndEvent(target: any, callback: Function) {
    this.jq(target).one('transitionend webkitTransitionEnd', callback);
  }

  public transitionCompleteEvent(targetName: any, callback: Function) {
    this.jq(targetName).on('transitionend', callback);
  }

  public dialog(targetName: string, customDialogOptions?: JqueryDialogOptions, callback?: Function) {
    Object.assign(this.jqueryDialogOptions, customDialogOptions);
    this.selector(targetName).dialog(this.jqueryDialogOptions);
  }

  public slider(targetName: string, sliderOptions?: object) {
    this.jq(targetName).slider(sliderOptions);
  }

  public singularOrPluralCheck(text: string, count: number) {
    return text.concat(count !== 1 ? 's' : '');
  }

  public widthHeight(targetName: any) {
    const targetSelector = this.jq(targetName);
    return ({width: targetSelector.width(), height: targetSelector.height()});
  }

  public windowWidthHeight() {
    return this.widthHeight(window);
  }

  public animate(targetName: string, animateOptions: object, animationCompleteCallback?: Function, animateDuration?: number) {
    const duration = animateDuration ? animateDuration : this.defaultAnimationDuration;
    this.selector(targetName).animate(animateOptions, duration, 'linear', animationCompleteCallback);
  }

  public imageLoaderEvent(selector: any, imageSrc: string, imageClassName: string, callback: Function, errCallback?: Function) {
    const image = new Image();
    image.src = imageSrc;
    image.onload = (event) => {
      this.jq(selector).append(this.jq('<img>', {src: image.src, class: imageClassName}));
      callback(event);
    };
    image.onerror = (error) => {
        if (errCallback) {
            errCallback(error);
        }
    }
  }

  public customAccordionMultipleExpandFunction() {
    return (event, ui) => {
      let currHeader;
      let currContent;
      if (ui.newHeader[0]) {
        currHeader = ui.newHeader;
        currContent = currHeader.next('.ui-accordion-content');
      } else {
        currHeader = ui.oldHeader;
        currContent = currHeader.next('.ui-accordion-content');
      }
      const isPanelSelected = currHeader.attr('aria-selected') === 'true';
      currHeader.toggleClass('ui-corner-all', isPanelSelected)
        .toggleClass('accordion-header-active ui-state-active ui-corner-top', !isPanelSelected)
        .attr('aria-selected', ((!isPanelSelected).toString()));

      currHeader.children('.ui-icon').toggleClass('ui-icon-triangle-1-e', isPanelSelected)
        .toggleClass('ui-icon-triangle-1-s', !isPanelSelected);

      currContent.toggleClass('accordion-content-active', !isPanelSelected);
      if (isPanelSelected) {
        currContent.slideUp();
      } else {
        currContent.slideDown();
      }

      return false;
    };
  }
}
