import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TreeViewItem } from '../../models';
import { EventsHubService } from '../../../core/events-hub.service';


/**
 * Component class for share multi select component
 */
@Component({
  selector: 'emap-tree-view-select',
  templateUrl: './tree-view-select.component.html',
  styleUrls: ['./tree-view-select.component.scss']
})
export class TreeViewSelectComponent implements OnInit {

  /**
   * Properties for input and output component from/to parent component
   */
  @ViewChild('optionsForm', {static: true}) form;
  @Input() loading: boolean;
  @Input() selectGroup: string;
  @Input() items: Array<TreeViewItem>;
  @Output() onSelect: EventEmitter<TreeViewItem> = new EventEmitter();

  public state: any;

  constructor(private eventsHubService: EventsHubService) {
    /**
     * Resets component tree on instantiation
     */
    this.eventsHubService.treeViewReset$
      .subscribe(() => this.reset()
    );
  }

  ngOnInit() {
  }

  /**
   * Handle on select tree item
   * @param {TreeViewItem} item: option list item
   */
  public select(item: TreeViewItem) {
    if (!item.isChecked) {
      item.isChecked = true;
      this.onSelect.emit(item);
    } else {
      item.isChecked = false;
    }
  }

  /**
   * handles open/close tree state
   * @param {TreeViewItem} item: option list item
   */
  public toggle(item: TreeViewItem) {
    item.expanded = !item.expanded;
  }

  /**
   * resets component state and form elements
   */
  private reset() {
    this.form.controls[this.selectGroup].reset();
  }
}
