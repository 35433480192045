import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../core/data-services/user.service';
import { PasswordResetService } from '../../../core/data-services/passwordReset.service';
import { User } from '../../models';
import { BannerNotificationsService, SpinnerService } from '../../../xform-compat';


enum SentStatus { ready, sent, failed }

@Component({
  selector: 'xform-user-password-reset',
  templateUrl: './user-password-reset.component.html',
  styleUrls: ['./user-password-reset.component.scss']
})
export class UserPasswordResetComponent implements OnInit {

  @Input() user: User;
  @Input() emailReset: string;

  public lastSent: string;
  public SentStatus: typeof SentStatus = SentStatus;
  public email: SentStatus = SentStatus.ready;

  constructor(
    private bannerNotificationsService: BannerNotificationsService,
    private passwordResetService: PasswordResetService,
    private translateService: TranslateService,
    private spinnerService: SpinnerService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.lastSent = 'a placeholder for date and hour';
  }

  public resetSentStatus() {
    this.email = SentStatus.ready;
  }

  public resetPassword() {
    this.passwordResetService.forgotPassword({ emailAddress: this.user.emailAddress })
      .subscribe(
        () => {
          this.spinnerService.stop();
          this.email = SentStatus.sent;
          this.bannerNotificationsService.success(this.translateService.instant('MESSAGES.EMAIL_SENT'));
        },
        (error) => {
          this.spinnerService.stop();
          this.email = SentStatus.failed;
          this.bannerNotificationsService.error(error);
        }
      );
  }

  public resendActivation() {
    this.userService.resendActivation(this.user)
      .subscribe(
        () => {
          this.spinnerService.stop();
          this.email = SentStatus.sent;
          this.bannerNotificationsService.success(this.translateService.instant('MESSAGES.EMAIL_SENT'));
        },
        (error) => {
          this.spinnerService.stop();
          this.email = SentStatus.failed;
          this.bannerNotificationsService.error(error);
        }
      );
  }

}
