<div class="container-fluid" [class.px-0]="noPadding">
  <div class="row">
    <div class="col-12 text-center">
      <div *ngIf="showTotalMessage" class="pull-left d-inline-block totalMsg">
        <p class="bold">Found {{totalRecords}} {{entityType}}</p>
      </div>
      <div class="pull-left">
        <ngb-pagination [collectionSize]="totalRecords" [(page)]="pagination.page" [pageSize]="pagination.pagesize"
                        [directionLinks]="pagination.directionLinks" [maxSize]="pagination.maxPaginationItems"
                        [boundaryLinks]="pagination.boundaryLinks" [ellipses]="pagination.ellipses"
                        [rotate]="pagination.rotate" [size]="pagination.size" (pageChange)="emitPageChange()"></ngb-pagination>
      </div>
      <div class="pull-right d-flex">
        <span class="pr-3 align-self-center text-uppercase">{{"HEADER.VIEW" | translate}}</span>
        <div class="pull-right w-auto">
          <ng-select
                  [dropdownPosition]="configDropdownCounter.dropdownPosition"
                  [clearable]="false"
                  [searchable]="false"
                  [items]="pagination.allowedRanges"
                  [disabled]="!(totalRecords > 0)"
                  bindLabel="range"
                  bindValue="range"
                  [placeholder]="'-'"
                  (change)="emitRecordsPerPageChange()"
                  class="width-page-results"
                  [(ngModel)]="pagination.pagesize">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</div>
