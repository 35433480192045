<aside [ngClass]="{'openMenu': openMobileMenu}">
  <ul>
    <li *ngFor="let item of menu" [routerLinkActive]="['active']">
      <a (click)="checkSubmenu(item)"><i class="fa fa-{{item.icon}}"></i>{{item.textKey | translate}}</a>
      <ul>
        <li *ngFor="let subItem of item.subMenu" [routerLinkActive]="['active']">
          <a [routerLink]="subItem.link" (click)="closeMenu()">{{subItem.textKey | translate}}</a>
        </li>
      </ul>
    </li>
  </ul>
</aside>
