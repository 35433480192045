<xform-page [sidebar]="false" [bgColor]="'white-bg'">
  <section class="container-fluid public-form">
    <div class="row justify-content-md-center m-top-100">
      <form #loginForm="ngForm" class="col-12 col-md-6 col-lg-4"
            [class.submitted]="loginForm.submitted" (ngSubmit)="login(loginForm)" novalidate>
        <h1 class="text-center">{{"LOGIN.TITLE" | translate}}</h1>
        <p class="sub-title text-center">{{"LOGIN.SUBTITLE" | translate}}</p>

        <div class="form-group">
          <label for="username">{{"FORMS.USERNAME" | translate}}
            <span class="error" *ngIf="loginForm.submitted">
              <span *ngIf="username.errors?.required">{{"ERRORS.IS_REQUIRED" | translate}}</span>
            </span>
          </label>
          <div class='d-inline-flex w-100 align-items-center'>
            <input [disabled]='emailValidated' type="email" name="username" id="username" class="form-control" ngModel #username="ngModel" required />

            <i *ngIf='!emailValidated' class="ml-2 fa fa-arrow-right text-success login-btn" (click)='emailChanged(username.value);'></i>
            <i *ngIf='emailValidated' class="ml-2 fa fa-arrow-left text-success login-btn" (click)='resetEmailValidation(loginForm);'></i>
          </div>
        </div>

        <div class="form-group" *ngIf='emailValidated'>
          <label for="password">{{"FORMS.PASSWORD" | translate}}
            <span class="error" *ngIf="loginForm.submitted">
              <span *ngIf="password.errors?.required">{{"ERRORS.IS_REQUIRED" | translate}}</span>
            </span>
          </label>
          <input type="password" name="password" id="password" class="form-control" ngModel #password="ngModel" required />
        </div>

        <p *ngIf="errorMsg" [innerText]="errorMsg" class="error mb-1"></p>

        <div *ngIf="emailValidated" class="form-group text-center m-top-30">
          <button type="submit" class="btn btn-primary btn-block" [disabled]="loading">{{"FORMS.SUBMIT" | translate}}</button>
          <div class="m-top-10">
            <a [routerLink]="['/forgot-password']" class="forgot-password">{{"FORGOT_PASSWORD.FORGOT_PASSWORD" | translate}}</a>
          </div>
        </div>

      </form>

    </div>
  </section>
</xform-page>
