import { Injectable } from '@angular/core';
import {ExceptionService} from '../../xform-compat';
import {HttpClient} from '@angular/common/http';
import {CONFIG} from '../../config';
import {NodeSearchResource} from '../../shared/models/resources/nodeSearchResource.model';
import { catchError } from 'rxjs/operators';

@Injectable()
export class NodeCategoryValueService {

  private apiPath = CONFIG.apiUrl;

  constructor(
    private exceptionService: ExceptionService,
    private http: HttpClient,
  ) { }

  public getAllNodeCategoryValue() {
    return this.getRequest(this.getAllNodeCategoryValuePath());
  }

  public getNodeCategoryValueByMapVersionId(mapVersionId: number) {
    return this.getRequest(this.getNodeCategoryValueByMapVersionIdPath(mapVersionId));
  }

  private getResourcePath(): string {
    return '/node/service';
  }

  private getAllNodeCategoryValuePath() {
    return this.apiPath.concat(this.getResourcePath()).concat('/search/all');
  }

  private getRequest(apiUrl: string) {
    return this.http.get<NodeSearchResource>(apiUrl)
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  private getNodeCategoryValueByMapVersionIdPath(mapVersionId: number) {
    return this.apiPath.concat(this.getResourcePath()).concat('/nodeinformation/').concat(mapVersionId.toString());
  }
}
