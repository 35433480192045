import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { MapAuthoringComponent } from './map-authoring/map-authoring.component';


const routes: Routes = [
  { path: 'map-authoring/:id', component: MapAuthoringComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
  // { path: 'finalization-report/:id', component: FinalizationReportComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthoringRoutingModule {}
