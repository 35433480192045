import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DesignIntegrationService } from '../../services/designIntegration.service';
import { Image } from '../../models/image.model';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { environment } from '../../../../environments/environment';
import { JqueryDialogOptions } from '../../models/jqueryDialogOptions.model';
import { CONFIG } from '../../../config';


@Component({
  selector: 'emap-chart-display',
  templateUrl: './chart-display.component.html',
  styleUrls: ['./chart-display.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChartDisplayComponent implements OnInit, OnDestroy {

  private CHART_DISPLAY_CHILD_CLASS = 'chart-display-child';
  private CHART_DISPLAY_CHILD_IMAGE_DIALOG_CLASS = 'chart-display-child-image-dialog';
  private CHART_DISPLAY_IMAGE_DIALOG_WIDGET_CLASS = 'chart-display-image-dialog-widget';
  private CHART_DISPLAY_IMAGE_DIALOG_MIN_MAX_CLASS = 'chart-display-image-dialog-min-max';
  private UI_DIALOG_TITLEBAR_CLASS = 'ui-dialog-titlebar';
  private UI_DIALOG_CLASS = 'ui-dialog';
  private CHART_DISPLAY_MAXIMIZE_CLASS = 'chart-display-maximize';
  private CHART_DISPLAY_IMAGE_DIALOG_WIDGET_MINIMIZED_CLASS = 'chart-display-image-dialog-widget-minimized';
  private s3Url = CONFIG.aws.s3[environment.env];
  private NAVIGATOR_CLASS = 'navigator';

  private DIALOG_WIDGET_MAX_WIDTH = 800;
  private DIALOG_WIDGET_MAX_HEIGHT = 480;
  private DIALOG_WIDGET_MIN_WIDTH = 350;
  private DIALOG_WIDGET_MIN_HEIGHT = 40;

  private minimizedDialogStateObject = {};
  private minimizeDialogPositionStack = [];

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private designIntegrationService: DesignIntegrationService
  ) { }

  ngOnInit() {
    this.chartImageClickHandler();
  }

  private chartImageClickHandler() {
    this.designIntegrationService.chartImageClickEventHandler.subscribe(value => {
      const imageList = value as Image;
      this.constructImageDivList(imageList);
    });
  }

  private constructImageDivList(image: Image) {
    const divImageId = 'chart-image-'.concat(image.id.toString());
    if (this.jqueryCommonServices.selector(divImageId.toId()).dialog('instance')) { return; }
    this.jqueryCommonServices.selector(this.CHART_DISPLAY_CHILD_CLASS.toClass())
      .append(
        this.jqueryCommonServices.divConstruct()
          .addClass(this.CHART_DISPLAY_CHILD_IMAGE_DIALOG_CLASS)
          .attr('id', divImageId)
          .attr('title', image.title)
          .append(this.jqueryCommonServices.selector('<img>').attr('src', this.s3Url.concat(image.s3Reference)))
      );
    this.initDialogOnImage(divImageId);
  }

  private initDialogOnImage(divImageId: string) {
    this.jqueryCommonServices.dialog(divImageId.toId(), this.getDialogOptions());
    this.chartDialogMinimizeMaximizeHandler();
  }

  private getDialogOptions() {
    const dialogOptions = new JqueryDialogOptions();
    dialogOptions.modal = false;
    dialogOptions.resizable = false;
    dialogOptions.dialogClass = this.CHART_DISPLAY_IMAGE_DIALOG_WIDGET_CLASS;
    dialogOptions.close = this.chartDialogClose.bind(this);
    dialogOptions.open = this.chartDialogOpen.bind(this);
    dialogOptions.width = this.DIALOG_WIDGET_MAX_WIDTH;
    dialogOptions.height = this.DIALOG_WIDGET_MAX_HEIGHT;
    return dialogOptions;
  }

  private chartDialogOpen(event, ui) {
    const parentWidget = this.jqueryCommonServices.selector(event.target).parent();
    const dialogTitleBar = parentWidget.find(this.UI_DIALOG_TITLEBAR_CLASS.toClass());
    dialogTitleBar.append(
      this.jqueryCommonServices.divConstruct()
        .addClass(this.CHART_DISPLAY_IMAGE_DIALOG_MIN_MAX_CLASS)
        .data('dialogId', event.target.id)
    );
    parentWidget.height(this.DIALOG_WIDGET_MAX_HEIGHT);
  }

  private chartDialogClose(event, ui) {
    const dialogId = event.target.id;
    this.deleteElementFromStackAndCache(dialogId);
    this.jqueryCommonServices.selector(event.target).dialog('destroy').remove();
    this.setOrUnsetTransition(true);
    this.updatePositionOfMinimizedDialog();
    this.transitionCompleteEventHandler(dialogId);
  }

  private chartDialogMinimizeMaximizeHandler() {
    this.jqueryCommonServices.selector(this.CHART_DISPLAY_IMAGE_DIALOG_MIN_MAX_CLASS.toClass()).off();
    this.jqueryCommonServices.clickEvent(this.CHART_DISPLAY_IMAGE_DIALOG_MIN_MAX_CLASS.toClass(), (event) => {
      const currentMinMaxSelector = this.jqueryCommonServices.selector(event.target);
      currentMinMaxSelector.toggleClass(this.CHART_DISPLAY_MAXIMIZE_CLASS);
      const minimizeState = currentMinMaxSelector.hasClass(this.CHART_DISPLAY_MAXIMIZE_CLASS);
      const dialogId = currentMinMaxSelector.data('dialogId');
      if (!dialogId) { return; }
      this.setOrUnsetTransition(true);
      this.updateDimensions(dialogId, minimizeState);
      this.updateObjectState(dialogId, minimizeState);
      if (!minimizeState) { this.updatePositionOfMinimizedDialog(); }
      this.transitionCompleteEventHandler(dialogId);
    });
  }

  private updateDimensions(dialogId: string, minState: boolean) {
    const currentDialogWidget = this.jqueryCommonServices.selector(dialogId.toId()).dialog('widget');
    currentDialogWidget.css(this.computeMinMaxDimensions(minState, dialogId));
  }

  private computeMinMaxDimensions(minState: boolean, dialogId: string) {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const objectPreviousState = this.minimizedDialogStateObject[dialogId];
    const currentMinimizedDialogLength = Object.keys(this.minimizedDialogStateObject).length + 1;
    const navigatorSelector = this.jqueryCommonServices.selector(this.NAVIGATOR_CLASS.toId());
    const topValue = minState ? (windowHeight - this.DIALOG_WIDGET_MIN_HEIGHT) :
      (objectPreviousState.top ? objectPreviousState.top : (windowHeight / 2 - this.DIALOG_WIDGET_MAX_HEIGHT / 2));
    const leftValue = minState ? (windowWidth - this.DIALOG_WIDGET_MIN_WIDTH * currentMinimizedDialogLength -
      (navigatorSelector.is(':visible') ? navigatorSelector.innerWidth() : 0)) :
      (objectPreviousState.left ? objectPreviousState.left : (windowWidth / 2 - this.DIALOG_WIDGET_MAX_WIDTH / 2));
    const widthValue = minState ? this.DIALOG_WIDGET_MIN_WIDTH : this.DIALOG_WIDGET_MAX_WIDTH;
    const heightValue = minState ? this.DIALOG_WIDGET_MIN_HEIGHT : this.DIALOG_WIDGET_MAX_HEIGHT;
    return { left: leftValue, top: topValue, width: widthValue, height: heightValue };
  }

  private updateObjectState(dialogId: string, updateState: boolean) {
    const currentDialogWidget = this.jqueryCommonServices.selector(dialogId.toId()).dialog('widget');
    if (updateState) {
      this.minimizedDialogStateObject[dialogId] = currentDialogWidget.position();
      this.minimizeDialogPositionStack.push(dialogId);
    } else {
      this.deleteElementFromStackAndCache(dialogId);
    }
  }

  private setOrUnsetTransition(setState: boolean) {
    const currentDialogWidget = this.jqueryCommonServices.selector(this.UI_DIALOG_CLASS.toClass());
    setState ? currentDialogWidget.addClass(this.CHART_DISPLAY_IMAGE_DIALOG_WIDGET_MINIMIZED_CLASS) :
      currentDialogWidget.removeClass(this.CHART_DISPLAY_IMAGE_DIALOG_WIDGET_MINIMIZED_CLASS);
  }

  private updatePositionOfMinimizedDialog() {
    const dialogIdKeys = Object.keys(this.minimizedDialogStateObject);
    const navigatorSelector = this.jqueryCommonServices.selector(this.NAVIGATOR_CLASS.toId());
    dialogIdKeys.forEach((dialogId) => {
      const currentDialogWidget = this.jqueryCommonServices.selector(dialogId.toId()).dialog('widget');
      const indexVal = this.minimizeDialogPositionStack.indexOf(dialogId) + 1;
      const finalLeftPosition = window.innerWidth - (navigatorSelector.is(':visible') ? navigatorSelector.innerWidth() : 0) -
        this.DIALOG_WIDGET_MIN_WIDTH * indexVal;
      currentDialogWidget.css({ left: finalLeftPosition });
    });
  }

  private transitionCompleteEventHandler(dialogId: string) {
    const currentDialogWidget = this.jqueryCommonServices.selector(dialogId.toId()).dialog('widget');
    this.jqueryCommonServices.transitionCompleteEvent(currentDialogWidget, () => {
      this.setOrUnsetTransition(false);
    });
  }

  private deleteElementFromStackAndCache(dialogId: string) {
    delete this.minimizedDialogStateObject[dialogId];
    const dialogIndex = this.minimizeDialogPositionStack.indexOf(dialogId);
    if (dialogIndex === -1) { return; }
    this.minimizeDialogPositionStack.splice(dialogIndex, 1);
  }

  ngOnDestroy(): void {
    this.jqueryCommonServices.selector(this.CHART_DISPLAY_CHILD_IMAGE_DIALOG_CLASS.toClass()).dialog('destroy').remove();
  }
}
