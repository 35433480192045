import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CONFIG } from '../../config';
import { Image } from '../../shared/models/image.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../services/authentication.service';
import { FileUploadService } from '../services/fileUpload.service';
import { FileLikeObject } from 'ng2-file-upload';
import { AbstractCRUD, BannerNotificationsService, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';


@Injectable()
export class ImageService extends AbstractCRUD<Image> implements CRUDService<Image>, Searcher {

  private _currentImageCategory: string;

  constructor(
    private fileUploader: FileUploadService,
    private authenticationService: AuthenticationService,
    private bannerNotificationsService: BannerNotificationsService,
    private translateService: TranslateService,
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
    this.initImageUploadService();
  }

  public getResourcePath() {
    return '/image/service';
  }

  public getDisplayName(item: any): string {
    return item.fullName;
  }

  private getAllImagesPath() {
    return super.getApiPath().concat('/all');
  }

  public getAllImages(params: HttpParams) {
    return this.getRequest(this.getAllImagesPath(), params);
  }

  private getRequest(apiUrl: string, httpParams?: HttpParams) {
    return this.getHTTP().get(apiUrl, { params: httpParams })
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  private postRequest(apiUrl: string, body: any) {
    return this.getHTTP().post(apiUrl, body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public setFileUploadPath() {
    this.fileUploader.uploader.options.url = this.getImageUploadPath();
  }

  public getImageUploadPath() {
    return super.getApiPath().concat('/upload/').concat(this.currentImageCategory);
  }

  public getImageUploader() {
    return this.fileUploader.uploader;
  }

  public initImageUploadService() {
    this.fileUploader.initFileUploader();
    this.fileUploader.uploaderSettings();
    this.setAuthTokenHeader();
  }

  private setAuthTokenHeader(): void {
    this.fileUploader.uploader.authTokenHeader = CONFIG.authHeader;
    this.fileUploader.uploader.authToken = CONFIG.authPreamble + this.authenticationService.getTokenInfo().accessToken;
  }

  get currentImageCategory(): string {
    return this._currentImageCategory;
  }

  set currentImageCategory(value: string) {
    this._currentImageCategory = value;
  }
}
