<div class="d-flex flex-wrap justify-content-end align-items-center">
    <div class="maps-manager-version-icon icon-dark maps-manager-version-mindmap mx-2"
         [class.disabled]="!mapsManagerVersion.interactive"
         (click)="openMindMapCore()"
         ngbTooltip="Edit MindMap"></div>
    <div class="maps-manager-version-icon icon-dark maps-manager-version-nodes mx-2"
         (click)="viewNodesTable()"
         [class.disabled]="!mapsManagerVersion.interactive"
         ngbTooltip="View Nodes Table"></div>
    <div class="maps-manager-version-icon maps-manager-version-submenu mx-2"
         [ngbTooltip]="subMenu"
         [tooltipClass]="'maps-action-submenu'"
         placement="left-top"
         triggers="click:blur">
        <img src="../../../../assets/icons/toolbox/ThreeDots.svg" alt="Submenu"/>
    </div>
</div>

<ng-template #subMenu>
    <div class="submenu-row" (click)="showPublishModal()">
        <div class="maps-manager-version-icon maps-manager-version-publish"></div>
        <div>Publish to TVs</div>
    </div>
    <div class="submenu-row" (click)="openMapCore()" [class.disabled]="this.mapsManagerVersion.mapImage == null">
        <div class="maps-manager-version-icon maps-manager-version-emaps"
             [class.disabled]="this.mapsManagerVersion.mapImage == null"></div>
        <div>Open eMap</div>
    </div>
    <div class="submenu-row" *ngIf="canEdit(PERMISSIONS.EmapVersionUpdateIndesign)" (click)="showTemplateModal(uploadInDesignModal)">
        <div class="maps-manager-version-icon maps-manager-version-indesign"></div>
        <div>Upload Bundle</div>
    </div>
    <div class="submenu-row" *ngIf="canEdit(PERMISSIONS.EmapVersionCreate)" (click)="showTemplateModal(newVersionModal)">
        <div class="maps-manager-version-icon maps-manager-version-new"></div>
        <div>New Version</div>
    </div>
    <div class="submenu-row" (click)="editIndications()">
        <div class="maps-manager-version-icon maps-manager-version-indications"></div>
        <div>Manage Indications</div>
    </div>
    <ng-container *ngIf="canEdit(PERMISSIONS.EmapVersionDelete)">
        <div class="divider"></div>
        <div class="submenu-row" [ngClass]="{'disabled':  isVersionPage && islastVersion()}"
             (click)="!isVersionPage ? showTemplateModal(deleteMapsManagerModal) : (!islastVersion() && showTemplateModal(deleteVersionModal))">
            <div class="maps-manager-version-icon maps-manager-trash"
                 [hidden]="isVersionPage">
            </div>
            <div class="maps-manager-version-icon maps-manager-version-trash"
                 [hidden]="!isVersionPage"
                 [ngClass]="{'disabled':  islastVersion()}">
            </div>
            <div>Delete</div>
        </div>
    </ng-container>
</ng-template>

<ng-template #newVersionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="new-version-modal-basic-title">New Version</h4>
    </div>
    <div class="modal-body">
        <span>
            A new map version contains a combination of minor and major changes to the map. Please work
            with research and the graphics team to ensure versions are not created for simple, minor changes.
        </span>
        <form>
            <div class="mb-3">
                <div class="d-flex flex-row justify-content-between">
                    <div class="d-flex flex-column flex-grow-1">
                        <label for="new-version-mapname">Map Name</label>
                        <input id="new-version-mapname" disabled [value]="mapsManager.name">
                    </div>
                    <div class="d-flex flex-column flex-grow-1 ml-3">
                        <label for="mapversionname">Map Version Name</label>
                        <input id="mapversionname" #newVersionName>
                        <span class="align-self-end">Last Version: {{mapsManagerVersion.name}}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer d-flex flex-row justify-content-between">
        <button type="button" class="btn border" (click)="modal.close()">Close</button>
        <button type="button"
                class="btn btn-primary"
                (click)="modal.close({type: 'version', name: newVersionName.value, desc: ''})"
        >Create Version
        </button>
    </div>
</ng-template>


<ng-template #uploadInDesignModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="upload-modal-basic-title">Upload Latest Bundle</h4>
    </div>
    <div class="modal-body">
        <form>
            <div class="mb-3">
                <div class="d-flex flex-row justify-content-between">
                    <div class="d-flex flex-column w-50">
                        <label for="upload-mapname">Map Name</label>
                        <input id="upload-mapname" disabled [value]="mapsManager.name">
                    </div>
                </div>
                <div class="mt-3">
                    <div class="d-flex flex-column">
                        <label for="indesignfile">InDesign File</label>
                        <input #indesignfile id="indesignfile" type="file" accept=".emapBundle" ng2FileSelect
                               [uploader]="uploader">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer d-flex flex-row justify-content-between">
        <button type="button" class="btn border" (click)="modal.close()">Close</button>
        <button type="button"
                class="btn btn-primary"
                (click)="modal.close({type: 'indesign', path: indesignfile.files[0]})"
        >Upload Latest Bundle
        </button>
    </div>
</ng-template>

<ng-template #deleteVersionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="delete-version-modal-basic-title">Delete Version</h4>
    </div>
    <div class="modal-body">
        Are you sure you want to delete version {{mapsManagerVersion.name}} for {{mapsManager.name}}
    </div>
    <div class="modal-footer d-flex flex-row justify-content-between">
        <button type="button" class="btn border" (click)="modal.close()">Close</button>
        <button type="button"
                class="btn btn-primary"
                (click)="modal.close({type: 'delete'})"
        >Delete
        </button>
    </div>
</ng-template>

<ng-template #deleteMapsManagerModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Delete Map</h4>
    </div>
    <div class="modal-body">
        Are you sure? You are about to delete this map and all of its version:
        {{mapsManager.name}}
    </div>
    <div class="modal-footer d-flex flex-row justify-content-between">
        <button type="button" class="btn border" (click)="modal.close()">Close</button>
        <button type="button"
                class="btn btn-primary"
                (click)="modal.close({type: 'deleteMap'})"
        >Delete
        </button>
    </div>
</ng-template>

