import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MindMapRoutingModule} from './mind-map-routing.module';
import {MindMapCoreComponent} from './mind-map-core/mind-map-core.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../shared/shared.module';
import {FileUploadModule} from 'ng2-file-upload';
import {DataBindingModalComponent} from './mind-map-core/data-binding-modal/data-binding-modal.component';
import {NavigationModalComponent} from './mind-map-core/navigation-modal/navigation-modal.component';
import {RichContentModalComponent} from './mind-map-core/rich-content-modal/rich-content-modal.component';
import {NgbAccordion} from '@ng-bootstrap/ng-bootstrap';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {GojsAngularModule} from "gojs-angular";


@NgModule({
  declarations: [MindMapCoreComponent, DataBindingModalComponent, NavigationModalComponent, RichContentModalComponent],
  exports: [MindMapCoreComponent],
    imports: [
        SharedModule,
        CommonModule,
        MindMapRoutingModule,
        TranslateModule,
        FileUploadModule,
        DragDropModule,
        GojsAngularModule,
    ],
  providers: [NgbAccordion]
})
export class MindMapModule {
}
