import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class ToolboxAllPanelServices {
  private _toolboxButtonClickEventEmitter: Subject<object> = new Subject<object>();
  private _navigatorButtonClickEventEmitter: Subject<object> = new Subject<object>();
  private _filterButtonClickEventEmitter: Subject<boolean> = new Subject<boolean>();
  private _searchInputClickEventEmitter: Subject<boolean> = new Subject<boolean>();
  private _swapButtonClickEventEmitter: Subject<boolean> = new Subject<boolean>();
  private _mapIdSelectionEventEmitter: Subject<object> = new Subject<object>();

  private _globalPanelOpenState = false;
  private _filterPanelOpenState = false;
  private _swapState = false;
  private _toolboxWidth: number;
  private _globalPanelWidth: number;
  private _filterPanelWidth: number;
  private _keyboardPanelWidth: number;
  private _windowWidth: number;

  get toolboxButtonClickEventEmitter(): Subject<object> {
    return this._toolboxButtonClickEventEmitter;
  }

  get navigatorButtonClickEventEmitter(): Subject<object> {
    return this._navigatorButtonClickEventEmitter;
  }

  get filterButtonClickEventEmitter(): Subject<boolean> {
    return this._filterButtonClickEventEmitter;
  }

  get searchInputClickEventEmitter(): Subject<boolean> {
    return this._searchInputClickEventEmitter;
  }

  get swapButtonClickEventEmitter(): Subject<boolean> {
    return this._swapButtonClickEventEmitter;
  }

  get mapIdSelectionEventEmitter(): Subject<object> {
    return this._mapIdSelectionEventEmitter;
  }

  get globalPanelOpenState() {
    return this._globalPanelOpenState;
  }

  get filterPanelOpenState(): boolean {
    return this._filterPanelOpenState;
  }

  get swapState(): boolean {
    return this._swapState;
  }

  set swapState(value: boolean) {
    this._swapState = value;
  }

  get toolboxWidth(): number {
    return this._toolboxWidth;
  }

  set toolboxWidth(value: number) {
    this._toolboxWidth = value;
  }

  get globalPanelWidth(): number {
    return this._globalPanelWidth;
  }

  set globalPanelWidth(value: number) {
    this._globalPanelWidth = value;
  }

  get filterPanelWidth(): number {
    return this._filterPanelWidth;
  }

  set filterPanelWidth(value: number) {
    this._filterPanelWidth = value;
  }

  set globalPanelOpenState(value: boolean) {
    this._globalPanelOpenState = value;
  }

  set filterPanelOpenState(value: boolean) {
    this._filterPanelOpenState = value;
  }

  get keyboardPanelWidth(): number {
    return this._keyboardPanelWidth;
  }

  set keyboardPanelWidth(value: number) {
    this._keyboardPanelWidth = value;
  }

  get windowWidth(): number {
    return this._windowWidth;
  }

  set windowWidth(value: number) {
    this._windowWidth = value;
  }
}
