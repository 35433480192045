<div class="indications-modal-container">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Manage Indications for {{ mapsManagerVersion.name }}</h4>
    </div>
    <div class="modal-subtitle">This module allows you to add and/or exclude indications on the map</div>

    <div class="indications-card mt-3">
        <div class="indications-title">Included Indications</div>
        <div class="indication-select-container my-3">
            <ng-select #indicationSelect class="indication-select"
                       [items]="filteredIndications"
                       [multiple]="false"
                       bindLabel="name"
            ></ng-select>
            <button class="btn btn-primary ml-3" (click)="addSelectedIndication(indicationSelect)">Add</button>
        </div>
        <div *ngIf="includedIndications.length > 0 || addedIndications.length > 0; else noInclusions" class="indications-list-container included">
            <div *ngFor="let indication of addedIndications | orderBy:'indication.name'" class="indication-row">
                <div class="added">{{ indication.indication.name }}</div>
                <div class="indication-action-button" (click)="removeAddedIndication(indication)">Remove</div>
            </div>
            <div *ngFor="let indication of includedIndications | orderBy:'indication.name'" class="indication-row">
                <div>{{ indication.indication.name }}</div>
                <div class="indication-action-button" (click)="excludeIndication(indication)">Exclude</div>
            </div>
            <div *ngFor="let loading of loadingIndications" class="indication-row">
                <i class="fa fa-spinner fa-spin"></i>
            </div>
        </div>
        <ng-template #noInclusions>
            <div class="indication-row">
                <div class="empty">No included indications</div>
            </div>
        </ng-template>

        <div class="indications-title mt-4">Excluded Indications</div>
        <div *ngIf="excludedIndications.length > 0; else noExclusions" class="indications-list-container excluded">
            <div *ngFor="let indication of excludedIndications | orderBy:'indication.name'" class="indication-row">
                <div class="excluded">{{ indication.indication.name }}</div>
                <div class="indication-action-button restore" (click)="restoreIndication(indication)">Restore</div>
            </div>
        </div>
        <ng-template #noExclusions>
            <div class="indication-row">
                <div class="empty">
                    No excluded indications
                </div>
            </div>
        </ng-template>
    </div>

    <div class="modal-footer d-flex mt-3">
        <button type="button" class="btn border" (click)="close()">Close</button>
        <button type="button" class="btn btn-primary" (click)="save()">Save</button>
    </div>
</div>
