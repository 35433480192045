import {
  Component, ComponentFactoryResolver,
  ComponentRef,
  Input, OnDestroy,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {MilestoneData, NodesManagerFilter} from '../nodes-manager-list/nodes-manager-list.component';
import {MILESTONE_CATEGORY} from '../../../shared/enums';
import {Sort} from '@angular/material/sort';
import {NodesManagerFilterComponent} from '../nodes-manager-filter/nodes-manager-filter.component';

@Component({
  selector: 'emap-nodes-manager-milestones',
  templateUrl: './nodes-manager-milestones.component.html',
  styleUrls: ['./nodes-manager-milestones.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NodesManagerMilestonesComponent implements OnInit, OnDestroy {

  @Input() milestoneDataList: Array<MilestoneData>;

  public columnHeaderList: Array<string> = Object.values(MILESTONE_CATEGORY);
  public filterMapActive: Map<string, Array<string>> = new Map<string, Array<string>>();
  private filterMap: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
  private filterComponentInstance: ComponentRef<NodesManagerFilterComponent> = null;
  private currentFilterColumn: string;
  milestoneTypeList = ['eMaps', 'RMS', 'BMT'];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
  }

  ngOnInit() {
    this.initializeFilterMap();
  }

  ngOnDestroy() {
    if (this.filterComponentInstance) {
      this.filterComponentInstance.destroy();
    }
  }

  public getCategoryValue(milestoneData: MilestoneData, columnHeader: string) {
    return milestoneData.milestoneCategoryMap.has(columnHeader) ? milestoneData.milestoneCategoryMap.get(columnHeader).join(', ') : '';
  }

  public filterData(event: Event, elementId: string) {
    event.stopPropagation();
    if (this.filterComponentInstance) {
      this.filterComponentInstance.destroy();
      this.filterComponentInstance = null;
      if (this.currentFilterColumn !== elementId) {
        this.openFilterPanel(elementId);
      }
    } else {
      this.openFilterPanel(elementId);
    }
  }

  private openFilterPanel(elementId: string) {
    const element: HTMLElement = document.getElementById(elementId);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NodesManagerFilterComponent);
    this.filterComponentInstance = this.viewContainerRef.createComponent(componentFactory);
    this.filterComponentInstance.instance.filterMap = this.filterMap.get(elementId);
    this.filterComponentInstance.instance.close.subscribe(() => {
      this.filterComponentInstance.destroy();
      this.filterComponentInstance = null;
    });
    const filterComponentElement: HTMLElement = this.filterComponentInstance.location.nativeElement;
    filterComponentElement.className = 'filter-panel';
    filterComponentElement.style.left = String(element.offsetLeft) + 'px';
    filterComponentElement.style.top = String(element.offsetTop + 44) + 'px';
    element.parentElement.parentElement.parentElement.append(filterComponentElement);
    this.currentFilterColumn = elementId;
  }

  public sortData(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }
    const direction = sort.direction === 'asc' ? 1 : -1;
    switch (sort.active) {
      case 'companyProductLabel':
        this.milestoneDataList.sort((a, b) => {
          if (a.companyProductLabel.startsWith('(')) {
            return 1 * direction;
          }
          if (b.companyProductLabel.startsWith('(')) {
            return -1 * direction;
          }
          if (a.companyProductLabel.toUpperCase() < b.companyProductLabel.toUpperCase()) {
            return -1 * direction;
          }
          if (a.companyProductLabel.toUpperCase() > b.companyProductLabel.toUpperCase()) {
            return 1 * direction;
          }
          return 0;
        });
        break;
      default:
        this.milestoneDataList.sort((a, b) => {
          if (this.getCategoryValue(a, sort.active) === '') {
            return 1 * direction;
          }
          if (this.getCategoryValue(b, sort.active) === '') {
            return -1 * direction;
          }
          if (this.getCategoryValue(a, sort.active).toUpperCase() < this.getCategoryValue(b, sort.active).toUpperCase()) {
            return -1 * direction;
          }
          if (this.getCategoryValue(a, sort.active).toUpperCase() > this.getCategoryValue(b, sort.active).toUpperCase()) {
            return 1 * direction;
          }
          return 0;
        });
    }
  }

  public getMilestoneDataList(milestoneType: string) {
    const milestoneData: Array<MilestoneData> = new Array<MilestoneData>();
    this.setFilterMapActive();
    if (this.activeFilters()) {
      this.milestoneDataList.forEach(value => {
        if (this.includeMilestoneData(value)) {
          milestoneData.push(value);
        }
      });
      return this.filterBySource(milestoneData, milestoneType);
    } else {
      return this.filterBySource(this.milestoneDataList, milestoneType);
    }
  }

  private filterBySource(milestoneDataList: Array<MilestoneData>, source: string) {
    return milestoneDataList.filter(value => {
      return (value.source === source);
    });
  }

  private includeMilestoneData(milestoneData: MilestoneData) {
    if (!(this.filterMapActive.get('Company/Product').length === 0 ||
      this.filterMapActive.get('Company/Product').includes(milestoneData.companyProductLabel))) {
      return false;
    }
    if (!(this.filterMapActive.get('Phase').length === 0 || (milestoneData.milestoneCategoryMap.has('Phase') &&
      milestoneData.milestoneCategoryMap.get('Phase').some(r => this.filterMapActive.get('Phase').includes(r))))) {
      return false;
    }
    if (!(this.filterMapActive.get('Trial Type').length === 0 || (milestoneData.milestoneCategoryMap.has('Trial Type') &&
      milestoneData.milestoneCategoryMap.get('Trial Type').some(r => this.filterMapActive.get('Trial Type').includes(r))))) {
      return false;
    }
    if (!(this.filterMapActive.get('Event').length === 0 || (milestoneData.milestoneCategoryMap.has('Event') &&
      milestoneData.milestoneCategoryMap.get('Event').some(r => this.filterMapActive.get('Event').includes(r))))) {
      return false;
    }
    if (!(this.filterMapActive.get('Date').length === 0 || (milestoneData.milestoneCategoryMap.has('Date') &&
      milestoneData.milestoneCategoryMap.get('Date').some(r => this.filterMapActive.get('Date').includes(r))))) {
      return false;
    }
    if (!(this.filterMapActive.get('Note').length === 0 || (milestoneData.milestoneCategoryMap.has('Note') &&
      milestoneData.milestoneCategoryMap.get('Note').some(r => this.filterMapActive.get('Note').includes(r))))) {
      return false;
    }
    return this.filterMapActive.get('Indication').length === 0 || (milestoneData.milestoneCategoryMap.has('Indication') &&
      milestoneData.milestoneCategoryMap.get('Indication').some(r => this.filterMapActive.get('Indication').includes(r)));
  }

  private setFilterMapActive() {
    this.filterMapActive = new Map<string, Array<string>>();
    this.filterMapActive.set('Company/Product', new Array<string>());
    this.columnHeaderList.forEach(columnHeader => this.filterMapActive.set(columnHeader, new Array<string>()));
    for (const [key1, value1] of this.filterMap.entries()) {
      for (const [key2, value2] of value1.entries()) {
        if (value2) {
          this.filterMapActive.get(key1).push(key2);
        }
      }
    }
  }

  private activeFilters(): boolean {
    for (const value of this.filterMapActive.values()) {
      if (value.length > 0) {
        return true;
      }
    }
    return false;
  }

  public getFilterList() {
    const filterList = new Array<NodesManagerFilter>();
    this.filterMapActive.get('Company/Product').forEach(filter => filterList.push({category: 'Company/Product', value: filter}));
    this.columnHeaderList.forEach(columnHeader => {
      this.filterMapActive.get(columnHeader).forEach(filter => filterList.push({category: columnHeader, value: filter}));
    });
    return filterList;
  }

  public removeFilter(filter: NodesManagerFilter) {
    this.filterMap.get(filter.category).set(filter.value, false);
  }

  private initializeFilterMap() {
    this.filterMap = new Map<string, Map<string, boolean>>();
    this.filterMap.set('Company/Product', new Map<string, boolean>());
    this.columnHeaderList.forEach(columnHeader => this.filterMap.set(columnHeader, new Map<string, boolean>()));
    this.milestoneDataList.forEach(milestoneData => {
      this.filterMap.get('Company/Product').set(milestoneData.companyProductLabel, false);
      this.columnHeaderList.forEach(columnHeader => {
        if (milestoneData.milestoneCategoryMap.has(columnHeader)) {
          milestoneData.milestoneCategoryMap.get(columnHeader).forEach(value => {
            this.filterMap.get(columnHeader).set(value, false);
          });
        }
      });
    });
  }
}
