import { Image } from './image.model';


export class LegendItem {
  id: number;
  isHeader: boolean;
  text: string;
  image: Image;
  description: string;

  constructor(obj: LegendItem = {} as LegendItem) {
    this.id = obj.id;
    this.isHeader = obj.isHeader;
    this.text = obj.text;
    this.image = obj.image;
    this.description = obj.description;
  }
}