import { BaseEntity } from '../../xform-compat';
import { BasicUser } from './basicUser.model';
import { MapVersionNode } from './mapVersionNode.model';
import { MindMapVersionNode } from './mindMapVersionNode.model';
import { Image } from './image.model';
import { MapVersionNodeHierarchy } from './mapVersionNodeHierarchy.model';
import { RemoteServerDeploy } from './remoteServerDeploy.model';
import {MapsManagerVersionArchive} from './mapsManagerVersionArchive.model';
import {MapVersionIndication} from "./mapsManagerVersionIndication.model";


export class MapsManagerVersion implements BaseEntity {
  id: number;
  mapsManagerId: number;
  name: string;
  interactive: boolean;
  finalized: string;
  mapVersionNodes: Array<MapVersionNode>;
  mindMapVersionNodes: Array<MindMapVersionNode>;
  mapVersionNodeHierarchies: Array<MapVersionNodeHierarchy>;
  mapsManagerVersionArchives: Array<MapsManagerVersionArchive>;
  remoteServerDeploys: Array<RemoteServerDeploy>;
  mapImage: Image;
  navigatorImage: Image;
  indications: Array<MapVersionIndication>;
  updatedBy: BasicUser;
  created: string;
  updated: string;
  active: boolean;
  isEdit: boolean;

  constructor(mapsManagerVersion: MapsManagerVersion = {} as MapsManagerVersion) {
    this.id = mapsManagerVersion.id || null;
    this.name = mapsManagerVersion.name || null;
    this.interactive = mapsManagerVersion.interactive || false;
    this.finalized = mapsManagerVersion.finalized || null;
    this.mapsManagerId = mapsManagerVersion.mapsManagerId || null;
    this.mapVersionNodes = mapsManagerVersion.mapVersionNodes || null;
    this.mindMapVersionNodes = mapsManagerVersion.mindMapVersionNodes || null;
    this.mapsManagerVersionArchives = mapsManagerVersion.mapsManagerVersionArchives || null;
    this.remoteServerDeploys = mapsManagerVersion.remoteServerDeploys || null;
    this.mapImage = mapsManagerVersion.mapImage || null;
    this.navigatorImage = mapsManagerVersion.navigatorImage || null;
    this.indications = mapsManagerVersion.indications || null;
    this.updatedBy = mapsManagerVersion.updatedBy || null;
    this.created = mapsManagerVersion.created || null;
    this.updated = mapsManagerVersion.updated || null;
    this.active = mapsManagerVersion.active || null;
    this.isEdit = mapsManagerVersion.isEdit || false;
  }
}
