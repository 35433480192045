import { SpinnerService } from './spinner.service';


export abstract class AbstractSpinner {

  public active: boolean;

  protected constructor(spinner: SpinnerService) {
    spinner.status$.subscribe((status: boolean) => {
      this.active = status;
    });
  }

}
