<div class="xform-dropdown-container">
  <div class="input-group xform-dropdown">
    <input type="text" class="form-control"
           [(ngModel)]="selectedItem"
           [editable]="false"
           [ngbTypeahead]="search"
           [inputFormatter]="displayFormatter"
           [resultFormatter]="displayFormatter"
           [disabled]="disabled"
           [placeholder]="placeholder"
           (selectItem)="onItemSelected($event.item)"
           (click)="onFieldClicked($event)"
           (focus)="onFocus($event)"
    />
  </div>
  <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="searching && !hideLoadingIcon"></i>
  <div class="error" *ngIf="itemsNotFound">
    <span>No matches found</span>
  </div>
</div>
