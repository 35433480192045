import {AfterContentInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MILESTONE_CATEGORY, NODES_MANAGER_CATEGORY, PRODUCT_CATEGORY} from '../../../shared/enums';
import {MindMapVersionNodeService} from '../../../core/data-services/mindMapVersionNode.service';
import {MindMapVersionNode} from '../../../shared/models/mindMapVersionNode.model';
import {SpinnerService} from '../../../xform-compat';

@Component({
  selector: 'emap-nodes-manager-list',
  templateUrl: './nodes-manager-list.component.html',
  styleUrls: ['./nodes-manager-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NodesManagerListComponent implements OnInit, AfterContentInit {

  public readonly PRODUCTS = NODES_MANAGER_CATEGORY.Products;
  public readonly MILESTONES = NODES_MANAGER_CATEGORY.Milestones;

  public mapsManagerVersionName: string;
  public mapsManagerVersionId: number;
  public tabList: Array<string>;
  public currentTab: number;

  public productDataList: Array<ProductData> = new Array<ProductData>();
  public milestoneDataList: Array<MilestoneData> = new Array<MilestoneData>();

  private productDataMap: Map<string, ProductData> = new Map<string, ProductData>();
  private milestoneDataMap: Map<string, MilestoneData> = new Map<string, MilestoneData>();

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private mindMapVersionNodeService: MindMapVersionNodeService,
      private spinnerService: SpinnerService,
  ) {}

  ngOnInit() {
    this.initTabs();
  }

  ngAfterContentInit() {
    this.route.params.subscribe(params => {
      this.mapsManagerVersionName = params.name;
      this.mapsManagerVersionId = params.id;
      this.getMindMapVersionNodeList();
    });
  }

  public getDataLength(tab: string) {
    switch (tab) {
      case NODES_MANAGER_CATEGORY.Products:
        return this.productDataList.length > 0 ? ` (${this.productDataList.length})` : '';
      case NODES_MANAGER_CATEGORY.Milestones:
        return this.milestoneDataList.length > 0 ? ` (${this.milestoneDataList.length})` : '';
    }
  }

  public returnToMapsManagerPage() {
    this.router.navigate(['/mapsManager/list']);
  }

  public downloadTable(tableName: string) {
    const data: Array<string> = [];
    if (tableName === this.PRODUCTS) {
      const categories = Object.keys(PRODUCT_CATEGORY).map(key => PRODUCT_CATEGORY[key]);
      data.push(`Company,Product,${categories.join(',')}`);
      this.productDataList.forEach((p) => {
        const values = categories.map((c) => p.productCategoryMap.has(c) ?
            this.escapeCSV(p.productCategoryMap.get(c).join('; '))
            : '');
        data.push([this.escapeCSV(p.companyName), this.escapeCSV(p.productName), ...values].join(','));
      });
    } else if (tableName === this.MILESTONES) {
      const categories = Object.keys(MILESTONE_CATEGORY).map(key => MILESTONE_CATEGORY[key]);
      data.push(`Company/Product,${categories.join(',')}`);
      this.milestoneDataList.forEach((m) => {
        const values = categories.map((c) => m.milestoneCategoryMap.has(c) ?
            this.escapeCSV(m.milestoneCategoryMap.get(c).join('; '))
            : '');
        data.push([this.escapeCSV(m.companyProductLabel), ...values].join(','));
      });
    }

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.join('\n')));
    element.setAttribute('download', `${this.mapsManagerVersionName}-${this.tabList[this.currentTab]}.csv`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  private initTabs() {
    this.tabList = Object.keys(NODES_MANAGER_CATEGORY).map(key => NODES_MANAGER_CATEGORY[key]);
    this.currentTab = 0;
  }

  private getMindMapVersionNodeList() {
    this.spinnerService.start();
    this.mindMapVersionNodeService.getAllNoPagination(this.mapsManagerVersionId).subscribe((data: Array<MindMapVersionNode>) => {
      this.processMindMapVersionNodeList(data);
      this.spinnerService.stop();
    });
  }

  private processMindMapVersionNodeList(mindMapVersionNodeList: Array<MindMapVersionNode>) {
    mindMapVersionNodeList.forEach(node => {
      this.addProductData(node);
      this.addMilestoneData(node);
    });
    this.sortData();
  }

  private addProductData(mindMapVersionNode: MindMapVersionNode) {
    mindMapVersionNode.productCompanyInstances.forEach(productCompany => {
      productCompany.products.forEach(product => {
        const productCategoryMap = new Map<string, Array<string>>();
        let manualRoA = false;
        for (let productCategory of Object.keys(PRODUCT_CATEGORY)) {
          if (productCategory === 'RoA') {
            productCategory = 'Route of Administration';
          }
          const nodePCVs = mindMapVersionNode.productCategoryValues.filter(pcv => pcv.productCategory === productCategory);
          if (nodePCVs.length > 0) {
            productCategoryMap.set(productCategory, nodePCVs.map(pcv => pcv.name));
            if (productCategory === 'Route of Administration') {
              manualRoA = true;
            }
          } else {
            productCategoryMap.set(productCategory, new Array<string>());
            for (const productCategoryValue of product.productCategoryValues.filter(pcv => pcv.productCategory === productCategory)) {
              productCategoryMap.get(productCategory).push(productCategoryValue.name);
            }
          }
        }
        if (productCompany.companies.length > 0) {
          productCompany.companies.forEach(company => {
            this.productDataMap.set(product.name + company.name, { productName: product.name, companyName: company.name,
              productCategoryMap: productCategoryMap, manualRoA: manualRoA });
          });
        } else {
          this.productDataMap.set(product.name, { productName: product.name, companyName: '',
            productCategoryMap: productCategoryMap, manualRoA: manualRoA });
        }
      });
    });
  }

  private addMilestoneData(mindMapVersionNode: MindMapVersionNode) {
    const productNameList = mindMapVersionNode.productCompanyInstances.map(productCompany =>
        productCompany.products.map(product => product.name)).reduce((accumulator, value) =>
        accumulator.concat(value), []);
    const companyNameList = mindMapVersionNode.productCompanyInstances.map(productCompany =>
        productCompany.companies.map(company => company.name)).reduce((accumulator, value) =>
        accumulator.concat(value), []);
    const productCompanyLabel = NodesManagerListComponent.getProductCompanyLabel(productNameList, companyNameList);
    mindMapVersionNode.milestones.forEach(milestone => {
      const milestoneCategoryMap = new Map<string, Array<string>>();
      const source = milestone['source'];
      milestone.milestoneCategoryValues.forEach(milestoneCategoryValue => {
        if (!milestoneCategoryMap.has(milestoneCategoryValue.milestoneCategory)) {
          milestoneCategoryMap.set(milestoneCategoryValue.milestoneCategory, new Array<string>());
        }
        milestoneCategoryMap.get(milestoneCategoryValue.milestoneCategory).push(milestoneCategoryValue.name);
      });
      milestoneCategoryMap.set(MILESTONE_CATEGORY.Indication, milestone.synonyms.map(synonym => synonym.name));
      this.milestoneDataMap.set(NodesManagerListComponent.getMilestoneDataHash({ companyProductLabel:
        productCompanyLabel, source: source, milestoneCategoryMap: milestoneCategoryMap }), { companyProductLabel: productCompanyLabel,
        source: source, milestoneCategoryMap: milestoneCategoryMap });
    });
  }

  private static getMilestoneDataHash(milestoneData: MilestoneData): string {
    return milestoneData.companyProductLabel + [...milestoneData.milestoneCategoryMap.values()]
        .reduce((accumulator, value) => accumulator.concat(value), []).join('');
  }

  private static getProductCompanyLabel(productNameList: Array<string>, companyNameList: Array<string>) {
    return companyNameList.length > 0 ? `${companyNameList.join(', ')} (${productNameList.join(' + ')})` :
        `(${productNameList.join(' + ')})`;
  }

  private sortData() {
    this.productDataList = [...this.productDataMap.values()].sort((a, b) => {
      if (a.companyName === '') return 1;
      if (b.companyName === '') return -1;
      if (a.companyName.toUpperCase() > b.companyName.toUpperCase()) return 1;
      if (a.companyName.toUpperCase() < b.companyName.toUpperCase()) return -1;
      return 0;
    });
    this.milestoneDataList = [...this.milestoneDataMap.values()].sort((a, b) => {
      if (a.companyProductLabel.startsWith('(')) return 1;
      if (b.companyProductLabel.startsWith('(')) return -1;
      if (a.companyProductLabel.toUpperCase() > b.companyProductLabel.toUpperCase()) return 1;
      if (a.companyProductLabel.toUpperCase() < b.companyProductLabel.toUpperCase()) return -1;
      return 0;
    });
  }

  private escapeCSV(text: string) {
    return text.includes(',') ? `"${text}"` : text;
  }
}

export interface ProductData {
  productName: string;
  companyName: string;
  productCategoryMap: Map<string, Array<string>>;
  manualRoA?: boolean;
}

export interface MilestoneData {
  companyProductLabel: string;
  source: string;
  milestoneCategoryMap: Map<string, Array<string>>;
}

export interface NodesManagerFilter {
  category: string;
  value: string;
}
