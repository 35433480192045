import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ExceptionService, UtilsService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';


/**
 * Data service to get audit sessions information, summary and table list
 */
@Injectable()
export class AuditSessionService {

  private url = '/api/rms/usersessions';

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private exceptionService: ExceptionService
  ) { }

  public getAuditSessionSummary(params?: object) {
    const httpParams: HttpParams = this.utilsService.generateParams(params);

    return this.http
      .get(`${this.url}/summary`, { params: httpParams })
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getAuditSessionDetails(params?: object) {
    const httpParams: HttpParams = this.utilsService.generateParams(params);

    return this.http
      .get(`${this.url}/userdetails`, { params: httpParams })
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

}
