import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {SimpleMapResource} from '../models/resources/simpleMapResource.model';

@Injectable()
export class DesignIntegrationService {

  private _activeMapList: Array<SimpleMapResource>;
  private _initialMapNodeListTable = {};
  private _currentMapNodeListTable = {};
  private _placeDrugInfoTableEventEmitter: Subject<object> = new Subject<object>();
  private _removeDrugInfoTableEventEmitter: Subject<object> = new Subject<object>();
  private _showCookieTrailEventEmitter: Subject<object> = new Subject<object>();
  private _returnCookieTrailEventEmitter: Subject<object> = new Subject<object>();
  private _chartImageClickEventHandler: Subject<object> = new Subject<object>();
  private _initialHammerPressEventEmitter: Subject<string> = new Subject<string>();
  private _mapSwapEventEmitter: Subject<object> = new Subject<object>();


  get activeMapList(): Array<SimpleMapResource> {
    return this._activeMapList;
  }

  set activeMapList(value: Array<SimpleMapResource>) {
    this._activeMapList = value;
  }

  get initialMapNodeListTable(): {} {
    return this._initialMapNodeListTable;
  }

  set initialMapNodeListTable(value: {}) {
    this._initialMapNodeListTable = value;
  }

  get currentMapNodeListTable(): {} {
    return this._currentMapNodeListTable;
  }

  get placeDrugInfoTableEventEmitter(): Subject<object> {
    return this._placeDrugInfoTableEventEmitter;
  }

  get removeDrugInfoTableEventEmitter(): Subject<object> {
    return this._removeDrugInfoTableEventEmitter;
  }

  get showCookieTrailEventEmitter(): Subject<object> {
    return this._showCookieTrailEventEmitter;
  }

  get returnCookieTrailEventEmitter(): Subject<object> {
    return this._returnCookieTrailEventEmitter;
  }

  get initialHammerPressEventEmitter(): Subject<string> {
    return this._initialHammerPressEventEmitter;
  }

  get chartImageClickEventHandler(): Subject<object> {
    return this._chartImageClickEventHandler;
  }

  get mapSwapEventEmitter(): Subject<object> {
    return this._mapSwapEventEmitter;
  }

}
