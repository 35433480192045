import { Component, OnInit } from '@angular/core';
import { JqueryCommonServices } from '../../../shared/services/jqueryCommon.services';
import { ToolboxAllPanelServices } from '../../../shared/services/toolboxAllPanel.services';
import { AuthoringIntegrationService } from '../../../shared/services/authoringIntegration.service';
import { MAP_VIEW_STATE } from '../../../shared/enums';
import { GlobalIntegrationServices } from '../../../shared/services/globalIntegration.services';
import * as $ from 'jquery';

@Component({
  selector: 'emap-map-design',
  templateUrl: './map-design.component.html',
  styleUrls: ['./map-design.component.scss']
})
export class MapDesignComponent implements OnInit {

    constructor(
        private jqueryCommonServices: JqueryCommonServices,
        private toolboxAllPanelServices: ToolboxAllPanelServices,
        private authoringIntegrationService: AuthoringIntegrationService,
        private globalIntegrationServices: GlobalIntegrationServices,
    ) { }

    ngOnInit() {
        this.updateWindowDimensions();
        this.setMapDesignState();
    }

    private updateWindowDimensions() {
        this.toolboxAllPanelServices.windowWidth = this.jqueryCommonServices.windowWidthHeight()['width'];
    }

    private setMapDesignState() {
        this.globalIntegrationServices.currentMapViewState = MAP_VIEW_STATE.Design;
        this.authoringIntegrationService.authoringHeaderModel.isHierarchyModeActive = false;
        this.authoringIntegrationService.authoringHeaderModel.isEditModeActive = false;
        this.authoringIntegrationService.authoringToolboxModel.isVisible = false;
        this.authoringIntegrationService.authoringToolboxModel.isHierarchyModeActive = false;
        this.authoringIntegrationService.nodeLayerModel.primarySelectionId = null;
        this.authoringIntegrationService.nodeLayerModel.secondarySelectionIds.clear();
    }
}
