import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'emap-insight-panel',
  templateUrl: './insight-panel.component.html',
  styleUrls: ['./insight-panel.component.scss']
})
export class InsightPanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
