<div class="container-fluid">
  <div class="row profile-data" *ngIf="user">
    <div class="col-xs-12 col-md-2 profile-image-wrapper">
      <img alt="" [src]="user.profileImage ? user.profileImage : defaultImage" class="profile-image">
    </div>
    <div class="col-xs-12 col-md-4 p-top-bottom-22">
      <table>
        <tr>
          <td class="bold">{{"USERS.LIST.FIRSTNAME" | translate}}:</td>
          <td>{{user.firstName}}</td>
        </tr>
        <tr>
          <td class="bold">{{"USERS.LIST.LASTNAME" | translate}}:</td>
          <td>{{user.lastName}}</td>
        </tr>
        <tr>
          <td class="bold">{{"USERS.JOB_TITLE" | translate}}:</td>
          <td>{{user.attributes.jobTitle}}</td>
        </tr>
        <tr>
          <td class="bold">{{"USERS.EMAIL_ADDRESS" | translate}}:</td>
          <td>{{user.emailAddress}}</td>
        </tr>
        <tr>
          <td class="bold">{{"DATE.ADDED" | translate}}:</td>
          <td>{{user.created | amDateFormat: dateFormat}}</td>
        </tr>
      </table>
    </div>
    <div class="col-xs-12 col-md-6 p-top-bottom-22">
      <table>
        <tr>
          <td class="bold">{{"GENERICS.ROLES" | translate}}:</td>
          <td>
            <ng-container *ngIf="user.userTenants && user.userTenants.length > 0">
              <span *ngFor="let subItem of user.userTenants[0].tenantRoles; let isLastRole = last">
                {{subItem.name}}{{isLastRole ? '' : ', '}}
              </span>
            </ng-container>
          </td>
        </tr>
        <tr *ngIf="user.createdBy">
          <td class="bold">{{"GENERICS.CREATED_BY" | translate}}:</td>
          <td>{{user.createdBy.firstName}} {{user.createdBy.lastName}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
