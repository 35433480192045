<div class="filter-container" cdkDrag>
    <div class="filter-header">
        <label class="search-input">
            <i class="fa fa-times-circle close-panel" (click)="closePanel()"></i>
            <i class="fa fa-circle"></i>
            <input type="text" id="search" class="form-control" [(ngModel)]="searchTerm" (ngModelChange)="search()" [placeholder]="'&#xF002; Search'"/>
        </label>
    </div>
    <div class="filter-list">
        <div *ngFor="let value of filterList">
            <label class="filter-item" for="{{value}}">{{value}}
                <input type="checkbox" name="{{value}}" id="{{value}}" [ngModel]="filterMap.get(value)" (ngModelChange)="toggleFilter(value, $event)">
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
    <div class="action-buttons">
        <button type="button" class="btn btn-primary clear-button" (click)="toggleAll(false)">Clear</button>
        <button type="button" class="btn btn-primary" (click)="toggleAll(true)">Select All</button>
    </div>
</div>

