import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCRUD, CRUDService, ExceptionService, UtilsService } from '../../xform-compat';
import { RemoteServer } from '../../shared/models/remoteServer.model';
import { catchError } from 'rxjs/operators';


@Injectable()
export class RemoteServerService extends AbstractCRUD<RemoteServer> implements CRUDService<RemoteServer> {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/core/service/remoteservers';
  }

  public getAllRemoteServersPath() {
    return super.getApiPath().concat('/all');
  }

  public getAllRemoteServers() {
    return this.request(this.getAllRemoteServersPath());
  }

  private request(apiUrl: string) {
    return this.getHTTP().get(apiUrl)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }
}
