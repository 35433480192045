import { BaseEntity } from '../../xform-compat';
import { SubTenant } from './subTenant.model';
import { Role } from './role.model';
import { User } from './user.model';
import { BasicUser } from './basicUser.model';
import { Searchable } from './interfaces/searchable.interface';


export class Tenant implements BaseEntity, Searchable {
  id: number;
  name: string;
  description: string;
  url: string;
  tenants: Array<SubTenant>;
  parent: SubTenant;
  administrators: Array<User>;
  creator: BasicUser;
  active: boolean;
  roles: Array<Role>;
  rolesCount: number;
  usersCount: number;
  tenantsCount: number;
  created: string;
  updated: string;
  parentTenantId: number;

  constructor(tenant: any) {
    this.id = tenant.id || null;
    this.name = tenant.name || null;
    this.description = tenant.description || null;
    this.url = tenant.url || null;
    this.tenants = tenant.tenants || [];
    this.parent = tenant.parent || null;
    this.administrators = tenant.administrators || [];
    this.creator = tenant.creator || null;
    this.active = tenant.active || null;
    this.roles = tenant.roles || [];
    this.rolesCount = tenant.rolesCount || null;
    this.usersCount = tenant.usersCount || null;
    this.tenantsCount = tenant.tenantsCount || null;
    this.created = tenant.created || null;
    this.updated = tenant.updated || null;
    this.parentTenantId = tenant.parentTenantId || null;
  }

  public setAdministrators(admins: Array<User>) {
    this.administrators = admins;
  }

  public setRoles(roles: Array<Role>) {
    this.roles = roles;
  }

  public getDisplayName() {
    return this.name;
  }

}

