import { Injectable } from '@angular/core';
import * as Fuse from 'fuse.js';
import { FuseOptions } from 'fuse.js';
import { NodeSearchResource } from '../models/resources/nodeSearchResource.model';


@Injectable()
export class FuzzySearchServices {
  private readonly fuzzySearchOptions: FuseOptions;
  private fuse: Fuse;
  private _nodeSearchResourceList: NodeSearchResource[];
  private FUZZY_SEARCH_KEYS = ['nn', 'nm.cvn'];

  constructor() {
    this.fuzzySearchOptions = {
      shouldSort: false,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: this.FUZZY_SEARCH_KEYS
    };
  }

  get nodeSearchResourceList(): NodeSearchResource[] {
    return this._nodeSearchResourceList;
  }

  set nodeSearchResourceList(value: NodeSearchResource[]) {
    this._nodeSearchResourceList = value;
  }

  get getFuzzySearchOptions(): FuseOptions {
    return this.fuzzySearchOptions;
  }

  public setFuzzySearchOptionsKeys(options: object) {
    Object.assign(this.fuzzySearchOptions, options);
  }

  public initFuzzySearch(): Fuse {
    this.fuse = new Fuse(this._nodeSearchResourceList, this.fuzzySearchOptions);
    return this.fuse;
  }

  public fuzzySearchValue(searchTerm: string): NodeSearchResource[] {
    return this.fuse.search<NodeSearchResource>(searchTerm);
  }
}
