import { BaseEntity } from '../../xform-compat';
import { BasicUser } from './basicUser.model';
import { RemoteServer } from './remoteServer.model';


export class RemoteServerDeploy implements BaseEntity {
  id: number;
  isProcessed: boolean;
  isMapVisible: boolean;
  remoteServer: RemoteServer;
  mapVersionId: number;
  mapName: string;
  publishedState: string;
  updatedBy: BasicUser;
  created: string;
  updated: string;
  active: boolean;

  constructor(remoteServerDeploy: RemoteServerDeploy = {} as RemoteServerDeploy) {
    this.id = remoteServerDeploy.id || null;
    this.isProcessed = remoteServerDeploy.isProcessed || null;
    this.isMapVisible = remoteServerDeploy.isMapVisible || null;
    this.remoteServer = remoteServerDeploy.remoteServer || null;
    this.mapVersionId = remoteServerDeploy.mapVersionId || null;
    this.mapName = remoteServerDeploy.mapName || null;
    this.publishedState = remoteServerDeploy.publishedState || null;
    this.updatedBy = remoteServerDeploy.updatedBy || null;
    this.created = remoteServerDeploy.created || null;
    this.updated = remoteServerDeploy.updated || null;
    this.active = remoteServerDeploy.active || null;
  }
}
