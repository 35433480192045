import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SearchFilterKeyboardServices } from '../../services/searchFilterKeyboard.services';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { ToolboxAllPanelServices } from '../../services/toolboxAllPanel.services';


@Component({
  selector: 'emap-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  providers: [JqueryCommonServices],
  encapsulation: ViewEncapsulation.None
})
export class FilterPanelComponent implements OnInit {
  private FILTER_PANEL_RESULTS_TABLE_CLASS = 'filter-panel-results-table';
  private FILTER_PANEL_PARENT_CLASS = 'filter-panel-parent';
  private FILTER_PANEL_CHILD_CLASS = 'filter-panel-child';
  private FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CLASS = 'filter-panel-results-table-node-name';
  private FILTER_PANEL_RESULTS_TABLE_HEADER_CLASS = 'filter-panel-results-table-header';
  private FILTER_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS = 'filter-panel-results-table-header-icon';
  private FILTER_PANEL_RESULTS_TABLE_HEADER_MAP_NAME_CLASS = 'filter-panel-results-table-header-map-name';
  private FILTER_PANEL_RESULTS_TABLE_NODE_LIST_CLASS = 'filter-panel-results-table-node-list';
  private FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CHECKBOX_CLASS = 'filter-panel-results-table-node-name-checkbox';
  private FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CHECKBOX_CHECKED_CLASS = 'category-checked';
  private FILTER_PANEL_RESULTS_TABLE_NODE_NAME_VALUE_CLASS = 'filter-panel-results-table-node-name-value';
  private FILTER_PANEL_CLOSE_LEFT_CLASS = 'filter-panel-close-left';
  private FILTER_PANEL_CLOSE_RIGHT_CLASS = 'filter-panel-close-right';
  private FILTER_PANEL_HEADER_RESULTS_CLASS = 'filter-panel-header-results';
  private FILTER_PANEL_SWAP_ACTIVE_CLASS = 'filter-panel-swap-active';
  private PANEL_LEFT_SLIDE_OUT_CLASS = 'panel-left-slide-out';
  private SWAP_PANEL_SLIDE_CLASS = 'panel-right-slide-in panel-right-slide-out';
  private PANEL_DISPLAY_NONE = 'panel-display-none';
  private CATEGORY_ID_PREFIX_NAME = 'category_id_';
  private CATEGORY_VALUE_ID_PREFIX_NAME = 'category_value_id_';
  private CATEGORY_DETAILS_ACCESSOR = 'categoryDetails';

  private currentFilterDataState: object;

  constructor(
    private searchFilterKeyboardServices: SearchFilterKeyboardServices,
    private jqueryCommonServices: JqueryCommonServices,
    private toolboxAllPanelServices: ToolboxAllPanelServices
  ) { }

  ngOnInit() {
    this.updateFilterPanelDimensions();
    this.filterPanelAccordionInit();
    this.updateFilterResults();
    this.filterSelectionRemoveHandler();
    this.filterOpenEventHandler();
    this.filterAnimationCompleteEventHandler();
    this.toolboxSwapButtonClickHandler();
  }

  private updateFilterPanelDimensions() {
    this.toolboxAllPanelServices.filterPanelWidth = this.jqueryCommonServices.widthHeight(this.FILTER_PANEL_PARENT_CLASS.toClass())['width'];
  }

  private filterPanelAccordionInit() {
    const customAccordionOption: any = { beforeActivate: this.jqueryCommonServices.customAccordionMultipleExpandFunction() };
    this.jqueryCommonServices.initiateAccordion(this.FILTER_PANEL_RESULTS_TABLE_CLASS.toClass(), customAccordionOption);
  }

  private updateFilterResults() {
    this.searchFilterKeyboardServices.searchResultChangeEmitter.subscribe((val) => {
      this.constructFilterPanelData();
    });
  }

  private filterSelectionEventHandler() {
    this.jqueryCommonServices.clickEvent(this.FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CLASS.toClass(), (event: any) => {
      this.toggleCheckBoxHandler(event.target);
      this.filterChangeEventHandler();
      this.searchFilterKeyboardServices.filterResultChangeEmitter.next(true);
    });
  }

  private toggleCheckBoxHandler(target: any) {
    if (this.jqueryCommonServices.selector(target).hasClass(this.FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CHECKBOX_CLASS)) {
      this.jqueryCommonServices.selector(target).toggleClass(this.FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CHECKBOX_CHECKED_CLASS);
    } else {
      const checkBoxSelector = this.jqueryCommonServices.selector(target).siblings();
      checkBoxSelector.toggleClass(this.FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CHECKBOX_CHECKED_CLASS);
    }
  }

  private constructFilterPanelData() {
    const filterResultsTable = this.jqueryCommonServices.selector(this.FILTER_PANEL_RESULTS_TABLE_CLASS.toClass());
    filterResultsTable.empty();
    const currentCategoryObject = this.searchFilterKeyboardServices.filterData;
    this.constructCategoryElements(currentCategoryObject, filterResultsTable);
    this.jqueryCommonServices.accordionRefresh(this.FILTER_PANEL_RESULTS_TABLE_CLASS.toClass());
    this.filterSelectionEventHandler();
  }

  private constructCategoryElements(currentCategoryObject, filterResultsTable) {
    for (const eachCategory in currentCategoryObject) {
      if (!currentCategoryObject.hasOwnProperty(eachCategory)) { continue; }
      const categoryDiv = this.jqueryCommonServices.divConstruct()
        .addClass(this.FILTER_PANEL_RESULTS_TABLE_HEADER_CLASS)
        .attr('id', (this.CATEGORY_ID_PREFIX_NAME + eachCategory));
      categoryDiv.append(this.jqueryCommonServices.divConstruct().addClass(this.FILTER_PANEL_RESULTS_TABLE_HEADER_ICON_CLASS));
      categoryDiv.append(this.jqueryCommonServices.divConstruct(currentCategoryObject[eachCategory]['cn'])
        .addClass(this.FILTER_PANEL_RESULTS_TABLE_HEADER_MAP_NAME_CLASS));
      const categoryValueListDiv = this.jqueryCommonServices.divConstruct().addClass(this.FILTER_PANEL_RESULTS_TABLE_NODE_LIST_CLASS);
      const currentCategoryValueObject = currentCategoryObject[eachCategory]['cv'];
      filterResultsTable.append(categoryDiv);
      this.constructCategoryValueElements(currentCategoryValueObject, categoryValueListDiv, eachCategory);
      filterResultsTable.append(categoryValueListDiv);
    }
  }

  private constructCategoryValueElements(currentCategoryValueObject, categoryValueListDiv, currentCategoryId) {
    for (const eachCategoryValue in currentCategoryValueObject) {
      if (!currentCategoryValueObject.hasOwnProperty(eachCategoryValue)) { continue; }
      const eachCategoryValueDiv = this.jqueryCommonServices.divConstruct()
        .addClass(this.FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CLASS)
        .attr('id', (this.CATEGORY_VALUE_ID_PREFIX_NAME + eachCategoryValue))
        .data(this.CATEGORY_DETAILS_ACCESSOR, { ci: currentCategoryId, cvi: eachCategoryValue });
      const checkBoxDiv = this.jqueryCommonServices.divConstruct().addClass(this.FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CHECKBOX_CLASS);
      if (this.checkHighlightCategory(currentCategoryId, Number(eachCategoryValue))) {
        checkBoxDiv.addClass(this.FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CHECKBOX_CHECKED_CLASS);
      }
      eachCategoryValueDiv.append(checkBoxDiv);
      eachCategoryValueDiv.append(this.jqueryCommonServices.divConstruct(currentCategoryValueObject[eachCategoryValue])
        .addClass(this.FILTER_PANEL_RESULTS_TABLE_NODE_NAME_VALUE_CLASS));
      categoryValueListDiv.append(eachCategoryValueDiv);
    }
  }

  private filterChangeEventHandler() {
    this.currentFilterDataState = {};
    this.jqueryCommonServices.selector(this.FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CHECKBOX_CHECKED_CLASS.toClass()).each((index, eachElement) => {
      const categoryDetails = this.jqueryCommonServices.selector(eachElement).parent().data(this.CATEGORY_DETAILS_ACCESSOR);
      this.constructFilterDataState(categoryDetails);
    });
    this.searchFilterKeyboardServices.filterDataState = this.currentFilterDataState;
  }

  private constructFilterDataState(categoryDetails: any) {
    if (!categoryDetails) { return; }
    const categoryId = Number(categoryDetails['ci']);
    const categoryValueId = Number(categoryDetails['cvi']);
    if (!this.currentFilterDataState.hasOwnProperty(categoryId)) {
      this.currentFilterDataState[categoryId] = [categoryValueId];
    } else {
      this.currentFilterDataState[categoryId].push(categoryValueId);
    }
  }

  private checkHighlightCategory(currentCategoryId: any, eachCategoryValue: Number) {
    let highlightState = false;
    const filterDataState = this.searchFilterKeyboardServices.filterDataState;
    if (filterDataState.hasOwnProperty(currentCategoryId)) {
      highlightState = filterDataState[currentCategoryId].includes(eachCategoryValue);
    }
    return highlightState;
  }

  private filterSelectionRemoveHandler() {
    this.searchFilterKeyboardServices.filterResultRemoveEmitter.subscribe((data: object) => {
      const filterSelectionRemoveCategoryId = '#' + this.CATEGORY_ID_PREFIX_NAME + data['ci'];
      const filterSelectionRemoveCategoryValueId = '#' + this.CATEGORY_VALUE_ID_PREFIX_NAME + data['cvi'];
      const checkBoxDiv = this.jqueryCommonServices.selector(filterSelectionRemoveCategoryValueId)
        .find(this.FILTER_PANEL_RESULTS_TABLE_NODE_NAME_CHECKBOX_CHECKED_CLASS.toClass());
      this.toggleCheckBoxHandler(checkBoxDiv);
      this.filterChangeEventHandler();
      this.searchFilterKeyboardServices.filterResultChangeEmitter.next(true);
    });
  }

  private filterOpenEventHandler() {
    this.toolboxAllPanelServices.filterButtonClickEventEmitter.subscribe(value => {
      const filterPanelChildSelector = this.jqueryCommonServices.selector(this.FILTER_PANEL_CHILD_CLASS.toClass());
      filterPanelChildSelector.parent().removeClass(this.PANEL_DISPLAY_NONE);
      filterPanelChildSelector.toggleClass(this.PANEL_LEFT_SLIDE_OUT_CLASS);
      this.toolboxAllPanelServices.filterPanelOpenState = !filterPanelChildSelector.hasClass(this.PANEL_LEFT_SLIDE_OUT_CLASS);
    });
  }

  private filterAnimationCompleteEventHandler() {
    this.jqueryCommonServices.animationCompleteEvent(this.FILTER_PANEL_CHILD_CLASS.toClass(), (event) => {
      const filterPanelChildSelector = this.jqueryCommonServices.selector(this.FILTER_PANEL_CHILD_CLASS.toClass());
      if (filterPanelChildSelector.hasClass(this.PANEL_LEFT_SLIDE_OUT_CLASS)) {
        filterPanelChildSelector.parent().addClass(this.PANEL_DISPLAY_NONE);
      } else {
        filterPanelChildSelector.parent().removeClass(this.PANEL_DISPLAY_NONE);
      }
    });
  }

  private toolboxSwapButtonClickHandler() {
    this.toolboxAllPanelServices.swapButtonClickEventEmitter.subscribe(value => {
      const toolboxWidth = this.toolboxAllPanelServices.toolboxWidth;
      const globalPanelWidth = this.toolboxAllPanelServices.globalPanelWidth;
      const filterPanelWidth = this.toolboxAllPanelServices.filterPanelWidth;
      const windowWidth = this.toolboxAllPanelServices.windowWidth;
      this.jqueryCommonServices.selector(this.FILTER_PANEL_PARENT_CLASS.toClass())
        .css({ left: !value ? (toolboxWidth + globalPanelWidth) : (windowWidth - (toolboxWidth + globalPanelWidth + filterPanelWidth)) });
      this.handlerFilterPanelCloseOnSwap(value);
      this.swapPanelAnimationHandler(value);
    });
  }

  private handlerFilterPanelCloseOnSwap(swapActive: boolean) {
    const filterPanelSwapElementsSelector = this.jqueryCommonServices.selector(
      this.FILTER_PANEL_CLOSE_LEFT_CLASS.toClass().concat(',')
        .concat(this.FILTER_PANEL_CLOSE_RIGHT_CLASS.toClass()).concat(',')
        .concat(this.FILTER_PANEL_HEADER_RESULTS_CLASS.toClass())
    );
    if (swapActive) {
      filterPanelSwapElementsSelector.addClass(this.FILTER_PANEL_SWAP_ACTIVE_CLASS);
    } else {
      filterPanelSwapElementsSelector.removeClass(this.FILTER_PANEL_SWAP_ACTIVE_CLASS);
    }
  }

  private swapPanelAnimationHandler(value: boolean) {
    const filterPanelChildSelector = this.jqueryCommonServices.selector(this.FILTER_PANEL_CHILD_CLASS.toClass());
    if (value) {
      filterPanelChildSelector.addClass(this.SWAP_PANEL_SLIDE_CLASS);
    } else {
      filterPanelChildSelector.removeClass(this.SWAP_PANEL_SLIDE_CLASS);
    }
  }
}
