<div class="container-fluid">
  <div class="row">
    <div class="col-12">

      <ngb-tabset class="detail-tabs tabs-api-docs no-border" [activeId]="preSelectedTab" (tabChange)="updateLink($event.nextId)">
        <ngb-tab *ngFor="let type of types" [id]="type">
          <ng-template ngbTabTitle>{{ type | uppercase }}</ng-template>
          <ng-template ngbTabContent>
            <iframe #iframe [src]="link" frameborder="0"></iframe>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>

      <div>
      </div>
    </div>
