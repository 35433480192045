<ng-template #imageUploadModal let-c="close" let-d="dismiss">
    <form #imageUploadForm="ngForm" [class.submitted]="imageUploadForm.submitted" (ngSubmit)="processRecord(imageUploadForm)" novalidate>
        <div class="modal-header">
            <h4 class="modal-title">{{"MEDIA.ADD_IMAGE" | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="d()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <div class="form-group">
                        <label for="imageCategory">{{"MEDIA.SELECT_CATEGORY" | translate}}:
                            <span class="error" *ngIf="imageUploadForm.submitted">
                                <span *ngIf="image.errors?.required">{{"ERRORS.IS_REQUIRED" | translate}}</span>
                                <span *ngIf="image.errors?.pattern">{{"ERRORS.INVALID_PATTERN" | translate}}</span>
                            </span>
                        </label>
                        <select id="imageCategory" name="imageCategory" (change)="updateImageCategory($event)"
                                [ngModel]="imageCategory" class="form-control">
                            <option *ngFor="let eachImageCategory of imageCategory" [ngValue]="eachImageCategory">{{ eachImageCategory }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-xs-12 col-md-12">
                    <div class="form-group">
                        <label for="imageFileUpload">{{"MEDIA.SELECT_IMAGE_FILES" | translate}}:
                            <span class="error" *ngIf="imageUploadForm.submitted">
                              <span *ngIf="uploader.queue[0] == null">{{"ERRORS.IS_REQUIRED" | translate}}</span>
                          </span>
                        </label>
                        <input id="imageFileUpload" name="imageFileUpload" type="file" ng2FileSelect [uploader]="uploader" multiple #imageFileUpload accept="image/png">
                    </div>
                </div>
                <div class="col-xs-12 col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="40%">Name</th>
                                <th>Size</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of uploader.queue">
                                <td><strong>{{ item?.file?.name }}</strong></td>
                                <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                                <td class="text-center">
                                    <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                                    <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
                                    <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                                </td>
                                <td nowrap>
                                    <button type="button" class="btn btn-success btn-xs"
                                            (click)="item.upload()" [disabled]="item.isReady || item.isUploading || item.isSuccess || (currentImageCategory == null)">
                                        <span class="glyphicon glyphicon-upload"></span> Upload
                                    </button>
                                    <button type="button" class="btn btn-warning btn-xs"
                                            (click)="item.cancel()" [disabled]="!item.isUploading">
                                        <span class="glyphicon glyphicon-ban-circle"></span> Cancel
                                    </button>
                                    <button type="button" class="btn btn-danger btn-xs"
                                            (click)="item.remove()">
                                        <span class="glyphicon glyphicon-trash"></span> Remove
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div> Queue progress:
                        <div class="progress" style="">
                            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                        </div>
                    </div>
                    <br>
                    <button type="button" class="btn btn-success btn-s"
                            (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length || (currentImageCategory == null)">
                        <span class="glyphicon glyphicon-upload"></span> Upload all
                    </button>
                    <button type="button" class="btn btn-warning btn-s"
                            (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
                        <span class="glyphicon glyphicon-ban-circle"></span> Cancel all
                    </button>
                    <button type="button" class="btn btn-danger btn-s"
                            (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                        <span class="glyphicon glyphicon-trash"></span> Remove all
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="c()">{{"GENERICS.CLOSE" | translate}}</button>
        </div>
    </form>
</ng-template>
