import { PERMISSIONS } from './enums';


export const MENU = [
  {
    textKey: 'MENU.MAPS_MANAGER',
    link: '/mapsManager/list',
    icon: '',
    permissionsNeeded: ['ADMIN_USER_READ'],
    subMenu: []
  },
  {
    textKey: 'MENU.MEDIA',
    link: null,
    icon: 'chevron-down',
    permissionsNeeded: [],
    subMenu: [
      {
        textKey: 'MENU.MEDIA_MANAGEMENT',
        link: 'admin/media',
        icon: '',
        permissionsNeeded: [PERMISSIONS.EmapVersionUpdateIndesign]
      }
    ]
  }
];
