import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'orderBy',
  pure: false
})
export class OrderByPipe implements PipeTransform {
  transform<T>(array: T[], sortBy?: string|Array<any>[]|Function[]|Object[]|string[]|((o1: any, o2: any) => number), order?: 'asc'|'desc'|Array<'asc'|'desc'>): T[] {
    const sortOrder = order ? order : 'asc'; // setting default ascending order

    if (!sortBy) {
      return array.sort();
    }

    if (sortBy instanceof Function) {
        return array.sort(sortBy);
    }

    // TODO if we upgrade to es2019, replace the concat calls with arr.flat()
    return orderBy(array, <string[]>[].concat(...[sortBy]), <Array<'asc'|'desc'>>[].concat(...[sortOrder]));
  }
}
