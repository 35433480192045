import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExceptionService } from '../../xform-compat';


@Injectable({
  providedIn: 'root'
})
export class PasswordResetService {

  private apiUrl = '/api/rms/authorization';

  constructor(
    private http: HttpClient,
    private exceptionService: ExceptionService
  ) {}

  public forgotPassword(body: object) {
    return this.request(body, `${this.apiUrl}/forgotpassword`);
  }

  public resetPassword(body: object) {
    return this.request(body, `${this.apiUrl}/resetpassword`);
  }

  private request(body: object, apiUrl: string) {
    return this.http.post(apiUrl, body).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

}
