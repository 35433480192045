import { Component, OnInit } from '@angular/core';
import { AuthoringIntegrationService } from '../../../shared/services/authoringIntegration.service';
import { GlobalIntegrationServices } from '../../../shared/services/globalIntegration.services';
import { MAP_VIEW_STATE } from '../../../shared/enums';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'emap-map-authoring',
  templateUrl: './map-authoring.component.html',
  styleUrls: ['./map-authoring.component.scss']
})
export class MapAuthoringComponent implements OnInit {

    constructor(
        private authoringIntegrationService: AuthoringIntegrationService,
        private globalIntegrationServices: GlobalIntegrationServices,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.setMapAuthoringState();
        this.initGlobalVar();
        this.initRouteSubscription();
    }

    private setMapAuthoringState() {
        this.authoringIntegrationService.authoringHeaderModel.isHierarchyModeActive = false;
        this.authoringIntegrationService.authoringHeaderModel.isEditModeActive = false;
        this.authoringIntegrationService.authoringToolboxModel.isVisible = false;
        this.authoringIntegrationService.authoringToolboxModel.isHierarchyModeActive = false;
        this.authoringIntegrationService.nodeLayerModel.primarySelectionId = null;
        this.authoringIntegrationService.nodeLayerModel.secondarySelectionIds.clear();
    }

    private initGlobalVar() {
        this.globalIntegrationServices.currentMapViewState = MAP_VIEW_STATE.Authoring;
    }

    private initRouteSubscription() {
        this.route.params.subscribe(params => {
            this.globalIntegrationServices.currentMapVersionId = params.id;
        });
    }
}
