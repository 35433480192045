import { Component, OnDestroy, OnInit } from '@angular/core';
import { BannerNotificationsService, SpinnerService } from '../../../xform-compat';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CONFIG } from '../../../config';
import { Subscription } from 'rxjs';
import { EventsHubService } from '../../../core/events-hub.service';
import { PasswordExpiredService } from '../../../core/data-services/passwordExpired.service';


@Component({
  selector: 'rms-password-expired',
  templateUrl: './password-expired.component.html',
  styleUrls: ['./password-expired.component.scss']
})
export class PasswordExpiredComponent implements OnInit, OnDestroy {

  public passwordPattern = CONFIG.passwordPattern;
  private passwordExpired: Subscription;
  private credentials: object;

  constructor(
    private router: Router,
    private spinnerService: SpinnerService,
    private passwordExpiredService: PasswordExpiredService,
    private bannerNotificationsService: BannerNotificationsService,
    private translateService: TranslateService,
    private eventsHubService: EventsHubService
  ) {
  }

  ngOnInit() {
    this.passwordExpired = this.eventsHubService.passwordExpired$
      .subscribe(
        value => this.credentials = value
      );
  }

  public onSubmit(form: any) {
    this.spinnerService.start();

    if (form.invalid) {
      this.spinnerService.stop();
      return false;
    }

    const body = form.value;
    Object.assign(body, this.credentials);
    delete body.confirmPassword;

    // this.passwordExpiredService.resetPassword(body)
    //   .subscribe(
    //     () => {
    //       this.spinnerService.stop();
    //       this.router.navigate(['/login']);
    //       this.bannerNotificationsService.success(this.translateService.instant('MESSAGES.RESET_PASSWORD'));
    //     },
    //     (error) => {
    //       this.spinnerService.stop();
    //       this.bannerNotificationsService.error(error);
    //     }
    //   );
  }

  ngOnDestroy() {
    this.passwordExpired.unsubscribe();
  }

}
