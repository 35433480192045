import { Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CONFIG } from '../../../config';
import { SpinnerService } from '../../../xform-compat';


@Component({
  selector: 'xform-api-docs',
  templateUrl: './api-docs.component.html',
  styleUrls: ['./api-docs.component.scss']
})
export class ApiDocsComponent implements DoCheck, OnInit {
  @ViewChild('iframe', {static: true}) private iframe: ElementRef;
  public types: Array<string>;
  public link: SafeResourceUrl;
  public preSelectedTab: string;

  constructor(
    private domSanitizer: DomSanitizer,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit() {
    this.types = ['uaa', 'rms', 'search', 'notifications', 'measures'];
    this.preSelectedTab = this.types[0];
    this.updateLink(this.preSelectedTab);
    this.spinnerService.start();
  }

  ngDoCheck() {
    this.iframeLoaded();
  }

  onIframeLoaded() {
    this.spinnerService.stop();
  }

  public updateLink(type: string) {
    this.spinnerService.start();
    const url = `/swagger/index.html?url=${CONFIG.apiUrl}/${type}/swagger.json`;
    this.link = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private iframeLoaded() {
    if (this.iframe) {
      const body = this.iframe.nativeElement.contentWindow.document.body;
      const height = body ? body.scrollHeight : 0;
      this.iframe.nativeElement.addEventListener('load', this.onIframeLoaded.bind(this));
      if (height > 150 && height) {
        this.iframe.nativeElement.style.height = `${height}px`;
      }
    }
  }

}
