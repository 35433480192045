import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MapsManagerVersion} from "../../../shared/models/mapsManagerVersion.model";
import {Synonym} from "../../../shared/models/synonym.model";
import {DataBindService} from "../../../core/data-services/data-bind.service";
import {INDICATION_STATUS} from "../../../shared/enums";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {MapVersionIndication} from "../../../shared/models/mapsManagerVersionIndication.model";
import {NgSelectComponent} from "@ng-select/ng-select";
import {MapsManagerVersionService} from "../../../core/data-services/mapsManagerVersion.service";

@Component({
  selector: 'emap-indication-manager',
  templateUrl: './indication-manager.component.html',
  styleUrls: ['./indication-manager.component.scss']
})
export class IndicationManagerComponent implements OnInit, OnDestroy {

  @Input() mapsManagerVersion: MapsManagerVersion;

  public filteredIndications: Array<Synonym> = [];
  public excludedIndications: Array<MapVersionIndication> = [];
  public includedIndications: Array<MapVersionIndication> = [];
  public addedIndications: Array<MapVersionIndication> = [];
  public loadingIndications = []; // An array of empty objects to iterate over to display loading symbols

  private allIndications: Set<Synonym> = new Set<Synonym>();

  constructor(
      private dataService: DataBindService,
      private modal: NgbActiveModal,
      private mapsManagerVersionService: MapsManagerVersionService
  ) { }

  ngOnInit(): void {
    this.fetchIndications();

    this.mapsManagerVersion.indications.forEach(i => {
      i = new MapVersionIndication(i);

      if (i.status === INDICATION_STATUS.EXCLUDED) {
        this.excludedIndications.push(i);
      } else if (i.status === INDICATION_STATUS.ADDED) {
        this.addedIndications.push(i);
      } else {
        this.includedIndications.push(i);
      }
    });
  }

  /**
   * On component destruction, clear all the lists to prevent holding unnecessary data
   */
  ngOnDestroy() {
    this.allIndications.clear();
    this.addedIndications = [];
    this.includedIndications = [];
    this.excludedIndications = [];
    this.filteredIndications = [];
  }

  public addSelectedIndication(select: NgSelectComponent) {
    const selection = select.selectedItems;
    if (selection && selection.length > 0) {
      const indication = selection[0].value as Synonym;
      const indicationLink = new MapVersionIndication({
        indication: indication,
        indicationId: selection[0].id,
        mapsManagerVersionId: this.mapsManagerVersion.id,
        status: INDICATION_STATUS.ADDED
      });
      this.addedIndications = [...this.addedIndications, indicationLink];
      this.filteredIndications.splice(this.filteredIndications.findIndex(i => i.id === indication.id), 1);
      this.filteredIndications = [...this.filteredIndications]; // Force a refresh of the list for change detection

      select.clearModel();
    }
  }

  public removeAddedIndication(indication: MapVersionIndication) {
    this.removeIndicationFromList(indication, this.addedIndications);
    this.filteredIndications = [...this.filteredIndications, indication.indication];
  }

  public excludeIndication(ind: MapVersionIndication) {
    this.removeIndicationFromList(ind, this.includedIndications);
    ind.status = INDICATION_STATUS.EXCLUDED;
    this.excludedIndications = [...this.excludedIndications, ind];
  }

  public restoreIndication(ind: MapVersionIndication) {
    this.removeIndicationFromList(ind, this.excludedIndications);
    this.loadingIndications = [...this.loadingIndications, {}];
    this.mapsManagerVersionService.indicationExists(this.mapsManagerVersion.id, ind.indicationId).subscribe((resp) => {
      if (resp) {
        this.includedIndications = [...this.includedIndications, ind];
      }
      this.loadingIndications.pop()
    }, () => this.loadingIndications.pop())
  }

  private removeIndicationFromList(ind: MapVersionIndication, list: Array<MapVersionIndication>) {
    list.splice(list.findIndex(i => i.indicationId === ind.indicationId), 1);
  }

  public save() {
    this.close([...this.addedIndications, ...this.excludedIndications, ...this.includedIndications]);
  }

  public close(reason?: any) {
    this.modal.close(reason);
  }

  private fetchIndications() {
    this.dataService.getIndications().subscribe((resp: Array<Synonym>) => {
      this.allIndications = new Set<Synonym>(resp.map(r => new Synonym(r)));

      const usedIndicationIds = new Set<number>([...this.excludedIndications, ...this.includedIndications, ...this.addedIndications].map(i => i.indicationId));
      this.filteredIndications = Array.from(this.allIndications).filter(i => !usedIndicationIds.has(i.id));
    });
  }

}
