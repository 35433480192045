import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  public canLoad(route: Route) {
    if (this.authenticationService.isLoggedIn()) {
      return true;
    }
    const url = `/${route.path}`;
    this.router.navigate(['/login'], { queryParams: { redirectTo: url } });
    return this.authenticationService.isLoggedIn();
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (this.authenticationService.isLoggedIn()) {
      return true;
    }
    this.router.navigate(['/login'], { queryParams: { redirectTo: state.url } });

    return false;
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.canActivate(route, state);
  }
}
