<div id="Wrapper">
  <xform-banner-notifications></xform-banner-notifications>
  <xform-header></xform-header>

  <div class="page-container">
    <router-outlet></router-outlet>

    <xform-spinner></xform-spinner>
  </div>
</div>
