import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {SpinnerService, UtilsService} from '../../../xform-compat';
import { TokenInfo, User } from '../../../shared/models';
import { EventsHubService } from '../../../core/events-hub.service';
import { CONFIG } from '../../../config';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { TemplateModalService } from '../../../core/services/template-modal.service';
import { UserService } from '../../../core/data-services/user.service';
import Swal from "sweetalert2";


@Component({
  selector: 'xform-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private redirect: string;
  public emailValidated: boolean;
  public errorMsg: string;
  public loading: boolean;
  public twoFactorEnabled: boolean;
  public translations: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private eventsHubService: EventsHubService,
    private router: Router,
    private spinnerService: SpinnerService,
    private templateModalService: TemplateModalService,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    this.errorMsg = '';
    this.loading = false;
    this.twoFactorEnabled = false;
  }

  ngOnInit() {
    this.translateAlertMsgs();
    this.activatedRoute.queryParams
      .subscribe(
        (queryParams) => {
          if (queryParams['expired']) {
            setTimeout(() => {
              this.showInactivityModal();
            }, 100);
          }
          this.redirect = queryParams['redirectTo'];
        }
      );
  }

  public resetEmailValidation(form: any) {
    this.emailValidated = false;
    form.username = '';
    form.password = null;
    this.errorMsg = null;
  }
  public emailChanged(email: string): void {
    if ((!email) || (email.trim().length < 1)) {
      this.emailValidated = false;
      return;
    }
    this.emailValidated = false;
    this.authenticationService.userEmail(email).then((result: any) => {
      const uri = result.redirectUri;
      if (uri) {
        window.location = uri;
      } else {
        this.emailValidated = true;
      }
    });
  }

  /**
   * Login to the last navigated page
   * @param form
   */
  public login(form: any) {
    let tokenInfo: TokenInfo;
    const username = form.form.controls['username'].value;
    const password = (form.value) ? form.value.password : null;

    if (!this.emailValidated) {
      this.emailChanged(username);
      form.submitted = false;
      return;
    }
    this.spinnerService.start();

    if (form.invalid) {
      this.spinnerService.stop();
      return false;
    }

    const loginBody = {
      username: username,
      password: password
    };

    this.loading = true;
    this.errorMsg = '';
    this.authenticationService.login(loginBody)
      .then(
        (response) => {
          tokenInfo = response;
          this.userService.getLoggedUser()
            .pipe(finalize(() => this.spinnerService.stop()))
            .subscribe(
              (user: User) => {
                this.loading = false;
                this.authenticationService.setLoggedUser(user);
                this.authenticationService.setLang(user.attributes.language);
                this.eventsHubService.setLoggedIn(true);
                this.translateService.use(user.attributes.language || CONFIG.defaultLang);
                this.router.navigate(['/mapsManager/list']);
              },
              (error) => {
                this.errorMsg = error.message;
                this.loading = false;
              }
            );
        })
      .catch(response => {
        const errorCode = (response && response.error) ? response.error.code : CONFIG.errors.authErrorCode;
        const authCode = (response && response.error) ? response.error.authCode : CONFIG.errors.authErrorCode;
        this.errorMsg = this.translateService.instant(`API_ERRORS.${errorCode}`);
        this.loading = false;
        if (errorCode === CONFIG.errors.twoFactorAuthErrorCode) {
          this.twoFactorEnabled = true;
          const duoData = {
            username: username,
            password: password,
            authCode: authCode,
            twoFactor: undefined
          };
          this.eventsHubService.duoSecureError(duoData);
          this.router.navigate(['/two-factor']);
        } else if (errorCode === CONFIG.errors.passwordExpiredErrorCode) {
          const formData = {
            username: username,
            currentPassword: form.value.password
          };
          form.value.currentPassword = form.value.password;
          delete form.value.password;
          this.eventsHubService.passwordExpired(formData);
          this.router.navigate(['/password-expired']);
        }
        this.spinnerService.stop();
      });

  }

  private showInactivityModal() {
    Swal.fire(this.templateModalService.getInactivityOptions(this.translations['KEEP_ALIVE.SESSION_EXPIRED'],
      this.translations['KEEP_ALIVE.EXPIRED_MESSAGE']));
  }

  private translateAlertMsgs() {
    const messages = ['KEEP_ALIVE.SESSION_EXPIRED', 'KEEP_ALIVE.EXPIRED_MESSAGE'];
    this.translateService.get(messages)
      .subscribe(
        (translations) => {
          this.translations = translations;
        }
      );
  }

}
