export class Pagination {
  allowedRanges: Array<{ range: any }>;
  maxPaginationItems: number;
  directionLinks: boolean;
  boundaryLinks: boolean;
  ellipses: boolean;
  rotate: boolean;
  size: string;

  page: number;
  pagesize: number;
  sort: string;
  direction: string;

  constructor(pagination: any) {
    this.allowedRanges = pagination.allowedRanges;
    this.maxPaginationItems = pagination.maxPaginationItems;
    this.directionLinks = pagination.directionLinks;
    this.boundaryLinks = pagination.boundaryLinks;
    this.ellipses = pagination.ellipses;
    this.rotate = pagination.rotate;

    this.size = pagination.size;
    this.page = 1;
    this.pagesize = this.allowedRanges[0].range;
    this.sort = '';
    this.direction = 'ASC';
  }
}

