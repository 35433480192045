import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';


import { RmsListAbstract } from '../../../../core/components/rms-list.abstract';
import { User } from '../../../../shared/models';
import { EventsHubService } from '../../../../core/events-hub.service';
import { AuthorizationService } from '../../../../core/services/authorization.service';
import { TemplateModalService } from '../../../../core/services/template-modal.service';
import { UserService } from '../../../../core/data-services/user.service';
import { CONFIG } from '../../../../config';
import { BannerNotificationsService, SpinnerService } from '../../../../xform-compat';

@Component({
  selector: 'rms-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends RmsListAbstract<User> implements OnInit {

  public dateFormatConfig = CONFIG.shortDateFormat;
  public rolesList: Array<string>;

  constructor(
    userService: UserService,
    spinnerService: SpinnerService,
    bannerNotificationService: BannerNotificationsService,
    translateService: TranslateService,
    eventsHubService: EventsHubService,
    templateModalService: TemplateModalService,
    public authorizationService: AuthorizationService
  ) {
    super(eventsHubService, userService, spinnerService, translateService, bannerNotificationService, templateModalService);
  }

  getDefaultSort(): string {
    return 'firstName';
  }

  getRecordType(): string {
    return 'RmsUser';
  }

  ngOnInit() {
    super.translateAlertMsgs(this.getRecordType());
    super.ngOnInit();
    super.getRecords();
  }

  public recordAdded () {
    super.getRecords();
  }

}
