import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';


@Injectable()
export class LoggedGuard implements CanActivate, CanLoad {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  public canLoad(route: Route) {
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate(['/mapsManager/list']);
      return false;
    }
    return true;
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate(['/mapsManager/list']);
      return false;
    }
    return true;
  }
}
