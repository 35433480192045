export enum PERMISSIONS {
  // password expiration
  PasswordExpirationRead = 'ADMIN_PASSWORD_EXPIRATION_EVENT_READ',
  PasswordExpirationCreate = 'ADMIN_PASSWORD_EXPIRATION_EVENT_CREATE',
  PasswordExpirationUpdate = 'ADMIN_PASSWORD_EXPIRATION_EVENT_UPDATE',
  PasswordExpirationDelete = 'ADMIN_PASSWORD_EXPIRATION_EVENT_DELETE',
  PasswordExpirationActivate = 'ADMIN_PASSWORD_EXPIRATION_EVENT_DELETE',
  PasswordExpirationDeactivate = 'ADMIN_PASSWORD_EXPIRATION_EVENT_DELETE',
  // user groups
  UserGroupRead = 'ADMIN_USER_GROUP_READ',
  UserGroupCreate = 'ADMIN_USER_GROUP_CREATE',
  UserGroupUpdate = 'ADMIN_USER_GROUP_UPDATE',
  UserGroupDelete = 'ADMIN_USER_GROUP_DELETE',
  UserGroupActivate = 'ADMIN_USER_GROUP_DELETE',
  UserGroupDeactivate = 'ADMIN_USER_GROUP_DELETE',
  // users
  UserRead = 'ADMIN_USER_READ',
  UserCreate = 'ADMIN_USER_CREATE',
  UserUpdate = 'ADMIN_USER_EDIT',
  UserDelete = 'ADMIN_USER_DELETE',
  // tenants
  TenantRead = 'ADMIN_TENANT_READ',
  TenantCreate = 'ADMIN_TENANT_EDIT',
  TenantDelete = 'ADMIN_TENANT_DELETE',
  TenantUpdate = 'ADMIN_TENANT_EDIT',
  // Holiday
  HolidayRead = 'ADMIN_HOLIDAY_READ',
  HolidayCreate = 'ADMIN_HOLIDAY_CREATE',
  HolidayUpdate = 'ADMIN_HOLIDAY_UPDATE',
  HolidayDelete = 'ADMIN_HOLIDAY_DELETE',
  HolidayActivate = 'ADMIN_HOLIDAY_DELETE',
  HolidayDeactivate = 'ADMIN_HOLIDAY_DELETE',
  // Audit Reports
  ReadAuditReports = 'VIEW_MARKET_DATA_AUDIT_REPORT',
  ReadMarketData = 'VIEW_MARKET_DATA',

  USER_SETTINGS_EDIT = 'USER_SETTINGS_EDIT',

  EmapMedia = 'EMAP_MEDIA',
  EmapAdmin = 'EMAP_ADMIN',
  EmapVersionCreate = 'EMAP_VERSION_CREATE',
  EmapVersionDelete = 'EMAP_VERSION_DELETE',
  EmapVersionUpdateIndesign = 'EMAP_VERSION_UPDATE_INDESIGN',
}

export enum MAP_VIEW_STATE {
  Authoring = 'AUTHORING',
  Design = 'DESIGN',
  Deploy = 'DEPLOY'
}

export enum MAP_IMAGE_ACCESSOR {
    Pdf = 'PDF',
    Png = 'PNG',
    Svg = 'SVG',
    Nav = 'NAVIGATOR'
}

export enum ROUTE_PATHS {
    MapList = '/map/list',
    MapAuthoring = '/authoring/map-authoring/',
    PVC = '/authoring/pvc/',
    MVC = '/authoring/mvc/',
    CompanyRecon = '/recon/company/',
    ProductInstanceRecon = '/recon/product-instance/',
    ProductRecon = '/recon/product/',
    Finalization = '/authoring/finalization-report/',
}

export enum RECON_RESOURCE_STATE {
  AutoRecon = 'Auto Reconciled',
  Ignored = 'Ignored',
  ToBeRecon = 'Not Yet Reconciled',
  Synonym = 'Manually Reconciled - Synonym',
  Rename = 'Manually Reconciled - Rename',
  Create = 'New Record to be Created'
}

export enum NODES_MANAGER_CATEGORY {
    Products = 'Products',
    Milestones = 'Milestones',
}

export enum PRODUCT_CATEGORY {
    Modality = 'Modality',
    Target = 'Target',
    RoA = 'Route of Administration',
    Dosing = 'Dosing',
}

export enum MILESTONE_CATEGORY {
    Phase = 'Phase',
    TrialType = 'Trial Type',
    Event = 'Event',
    Date = 'Date',
    Indication = 'Indication',
    Note = 'Note',
}

export enum INDICATION_STATUS {
    ADDED = 'ADDED',
    EXCLUDED = 'EXCLUDED'
}
