import { Component, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractBannerNotifications, BannerNotificationsService } from '../../../xform-compat';


@Component({
  selector: 'xform-banner-notifications',
  templateUrl: './banner-notifications.component.html',
  styleUrls: ['./banner-notifications.component.scss']
})
export class BannerNotificationsComponent extends AbstractBannerNotifications {

  constructor(
    bannerNotificationsService: BannerNotificationsService,
    elementRef: ElementRef,
    translateService: TranslateService,
  ) {
    super(bannerNotificationsService, elementRef, translateService);
  }

}
