import { ChartItem } from '../chartItem.model';


export class CreateChartResource {
  mapId: number;
  createChartItemResourceList: Array<ChartItem>;

  constructor(createChartResource: CreateChartResource = {} as CreateChartResource) {
    this.mapId = createChartResource.mapId;
    this.createChartItemResourceList = createChartResource.createChartItemResourceList;
  }
}
