import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { LegendItem } from '../../shared/models/legendItem.model';
import { CreateLegendResource } from '../../shared/models/resources/createLegendResource.model';
import { catchError } from 'rxjs/operators';


@Injectable()
export class LegendItemService extends AbstractCRUD<LegendItem> implements CRUDService<LegendItem>, Searcher {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/core/service/legenditem';
  }

  public getLegendItem(httpParams?: HttpParams) {
    return this.getRequest(this.getLegendItemPath(), { params: httpParams });
  }

  private getLegendItemPath() {
    return super.getApiPath();
  }

  public postLegendItemData(createLegendResource: CreateLegendResource) {
    return this.postRequest(super.getApiPath(), createLegendResource);
  }

  private getRequest(apiUrl: string, options: object) {
    return this.getHTTP().get(apiUrl, options)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  private postRequest(apiUrl: string, body: object) {
    return this.getHTTP().post(apiUrl, body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public getDisplayName(item: any): string {
    return item.fullName;
  }
}
