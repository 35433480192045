import { Injectable } from '@angular/core';
import { AuthorizationServiceInterface } from './authorizationService.interface';
import { EventsHubService } from '../events-hub.service';
import { AuthenticationService } from './authentication.service';
import { ItemMenu, Tenant, User, UserTenant } from '../../shared/models';


@Injectable()
export class AuthorizationService implements AuthorizationServiceInterface {

  private currentUser: User;
  private allUserPermissions: Array<string>;
  private filteredMenu: Array<ItemMenu>;

  constructor(
    private eventsHubService: EventsHubService,
    private authenticationService: AuthenticationService
  ) {
    this.getCurrentUser();
    this.loggedInSubscription();
  }

  public isAdmin(): boolean {
    return this.currentUser.superUser;
  }

  public hasPermission(permissions: Array<string>): boolean {
    let isGranted: boolean;
    if (!permissions.length || this.isAdmin()) {
      isGranted = true;
    } else {
      isGranted = this.checkPermissionList(permissions);
    }
    return isGranted;
  }

  private checkPermissionList(requiredPermissions: Array<string>): boolean {
    let userPermissions: Array<string>;
    let hasAllPermissions = true;

    userPermissions = this.getAllUserPermissions();

    for (const permission of requiredPermissions) {
      if (!userPermissions.includes(permission)) {
        hasAllPermissions = false;
      }
    }

    return hasAllPermissions;
  }

  private getAllUserPermissions(): Array<string> {
    let userPermissions: Array<string> = [];

    if (!this.allUserPermissions) {

      for (const uTenant of this.currentUser.userTenants) {
        const tenantPermissions = this.getUserPermissionsForTenant(new Tenant({ id: uTenant.id }));
        userPermissions = userPermissions.concat(tenantPermissions);
      }
      this.allUserPermissions = Array.from(new Set(userPermissions)); // Remove Duplicates;
    }
    userPermissions = this.allUserPermissions;

    return userPermissions;
  }

  private getUserTenant(tenant: Tenant): UserTenant {
    let userTenant: UserTenant;

    for (const uTenant of this.currentUser.userTenants) {
      if (uTenant.id === tenant.id) {
        userTenant = new UserTenant(uTenant);
      }
    }

    return userTenant || new UserTenant();
  }

  private extractPermissionsFromUserTenant(userTenant: UserTenant): Array<string> {
    const userTenantPermissions: Array<string> = [];

    for (const role of userTenant.tenantRoles) {
      for (const permission of role.permissions) {
        userTenantPermissions.push(permission.code);
      }
    }

    return userTenantPermissions;
  }

  private getUserPermissionsForTenant(tenant: Tenant): Array<string> {
    const userTenant: UserTenant = this.getUserTenant(tenant);
    let permissions: Array<string>;

    permissions = userTenant ? this.extractPermissionsFromUserTenant(userTenant) : [];

    return permissions;
  }

  public filterMenu(menu: Array<ItemMenu>): Array<any> {
    let filteredMenu: Array<ItemMenu> = [];

    if (!this.filteredMenu) {
      for (const item of menu) {
        if (this.hasPermission(item.permissionsNeeded)) {
          filteredMenu.push(item);
        }
      }
      this.filteredMenu = filteredMenu;
    }

    filteredMenu = this.filteredMenu;
    return filteredMenu;
  }

  private loggedInSubscription() {
    this.eventsHubService.isLoggedIn$
      .subscribe(
        (loggedIn: boolean) => {
          if (loggedIn) {
            this.getCurrentUser();
          }
        }
      );
  }

  private getCurrentUser() {
    this.currentUser = this.authenticationService.getLoggedUser();
  }

}
