import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MapVersionNode } from '../../shared/models/mapVersionNode.model';
import { AbstractCRUD, CRUDService, ExceptionService, UtilsService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';


@Injectable()
export class MapVersionNodeService extends AbstractCRUD<MapVersionNode> implements CRUDService<MapVersionNode> {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/map/service';
  }

  public getAllMapVersionNodesPath() {
    return super.getApiPath().concat('/mapversions/all');
  }

  public getAllMapVersionNodes() {
    return this.getRequest(this.getAllMapVersionNodesPath());
  }

  public updateMapVersionNodesPath() {
    return super.getApiPath().concat('/mapversions/update');
  }

  public updateMapVersionNodes(mapVersionsList: Array<MapVersionNode>) {
    return this.updateRequest(this.updateMapVersionNodesPath(), mapVersionsList);
  }

  public createMapVersionNodePath() {
    return super.getApiPath().concat('/mapversions/create');
  }

  public createMapVersionNode(mapVersion: MapVersionNode) {
    return this.postRequest(this.createMapVersionNodePath(), mapVersion);
  }

  public deleteMapVersionNode(mapVersion: MapVersionNode) {
    const deleteMapVersionNodeByIdUrl = super.getApiPath().concat('/mapversions/').concat(String(mapVersion.id));
    return this.getHTTP().delete(deleteMapVersionNodeByIdUrl)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  private getRequest(apiUrl: string) {
    return this.getHTTP().get(apiUrl)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  private postRequest(apiUrl: string, body: object) {
    return this.getHTTP().post(apiUrl, body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  private updateRequest(apiUrl: string, body: any) {
    return this.getHTTP().put(apiUrl, body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }
}
