import { OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

export class DestroyerAbstract implements OnDestroy {

    private subscriptionsToDestroy: Subscription = new Subscription();

    public ngOnDestroy() {
        this.subscriptionsToDestroy.unsubscribe();
    }

    protected customSubscribe(subject: Subject<object>, callback: (value: object) => void): Subscription {
        const subscription = subject.subscribe(callback);
        this.subscriptionsToDestroy.add(subscription);
        return subscription;
    }
}
