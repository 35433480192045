import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { AuthoringIntegrationService } from '../../services/authoringIntegration.service';
import { GlobalIntegrationServices } from '../../services/globalIntegration.services';
import { DestroyerAbstract } from '../../../core/components/destroyer.abstract';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'emap-authoring-header',
  templateUrl: './authoring-header.component.html',
  styleUrls: ['./authoring-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthoringHeaderComponent extends DestroyerAbstract implements OnInit, OnDestroy {

  private AUTHORING_HEADER_BUTTON_CLASS = 'authoring-header-panel-icons';
  private AUTHORING_HEADER_ICON_ID_PREFIX = 'authoring-header-id-';

  private AUTHORING_HEADER_SAVE_BUTTON_CLASS = 'authoring-header-id-save';
  private AUTHORING_HEADER_HIERARCHY_BUTTON_CLASS = 'authoring-header-id-hierarchy';
  private AUTHORING_HEADER_EDIT_BUTTON_CLASS = 'authoring-header-id-edit';

  private AUTHORING_HEADER_BUTTON_ACTIVE_CLASS = 'authoring-header-panel-button-active';
  private AUTHORING_HEADER_BUTTON_DISABLED_CLASS = 'authoring-header-panel-button-disabled';

  private AUTHORING_HEADER_ZOOM_SLIDER_CLASS = 'authoring-header-zoom-slider';
  private AUTHORING_HEADER_RIGHT_SIDE_PANEL_SCROLL_CLASS = 'authoring-header-right-side-panel-scroll';
  private SLIDER_DISPLAY_NONE_CLASS = 'slider-display-none';

  private sliderOptions = {
    range: 'max',
    min: 0,
    max: 200,
    value: 0,
    step: 10,
  };

  private eventHandlersToGC: Array<string> = [];

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private authoringIntegrationService: AuthoringIntegrationService,
    private globalIntegrationServices: GlobalIntegrationServices,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.initRouteSubscription();
    this.initAuthoringHeaderIconClickHandler();
    this.initAuthoringHeaderIconClickEventRouter();
    this.initNodeLayerUnsavedChangesEventHandler();
    this.loadAuthoringHeaderState();
    this.initSlider();
  }

  private initRouteSubscription() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.panel === 'product-recon') {
        this.setHierarchyButtonEnabled(false);
        this.setEditButtonEnabled(false);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    for (const handlerSelector of this.eventHandlersToGC) {
      // TODO: only disable the handler we created
      this.jqueryCommonServices.selector(handlerSelector).off();
    }
  }

  private loadAuthoringHeaderState() {
    const hierarchyState = this.authoringIntegrationService.authoringHeaderModel.isHierarchyModeActive;
    const editState = this.authoringIntegrationService.authoringHeaderModel.isEditModeActive;
    this.setSaveButtonEnabled(this.authoringIntegrationService.nodeLayerModel.hasUnsavedChanges);
    this.setHeaderHierarchyButtonActive(hierarchyState);
    this.setHeaderEditButtonActive(editState);
  }

  private initAuthoringHeaderIconClickHandler() {
    this.eventHandlersToGC.push(this.AUTHORING_HEADER_BUTTON_CLASS.toClass());
    this.jqueryCommonServices.clickEvent(this.AUTHORING_HEADER_BUTTON_CLASS.toClass(), (event) => {
      if (!event.hasOwnProperty('target')) { return; }
      this.authoringIntegrationService.authoringHeaderIconClickEventHandler.next(event);
    });
  }

  private initAuthoringHeaderIconClickEventRouter() {
    // TODO: pass dynamic map ID
    this.customSubscribe(
      this.authoringIntegrationService.authoringHeaderIconClickEventHandler,
      value => {
        if (!value.hasOwnProperty('target')) { return; }
        const elementId = (value as any).target.id;
        const elementRouteName = elementId.replace(this.AUTHORING_HEADER_ICON_ID_PREFIX, '');
        if (!this.isButtonEnabled(elementId.toId())) { return; }
        switch (elementRouteName) {
          case 'save':
            this.authoringIntegrationService.authoringSaveIconClickEventHandler.next({ mapId: 1 });
            break;
          case 'hierarchy':
            this.handleHierarchyButtonClickEvent();
            this.authoringIntegrationService.authoringHierarchyIconClickEventHandler.next({ mapId: 1 });
            break;
          case 'edit':
            this.handleEditButtonClickEvent();
            this.authoringIntegrationService.authoringEditIconClickEventHandler.next({ mapId: 1 });
            break;
          case 'legend':
            this.authoringIntegrationService.authoringLegendIconClickEventHandler.next({ mapId: 1 });
            break;
          case 'chart':
            this.authoringIntegrationService.authoringChartIconClickEventHandler.next({ mapId: 1 });
            break;
          case 'zoom-scroll':
            this.authoringIntegrationService.authoringZoomScrollIconClickEventHandler.next({ mapId: 1 });
            break;
          case 'zoom-full':
            this.authoringIntegrationService.authoringZoomFullIconClickEventHandler.next({ mapId: 1 });
            break;
          case 'zoom-100':
            this.authoringIntegrationService.authoringZoom100IconClickEventHandler.next({ mapId: 1 });
            break;
        }
      }
    );
  }

  initNodeLayerUnsavedChangesEventHandler() {
    this.customSubscribe(
      this.authoringIntegrationService.nodeLayerUnsavedChangesEventHandler,
      () => this.setSaveButtonEnabled(this.authoringIntegrationService.nodeLayerModel.hasUnsavedChanges)
    );
  }

  handleHierarchyButtonClickEvent() {
    this.setHeaderHierarchyButtonActive(!this.authoringIntegrationService.authoringHeaderModel.isHierarchyModeActive);
    this.setHeaderEditButtonActive(false);
    this.emitToolboxVisibleEvent(false);
  }

  handleEditButtonClickEvent() {
    const state = !this.authoringIntegrationService.authoringHeaderModel.isEditModeActive;
    this.setHeaderEditButtonActive(state);
    this.emitToolboxVisibleEvent(state);
    this.setHeaderHierarchyButtonActive(false);
  }

  setSaveButtonEnabled(state: boolean) {
    // TODO: move save button state to authoringHeaderModel for consistency?
    this.setButtonEnabled(state, this.AUTHORING_HEADER_SAVE_BUTTON_CLASS.toId());
  }

  setHierarchyButtonEnabled(state: boolean) {
    this.setButtonEnabled(state, this.AUTHORING_HEADER_HIERARCHY_BUTTON_CLASS.toId());
  }

  setEditButtonEnabled(state: boolean) {
    this.setButtonEnabled(state, this.AUTHORING_HEADER_EDIT_BUTTON_CLASS.toId());
  }

  setHeaderHierarchyButtonActive(state: boolean) {
    this.authoringIntegrationService.authoringHeaderModel.isHierarchyModeActive = state;
    const hierarchyButtonSelector = this.jqueryCommonServices.selector(this.AUTHORING_HEADER_HIERARCHY_BUTTON_CLASS.toId());
    hierarchyButtonSelector.toggleClass(this.AUTHORING_HEADER_BUTTON_ACTIVE_CLASS, state);
  }

  setHeaderEditButtonActive(state: boolean) {
    this.authoringIntegrationService.authoringHeaderModel.isEditModeActive = state;
    const editButtonSelector = this.jqueryCommonServices.selector(this.AUTHORING_HEADER_EDIT_BUTTON_CLASS.toId());
    editButtonSelector.toggleClass(this.AUTHORING_HEADER_BUTTON_ACTIVE_CLASS, state);
  }

  emitToolboxVisibleEvent(state: boolean) {
    this.authoringIntegrationService.authoringToolboxModel.isVisible = state;
    this.authoringIntegrationService.authoringToolboxVisibilityEventHandler.next();
  }

  isButtonEnabled(elemId: string) {
    const buttonSelector = this.jqueryCommonServices.selector(elemId);
    return !buttonSelector.hasClass(this.AUTHORING_HEADER_BUTTON_DISABLED_CLASS);
  }

  setButtonEnabled(state: boolean, elemId: string) {
    const buttonSelector = this.jqueryCommonServices.selector(elemId);
    buttonSelector.toggleClass(this.AUTHORING_HEADER_BUTTON_DISABLED_CLASS, !state);
  }

  private initSlider() {
    this.bindSliderToZpan();
    this.jqueryCommonServices.slider(this.AUTHORING_HEADER_ZOOM_SLIDER_CLASS.toId(), this.sliderOptions);
    this.toggleSlider();
    this.sliderValueUpdateEvent();
  }

  private bindSliderToZpan() {
    this.sliderOptions['slide'] = (event, ui) => {
      this.globalIntegrationServices.currentZpanCommonService.zoomToVal(ui.value);
    };
  }

  private toggleSlider() {
    this.authoringIntegrationService.authoringZoomScrollIconClickEventHandler.subscribe(value => {
      const sliderParentSelector = this.jqueryCommonServices.selector(this.AUTHORING_HEADER_RIGHT_SIDE_PANEL_SCROLL_CLASS.toClass());
      sliderParentSelector.toggleClass(this.SLIDER_DISPLAY_NONE_CLASS);
    });
  }

  private sliderValueUpdateEvent() {
    const sliderSelector = this.jqueryCommonServices.selector(this.AUTHORING_HEADER_ZOOM_SLIDER_CLASS.toId());
    this.globalIntegrationServices.zoomCompleteEventEmitter.subscribe(value => {
      sliderSelector.slider('value', (value as any).zoom);
    });
  }
}
