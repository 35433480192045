import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractCRUD, CRUDService, ExceptionService, Searcher, UtilsService } from '../../xform-compat';
import { ChartItem } from '../../shared/models/chartItem.model';
import { CreateChartResource } from '../../shared/models/resources/createChartResource.model';
import { catchError } from 'rxjs/operators';


@Injectable()
export class ChartItemService extends AbstractCRUD<ChartItem> implements CRUDService<ChartItem>, Searcher {

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/core/service/chartitem';
  }

  public getChartItem(httpParams?: HttpParams) {
    return this.getRequest(this.getChartItemPath(), { params: httpParams });
  }

  public postChartItemData(createChartResource: CreateChartResource) {
    return this.postRequest(super.getApiPath(), createChartResource);
  }

  private getChartItemPath() {
    return super.getApiPath();
  }

  private getRequest(apiUrl: string, options: object) {
    return this.getHTTP().get(apiUrl, options)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  private postRequest(apiUrl: string, body: object) {
    return this.getHTTP().post(apiUrl, body)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public getDisplayName(item: any): string {
    return item.fullName;
  }
}
