import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';

@Component({
    selector: 'emap-navigation-modal',
    templateUrl: './navigation-modal.component.html',
    styleUrls: ['./navigation-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavigationModalComponent implements OnInit {
    navigationSubscription$: Subject<boolean>;
    saveMindMapSubscription$: Subject<boolean>;

    constructor(
        public activeModal: NgbActiveModal
    ) {
    }

    ngOnInit() {
    }

    onDismiss() {
        this.activeModal.dismiss(false);
        this.navigationSubscription$.next(false);
        this.navigationSubscription$.complete();
    }

    onConfirmation(isSave: boolean) {
        if (this.saveMindMapSubscription$) {
            this.saveMindMapSubscription$.next(isSave);
        }
        this.activeModal.close();
        this.navigationSubscription$.next(true);
        this.navigationSubscription$.complete();
    }
}
