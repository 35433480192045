import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractCRUD, CRUDService, ExceptionService, PaginatedList, Searcher, UtilsService } from '../../xform-compat';
import { CONFIG } from '../../config';
import { RmsUser, User, UserMetadata } from '../../shared/models';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractCRUD<User> implements CRUDService<User>, Searcher {

  private metadataPath = CONFIG.metadataPath;

  constructor(
    exceptionService: ExceptionService,
    http: HttpClient,
    utilsService: UtilsService
  ) {
    super(exceptionService, http, utilsService);
  }

  public getResourcePath() {
    return '/rms/users';
  }

  public getDisplayName(item: any): string {
    return item.fullName;
  }

  public getLoggedUser() {
    return super.getHTTP().get<User>(`${super.getApiPath()}/me`);
  }

  public getMetadata() {
    return super.getHTTP().get<UserMetadata>(`${super.getApiPath()}${this.metadataPath}`)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  public activateUser(user: User) {
    return this.request(`${super.getApiPath()}/${user.id}/activate`);
  }

  public deactivateUser(user: User) {
    return this.request(`${super.getApiPath()}/${user.id}/deactivate`);
  }

  public resendActivation(user: User) {
    return this.request(`${super.getApiPath()}/${user.id}/confirm`);
  }

  private request(apiUrl: string) {
    return this.getHTTP().post(apiUrl, null)
      .pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

  /**
   * Autocomplete search for users
   * @param {string} term
   * @returns {Observable<PaginatedList<RmsUser>>}
   */
  public autocompleteUsers(term: string): Observable<PaginatedList<RmsUser>> {
    return super.getHTTP().get(`/api/rms/autocomplete/users?q=${term}`).pipe(catchError(ex => super.getException().catchBadResponse(ex)));
  }

}
