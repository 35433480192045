import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CONFIG } from '../../config';
import { ExceptionService } from '../../xform-compat';
import { catchError } from 'rxjs/operators';

@Injectable()
export class DuoSecurityService {

  constructor(
    private http: HttpClient,
    private exceptionService: ExceptionService
  ) { }

  public getDuoHostAndHash (loginInfo) {
    const url = `${CONFIG.apiUrl}/uaa/authorization/2fa/apps/duo?username=${loginInfo.username}&code=${loginInfo.authCode}`;
    return this.http.get(url)
      .pipe(catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }
}
