
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CONFIG } from '../../config';
import { UtilsService } from '../../xform-compat';


@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private emailCheckPath = `${CONFIG.apiUrl}/uaa/oidc/signin-redirect`;
  private tokenPath = `${CONFIG.apiUrl}/uaa/oauth/token`;
  private checkTokenPath = `${CONFIG.apiUrl}/uaa/oauth/check_token`;
  private logoutPath = `${CONFIG.apiUrl}/uaa/authorization/logout`;
  private authInfo = CONFIG.oAuth.regular;
  private authInfoTwoFactor = CONFIG.oAuth.twoFactor;
  private refreshTokenInfo = CONFIG.oAuth.refreshToken;
  private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
  ) {}

  public getLoginRedirectUrl(email: string) {
    const httpParams: HttpParams = this.utilsService.generateParams({
      username: email,
      appName: 'emaps',
    });
    return this.http.get(this.emailCheckPath, {params: httpParams}).pipe(catchError(ex => {throw ex; }));
  }

  public login(credentials) {
    let data = new HttpParams()
      .set('client_id', this.authInfo.clientId)
      .set('client_secret', this.authInfo.clientSecret)
      .set('username', credentials.username)
      .set('password', credentials.password);

    if (!credentials.twoFactor) {
      data = data.set('grant_type', this.authInfo.grantType);
    } else {
      data = data.set('grant_type', this.authInfoTwoFactor.grantType)
        .set('app_name', `${CONFIG.oAuth.twoFactor.appName}`)
        .set('secret_code', credentials.secretCode);
    }

    return this.http.post(this.tokenPath, data, { headers: this.headers }).pipe(
      catchError(ex => { throw ex; }));
  }

  /**
   * Service method to refresh user session info
   * @param {string} refreshToken: current logged user info
   * @returns {Observable<Object>}: Response from the request
   */
  public refreshToken (refreshToken: string) {
    const data = new HttpParams()
      .set('grant_type', this.refreshTokenInfo.grantType)
      .set('client_id', this.authInfo.clientId)
      .set('client_secret', this.authInfo.clientSecret)
      .set('refresh_token', refreshToken);

    return this.http.post(this.tokenPath, data);
  }

  public logout() {
    return this.http.post(this.logoutPath, {}).pipe(
      catchError(ex => { throw ex; }));
  }

  public validateToken(token) {
    const data = new HttpParams()
      .set('token', token);

    return this.http.post(this.checkTokenPath, data, { headers: this.headers }).pipe(
      catchError(ex => { throw ex; }));
  }
}
