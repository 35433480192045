export class TokenInfo {
  accessToken: string;
  tokenType: string;
  refreshToken: string;
  expiresIn: number;
  scope: string;
  userId: number;

  constructor(oAuthToken: any) {
    this.accessToken = oAuthToken.access_token;
    this.tokenType = oAuthToken.token_type;
    this.refreshToken = oAuthToken.refresh_token;
    this.expiresIn = oAuthToken.expires_in;
    this.scope = oAuthToken.scope;
    this.userId = oAuthToken.user_id;

  }
}

