import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BannerNotification } from './banner-notification.model';


@Injectable()
export class BannerNotificationsService {

  public notification$: Subject<BannerNotification> = new Subject();

  constructor() {}

  protected show(type: string, text: string, link?: string, timeout?: number) {
    let notification: BannerNotification;
    notification = new BannerNotification(true, type, text, link, timeout);
    this.notification$.next(notification);
  }

  public hide() {
    this.notification$.next(new BannerNotification(false));
  }

  public success(text: string, link?: string, timeout?: number) {
    const type = 'success';
    this.show(type, text, link, timeout);
  }

  public warning(text: string, link?: string, timeout?: number) {
    const type = 'warning';
    this.show(type, text, link, timeout);
  }

  public error(text: string, link?: string, timeout?: number) {
    const type = 'error';
    this.show(type, text, link, timeout);
  }

  public info(text: string, link?: string, timeout?: number) {
    const type = 'info';
    this.show(type, text, link, timeout);
  }

}
