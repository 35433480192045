
import { Injectable } from '@angular/core';
import { jsPlumb } from 'jsplumb';

@Injectable()
export class JsPlumbCommonService {

    private _isReady = false;
    private jsPlumbInstance: any;

    private commonOverlay: any;
    private baseJsPlumbParameters: any;
    private sourceJsPlumbParameters: any;
    private targetJsPlumbParameters: any;

    constructor(
    ) { }

    private initJsPlumbDefaults() {
        this.jsPlumbInstance.importDefaults({
            Connector : [ 'StateMachine', { margin: 5, curviness: 10, proximityLimit: 80 } ],
            Anchor: 'Continuous',
            ConnectionsDetachable: false,
        });
        this.commonOverlay = [
            ['Arrow', { location: 0.50, width: 40, length: 40, cssClass: 'node-layer-connection-arrow' }],
        ];
        this.baseJsPlumbParameters = {
            overlays: this.commonOverlay,
            connectorOverlays: this.commonOverlay,
            endpoint: ['Rectangle', { width: 5, height: 5 }],
            cssClass: 'node-layer-connection',
            connectorClass: 'node-layer-connection-dragging',
        };
        this.sourceJsPlumbParameters = {...this.baseJsPlumbParameters};
        this.targetJsPlumbParameters = {...this.baseJsPlumbParameters};
        this.targetJsPlumbParameters['maxConnections'] = 1;
    }

    public initialize(callback?: Function) {
        const that = this;
        this.jsPlumbInstance = jsPlumb.getInstance();
        this.jsPlumbInstance.ready(function() {
            that.initJsPlumbDefaults();
            that._isReady = true;
            callback();
        });
    }

    public isReady(): boolean {
        return this._isReady;
    }

    public setZoom(ratio: number) {
        this.jsPlumbInstance.setZoom(ratio);
    }

    public repaint() {
        this.jsPlumbInstance.repaintEverything();
    }

    public revalidate(elems: any) {
        this.jsPlumbInstance.revalidate(elems);
    }

    public reset() {
        // TODO: determine if jsPlumb.reset() breaks setZoom functionality...
        // this.jsPlumbInstance.reset();
        this.jsPlumbInstance.deleteEveryConnection();
        this.jsPlumbInstance.deleteEveryEndpoint();
        this.jsPlumbInstance.unbind();
    }

    public onConnect(callback: Function) {
        this.jsPlumbInstance.bind('connection', callback);
    }

    public onConnectBeforeDrop(callback: Function) {
        this.jsPlumbInstance.bind('beforeDrop', callback);
    }

    public onConnectionDrag(callback: Function) {
        this.jsPlumbInstance.bind('connectionDrag', callback);
    }

    public onConnectionDragStop(callback: Function) {
        this.jsPlumbInstance.bind('connectionDragStop', callback);
    }

    public onConnectionDragAbort(callback: Function) {
        this.jsPlumbInstance.bind('connectionDragAborted', callback);
    }

    public enableConnections(elems: any) {
        if (!elems.length) { return; }  // makeSource() fails with empty jQuery results

        this.disableConnections();  // Prevents duplicate sources from single nodes
        this.jsPlumbInstance.makeSource(elems, this.sourceJsPlumbParameters);
        this.jsPlumbInstance.makeTarget(elems, this.targetJsPlumbParameters);
    }

    public disableConnections() {
        this.jsPlumbInstance.unmakeEverySource();
        this.jsPlumbInstance.unmakeEveryTarget();
    }

    public showConnections(elems: any) {
        const that = this;
        elems.each(
            (function (index, elem) {
                that.jsPlumbInstance.show(elem, true);
            })
        );
    }

    public hideConnections(elems: any) {
        const that = this;
        elems.each(
            (function (index, elem) {
                that.jsPlumbInstance.hide(elem, true);
            })
        );
    }

    public createConnection(sourceElem: any, targetElem: any) {
        const parameters = {...this.baseJsPlumbParameters};
        parameters['source'] = sourceElem;
        parameters['target'] = targetElem;
        this.jsPlumbInstance.connect(parameters);
    }

    public deleteConnection(conn: any) {
        this.jsPlumbInstance.deleteConnection(conn);
    }

    public deleteConnectionsForElement(elem: any) {
        this.jsPlumbInstance.deleteConnectionsForElement(elem);
    }
}
