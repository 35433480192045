import {of as observableOf,  Observable } from 'rxjs';
import {catchError, tap, switchMap, debounceTime} from 'rxjs/operators';
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Searcher } from './searcher.interface';


@Component({
  selector: 'xform-type-ahead',
  templateUrl: './type-ahead.component.html',
  styleUrls: ['./type-ahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeAheadComponent),
      multi: true
    }
  ]
})
export class TypeAheadComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() hideLoadingIcon: boolean;
  @Input() service: Searcher;
  @Input() filters: Function;
  @Input() reset: number;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  public selectedItem: any;
  public itemsNotFound: boolean;
  public searching: boolean;

  constructor() { }

  ngOnInit() {
    this.disabled = (this.disabled);
    this.hideLoadingIcon = (this.hideLoadingIcon);
    this.placeholder = (this.placeholder) ? this.placeholder : '';
    this.searching = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reset) {
      this.selectedItem = null;
    }
  }

  /* Control Value Accessor Methods */

  public writeValue(value: any) {
    if (value !== undefined) {
      this.selectedItem = value;
    }
  }

  public registerOnChange(fn) {
    this.propagateChange = fn;
  }

  public propagateChange = (value) => { };

  public registerOnTouched() {}


  public search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(300),
      switchMap(
        (term) => {
          this.searching = true;
          const params = this.getFilters(term);
          return this.service.getRecords(params, true);
        }
      ),
      tap(
        (result: any) => {
          this.itemsNotFound = !(result.length);
          this.searching = false;
        }
      ),
      catchError(
        () => {
          return observableOf([]);
        }
      ));
  }

  private defaultFilter(term: string): object {
    return { name: term };
  }

  public getFilters(term): object {
    return (this.filters) ? this.filters(term) : this.defaultFilter(term);
  }

  public onItemSelected(item: any) {
    this.itemSelected.emit(item);
    this.propagateChange(item.id);
  }

  public displayFormatter = (item: any): string => {
    return this.service.getDisplayName(item);
  }

  public onFieldClicked(event) {
    event.stopPropagation();
    this.onClick.emit(event);
  }

  public onFocus(e: Event): void {
    e.stopPropagation();
    this.searching = true;
    const inputEvent: Event = new Event('input');
    e.target.dispatchEvent(inputEvent);
  }

}
