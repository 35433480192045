<div class="authoring-legend-parent">
    <div class="authoring-legend-child">
        <div id="authoring-legend-dialog" class="authoring-legend-dialog-display-none">
            <div class="authoring-legend-dialog-new-section">
                <div class="authoring-legend-dialog-new-section-text">Add New Section</div>
            </div>
            <div class="authoring-legend-dialog-accordion-main global-panel-results">
            </div>
            <div class="global-panel-results">
                <div class="authoring-legend-dialog-accordion-main global-panel-results-table">
                    <div class="authoring-legend-dialog-accordion-panel"></div>
                </div>
            </div>
            <div class="authoring-legend-dialog-accordion-form-button">
                <div class="authoring-legend-dialog-accordion-save authoring-legend-dialog-accordion-button center">Save</div>
                <div class="authoring-legend-dialog-accordion-cancel authoring-legend-dialog-accordion-button center">Cancel</div>
            </div>
        </div>
        <div id="authoring-legend-upload-dialog" class="authoring-legend-dialog-display-none">
            <div class="authoring-legend-upload-dialog-filter-image">
                <input id="authoring-legend-upload-dialog-filter-image-input" placeholder="Filter Image Selector" type="text" class="form-control"/>
                <button id="authoring-legend-upload-dialog-filter-button" type="button" class="btn btn-primary btn-xs" (click)="filterImageDropDownImages()">
                    <span class="glyphicon glyphicon-upload"></span> Filter
                </button>
            </div>
            <div id="authoring-legend-upload-dialog-select-parent"></div>
            <div class="authoring-legend-upload-dialog-accordion-form-button">
                <div class="authoring-legend-upload-dialog-accordion-update authoring-legend-upload-dialog-accordion-button center">Update</div>
                <div class="authoring-legend-upload-dialog-accordion-cancel authoring-legend-upload-dialog-accordion-button center">Cancel</div>
            </div>
        </div>
    </div>
</div>