import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { JqueryCommonServices } from '../../services/jqueryCommon.services';
import { environment } from '../../../../environments/environment';
import { GlobalIntegrationServices } from '../../services/globalIntegration.services';
import { MAP_IMAGE_ACCESSOR, MAP_VIEW_STATE } from '../../enums';
import { ToolboxAllPanelServices } from '../../services/toolboxAllPanel.services';
import { CONFIG } from '../../../config';


@Component({
  selector: 'emap-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigatorComponent implements OnInit {
  private NAVIGATOR_CLASS = 'navigator';
  private NAVIGATOR_INNER_CLASS = 'navInner';
  private AUTHORING_HEADER_CHILD_CLASS = 'authoring-header-child';
  private s3Url = CONFIG.aws.s3[environment.env];
  private navigatorSelector;

  constructor(
    private jqueryCommonServices: JqueryCommonServices,
    private globalIntegrationServices: GlobalIntegrationServices,
    private toolboxAllPanelServices: ToolboxAllPanelServices
  ) { }

  ngOnInit() {
    this.initMapImageIndexLoadSubscription();
    this.toolboxSwapButtonClickHandler();
  }

  private initMapImageIndexLoadSubscription() {
    this.globalIntegrationServices.mapImageIndexLoadEventEmitter.subscribe(value => this.initZnavService());
  }

  private initZnavService() {
    const imageSrc = this.globalIntegrationServices.getMapVersionImageSource(this.globalIntegrationServices.currentMapVersionId, MAP_IMAGE_ACCESSOR.Nav);
    this.navigatorSelector = this.jqueryCommonServices.selector(this.NAVIGATOR_CLASS.toId());
    this.jqueryCommonServices.imageLoaderEvent(this.NAVIGATOR_INNER_CLASS.toId(), imageSrc, this.globalIntegrationServices.mapImageClassName,
      (event) => {
      this.resetNavigatorX();
      this.resetNavigatorY();
      this.navigatorResizeHandler();
      this.navigatorSelector.znav({
        containment: this.NAVIGATOR_CLASS.toId(),
        dragstart: this.navigatorDragStartEvent.bind(this),
        dragstop: this.navigatorDragStopEvent.bind(this),
        drag: this.navigatorDragEvent.bind(this),
        click: this.navigatorClickEvent.bind(this),
      });
      this.realignNavigator();
    });
  }

  private resetNavigatorY() {
    this.navigatorSelector.css({
      'top': window.innerHeight - this.navigatorSelector.innerHeight(),
    });
  }

  private resetNavigatorX() {
    const navigatorSelector = this.jqueryCommonServices.selector(this.NAVIGATOR_CLASS.toId());
    const globalOpen = this.toolboxAllPanelServices.globalPanelOpenState;
    const filterOpen = this.toolboxAllPanelServices.filterPanelOpenState;

    const panelWidthOffset = ((globalOpen || filterOpen) ? this.toolboxAllPanelServices.toolboxWidth : 0) +
      (globalOpen ? this.toolboxAllPanelServices.globalPanelWidth : 0) +
      (filterOpen ? this.toolboxAllPanelServices.filterPanelWidth - 40 : 0);  // 40px = filter-panel-close width

    if (!this.toolboxAllPanelServices.swapState) {
      navigatorSelector.css({ 'left': panelWidthOffset });
    } else {
      navigatorSelector.css({ 'left': window.innerWidth - navigatorSelector.innerWidth() - panelWidthOffset });
    }
  }

  private navigatorResizeHandler() {
    this.jqueryCommonServices.selector(window).resize(() => {
      this.resetNavigatorX();
      this.resetNavigatorY();
    });
  }

  private toolboxSwapButtonClickHandler() {
    this.toolboxAllPanelServices.swapButtonClickEventEmitter.subscribe(() => {
      this.resetNavigatorX();
    });
    this.toolboxAllPanelServices.toolboxButtonClickEventEmitter.subscribe(() => {
      this.resetNavigatorX();
    });
    this.toolboxAllPanelServices.filterButtonClickEventEmitter.subscribe(() => {
      this.resetNavigatorX();
    });
  }

  private navigatorClickEvent(event, mouseEvent) {
    const dragBoxWidth = this.getDragBoxWidth(this.globalIntegrationServices.ratio);
    const dragBoxHeight = this.getDragBoxHeight(this.globalIntegrationServices.ratio);

    const wHalf = dragBoxWidth / 2;
    const hHalf = dragBoxHeight / 2;
    let centerX = mouseEvent.x - wHalf;
    let centerY = mouseEvent.y - hHalf;
    const navWidth = this.navigatorSelector.innerWidth();
    const navHeight = this.navigatorSelector.innerHeight();

    if (centerX < 0) {
      centerX = 0;
    } else if (centerX + dragBoxWidth > navWidth) {
      centerX = navWidth - dragBoxWidth;
    }

    if (centerY < 0) {
      centerY = 0;
    } else if (centerY + dragBoxHeight > navHeight) {
      centerY = navHeight - dragBoxHeight;
    }

    if (this.navigatorSelector.znav('instance')) {
      this.navigatorSelector.znav('option', 'location', {left: centerX, top: centerY});
    }

    this.viewMapByPosition(centerX, centerY, false);
  }

  private navigatorDragStartEvent() {

  }

  private navigatorDragEvent(event, ui) {
    this.viewMapByPosition(ui.position.left, ui.position.top);
  }

  private navigatorDragStopEvent() {

  }

  private realignNavigator() {
    this.globalIntegrationServices.zoomCompleteEventEmitter.subscribe((value => {
      if (this.navigatorSelector.znav('instance')) {
        const dragBoxWidth = this.getDragBoxWidth(false);
        const dragBoxHeight = this.getDragBoxHeight(false);
        const left = dragBoxWidth === this.navigatorSelector.innerWidth() ? 0 : this.getDragBoxLeft(value);
        const top = this.getDragBoxTop(value);
        this.navigatorSelector.znav('option', 'dimensions', { width: dragBoxWidth, height: dragBoxHeight });
        this.navigatorSelector.znav('option', 'location', {left: left, top: top});
      }
    }));
  }

  private getDragBoxTop(obj: any) {
    let normY;
    if (obj) {
      normY = obj.normY;
    } else {
      normY = this.globalIntegrationServices.zoomData.normY;
    }
    const scrollTop = this.globalIntegrationServices.currentMapImageSelector.offsetParent().scrollTop();
    if (!isNaN(scrollTop)) {
      normY -= scrollTop / this.globalIntegrationServices.ratio;
    }
    return (-normY + this.globalIntegrationServices.getMarginTopUnscaled()) * this.getNavMapFracHeight(false);
  }

  private getDragBoxLeft(obj: any) {
    let normX;
    if (obj) {
      normX = obj.normX;
    } else {
      normX = this.globalIntegrationServices.zoomData.normX;
    }
    const scrollLeft = this.globalIntegrationServices.currentMapImageSelector.offsetParent().scrollLeft();
    if (!isNaN(scrollLeft)) {
      normX -= (scrollLeft / this.globalIntegrationServices.ratio);
    }
    return (-normX + this.globalIntegrationServices.getMarginLeftUnscaled()) * this.getNavMapFracWidth(false);
  }

  private getDragBoxHeight(margins) {
    const mapHeight = this.globalIntegrationServices.currentZpanCommonService.getMapHeight(margins);
    const mapHeightScaled = mapHeight * this.globalIntegrationServices.ratio;
    let dragBoxHeight = this.navigatorSelector.innerHeight() * window.innerHeight / mapHeightScaled;
    const navigatorHeight = this.navigatorSelector.innerHeight();
    dragBoxHeight = dragBoxHeight > navigatorHeight ? navigatorHeight : dragBoxHeight;
    return dragBoxHeight;
  }

  private getDragBoxWidth(margins) {
    const mapWidth = this.globalIntegrationServices.currentZpanCommonService.getMapWidth(margins);
    const mapWidthScaled = mapWidth * this.globalIntegrationServices.ratio;
    let dragBoxWidth = this.navigatorSelector.innerWidth() * window.innerWidth / mapWidthScaled;
    const navigatorWidth = this.navigatorSelector.innerWidth();
    dragBoxWidth = dragBoxWidth > navigatorWidth ? navigatorWidth : dragBoxWidth;
    return dragBoxWidth;
  }

  private getNavMapFracWidth(margins) {
    let miniNavWidth;
    if (margins) {
      miniNavWidth = this.navigatorSelector.innerWidth();
    } else {
      miniNavWidth = this.jqueryCommonServices.selector(this.NAVIGATOR_INNER_CLASS.toId()).innerWidth();
    }
    const mapWidth = this.globalIntegrationServices.currentZpanCommonService.getMapWidth(margins);
    return(miniNavWidth / mapWidth);

  }

  private getNavMapFracHeight(margins) {
    return this.getNavMapFracWidth(margins);
  }

  private getMapNavFracWidth() {
    const miniNavWidth = this.navigatorSelector.innerWidth();
    const mapWidth = this.globalIntegrationServices.currentZpanCommonService.getMapWidth(true);
    return (mapWidth / miniNavWidth);
  }

  private getMapNavFracHeight() {
    return this.getMapNavFracWidth();
  }

  private getDragBoxLeft2(obj, navMapFracWidth) {
    let normX = obj.normX;
    const scrollLeft = this.globalIntegrationServices.currentMapImageSelector.offsetParent().scrollLeft();
    if (!isNaN(scrollLeft)) {
      normX -= (scrollLeft / this.globalIntegrationServices.ratio);
    }
    return (-normX + this.globalIntegrationServices.getMarginLeftUnscaled()) * navMapFracWidth;

  }

  private getDragBoxTop2(obj, navMapFracHeight) {
    let normY = obj.normY;
    const scrollTop = this.globalIntegrationServices.currentMapImageSelector.offsetParent().scrollTop();
    if (!isNaN(scrollTop)) {
      normY -= scrollTop / this.globalIntegrationServices.ratio;
    }
    return (-normY + this.globalIntegrationServices.getMarginTopUnscaled()) * navMapFracHeight;
  }

  private getNormX(dragBoxLeft, navMapFracWidth) {
    return (-dragBoxLeft) * navMapFracWidth + this.globalIntegrationServices.getMarginLeftUnscaled();
  }

  private getNormY(dragBoxTop, navMapFracHeight) {
    return (-dragBoxTop) * navMapFracHeight + this.globalIntegrationServices.getMarginTopUnscaled();
  }

  private viewMapByPosition(leftVal: any, topVal: any, disableAnimation?: boolean) {
    let left = this.getNormX(leftVal, this.getMapNavFracWidth());
    let top = this.getNormY(topVal, this.getMapNavFracHeight());
    const scrollLeft = this.globalIntegrationServices.currentMapImageSelector.offsetParent().scrollLeft();
    left += scrollLeft / this.globalIntegrationServices.ratio;
    const scrollTop = this.globalIntegrationServices.currentMapImageSelector.offsetParent().scrollTop();
    top += scrollTop / this.globalIntegrationServices.ratio;
    this.globalIntegrationServices.currentZpanCommonService.setNorm(left, top, disableAnimation);
  }
}
